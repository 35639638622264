<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';

const emits = defineEmits(['onClick']);
</script>

<template>
  <SoonaButton
    element="a"
    href="/users/password/new"
    type="button"
    variation="tertiary"
    @on-click="() => emits('onClick')"
  >
    forgot password
  </SoonaButton>
</template>
