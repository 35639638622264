<script setup>
import { ref } from 'vue';
import PrimaryNav from './PrimaryNav.vue';
import UserNav from './UserNav.vue';

const emit = defineEmits(['close']);

const userNavOpen = ref(false);

function navClickAutoClose(e) {
  // auto-close menu when clicking on any link
  if (e.target.tagName === 'A') {
    emit('close');
    window.scrollTo(0, 0);
  }
}
</script>

<template>
  <div class="sidebar-wrap" @click="navClickAutoClose">
    <TransitionGroup name="user-menu">
      <PrimaryNav
        v-if="!userNavOpen"
        key="primary"
        class="platform-side-nav"
        @open-user-nav="userNavOpen = true"
      />
      <UserNav
        v-else
        key="user"
        class="platform-user-nav"
        @close="userNavOpen = false"
      />
    </TransitionGroup>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

/* wrap to allow primary and user navs to be absolutely positioned for transitions */
.sidebar-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  background-image: linear-gradient(0deg, #fafafa 94.42%, #fff 99.3%);
}

.platform-side-nav {
  position: absolute;
}

.platform-user-nav {
  position: absolute;
  opacity: 1;
}

.user-menu-enter-from,
.user-menu-leave-to {
  &.platform-side-nav {
    transform: translateX(-100%);
  }
  &.platform-user-nav {
    opacity: 0;
    transform: translateX(100%);
  }
}

.user-menu-enter-active {
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-in;
}
.user-menu-leave-active {
  transition:
    transform 0.2s ease-in-out,
    opacity 0.2s ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .user-menu-enter-active,
  .user-menu-leave-active {
    transition: none;
  }
}
</style>
