<script setup>
import { computed, toRefs, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRouter, useRoute } from 'vue-router';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { useMe } from '@/composables/user/useMe';
import { usePriorityError } from '@/composables/usePriorityError';
import { useStorageUsage } from '@/queries/account/useStorageUsage';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUploader } from '@/composables/useUploader';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaProgress from '@/components/ui_library/SoonaProgress.vue';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import UploadProgressDialog from '@/components/media-editor/UploadProgressDialog.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  albumId: {
    default: null,
    type: [Number, String],
  },
  hideCloudIcon: {
    default: false,
    type: Boolean,
  },
  label: {
    required: true,
    type: String,
  },
  labelIcon: {
    default: null,
    type: String,
  },
  subContext: {
    required: true,
    type: String,
  },
});

const { accountId, albumId } = toRefs(props);

// use
const { linkClicked } = useBaseEvents();
const route = useRoute();
const router = useRouter();
const { addToast } = useSoonaToast();
const { currentUserId } = useMe();

const { data: accountStorage, error: availStorageFetchError } =
  useStorageUsage(accountId);

const { mutate: createDigitalAsset, error: createError } =
  useCreateDigitalAsset(accountId);

// refs
const maxConcurrent = ref(3);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const isDragover = ref(false);
const alertMessage = ref('');
const errorMessage = ref('');
const acceptedFileTypes = ref('.png,.jpg,.jpeg');
const isRedirecting = ref(false);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

function redirectToMediaEditor(assetId) {
  isRedirecting.value = true;

  const routerDestination = {
    name: 'uploads-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: assetId,
    },
  };

  router.push(routerDestination);
}

const showAlertOrError = (type = 'alert', message) => {
  if (type === 'error') {
    errorMessage.value = message;
    return;
  }

  alertMessage.value = message;
};

const handleUploadClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: props.subContext,
    linkLabel: props.label,
    linkHref: null,
  });
};

const handleUploadError = errMsg => {
  cancelUploadsLocal.value();
  showAlertOrError('error', errMsg);
};

const handleUploadComplete = blob => {
  const digitalAsset = {
    asset_type: 'original',
    file: blob.signed_id,
    title: blob.filename,
    media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
    ownership: 'customer',
    origin: 'customer',
    origin_source: 'manual_upload',
    visibility: 'all',
  };

  if (albumId.value) {
    digitalAsset['album_collection_digital_assets_attributes'] = [
      {
        collection_id: albumId.value,
        added_by_user_id: currentUserId.value,
      },
    ];
  }

  createDigitalAsset(digitalAsset, {
    onSuccess: data => {
      if (activeUploadsLocal.value.length === 0 && data.id) {
        handleUploadClick();
        redirectToMediaEditor(data.id);
      }
    },
  });
};

const remainingStorageBytes = computed(() => {
  if (accountStorage.value) {
    return (
      accountStorage.value?.total_available_storage -
      accountStorage.value?.total_used_storage
    );
  }

  return 0;
});

const {
  handleUpload,
  handleDrop,
  activeUploads,
  percentComplete: totalPercentComplete,
  cancelUploads,
} = useUploader(
  maxConcurrent,
  uploadInterval,
  fileSizeLimit,
  validateNotCR2,
  handleUploadError,
  handleUploadComplete,
  remainingStorageBytes
);

activeUploadsLocal.value = activeUploads.value;
cancelUploadsLocal.value = cancelUploads;

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const isEditorTransition = computed(
  () =>
    (totalPercentComplete.value === 100 || isRedirecting.value) &&
    !createError.value
);

const handleDroppedFiles = e => {
  // don’t allow drag-and-drop upload if available storage check is still loading
  if (!accountStorage.value) return;

  isDragover.value = false;
  alertMessage.value = '';
  errorMessage.value = '';

  handleDrop(e);
};

const priorityError = usePriorityError(
  createError,
  availStorageFetchError,
  errorMessage
);

const isUploading = computed(() => activeUploads.value.length > 0);

const handleCancelUploads = () => {
  if (completedUploads.value.length > 0) {
    cancelUploads();
  } else {
    cancelUploads();
    addToast('upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};
</script>

<template>
  <SoonaUploadForm
    class="upload-asset-card"
    :accept="acceptedFileTypes"
    :active-uploads="activeUploads"
    :hide-cloud-icon="hideCloudIcon"
    is-multiple
    :label="label"
    :label-icon="labelIcon"
    :prevent-dragover-animation="!accountStorage"
    :suppress-default-content="!accountStorage || !!availStorageFetchError"
    @drop="handleDroppedFiles"
    @upload="
      $event => {
        alertMessage = '';
        errorMessage = '';
        handleUpload($event);
      }
    "
  >
    <template #content-top>
      <UploadProgressDialog v-if="isUploading || isEditorTransition">
        <div class="upload-asset-card__progresses">
          <em class="u-subheader--heavy upload-asset-card__progresses-title">
            {{
              isEditorTransition ? 'done! 🎉 opening AI studio…' : 'uploading…'
            }}
          </em>
          <SoonaProgress
            v-for="(upload, index) in activeUploads"
            :key="index"
            class="upload-asset-card__progress"
            :error="upload.error"
            :is-waiting="!upload.activated"
            :value="(upload.tracker?.loaded / upload.tracker?.total ?? 0) * 100"
          />
        </div>
        <SoonaButton
          v-if="isUploading"
          variation="tertiary"
          size="medium"
          @on-click="handleCancelUploads"
        >
          <template
            v-if="completedUploads.length > 0 && activeUploads.length > 1"
          >
            cancel incomplete upload(s)
          </template>
          <template v-else>cancel upload</template>
        </SoonaButton>
      </UploadProgressDialog>
      <BeatLoader
        v-if="!accountStorage && !availStorageFetchError"
        size="1rem"
      />
    </template>
    <template #content-bottom>
      <SoonaAlert
        v-if="alertMessage && !isEditorTransition"
        class="upload-asset-card__notification"
      >
        {{ alertMessage }}
      </SoonaAlert>
      <SoonaError
        v-if="errorMessage || priorityError"
        class="upload-asset-card__notification"
      >
        {{ errorMessage || priorityError }}
      </SoonaError>
    </template>
  </SoonaUploadForm>
</template>

<style lang="scss" scoped>
.upload-asset-card {
  height: 100%;
  min-height: auto;

  &__progresses {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;
    min-width: 17.75rem;

    &-title {
      font-style: normal;
    }
  }

  &__progress {
    width: 16.25rem;
  }

  &__notification {
    margin: 1rem 0 0;
  }
}
</style>
