<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingActionTemplate from '../ListingActionTemplate.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import ComparisonViewer from '../ComparisonViewer.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useTargetSize } from '@/components/user/anytime/listing_insights/listing_actions/actions/useTargetSize';
import { usePlatformName } from '@/components/user/anytime/listing_insights/listing_actions/actions/usePlatformName';
import ReplaceOnlyActionButtons from '@/components/user/anytime/listing_insights/listing_actions/actions/replace_only/ReplaceOnlyActionButtons.vue';
import { useReplaceOnlyActions } from './useReplaceOnlyActions';
import { useRoute } from 'vue-router';
import PublishToSiblingsDialog from '@/components/user/anytime/listing_insights/listing_actions/actions/PublishToSiblingsDialog.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  listingAction: {
    type: Object,
    required: true,
  },
  externalProductId: {
    type: String,
    default: null,
  },
  includeSiblingActions: {
    type: Boolean,
    default: false,
  },
  integrationId: {
    type: String,
    default: null,
  },
  isProcessing: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['showSubscriptionsDialog']);

const {
  accountId,
  listingAction,
  externalProductId,
  integrationId,
  includeSiblingActions,
} = toRefs(props);

const groupedListingActionsFlag = useFlag('pegasus_grouped_listing_actions');
const { buttonClicked } = useBaseEvents();

const route = useRoute();
const subContext = computed(() =>
  route.name === 'listing-optimize'
    ? 'listing detail page'
    : 'replace poor quality images'
);

const hasReplacementAsset = computed(() => {
  return listingAction.value.replacement_asset_preview_url;
});

const { platform } = usePlatformName(listingAction);
const { targetSize } = useTargetSize(listingAction);

const {
  canTakeAction,
  availableCreditsError,
  userCanUpgrade,
  optimizeTriggered,
  publishTriggered,
  isProcessing: replaceOnlyIsProcessing,
  handleUploadComplete,
  resetListingAction,
  createPublishTask,
  createListingActionDigitalAssetError,
  createDigitalAssetError,
  deleteListingDigitalAssetsError,
  deleteListingDigitalAssets,
} = useReplaceOnlyActions(accountId, listingAction, {
  externalProductId,
  integrationId,
  includeSiblings: includeSiblingActions.value,
});

const isActionProcessing = computed(() => {
  return props.isProcessing || replaceOnlyIsProcessing.value;
});

// loading state
const isOptimizing = computed(() => {
  if (listingAction.value.most_recent_bulk_action) {
    return listingAction.value.most_recent_bulk_action.processing;
  } else {
    return optimizeTriggered.value;
  }
});

const isPublishing = computed(() => {
  if (listingAction.value.most_recent_publish_task) {
    return listingAction.value.most_recent_publish_task.processing;
  } else {
    return publishTriggered.value;
  }
});

const displayPublishToSiblingsDialog = ref(false);

const publish = (include_siblings = false) => {
  publishTriggered.value = true;
  displayPublishToSiblingsDialog.value = false;
  const publishAssets = [
    {
      digital_asset_id: listingAction.value.replacement_asset.id,
      external_product_id: listingAction.value.external_product_id,
      image_position: listingAction.value.original_asset_image_position,
    },
  ];
  if (include_siblings === true && groupedListingActionsFlag.value) {
    publishAssets.push(
      ...listingAction.value.siblings.map(sibling => ({
        digital_asset_id: listingAction.value.replacement_asset.id,
        external_product_id: sibling.external_product_id,
        image_position: sibling.original_asset_image_position,
      }))
    );
  }
  createPublishTask(
    {
      publish_assets: publishAssets,
    },
    {
      onSuccess: () => {
        publishTriggered.value = false;
      },
    }
  );
};

const handlePublish = () => {
  publishTriggered.value = true;

  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel:
      listingAction.value === 'failed_to_publish'
        ? 'retry'
        : `publish to ${platform.value}`,
    buttonAction: 'publish',
  });

  if (
    listingAction.value.sibling_count > 0 &&
    groupedListingActionsFlag.value
  ) {
    displayPublishToSiblingsDialog.value = true;
  } else {
    publish();
  }
};

const captureUploadNewEvent = () => {
  if (canTakeAction.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upload new',
      buttonAction: 'upload new',
    });
  } else {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upload new',
      buttonAction: 'show subscriptions dialog',
    });
  }
};

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: subContext.value,
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

const handleResetListingAction = () => {
  resetListingAction();
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'cancel',
    buttonAction: 'reset listing action',
  });
};

const undoChanges = () => {
  deleteListingDigitalAssets(listingAction.value.id);
  buttonClicked({
    context: 'listing insights',
    subContext: subContext.value,
    buttonLabel: 'undo changes',
    buttonAction: 'undo changes',
  });
};

// comparison viewer module
const showComparison = ref(false);

// error handling
const priorityError = usePriorityError(
  createListingActionDigitalAssetError,
  createDigitalAssetError,
  deleteListingDigitalAssetsError,
  availableCreditsError
);

const iconLookup = {
  'low-resolution-replace': 'resolution',
  'poor-quality': 'circle-slash',
  'poor-composite': 'bandage',
};
</script>

<template>
  <ListingActionTemplate
    :is-optimizing="isOptimizing"
    :is-publishing="isPublishing"
    :is-processing="isActionProcessing"
    :listing-action="listingAction"
    @open-comparison-viewer="showComparison = true"
  >
    <template #error>
      <SoonaError v-if="priorityError" no-margin>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #listing-rule>
      <div class="listing-action-rule">
        <SoonaIcon
          :name="
            hasReplacementAsset
              ? 'image-square'
              : iconLookup[listingAction.listing_rule_slug]
          "
        />
        <div class="listing-action-rule__text">
          <div v-if="hasReplacementAsset">
            <div class="listing-action-rule__text--replacement u-body--heavy">
              uploaded image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
            <div
              v-if="listingAction.original_asset"
              class="listing-action-rule__text--original"
            >
              original image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
          </div>
          <div v-else>
            <template
              v-if="
                listingAction.listing_rule_title === 'low resolution replace'
              "
            >
              <dl>
                <dt>low resolution</dt>
                <dd>{{ listingAction.original_asset.resolution }}</dd>
              </dl>
              <dl>
                <dt>target size</dt>
                <dd>{{ targetSize }}</dd>
              </dl>
            </template>
            <template v-else>
              <div>
                <p>{{ listingAction.listing_rule_title }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <ReplaceOnlyActionButtons
        :account-id="accountId"
        :listing-action="listingAction"
        @capture-upload-new-event="captureUploadNewEvent"
        @handle-upgrade-click="handleUpgradeClick"
        @handle-upload-complete="handleUploadComplete($event)"
        @handle-reset-listing-action="handleResetListingAction"
        @handle-publish="handlePublish"
        @undo-changes="undoChanges"
      />
    </template>
  </ListingActionTemplate>
  <ComparisonViewer
    v-if="showComparison"
    :listing-action-slug="listingAction.listing_action_slug"
    :original-asset="listingAction.original_asset"
    :replacement-asset="listingAction.replacement_asset"
    @close="showComparison = false"
  />
  <PublishToSiblingsDialog
    v-if="displayPublishToSiblingsDialog"
    :listing-action="listingAction"
    :platform="platform"
    @close="displayPublishToSiblingsDialog = false"
    @publish-to-siblings="publish($event)"
  />
</template>

<style lang="scss" scoped>
@use 'src/variables';

.listing-action-rule {
  display: flex;
  gap: 0.5rem;

  &__text {
    div {
      dl {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        dt {
          font-weight: bold;
        }
      }

      p {
        font-weight: bold;
      }
    }
  }
}

.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>
