<script setup>
import { computed } from 'vue';
import { useSidebarIconData } from './useSidebarIconData';
import { Gray10 } from '@/variables.module.scss';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
});

/*
 * icon data should have a fill set to `var(--accent-color, currentColor)` to
 * support color customization. the standard icon path should still be set to
 * `currentColor`. Brian tried to do this with CSS, but it wasn't working.
 */

const sidebarIconData = useSidebarIconData();

const svgContent = computed(() => {
  return sidebarIconData.value
    ? sidebarIconData.value[props.name]
    : `<circle cx="12" cy="12" r="12" fill="${Gray10}" />`;
});
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    v-html="svgContent"
  />
</template>
