<script setup>
import ProfileCard from '@/components/user/anytime/profile/edit/cards/ProfileCard.vue';
import accountImage from 'images/account-placeholder.svg';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';
import { computed } from 'vue';
import { useFlag } from '@/composables/useFlag';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});
const canManageContributors = useFlag('can_manage_shopify_contributors');

const accountId = computed(() => props.accountId);
const { data: collaborators, isLoading, error } = useCollaborators(accountId);
const collaboratorsItems = computed(
  () => collaborators.value?.collaborators ?? []
);

const priorityError = usePriorityError(error);
</script>

<template>
  <div>
    <div v-if="canManageContributors">
      <ProfileCard
        title="team members"
        class="contributors-card"
        :icon="collaboratorsItems.length > 0 ? null : '👩‍💻'"
        :action-path="`/account/${accountId}/collaborators`"
        action-text="manage team members"
      >
        <SoonaLoading v-if="isLoading" is-loading is-contained />
        <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
        <div
          v-else-if="collaboratorsItems.length > 0"
          class="collaborator-container mb-s"
        >
          <div
            v-for="collaborator in collaboratorsItems"
            :key="collaborator.id"
            class="collaborator"
          >
            <img
              class="user-avatar mb-s"
              :src="collaborator.avatar_url || accountImage"
              alt=""
            />
            <p class="role-title text-s">{{ collaborator.role.name }}</p>
            <p class="collaborator-name">{{ collaborator.name }}</p>
          </div>
        </div>
        <p v-else class="text-s">
          build your team and share your content with others. collaborators can
          create and join shoots with you.
        </p>
      </ProfileCard>
    </div>
    <div v-else>
      <ProfileCard
        title="collaborators"
        banner="✨ COMING SOON ✨"
        class="contributors-card"
        :icon="'👩‍💻'"
      >
        <p class="text-s">
          build your team and share your content with others. collaborators can
          create and join shoots with you.
        </p>
      </ProfileCard>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.contributors-card {
  position: relative;

  .collaborator-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1.25rem;
  }

  .collaborator {
    flex: 1 0 10rem;
    padding: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    text-align: center;

    .user-avatar {
      width: 5rem;
      height: 5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 999px;
      object-fit: cover;
    }

    .role-title,
    .collaborator-name {
      font-weight: 800;
      width: 100%;
      margin: 0;
    }
    .collaborator-name {
      font-size: 14px;
    }
  }
}
</style>
