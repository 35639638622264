<script setup>
import { useSiteNavEvents } from '../useSiteNavEvents';

const { trackLinkClicked } = useSiteNavEvents();
</script>

<template>
  <a
    href="/"
    class="nav-logo"
    data-cypress="button-header-logo"
    @click="trackLinkClicked('soona', '/')"
  >
    <img src="@images/soona-logo-red.svg" alt="soona logo" />
  </a>
</template>

<style lang="scss" scoped>
@use '@/variables';

.nav-logo {
  padding: 0.75rem 0;
  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  img {
    display: block;
    height: 1rem;
    width: auto;
  }
}
</style>
