<script setup>
import { computed } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSchedulingPreference } from '@/queries/scheduling/useSchedulingPreference';
import { useUpdateSchedulingPreference } from '@/queries/scheduling/useUpdateSchedulingPreference';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SchedulingPreferencesForm from './SchedulingPreferencesForm.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';

const props = defineProps({
  editMode: {
    default: false,
    type: Boolean,
  },
  formId: {
    type: String,
    required: true,
  },
  cancelEdits: {
    default: false,
    type: Boolean,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
  showFormHeader: {
    default: true,
    type: Boolean,
  },
});

const emit = defineEmits(['close', 'reset-cancel-edits']);

const queryClient = useQueryClient();

const reservationId = computed(() => props.reservationId);

const {
  data: schedulingPreference,
  isLoading: schedulingPreferenceLoading,
  error: schedulingPreferenceError,
} = useSchedulingPreference(reservationId);

const schedulingPreferenceSlots = computed(
  () => schedulingPreference.value?.slots
);
const schedulingPreferenceNotes = computed(
  () => schedulingPreference.value?.note
);
const schedulingPreferenceTimeZone = computed(
  () => schedulingPreference.value?.preferred_time_zone
);

// mutate
const schedulingPreferenceId = computed(() => schedulingPreference.value?.id);
const {
  mutate,
  isPending: isUpdatigSchedulingPreference,
  error: updateSchedulingPreferenceError,
} = useUpdateSchedulingPreference(reservationId, schedulingPreferenceId);

const updateSchedulingPreference = preferences => {
  mutate(
    {
      note: preferences.notes,
      scheduling_preferences_slots_attributes: [...preferences.availablity],
      preferred_time_zone: preferences.selectedTimeZone,
    },
    {
      onSuccess: () => {
        emit('close', false);
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationReadinessSteps(reservationId),
        });
      },
    }
  );
};

const priorityError = usePriorityError(
  schedulingPreferenceError,
  updateSchedulingPreferenceError
);
</script>
<template>
  <div class="availability">
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div>
      <div v-if="schedulingPreferenceLoading" class="loading-state">
        <SoonaSkeleton v-for="i in 6" :key="i" class="loading-state__card" />
      </div>
      <SchedulingPreferencesForm
        v-else
        class="availability__preferences--form"
        :form-id="formId"
        :scheduling-preferences="schedulingPreferenceSlots"
        :scheduling-notes="schedulingPreferenceNotes"
        :scheduling-preferred-time-zone="schedulingPreferenceTimeZone"
        :show-form-header="showFormHeader"
        :edit-mode="editMode"
        :cancel-edits="cancelEdits"
        @submit="updateSchedulingPreference"
        @reset-cancel-edits="() => emit('reset-cancel-edits')"
      />
      <SoonaLoading
        v-if="isUpdatigSchedulingPreference"
        is-loading
        loading-text="loading"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.loading-state {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__card {
    height: 2.5rem;
    width: 100%;
    border-radius: variables.$radius-small;
  }
}
</style>
