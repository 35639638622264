import { useQuery } from '@tanstack/vue-query';
import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {string | Ref<string>} accountId
 * @param {string} assetType
 * @param {Array<string>} assetIds
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useBulkGetAssetAddOns(accountId, { assetType, assetIds }) {
  return useQuery({
    queryKey: queryKeys.bag(accountId, {
      assetType: toValue(assetType),
      assetIds,
    }),
    queryFn: ({ signal }) => {
      const params = {
        asset_type: toValue(assetType),
        asset_ids: toValue(assetIds),
      };
      return http.get(
        `accounts/${toValue(accountId)}/bag/bulk_get_asset_add_ons`,
        {
          params,
          signal,
        }
      );
    },
  });
}
