import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useSubscription } from '@/composables/subscription/useSubscription';
import { useTiers } from '@/composables/subscription/useTiers';

export function useSuggestedSubscription(accountId) {
  const { account, isDigitalSubscriber } = useAccount(accountId);

  const totalListings = computed(
    () => account.value?.total_listings_count ?? null
  );

  const totalCompetitors = computed(
    () => account.value?.total_competitors_count ?? null
  );

  const {
    hasLiveSubscription,
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
  } = useSubscription(accountId);

  const { basicTier, proTier, businessTier } = useTiers();

  const calculateSuggestedTier = total => {
    if (total === 0) {
      return {};
    }
    if (total <= 5 && !isDigitalSubscriber.value) {
      return basicTier.value;
    } else if (total <= 50 && !hasTierTwoSubscription.value) {
      return proTier.value;
    } else if (total <= 500 && !hasTierThreeSubscription.value) {
      return businessTier.value;
    } else {
      return {};
    }
  };

  const suggestedListingsTier = computed(() =>
    calculateSuggestedTier(totalListings.value)
  );

  const suggestedCompetitorsTier = computed(() =>
    calculateSuggestedTier(
      totalCompetitors.value,
      account.value?.max_competitors_count
    )
  );

  const competitorQuotaReached = computed(
    () => totalCompetitors.value >= account.value?.max_competitors_count
  );

  const hasActiveStoreIntegration = computed(() => {
    return account.value?.has_active_store_integration ?? false;
  });

  return {
    hasActiveStoreIntegration,
    hasLiveSubscription,
    hasTierOneSubscription,
    hasTierTwoSubscription,
    hasTierThreeSubscription,
    suggestedListingsTier,
    suggestedCompetitorsTier,
    competitorQuotaReached,
  };
}
