<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    default: 'large',
    type: String,
    validator: value => {
      return ['large', 'medium', 'small'].includes(value);
    },
  },
  to: {
    type: [String, Object],
    required: true,
  },
  variation: {
    default: 'periwink',
    type: String,
    required: false,
    validator: value => {
      return ['periwink', 'black'].includes(value);
    },
  },
});

const emit = defineEmits(['onClick']);

const onRouterLinkClick = (event, navigate) => {
  emit('onClick', event);

  navigate(event);
};

const isExternalLink = computed(() => {
  return typeof props.to === 'string' && /^(http|https):/.test(props.to);
});

const linkClasses = computed(() => ({
  'soona-link__small': props.size === 'small',
  'soona-link__medium': props.size === 'medium',
  'soona-link__black': props.variation === 'black',
}));
</script>

<template>
  <a
    v-if="isExternalLink"
    v-bind="$attrs"
    :href="to"
    class="soona-link"
    :class="linkClasses"
  >
    <slot />
  </a>
  <router-link
    v-else
    v-slot="{ href: slotHref, navigate }"
    :to="to"
    :custom="true"
  >
    <a
      v-bind="$attrs"
      class="soona-link"
      :class="linkClasses"
      :href="slotHref"
      @click="onRouterLinkClick($event, navigate)"
    >
      <slot />
    </a>
  </router-link>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-link {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: variables.$periwink-blue-70;

  &:hover {
    color: variables.$black-default;
    transition: color 0.1s ease-out;
  }

  &:active {
    text-decoration: none;
    color: variables.$periwink-blue-80;
  }

  &__medium {
    @include variables_fonts.u-label--regular;
  }

  &__small {
    @include variables_fonts.u-label--small;
  }

  &__black {
    color: variables.$black-default;

    &:hover {
      color: variables.$periwink-blue-70;
    }
  }
}

.soona-link :slotted(svg) {
  height: 1em;
  width: 1em;
}
</style>
