import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateDefaultSubscriptionPaymentMethod } from '@/api/subscriptions';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

export function useUpdateDefaultSubscriptionPaymentMethod(subscriptionId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ stripe, card }) =>
      updateDefaultSubscriptionPaymentMethod(
        unref(subscriptionId),
        stripe,
        card
      ),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.defaultSubscriptionPaymentMethod(subscriptionId),
        }),
      ]);
    },
  });
}
