<script setup>
import { computed, ref, toRefs, watchEffect } from 'vue';
import uniqueId from 'lodash/uniqueId';
import confetti from 'canvas-confetti';
import {
  BubbletapePink40,
  FriendlyRed50,
  Tangerine40,
  PeriwinkBlue50,
  GreenApple30,
} from 'src/variables.module.scss';
import { useRoute } from 'vue-router';
import { useCheckoutPromoCredits } from '@/components/checkout/useCheckoutPromoCredits';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const { accountId } = toRefs(props);

const {
  checkoutPromoCredits,
  preText,
  subText,
  meterValue,
  assetRecentlyAdded,
  earnedCreditsText,
} = useCheckoutPromoCredits(accountId);

const route = useRoute();

const id = uniqueId('soona-checkout-promo-credits-meter-');

const percentage = computed(() => meterValue.value * 100);

// confetti stuff
const showConfetti = ref(false);

const frame = () => {
  const colors = [
    BubbletapePink40,
    FriendlyRed50,
    Tangerine40,
    PeriwinkBlue50,
    GreenApple30,
  ];
  const selectedColor = colors[Math.floor(Math.random() * colors.length)];

  confetti({
    angle: 270,
    colors: [selectedColor],
    disableForReducedMotion: true,
    drift: Math.random() * 4 - 2,
    origin: { x: Math.random(), y: -0.1 },
    particleCount: 1,
    scalar: 1.8,
    shapes: ['circle', 'square', 'square'],
    spread: 180,
    startVelocity: 0.8,
    ticks: 400,
  });

  if (showConfetti.value) {
    requestAnimationFrame(frame);
  }
};

const setConfettiStop = () => {
  setTimeout(() => {
    showConfetti.value = false;
  }, 3000);
};

watchEffect(() => {
  if (
    meterValue.value === 1 &&
    !showConfetti.value &&
    assetRecentlyAdded.value
  ) {
    showConfetti.value = true;
    frame();
    setConfettiStop();
  }
});

const isGallery = computed(() => {
  return route.name === 'gallery';
});
</script>
<template>
  <div
    class="checkout-promo-credits"
    :class="{ 'checkout-promo-credits--gallery': isGallery }"
  >
    <div
      v-if="checkoutPromoCredits >= 1"
      class="checkout-promo-credits__earned"
    >
      <span class="checkout-promo-credits__circle">
        {{ checkoutPromoCredits }}
      </span>
      <span class="u-button--small-caps">
        photo {{ checkoutPromoCredits === 1 ? 'credit' : 'credits' }} earned
      </span>
    </div>
    <div>
      <template v-if="checkoutPromoCredits >= 1">
        <p class="checkout-promo-credits__copy u-body--heavy">
          {{ earnedCreditsText }}
        </p>
        <p>
          {{ subText }}
        </p>
      </template>
      <p v-else class="checkout-promo-credits__copy u-body--heavy">
        {{ preText }}! {{ subText }}
      </p>
    </div>
    <div>
      <figure :class="`soona-meter`">
        <div
          :aria-valuenow="meterValue"
          :aria-valuemin="0"
          :aria-valuemax="1"
          :aria-labelledby="`${id}-title ${id}-desc`"
        >
          <svg
            role="meter"
            :width="`${percentage}%`"
            height="100%"
            class="soona-meter__fill"
            aria-hidden="true"
          >
            <defs>
              <linearGradient id="gradient" x1="0" x2="0" y1="1" y2="0">
                <stop offset="0%" :stop-color="BubbletapePink40" />
                <stop offset="100%" :stop-color="BubbletapePink40" />
              </linearGradient>
            </defs>
            <rect
              x="0"
              y="0"
              rx="8"
              :width="`${percentage}%`"
              height="100%"
              fill="url(#gradient)"
            ></rect>
          </svg>
        </div>
      </figure>
      <img
        v-if="meterValue === 1"
        src="@images/open-present-icon.svg"
        class="present-icon present-icon--open"
        alt="open present icon"
      />
      <img
        v-else
        src="@images/closed-present-icon.svg"
        class="present-icon present-icon--closed"
        alt="closed present icon"
      />
      <img
        v-if="meterValue === 1"
        src="@images/available-asset-icon.svg"
        class="available-asset-icon"
        alt="available asset icon"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.checkout-promo-credits {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: 1rem;
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  position: relative;

  &--gallery {
    box-shadow: 0rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.2);
  }

  &__earned {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__circle {
    width: 2.375rem;
    height: 2.375rem;
    border-radius: 50%;
    background-image: linear-gradient(62.59deg, #3c51f1 2.88%, #f03742 98.95%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 900;
    color: variables.$white-default;
  }

  &__copy {
    max-width: 80%;
  }

  .soona-meter {
    margin: 0;
    view-transition-name: background-indicator;

    [role='meter'] {
      height: 1rem;
      border-radius: 1rem;
      width: 100%;
      background: linear-gradient(
        360deg,
        variables.$gradient-periwink-start 0%,
        variables.$gradient-periwink-end 100%
      );
      border: 0.0625rem solid variables.$gray-30;
    }

    &__fill {
      height: 100%;
      box-sizing: border-box;
      border-radius: 0.3125rem;
    }

    &__skeleton {
      width: 100%;
      height: 0.5rem;
      border-radius: 1rem;
      margin: 1rem 0;
    }
  }

  .present-icon {
    position: absolute;

    &--open {
      right: -0.25rem;
      bottom: 0.3rem;
    }

    &--closed {
      right: 0.25rem;
      bottom: 0.5rem;
      -webkit-animation-name: wiggle;
      -ms-animation-name: wiggle;
      -webkit-animation-delay: 5s;
      -ms-animation-delay: 5s;
      -ms-animation-duration: 5s;
      -webkit-animation-duration: 5s;
      -webkit-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      -ms-animation-timing-function: ease-in-out;
    }
  }

  .available-asset-icon {
    position: absolute;
    right: 1.5rem;
    bottom: 2.25rem;
    animation: float 6s ease-in-out infinite;
    -moz-animation: float 6s ease-in-out infinite;
    -webkit-animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  @keyframes wiggle {
    0% {
      transform: translateX(0) rotate(0);
    }
    45% {
      transform: translateX(0) rotate(0);
    }
    48% {
      transform: translateX(0.25rem) rotate(-4deg);
    }
    50% {
      transform: translateX(-0.25rem) rotate(3deg);
    }
    55% {
      transform: translateX(0.125rem) rotate(-2deg);
    }
    60% {
      transform: translateX(-0.125rem) rotate(2deg);
    }
    63% {
      transform: translateX(0) rotate(0);
    }
    100% {
      transform: translateX(0) rotate(0);
    }
  }
}
</style>
