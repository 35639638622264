<template>
  <div class="assignment-card">
    <a
      :href="`/user/anytime#/reservation/${reservation.id}/info`"
      class="image-item"
    >
      <img
        :src="mediaTypeImage"
        alt=""
        class="upcoming-location-image"
        :class="isPhoto ? 'photo-image' : 'video-image'"
      />
    </a>
    <div class="main-card">
      <a
        :href="`/user/anytime#/reservation/${reservation.id}/info`"
        class="info-wrapper"
      >
        <div class="booking-name-date">
          <p>
            <span v-if="reservation.account.vip" class="vip-booking">
              VIP
            </span>
            {{ reservation.title.substring(2).toUpperCase() }}
            - {{ reservation.name.toUpperCase() }}
            <span v-if="reservation.start">
              <span class="red"> | </span>
              <span class="date-time">
                {{ convertToMMDDYYYYlong(reservation.start) }} @
                {{ convertToHHMM12(reservation.start) }}
              </span>
            </span>
          </p>
        </div>
        <div class="booking-details">
          <SoonaTag
            type="assignment"
            :tags="otherTags().concat(proServiceTags())"
          />
        </div>
        <div class="crew">
          <img
            v-for="crew in reservation.crew"
            :key="crew.id"
            :src="crew.user_avatar || accountImage"
            class="crew-member"
          />
        </div>
        <div class="booking-location">
          <p class="location">
            <span
              class="bay-color"
              :style="{ backgroundColor: `${reservation.bay_color}` }"
            ></span>
            {{ reservation.bay_name.toUpperCase() }}-
            {{ reservation.location.city.toUpperCase() }}
            {{ reservation.location.address1 }}
            {{ reservation.location.address2 }}
          </p>
        </div>
      </a>
      <div
        v-for="assignment in assignments"
        :key="assignment.id"
        class="assignment"
      >
        <ProServiceAssignmentItem
          :assignment="assignment"
          :reservation="reservation"
        />
        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, useStore } from 'vuex';
import { computed } from 'vue';
import SoonaTag from 'src/components/SoonaTag.vue';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
import ProServiceAssignmentItem from 'src/components/user/anytime/pro_service/ProServiceAssignmentItem.vue';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import accountImage from 'images/account-placeholder.svg';
import photoImage from '@images/photo.png';
import videoImage from '@images/video.png';

import anytimeImage from '@images/anytime.png';
import headshotsForAllImage from '@images/headshots_for_all.png';
import inStudioImage from '@images/in_studio.png';
import internalImage from '@images/internal.png';
import surpriseImage from '@images/surprise.png';

const reservationTypeImageMap = {
  anytime: anytimeImage,
  headshots_for_all: headshotsForAllImage,
  in_studio: inStudioImage,
  internal: internalImage,
  surprise: surpriseImage,
};

export default {
  components: {
    SoonaTag,
    ProServiceAssignmentItem,
  },
  mixins: [dateTimeMixin],
  props: {
    title: String,
    reservation: Object,
    ownAccount: Boolean,
    type: String,
  },
  setup(props) {
    const store = useStore();
    const currentAccountOwner = computed(() => store.state.account.owner);
    const reservationId = computed(() => props.reservation.id);
    const { data: crewAssignments } = useGetCrewAssignments(reservationId, {
      without_declined: 'true',
    });
    const assignments = computed(() => {
      return crewAssignments.value
        ? crewAssignments.value.filter(
            crew => crew.user_id == currentAccountOwner.value.id
          )
        : [];
    });

    const isPhoto = computed(() =>
      props.reservation.tags.some(t => t.title === 'Photo')
    );

    const mediaTypeImage = computed(() =>
      isPhoto.value ? photoImage : videoImage
    );

    return {
      accountImage,
      assignments,
      currentAccountOwner,
      isPhoto,
      mediaTypeImage,
    };
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    ...mapGetters(['proServiceTypes']),
    ...mapState({
      isOwnAccount: state => state.account.id === state.currentUser.accountId,
    }),
  },
  methods: {
    proServiceTags() {
      let noPhotoVideo = this.reservation.tags.filter(
        tag => tag.title !== 'Photo' && tag.title !== 'Video'
      );
      let tagsArray = noPhotoVideo.filter(tag =>
        this.proServiceTypes.includes(tag.title)
      );
      return tagsArray;
    },
    otherTags() {
      let studioType;
      if (this.reservation.reservation_type === 'in_studio') {
        studioType = 'in-studio';
      } else if (this.reservation.reservation_type === 'anytime') {
        studioType = 'online';
      }
      let resType = {
        title: studioType ? studioType : this.reservation.reservation_type,
        image_url: reservationTypeImageMap[this.reservation.reservation_type],
      };
      let otherTagsArray = this.reservation.tags.filter(
        tag =>
          tag.title === '3 - 15' ||
          tag.title === '5 - 15' || // in production these are different
          tag.title === '1 - 3' ||
          tag.title === '1 - 5' || // in production these are different
          tag.title === '15+' ||
          tag.title === 'studio buy-out'
      );
      otherTagsArray.unshift(resType);
      return otherTagsArray;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.assignment-card {
  border-radius: 0.625rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  display: flex;
  margin: 1rem 0.25rem;
  max-height: none;
  min-height: 12.5rem;
  position: relative;

  .main-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 1rem;
    .assignment {
      margin: 0.25rem 0.75rem 0.25rem 0.75rem;
      hr {
        margin: 0.75rem 0rem 0.75rem 0rem;
      }
    }
  }
}

.upcoming-location-image {
  border-radius: 0.625rem 0 0 0.625rem;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.image-item {
  grid-area: 1 / 1 / 4 / 2;
  min-width: 100%;

  @media (min-width: variables.$screen-sm-min) {
    grid-area: 1 / 1 / 3 / 2;
    min-width: inherit;
  }
}

.photo-image {
  max-width: 95px;
  min-width: 95px;
  object-fit: cover;
  object-position: 75% 10%;
}

.video-image {
  max-width: 95px;
  min-width: 95px;
  object-fit: cover;
  object-position: 40% 10%;
}

.info-wrapper {
  padding: 15px;
  margin-bottom: 10px;
  width: 100%;
}

.booking-name-date,
.location {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1rem;
}

.red {
  color: variables.$friendly-red-50;
}

.date-time {
  letter-spacing: 0;
  font-weight: 100;
  font-size: 14px;
}

.booking-details {
  margin-top: 10px;
}

.role,
.role-notes {
  margin: 5px 0;
  width: 70%;
}

.crew {
  margin-left: -10px;
}

.crew-member {
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: variables.$curious-orange;
  margin: 10px 5px;
  color: variables.$white-default;
  font-size: 10px;
  object-fit: cover;
}

.bay-color {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
}

.vip-booking {
  margin-right: 5px;
  padding: 1px 0px 1px 7px;
  color: variables.$white-default;
  background-color: variables.$friendly-red-50;
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 3px;
  bottom: 1px;
  position: relative;
}

@media screen and (max-width: 415px) {
  .assignment-card {
    display: block;
  }
  .video-image,
  .photo-image {
    max-width: none;
    border-radius: 0;
    height: 90px;
    object-fit: cover;
    object-position: 40% 65%;
  }
  .photo-image {
    object-position: 40% 75%;
  }
}
</style>
