import * as types from 'src/store/mutation-types';
import io from 'socket.io-client';
import throttle from 'lodash/throttle';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';

const state = {
  reservation: null,
};

const mutations = {
  [types.SET_RESERVATION](state, { reservation }) {
    state.reservation = reservation;
  },
  [types.RESET](state) {
    state.reservation = null;
  },
};

const actions = {
  setReservation({ state, commit }, reservation) {
    if (
      (!state.reservation || state.reservation.id != reservation.id) &&
      import.meta.env.VITE_ANYTIME_HOST
    ) {
      if (this.socket) {
        this.socket.disconnect();
      }

      commit(types.RESET);
      commit(types.SET_RESERVATION, { reservation });

      let self = this;

      self.socket = io.connect(import.meta.env.VITE_ANYTIME_HOST, {
        transportOptions: {
          polling: {
            extraHeaders: {
              Authorization: state.reservation.id,
            },
          },
        },
      });
      self.socket.on('media-item', function (data) {
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationFileMediaAddOns(
            data.reservation_id,
            data.id
          ),
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationFileVariations(
            data.reservation_id,
            data.id
          ),
        });
        commit(`reservationFiles/${types.SET_RESERVATION_FILE}`, data, {
          root: true,
        });
      });
      self.socket.on(
        'order-updated',
        throttle(() => {
          queryClient.invalidateQueries({ queryKey: queryKeys.bag() });
        }, 1000)
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
