<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useAccount } from '@/queries/account/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useShowTag } from '@/queries/useShowTag';
import DashboardDialog from '@/components/user/anytime/dashboard/DashboardDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  tagId: {
    required: true,
    type: Number,
  },
});

const emits = defineEmits(['close']);

const { linkClicked } = useBaseEvents();
const route = useRoute();

const accountId = computed(() => props.accountId);

const { data: account, isLoading: accountIsLoading } = useAccount(accountId);
const { data: tagData, isLoading: tagIsLoading } = useShowTag(
  computed(() => props.tagId)
);

const bodyCopy = computed(() => tagData.value?.description_long);

const linkLabel = 'book this shoot';
const linkHref = computed(
  () =>
    `/booking/new?pro_service=${tagData.value?.title
      .replace(/\s+/g, '_')
      .replace(/&/g, 'and')}`
);

const handleLinkClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'pro services modal',
    linkLabel: linkLabel,
    linkHref: linkHref.value,
  });
};
</script>

<template>
  <DashboardDialog
    :content-data="tagData"
    sub-context="professional services modal"
    @close="emits('close')"
  >
    <SoonaLoading
      v-if="accountIsLoading || tagIsLoading"
      is-contained
      is-loading
    />
    <div class="pro-services-dialog__action-btns">
      <!-- TODO: make photo & video router-links with special flows to skip the first step -->
      <SoonaButton
        element="router-link"
        :to="linkHref"
        data-cypress="button-dialog-book-shoot"
        @on-click="handleLinkClick"
      >
        {{ linkLabel }}
      </SoonaButton>
    </div>
    <div class="pro-services-dialog__details">
      <div class="pro-services-dialog__body" v-html="bodyCopy"></div>
      <h4 class="u-label--heavy pro-services-dialog__description-heading">
        <span v-if="account?.preferred_discounted_rate > 0">
          pricing: starting at
          <s>{{ toCurrency(tagData?.rate, 'USD', 0) }}</s>
          {{}}
          <span class="pro-services-dialog__discounted-price">{{
            toCurrency(tagData?.rate * account.preferred_discounted_rate)
          }}</span
          >/hour
        </span>
        <span v-else>
          pricing: starting at {{ toCurrency(tagData?.rate, 'USD', 0) }}/hour
        </span>
      </h4>
    </div>
  </DashboardDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pro-services-dialog {
  &__action-btns {
    display: flex;
    gap: 1rem;
    padding-bottom: 2rem;

    :deep(.soona-button) {
      width: 100%;
    }
  }

  &__body {
    padding-bottom: 1rem;

    :deep(p) {
      padding-bottom: 0.75rem;
    }
  }

  &__description-heading {
    padding-bottom: 0.25rem;
    text-transform: uppercase;
  }

  &__discounted-price {
    color: variables.$friendly-red-70;
  }
}
</style>
