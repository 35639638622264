import { computed, ref, toValue, watch, watchEffect } from 'vue';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useFlag } from '@/composables/useFlag';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { storeToRefs } from 'pinia';
import { useCreateOrderSalesTax } from '@/queries/sales_tax/useCreateOrderSalesTax';

/**
 * @param {Ref<Number|String>} accountId
 * @param {Ref<Number|String>} orderId
 * @param {Ref<Number|String>} preSalesTaxSubtotal
 * @param {Ref<Boolean>} calculate
 * */
export function useOrderSalesTax({
  accountId,
  orderId,
  preSalesTaxSubtotal = ref(0),
  calculate = ref(true),
}) {
  const salesTaxStore = useSalesTaxStore();

  const paymentsSalesTaxFlag = useFlag('payments_sales_tax');
  const {
    salesTaxBillingAddressId,
    stripeSalesTaxCalculationId,
    taxAmountExclusiveInCents,
    taxAmountExclusiveDisplay,
    totalWithSurchargeInDollars,
    totalWithSurchargeDisplay,
  } = storeToRefs(salesTaxStore);

  const { data: account } = useAccountQuery(accountId, {
    enabled: computed(() => !!accountId.value),
  });

  const enabled = computed(() => {
    if (!toValue(calculate)) return false;
    return paymentsSalesTaxFlag.value && !!toValue(orderId);
  });

  const {
    data: response,
    error,
    isPending,
    isFetching,
  } = useCreateOrderSalesTax(
    { accountId, orderId, preSalesTaxSubtotal },
    {
      enabled,
      retry: 0,
      staleTime: 0,
      gcTime: 0,
    }
  );

  // todo: when checkout is refactored, look into having this call be at the top level and provide down to the child components
  watchEffect(() => {
    if (error.value || !response.value) {
      salesTaxStore.$reset();
      salesTaxStore.setPreSalesTaxSubtotalInDollars(
        toValue(preSalesTaxSubtotal)
      );
    } else {
      salesTaxStore.setStripeSalesTaxCalculationResponse(response.value);
    }
  });

  watch(
    preSalesTaxSubtotal,
    () => {
      salesTaxStore.setPreSalesTaxSubtotalInDollars(preSalesTaxSubtotal.value);
    },
    { immediate: true }
  );

  const isLoading = computed(() => {
    if (!enabled.value) return false;
    return isFetching.value || isPending.value;
  });

  watch(
    isLoading,
    () => {
      salesTaxStore.setIsCalculatingSalesTax(isLoading.value);
    },
    { immediate: true }
  );

  // payments_todo: why does watch not work??
  watchEffect(() => {
    if (
      salesTaxStore.salesTaxBillingAddressId?.value !==
      account.value?.billing_address?.id
    ) {
      salesTaxStore.setSalesTaxBillingAddressId(
        account.value?.billing_address?.id
      );
    }
  });

  // make sure to call salesTaxStore.$reset() when the component unmounts
  return {
    error,
    isLoading,
    salesTaxBillingAddressId,
    stripeSalesTaxCalculationId,
    taxAmountExclusiveInCents,
    taxAmountExclusiveDisplay,
    totalWithSurchargeInDollars,
    totalWithSurchargeDisplay,
  };
}
