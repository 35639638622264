<script setup>
import { computed, ref, watchEffect } from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters';
import { useIntervalFn } from '@vueuse/core';

const props = defineProps({
  isoDateTime: {
    type: String,
    required: true,
  },
});

dayjs.extend(relativeTime);

function relativeFormat() {
  try {
    return dayjs().to(dayjs(props.isoDateTime));
  } catch (error) {
    return '';
  }
}

const relativeTimeDisplay = ref('');

// handle if the time changes
watchEffect(() => {
  relativeTimeDisplay.value = relativeFormat();
});

useIntervalFn(() => {
  relativeTimeDisplay.value = relativeFormat();
}, 30_000);

const absoluteTimeDisplay = computed(() => {
  return convertToDDMMYYHHMM12z(props.isoDateTime);
});
</script>

<template>
  <time :datetime="props.isoDateTime" :title="absoluteTimeDisplay">
    {{ relativeTimeDisplay }}
  </time>
</template>
