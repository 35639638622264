<script setup>
import { computed, ref } from 'vue';
import { useCreateBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useCreateBagCollectionDigitalAsset';
import { useDeleteBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useDeleteBagCollectionDigitalAsset';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCapability } from '@/composables/useCapability';
import { useMe } from '@/composables/user/useMe';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { toCurrency } from '@/lib/currency';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  addOnProduct: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const mediaEditorStore = useMediaEditorStore();
const { assetAccountId, assetId } = storeToRefs(mediaEditorStore);

const {
  reservationDigitalAsset,
  rdaLoading,
  mediaType,
  title,
  ownedByCustomer,
  mediaUrl,
  bagCollectionCdaId,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const isDownloadFreeOpen = ref(false);

// use
const route = useRoute();
const { mediaAddedToCart, linkClicked } = useBaseEvents();

// capabilities
const { currentAccountId } = useMe();
const { hasCapability: canBag } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: currentAccountId.value,
});

// mutations
const { addToast } = useSoonaToast();
const { data: bagCollection } = useBagCollection(assetAccountId);
const bagCollectionId = computed(() => bagCollection.value?.id);

const { mutate: mutateAddToBag, isPending: mutateAddtoBagPending } =
  useCreateBagCollectionDigitalAsset(bagCollectionId);

const { mutate: mutateRemoveFromBag, isPending: mutateRemoveFromBagPending } =
  useDeleteBagCollectionDigitalAsset(bagCollectionId, bagCollectionCdaId);

const removeFromBag = () => {
  mutateRemoveFromBag(
    {},
    {
      onError: () => {
        addToast(`error removing asset from the bag`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

const addToBag = () => {
  mutateAddToBag(
    {
      digital_asset_id: assetId.value,
    },
    {
      onSuccess: () => {
        mediaAddedToCart({
          context: route.meta.context,
          subContext: mediaType.value,
          itemId: assetId.value,
          itemLabel: title,
          //   itemPrice: asset.value.rate, todo find price
          itemQuantity: 1,
        });
        isDownloadFreeOpen.value = false;
      },
      onError: () => {
        addToast(`error adding asset to the bag`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

const isMutating = computed(() => {
  return (
    rdaLoading.value ||
    mutateAddtoBagPending.value ||
    mutateRemoveFromBagPending.value
  );
});

// download free
const showDownloadFree = computed(() => {
  return (
    bagCollectionId.value &&
    ownedByCustomer &&
    reservationDigitalAsset.value?.content_category === 'behind_the_scenes' &&
    !bagCollectionCdaId.value
  );
});

function handleDownloadFreeClicked() {
  isDownloadFreeOpen.value = false;
  if (route.meta.context) {
    linkClicked({
      context: route.meta.context,
      subContext: 'media asset action',
      linkLabel: 'download free',
    });
  }
}

const filePrice = computed(() => {
  return toCurrency(+props.addOnProduct?.rate, 'USD', 0);
});
</script>

<template>
  <SoonaButton
    v-if="showDownloadFree"
    variation="secondary-black"
    aria-label="download free"
    @on-click="() => (isDownloadFreeOpen = true)"
  >
    <SoonaIcon name="arrow-down-to-bracket" />
    download free
  </SoonaButton>
  <SoonaButton
    :loading="isMutating"
    :disabled="!canBag"
    @click="bagCollectionCdaId ? removeFromBag() : addToBag()"
  >
    <template v-if="bagCollectionCdaId">
      <SoonaIcon name="check" />
      added to bag
    </template>
    <template v-else>
      <SoonaIcon name="soona-bag" />
      add to bag
    </template>
  </SoonaButton>
  <SoonaDialog
    v-if="isDownloadFreeOpen"
    @close="() => (isDownloadFreeOpen = false)"
  >
    <template #heading>download free</template>
    <p>
      enjoy this watermarked BTS clip. to remove the watermark add to bag and
      purchase for {{ filePrice }}. after purchase your BTS clip will be
      available instantly without the watermark.
    </p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @click.capture="close">
        cancel
      </SoonaButton>
      <SoonaButton
        element="a"
        type="submit"
        :href="mediaUrl"
        @on-click="() => handleDownloadFreeClicked()"
      >
        download free
      </SoonaButton>
      <SoonaButton
        data-cypress="button-add-to-bag"
        :loading="isMutating"
        @on-click="() => addToBag()"
      >
        add to bag
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bag-button--sm {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: all;
  margin: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  box-shadow: 0 0 0 0 variables.$gray-30;
  transition:
    background-color 0.1s ease-out,
    box-shadow 0.1s ease-out;

  &:hover,
  &:focus-visible {
    background-color: rgba(255, 255, 255, 0.85);
    box-shadow: 0 0 0 0.125rem variables.$gray-30;
  }

  &:active {
    transition: none;
    box-shadow:
      0 0 0 0.0625rem variables.$white-default,
      0 0 0 0.125rem variables.$periwink-blue-70;
  }
}

.bag-button--lg {
  display: inline-flex;
  background-color: variables.$friendly-red-50;
  font-weight: 900;
  font-size: 0.8125rem;
  line-height: 1.2308;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
  padding: 0.5rem 1rem 0.375rem 1rem;
  white-space: nowrap;
  border-color: variables.$friendly-red-50;
  color: variables.$white-default;
  outline: none;
  box-shadow: none !important;
  border-radius: 0.3125rem;

  &:hover,
  &:focus-visible,
  &:active {
    background-color: variables.$friendly-red-60;
    border-color: variables.$friendly-red-60;
    color: variables.$white-default;
  }
}
</style>
