<template>
  <div class="WelcomeMessage">
    <p class="name">soona</p>
    <div class="avatar_and_message">
      <div class="message_avatar">
        <img
          src="@images/account-wink@2x.png"
          alt="avatar of user who sent this message"
        />
      </div>
      <div class="message_text">
        welcome! we are so excited for your upcoming session!
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WelcomeMessage',
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.WelcomeMessage {
  padding: 0.75rem 0;
  .name {
    display: flex;
    align-items: center;
    color: variables.$gray-90;
    justify-content: flex-start;
    margin-left: 35px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    padding: 3px;
  }
  .avatar_and_message {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .message_avatar {
      height: 32px;
      min-width: 32px;
      img {
        height: 100%;
      }
    }
    .message_text {
      padding: 0.75rem;
      font-size: 14px;
      line-height: 19px;
      text-align: left;
      background: variables.$gray-20;
      border-radius: 1px 10px 10px 10px;
      margin-left: 5px;
      margin-right: 37px;
    }
  }
}
</style>
