<script setup>
import { computed } from 'vue';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useBulkCreateAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useBulkCreateAlbumCollectionDigitalAsset';
import { useCapability } from '@/composables/useCapability';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useMe } from '@/composables/user/useMe';
import { useRouter } from 'vue-router';
import { useUpdateBagCollection } from '@/queries/bag_collection/useUpdateBagCollection';
import { useUpdateFavoritesCollection } from '@/queries/favorites_collection/useUpdateFavoritesCollection';
import OrganizeMenu from '@/components/account_gallery/OrganizeMenu.vue';
// import BulkDownload from '@/components/account_gallery/BulkDownload.vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import useActionBarMediaQuery from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
  showAddToBag: {
    default: false,
    type: Boolean,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['close']);

const { actionBarIsMobile } = useActionBarMediaQuery();
const router = useRouter();

const accountId = computed(() => props.accountId);
const { currentUserId } = useMe();

const selectedAssets = computed(() => props.selectedAssets);
const numberOfAssetsSelected = computed(() => selectedAssets.value.length);

// bag
const { hasCapability: hasBagCapability } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: accountId,
});

const selectedAreAllInBag = computed(() =>
  selectedAssets.value.every(a => a?.bag_collection_digital_asset?.id)
);
const { data: bagCollection } = useBagCollection(accountId);
const bagCollectionId = computed(() => bagCollection.value?.id);

const {
  mutate: updateBagCollectionAssets,
  isPending: isLoadingUpdateBagCollectionAssets,
  error: updateBagCollectionAssetsError,
} = useUpdateBagCollection(accountId, bagCollectionId);

const addToBag = () => {
  const body = selectedAssets.value.reduce((acc, asset) => {
    if (!asset.bag_collection_digital_asset?.id) {
      return [
        ...acc,
        {
          added_by_user_id: currentUserId.value,
          digital_asset_id: asset.id,
        },
      ];
    }

    return acc;
  }, []);

  updateBagCollectionAssets(
    {
      bag_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        // TODO: segment event
        emit('close');
      },
    }
  );
};

// album
const {
  mutate: bulkCreateAlbumCollectionDigitalAssets,
  error: updateAlbumCollectionError,
} = useBulkCreateAlbumCollectionDigitalAsset();

const bulkAddToAlbum = collectionId => {
  bulkCreateAlbumCollectionDigitalAssets(
    {
      collectionId: collectionId,
      body: {
        digital_asset_ids: selectedAssets.value.map(a => a.id),
      },
    },
    {
      onSuccess: () => {
        // TODO: segment event
        router.push({
          path: `/account/${accountId.value}/gallery/albums/${collectionId}`,
        });
      },
    }
  );
};

// favorites
const { data: favoriteCollection } = useFavoritesCollection(accountId);
const favoritesCollectionId = computed(() => favoriteCollection.value?.id);

const {
  mutate: updateFavoritesCollectionAssets,
  error: updateFavoritesCollectionAssetsError,
} = useUpdateFavoritesCollection(accountId, favoritesCollectionId);

const bulkAddToFavorites = () => {
  const body = selectedAssets.value.reduce((acc, asset) => {
    if (!asset.favorites_collection_digital_asset?.id) {
      return [
        ...acc,
        {
          added_by_user_id: currentUserId.value,
          digital_asset_id: asset.id,
        },
      ];
    }

    return acc;
  }, []);

  updateFavoritesCollectionAssets(
    {
      favorites_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        // TODO: segment event
        emit('close');
      },
    }
  );
};

const hasError = computed(() => {
  return [
    updateBagCollectionAssetsError.value,
    updateAlbumCollectionError.value,
    updateFavoritesCollectionAssetsError.value,
  ].some(err => !!err);
});
</script>

<template>
  <SoonaActionBar
    class="multi-select-action-bar"
    :display="numberOfAssetsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #content>
      <SoonaError
        v-if="hasError"
        no-margin
        class="multi-select-action-bar__error"
      >
        something went wrong. please try again.
      </SoonaError>
      <p class="u-label--heavy">
        {{ numberOfAssetsSelected }} asset{{
          numberOfAssetsSelected > 1 ? 's' : ''
        }}
        selected
      </p>
    </template>
    <template #actions>
      <SoonaButton
        size="medium"
        :variation="
          actionBarIsMobile ? 'icon-transparent' : 'secondary-transparent'
        "
        @on-click="$emit('close')"
      >
        <SoonaIcon v-if="actionBarIsMobile" name="xmark" />
        <span :class="{ 'u-visually-hidden': actionBarIsMobile }">cancel</span>
      </SoonaButton>
      <div class="organize-menu">
        <OrganizeMenu
          :account-id="accountId"
          :add-to-album="bulkAddToAlbum"
          :add-to-favorites="bulkAddToFavorites"
          :is-icon-button="actionBarIsMobile"
          :selected-assets="selectedAssets"
          @cancel="$emit('close')"
        />
      </div>
      <SoonaButton
        v-if="showAddToBag"
        size="medium"
        :disabled="
          !hasBagCapability ||
          isLoadingUpdateBagCollectionAssets ||
          selectedAreAllInBag
        "
        :loading="isLoadingUpdateBagCollectionAssets"
        @on-click="addToBag"
      >
        <SoonaIcon name="soona-bag" />
        add to bag
      </SoonaButton>
      <!-- TODO: re-enable once bulk download logic is implemented -->
      <!-- <BulkDownload v-else :account-id="accountId" /> -->
    </template>
  </SoonaActionBar>
</template>

<style lang="scss" scoped>
.multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }
}

.organize-menu {
  margin-left: auto;
}
</style>
