<script setup>
import { ref, computed, watchEffect, onMounted } from 'vue';
import { useTagCategories } from 'src/queries/useTagCategories';
import ProServiceTypeHeader from './ProServiceTypeHeader.vue';
import ProServiceFiltersHeader from './ProServiceFiltersHeader.vue';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  proServiceType: {
    type: [String, null],
    default: '',
    required: true,
  },
  proServiceTypeId: {
    type: [Number, null],
    default: null,
    required: true,
  },
  proServiceCategoryId: {
    type: [Number, null],
    default: null,
    required: true,
  },
  numProviders: {
    type: Number,
    default: 0,
  },
  workedWithAccountId: {
    default: null,
    type: [Number, String],
  },
});

const emit = defineEmits([
  'update:proServiceType',
  'update:proServiceCategoryId',
  'update:proServiceTypeId',
  'update:locationIds',
  'select-models-worked-with',
]);

const filters = defineModel('filters', {
  type: Array,
  default: [],
  required: true,
});

const locationIds = defineModel('locationIds', {
  type: Array,
  default: [],
  required: true,
});

const { me, invalidateMe } = useMe();

const proServiceType = computed(() => props.proServiceType);
const proServiceTypeId = computed(() => props.proServiceTypeId);

const showFilters = ref(!!proServiceType.value && !!proServiceTypeId.value);

const { data: allProServiceOptions } = useTagCategories({ type: 2 });

const proServiceTypes = computed(() =>
  allProServiceOptions.value?.find(
    category => category.title === 'pro services'
  )
);

function getProServiceTypeDetails(proServiceType) {
  return proServiceTypes.value?.tags.find(tag => tag.title === proServiceType);
}

function toggleOpen() {
  showFilters.value = !showFilters.value;
}

function handleProServiceTypeChange(value) {
  if (value !== proServiceType.value) {
    let detail = getProServiceTypeDetails(value);
    const proServiceType = value;
    let proServiceCategoryId = null;
    let proServiceTypeId = null;
    if (detail) {
      proServiceCategoryId = detail.tag_category_id;
      proServiceTypeId = detail.id;
    }
    toggleOpen();
    emit('update:proServiceType', proServiceType);
    emit('update:proServiceCategoryId', proServiceCategoryId);
    emit('update:proServiceTypeId', proServiceTypeId);
  }
}

function close() {
  handleProServiceTypeChange('');
}

watchEffect(() => {
  if (
    locationIds.value.length === 0 &&
    me.value &&
    me.value?.default_or_previous_location
  ) {
    emit('update:locationIds', [me.value.default_or_previous_location.id]);
  }
});

onMounted(() => {
  invalidateMe();
});
</script>
<template>
  <ProServiceFiltersHeader
    v-if="showFilters"
    v-model:filters="filters"
    v-model:location-ids="locationIds"
    :pro-service-type="proServiceType"
    :pro-service-type-id="proServiceTypeId"
    :num-providers="numProviders"
    :worked-with-account-id="workedWithAccountId"
    @close="close()"
    @select-models-worked-with="emit('select-models-worked-with')"
  />
  <ProServiceTypeHeader
    v-else
    v-model:location-ids="locationIds"
    :selected-pro-service-type="proServiceType"
    @update:selected-pro-service-type="handleProServiceTypeChange($event)"
  />
</template>
