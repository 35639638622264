<script setup>
import { computed, ref, reactive, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';
import { useIntegrations } from '@/composables/useIntegrations';
import { useCreateReservationAdditionalChargeOrder } from '@/queries/orders/additional_charge/useCreateReservationAdditionalChargeOrder';
import ProServiceModelDetailsForm from './ProServiceModelDetailsForm.vue';
import ProServiceModelPaymentForm from './ProServiceModelPaymentForm.vue';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { storeToRefs } from 'pinia';

const props = defineProps({
  serviceType: {
    type: String,
    default: null,
  },
  proServiceRequirement: {
    type: Object,
    required: false,
  },
  proServiceExtrasOptions: {
    type: Object,
    required: true,
  },
  accountId: {
    type: [Number, String],
    required: false,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  shootType: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['submit', 'close']);

const salesTaxStore = useSalesTaxStore();
const paymentsSalesTaxFlag = useFlag('payments_sales_tax');

const uploadPercentComplete = ref(0);
const isSubmitted = ref(false);
const step = ref(1);
const selectedProducts = ref([]);
const formData = reactive({
  noteForModel: '',
  manicureSelection: '',
  pedicureSelection: '',
  manicurePressOnColorSelection: '',
  noteForCustomManicure: '',
  noteForCustomPedicure: '',
  hmuSelection: '',
  noteForHMU: '',
  wardrobeSelection: '',
  shavedLegsSelection: '',
  extrasImages: {},
});

const products = computed(() => props.proServiceExtrasOptions);
const reservationId = computed(() => props.reservationId);
const accountId = computed(() => props.accountId);
const serviceType = computed(() => props.serviceType);

const {
  salesTaxBillingAddressId,
  stripeSalesTaxCalculationId,
  taxAmountExclusiveInCents,
} = storeToRefs(salesTaxStore);

const route = useRoute();
const { buttonClicked } = useBaseEvents();
const { hasShopifyIntegration } = useIntegrations(accountId);
const {
  mutate: createAdditionalChargeOrder,
  isPending: isPending,
  error: errorCreatingOrder,
} = useCreateReservationAdditionalChargeOrder(reservationId);

const paymentsModelDetailsFlag = useFlag('payments_model_details');

const proServiceRequirementStatus = computed(
  () => props.proServiceRequirement?.status
);

const checkFullBodyModelFieldsMissing = computed(() => {
  let manicureNotesMissing = true;
  let pedicureNotesMissing = true;

  if (formData.manicureSelection == 'bare') {
    manicureNotesMissing = false;
  } else if (formData.manicureSelection === 'manicure press on') {
    manicureNotesMissing = formData.manicurePressOnColorSelection === '';
  } else if (formData.manicureSelection === 'manicure custom') {
    manicureNotesMissing = formData.noteForCustomManicure === '';
  }
  if (formData.pedicureSelection == 'bare') {
    pedicureNotesMissing = false;
  } else if (formData.pedicureSelection === 'pedicure custom') {
    pedicureNotesMissing = formData.noteForCustomPedicure === '';
  }
  const hmuNotesMissing =
    formData.hmuSelection === 'yes' ? formData.noteForHMU === '' : false;
  return (
    formData.manicureSelection === '' ||
    formData.pedicureSelection === '' ||
    formData.wardrobeSelection === '' ||
    formData.hmuSelection === '' ||
    manicureNotesMissing ||
    pedicureNotesMissing ||
    hmuNotesMissing
  );
});

const isUploading = computed(
  () => uploadPercentComplete.value > 0 && uploadPercentComplete.value !== 100
);
const isFullBodyModelMissing = computed(
  () =>
    serviceType.value === 'full body model' &&
    checkFullBodyModelFieldsMissing.value
);
const isHandModelMissing = computed(
  () => serviceType.value === 'hand model' && formData.manicureSelection === ''
);
const isFootModelMissing = computed(
  () =>
    serviceType.value === 'foot model' &&
    formData.pedicureSelection === '' &&
    formData.shavedLegsSelection === '' &&
    formData.wardrobeSelection === ''
);
const isPetModelMissing = computed(
  () => serviceType.value === 'pet model' && formData.wardrobeSelection === ''
);

const disableSubmit = computed(() => {
  return (
    isUploading.value ||
    isFullBodyModelMissing.value ||
    isHandModelMissing.value ||
    isPetModelMissing.value ||
    isFootModelMissing.value
  );
});

const preSalesTaxSubtotal = computed(() => {
  if (!paymentsModelDetailsFlag.value) {
    return 0;
  }

  let amount = 0;
  selectedProducts.value.forEach(product => {
    amount += product.rate;
  });
  return amount;
});

const productsList = computed(() => {
  return selectedProducts.value
    .filter(product => !!product.id)
    .map(product => {
      return {
        id: product.id,
        quantity: product.quantity || 1,
      };
    });
});

const ctaText = computed(() => {
  if (step.value === 1 && preSalesTaxSubtotal.value > 0) {
    return 'Continue';
  } else {
    return 'Confirm';
  }
});

const headingText = computed(() =>
  step.value === 1 ? 'Model details & extras' : 'Add payment details'
);

function updateSelectedProductIds(optionValue, options, selectionType) {
  const selectedOption = options.find(option => option.value === optionValue);

  // Remove any previous selection of the same type
  selectedProducts.value = selectedProducts.value.filter(
    product => product.type !== selectionType
  );

  // Add the new selection
  if (selectedOption) {
    selectedProducts.value.push({
      type: selectionType,
      value: selectedOption.value,
      id: selectedOption.id || null,
      rate: Number(selectedOption?.rate) || 0,
      tax_code: selectedOption?.tax_code || null,
    });
  }
}

function assignSavedValues() {
  let extras = props.proServiceRequirement?.pro_service_extras;
  formData.noteForModel = extras.note;
  formData.manicureSelection = extras.manicure?.selection;
  formData.pedicureSelection = extras.pedicure?.selection;
  formData.manicurePressOnColorSelection =
    formData.manicureSelection == 'manicure press on'
      ? extras.manicure?.selectionNote
      : '';
  formData.noteForCustomManicure =
    formData.manicureSelection == 'manicure custom'
      ? extras.manicure?.selectionNote
      : '';
  formData.noteForCustomPedicure =
    formData.pedicureSelection == 'pedicure custom'
      ? extras.pedicure?.selectionNote
      : '';
  formData.extrasImages.manicure_reference_image = props.proServiceRequirement
    ? props.proServiceRequirement.manicure_reference_image
    : {};
  formData.extrasImages.pedicure_reference_image = props.proServiceRequirement
    ? props.proServiceRequirement.pedicure_reference_image
    : {};
  formData.extrasImages.hmu_reference_image = props.proServiceRequirement
    ? props.proServiceRequirement.hmu_reference_image
    : {};
  formData.hmuSelection = extras.hmu?.selection;
  formData.noteForHMU = extras.hmu?.selectionNote;
  formData.wardrobeSelection = extras.wardrobe?.selection;
  formData.shavedLegsSelection = extras.shaved_legs?.selection;
}

function clearData() {
  formData.noteForModel = '';
  formData.manicureSelection = '';
  formData.manicurePressOnColorSelection = '';
  formData.noteForCustomManicure = '';
  formData.pedicureSelection = '';
  formData.noteForCustomPedicure = '';
  formData.hmuSelection = '';
  formData.noteForHMU = '';
  formData.wardrobeSelection = '';
  formData.shavedLegsSelection = '';
  formData.extrasImages = {};
}

function submitExtras(status) {
  buttonClicked({
    context: route.meta.context,
    subContext: `${serviceType.value} details & extras modal`,
    buttonLabel: 'confirm',
    buttonAction: `submits model details & extras data`,
  });

  let payload = { extrasPayload: {}, imagesPayload: {} };

  let manicureSelectionNote =
    formData.manicureSelection === 'manicure press on'
      ? formData.manicurePressOnColorSelection
      : formData.manicureSelection === 'manicure custom'
        ? formData.noteForCustomManicure
        : '';

  let pedicureSelectionNote =
    formData.pedicureSelection === 'pedicure custom'
      ? formData.noteForCustomPedicure
      : '';

  if (serviceType.value === 'full body model') {
    if (formData.hmuSelection === 'no') {
      formData.noteForHMU = '';
    }
    payload.extrasPayload = {
      note: formData.noteForModel,
      manicure: {
        selection: formData.manicureSelection,
        selectionNote: manicureSelectionNote,
      },
      pedicure: {
        selection: formData.pedicureSelection,
        selectionNote: pedicureSelectionNote,
      },
      hmu: {
        selection: formData.hmuSelection,
        selectionNote: formData.noteForHMU,
      },
      wardrobe: {
        selection: formData.wardrobeSelection,
      },
    };
  } else if (serviceType.value === 'hand model') {
    payload.extrasPayload = {
      note: formData.noteForModel,
      manicure: {
        selection: formData.manicureSelection,
        selectionNote: manicureSelectionNote,
      },
    };
  } else if (serviceType.value === 'pet model') {
    payload.extrasPayload = {
      note: formData.noteForModel,
      wardrobe: {
        selection: formData.wardrobeSelection,
      },
    };
  } else if (serviceType.value === 'content creator') {
    payload.extrasPayload = {
      note: formData.noteForModel,
    };
  } else if (serviceType.value === 'foot model') {
    payload.extrasPayload = {
      note: formData.noteForModel,
      pedicure: {
        selection: formData.pedicureSelection,
        selectionNote: pedicureSelectionNote,
      },
      wardrobe: {
        selection: formData.wardrobeSelection,
      },
      shaved_legs: {
        selection: formData.shavedLegsSelection,
      },
    };
  }

  if (formData.extrasImages.hmu_reference_image?.signed_id) {
    payload.imagesPayload.hmu_reference_image =
      formData.extrasImages.hmu_reference_image.signed_id;
  }
  if (formData.extrasImages.manicure_reference_image?.signed_id) {
    payload.imagesPayload.manicure_reference_image =
      formData.extrasImages.manicure_reference_image.signed_id;
  }
  if (formData.extrasImages.pedicure_reference_image?.signed_id) {
    payload.imagesPayload.pedicure_reference_image =
      formData.extrasImages.pedicure_reference_image.signed_id;
  }
  emit('submit', payload, status);
  clearData();
}

async function submitOrder(
  paymentMethodId,
  paymentMethodType,
  savePaymentMethod,
  shopifyStore
) {
  const purchasableProducts = {};
  selectedProducts.value.forEach(product => {
    if (product.id === null) {
      return;
    }
    purchasableProducts[product.id] = 1;
  });
  const body = {
    products: purchasableProducts,
    reservationId: reservationId.value,
    accountId: accountId.value,
    paymentMethodId: paymentMethodId,
    paymentMethodType: paymentMethodType,
    savePaymentMethod: savePaymentMethod,
    chargeImmediately: shopifyStore ? false : true,
    salesTax: paymentsSalesTaxFlag.value
      ? {
          soona_billing_address_id: salesTaxBillingAddressId.value,
          id: stripeSalesTaxCalculationId.value,
          tax_amount_exclusive: taxAmountExclusiveInCents.value,
        }
      : {},
  };

  await createAdditionalChargeOrder(body, {
    onSuccess: response => {
      if (response?.confirmation_url) {
        window.location.href = response.confirmation_url;
      }
      submitExtras();
    },
  });
}

function cancelDialog() {
  clearData();
  emit('close');
}

function handleButtonClick() {
  if (!paymentsModelDetailsFlag.value) {
    return submitExtras();
  }

  if (step.value === 1 && preSalesTaxSubtotal.value > 0) {
    step.value = 2;
  } else {
    submitExtras();
  }
}

function handleBackButtonClick() {
  step.value = 1;
}

onMounted(() => {
  const proServiceExtras = props.proServiceRequirement?.pro_service_extras;
  const purchasableProducts = ['press on', 'custom', 'hair'];

  let purchasedProServices = false;

  if (proServiceExtras && typeof proServiceExtras === 'object') {
    assignSavedValues();
    Object.entries(proServiceExtras).forEach(([, extras]) => {
      if (
        typeof extras.selection === 'string' &&
        purchasableProducts.some(product => extras.selection.includes(product))
      ) {
        purchasedProServices = true;
      }
    });
  }

  if (
    proServiceRequirementStatus.value === 'submitted' &&
    paymentsModelDetailsFlag.value &&
    purchasedProServices
  ) {
    isSubmitted.value = true;
  }
});
</script>

<template>
  <div class="pro-service-model-extras">
    <SoonaDialog
      aria-label="pro service provider selection extra info"
      @close="cancelDialog"
    >
      <template #heading>
        <span>{{ headingText }}</span>
      </template>
      <template #default>
        <SoonaButton
          v-if="step === 2"
          variation="tertiary"
          element="button"
          class="back-button"
          :is-disabled="isPending"
          @on-click="handleBackButtonClick"
        >
          <SoonaIcon name="arrow-left" />
          back
        </SoonaButton>
        <SoonaLoading
          v-if="isPending"
          is-loading
          loading-text="processing"
          is-contained
        />
        <SoonaError v-if="errorCreatingOrder" variation="danger">
          {{ errorCreatingOrder.response?.data?.message }}
        </SoonaError>
        <ProServiceModelDetailsForm
          v-if="step === 1"
          :products="products"
          :form-data="formData"
          :service-type="serviceType"
          :is-submitted="isSubmitted"
          :context="route.meta.context"
          :payments-model-details-flag="paymentsModelDetailsFlag"
          @update-selection="updateSelectedProductIds"
        />
        <ProServiceModelPaymentForm
          v-if="step === 2 && !isPending"
          :has-shopify-integration="hasShopifyIntegration"
          :account-id="accountId"
          :products-list="productsList"
          :pre-sales-tax-subtotal="preSalesTaxSubtotal"
          @submit="submitOrder"
        />
      </template>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" size="large" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton
          v-if="step === 1"
          data-cypress="button-dialog-confirm"
          :disabled="disableSubmit"
          @on-click="handleButtonClick"
        >
          {{ ctaText }}
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.pro-service-model-extras {
  @include variables_fonts.u-label--regular;

  .element {
    padding: 0;
    margin-top: 0.625rem;
  }

  .field-section {
    padding-bottom: 1.5rem;
  }
}

.back-button {
  margin-bottom: 1.5rem;
}

@media screen and (max-width: variables.$screen-xxs-max) {
  .pro-service-model-extras {
    .element {
      min-width: 20.3125rem !important;
    }
  }
}
</style>
