import { readonly } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import iconsJsonUrl from './sidebar-icons.json?url';

/**
 *
 * @returns {DeepReadonly<UnwrapNestedRefs<Ref<undefined | Object>>>}
 */
export function useSidebarIconData() {
  const { data } = useQuery({
    queryKey: ['sidebar-icon-data'],
    queryFn: () => fetch(iconsJsonUrl).then(response => response.json()),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  return readonly(data);
}
