<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

const props = defineProps({
  selectedSort: {
    required: true,
    type: String,
  },
  sortDirection: {
    required: true,
    type: String,
  },
});

const emits = defineEmits(['change-sort-direction', 'update:selectedSort']);

const sortOptions = [
  { label: 'date modified', value: 'date modified' },
  { label: 'date created', value: 'date created' },
  { label: 'alphabetical', value: 'alphabetical' },
];

const sortDirection = computed(() => props.sortDirection);
const directionIcon = computed(() => {
  return sortDirection.value === 'desc' ? 'arrow-down' : 'arrow-up';
});

const sortBy = computed({
  get() {
    return props.selectedSort;
  },
  set(sortBy) {
    emits('update:selectedSort', sortBy);
  },
});
</script>

<template>
  <div class="sort-by">
    <SoonaTooltip placement="top">
      <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
        <SoonaButton
          :ref="el => setRef(el)"
          class="u-button-reset"
          variation="icon-plain-gray"
          @click="emits('change-sort-direction')"
          @mouseenter="mouseenter"
          @focus="focus"
          @mouseleave="mouseleave"
          @blur="blur"
        >
          <SoonaIcon :name="directionIcon" />
        </SoonaButton>
      </template>
      <template #tooltip-content>change sort direction</template>
    </SoonaTooltip>
    <SoonaSelect
      v-model="sortBy"
      :options="sortOptions"
      placeholder="date modified"
    />
  </div>
</template>

<style lang="scss" scoped>
.sort-by {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
