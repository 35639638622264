<script setup>
import { computed, toRef } from 'vue';
import ProductConnectedListingCard from './ProductConnectedListingCard.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useGetCatalogItemActiveListings } from 'src/queries/useGetCatalogItemActiveListings';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  accountId: { type: String, required: true },
  product: { type: Object, required: true },
});

const product = computed(() => props.product);
const productId = computed(() => product.value.id);

const { data: listingsData, error } = useGetCatalogItemActiveListings(
  toRef(() => props.accountId),
  productId,
  {
    placeholderData: keepPreviousData,
  }
);

const listings = computed(() => listingsData.value?.listings ?? []);
</script>

<template>
  <div v-if="listings.length > 0" class="product-listings">
    <h2 class="product-listings__heading u-title--heavy">listing insights</h2>
    <div class="product-listings__content">
      <template v-for="listing in listings" :key="listing.id">
        <ProductConnectedListingCard
          :account-id="accountId"
          :listing="listing"
        />
      </template>
    </div>
  </div>
  <SoonaError v-if="error">{{ error.message }}</SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';

.product-listings {
  &__heading {
    margin-bottom: 0.75rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
  }
}
</style>
