<script setup>
import { computed, ref } from 'vue';
import { useFlag } from '@/composables/useFlag';
import { useSubscription } from '@/composables/subscription/useSubscription';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBulkGetAssetAddOns } from '@/queries/bag/useBulkGetAssetAddOns';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';

import SoonaLoading from '@/components/SoonaLoading.vue';
import { useBulkApplyAssetAddOns } from '@/queries/bag/useBulkApplyAssetAddOns';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  assetIds: {
    required: true,
    type: Array,
  },
  open: {
    required: true,
    type: Boolean,
  },
});

const emit = defineEmits(['close', 'addOnsSaved']);

const pegasusDigitalAssetsFlag = useFlag('pegasus_digital_assets');
const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const assetType = computed(() => {
  if (pegasusDigitalAssetsFlag.value) {
    return 'digital_asset';
  }
  return 'reservation_file';
});
const accountId = computed(() => props.accountId);
const assetIds = computed(() => props.assetIds);
const open = computed(() => props.open);
const isSaving = ref(false);

const {
  data: addOnData,
  isLoading: bulkAssetsLoading,
  error: loadSharedGalleryError,
} = useBulkGetAssetAddOns(accountId, { assetType, assetIds });

const { hasTierTwoSubscription, hasTierThreeSubscription } =
  useSubscription(accountId);

const { addToast } = useSoonaToast();

const bulkApplyAddOns = useBulkApplyAssetAddOns(accountId);

const priorityError = usePriorityError(loadSharedGalleryError);

const isLoading = computed(() => {
  return bulkAssetsLoading.value;
});

const lineItems = computed(() => addOnData.value?.data.line_items || []);

const lineItemsCreditedByMembership = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return (
      lineItems.value.some(li => li.credited_by_preferred) ||
      hasTierTwoSubscription.value ||
      hasTierThreeSubscription.value
    );
  } else {
    return lineItems.value.some(li => li.credited_by_preferred);
  }
});

const mediaAddOns = computed(
  () => lineItems.value[0]?.media_add_on_line_items || []
);

const toCurrency = (value, currency = 'USD') => {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });
};

const photosWithAddOns = addOnProductName => {
  return lineItems.value?.filter(lineItem =>
    lineItem.media_add_on_line_items?.some(
      addOn => addOn.product.name === addOnProductName && addOn.selected
    )
  );
};

const photoAddOnUsage = computed(() => {
  return mediaAddOns.value.reduce((addOnUsage, addOn) => {
    const addOnCount = photosWithAddOns(addOn.product.name).length;
    addOnUsage[addOn.product.name] = {
      all: addOnCount === assetIds.value?.length,
      none: addOnCount === 0,
      some: addOnCount > 0 && addOnCount < assetIds.value?.length,
    };
    return addOnUsage;
  }, {});
});

const add = ref({});
const remove = ref({});

const addProduct = addOnProductName => {
  delete remove.value[addOnProductName];

  for (const photo of addOnData.value.data.line_items) {
    const matchingAddOn = photo.media_add_on_line_items.find(
      addOn => addOn.product.name === addOnProductName
    );
    if (!matchingAddOn.selected) {
      addOnProductName in add.value || (add.value[addOnProductName] = []);
      if (pegasusDigitalAssetsFlag.value) {
        add.value[addOnProductName].push({
          product_id: matchingAddOn.product.id,
          reservation_id: photo.reservation_id,
          quantity: 1,
          digital_asset_id: photo.digital_asset_id,
        });
      } else {
        add.value[addOnProductName].push({
          product_id: matchingAddOn.product.id,
          reservation_id: photo.reservation_id,
          quantity: 1,
          reservation_file_id: photo.reservation_file_id,
        });
      }
    }
  }
};

const removeProduct = addOnProductName => {
  delete add.value[addOnProductName];

  for (const photo of addOnData.value.data.line_items) {
    const matchingAddOn = photo.media_add_on_line_items.find(
      addOn => addOn.product.name === addOnProductName
    );

    if (matchingAddOn.selected) {
      addOnProductName in remove.value || (remove.value[addOnProductName] = []);
      remove.value[addOnProductName].push({
        line_item_id: matchingAddOn.id,
      });
    }
  }
};

const handleSave = async () => {
  isSaving.value = true;

  bulkApplyAddOns.mutate(
    {
      addOnData: {
        add: add.value,
        remove: remove.value,
      },
    },
    {
      onSuccess: () => {
        isSaving.value = false;
        addToast('edits applied successfully!', {
          variation: 'success',
        });
        emit('addOnsSaved');
      },
      onError: () => {
        isSaving.value = false;
        addToast(`error applying edits`, {
          variation: 'error',
        });
      },
    }
  );
};

const handleCheckboxClick = (e, addOnProductName) => {
  const isChecked = e.target.checked;
  if (isChecked) {
    addProduct(addOnProductName);
  } else {
    removeProduct(addOnProductName);
  }
};

const headingCopy = computed(() => {
  const numberOfPhotos = assetIds.value?.length;
  return `apply premium edits to (${numberOfPhotos}) photo${
    numberOfPhotos > 1 ? 's' : ''
  }`;
});

const applyCopy = computed(() => {
  const numberOfPhotos = assetIds.value?.length;
  return `apply to (${numberOfPhotos}) photo${numberOfPhotos > 1 ? 's' : ''}`;
});
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaDialog v-if="open && !isLoading" @close="() => emit('close')">
    <Teleport v-if="isSaving" to="body">
      <SoonaLoading v-if="isSaving" :is-loading="true" loading-text="saving" />
    </Teleport>
    <template #heading>{{ headingCopy }}</template>
    <template #header>
      <div class="bulk-choose-edits-modal__pricing">
        <span class="u-body--heavy">
          photo premium edits are $9 each or FREE for customers with preferred
          credits benefits.
        </span>
      </div>
      <div
        v-if="lineItemsCreditedByMembership"
        class="bulk-choose-edits-modal__flag"
      >
        <SoonaFlag
          class="bulk-choose-edits-modal__flag"
          title="preferred credits available"
          background-color="#e4e8fd"
          border-color="#6b7eed"
          font-weight="bolder"
        />
      </div>
    </template>
    <div
      v-for="addOn in mediaAddOns"
      :key="addOn.id"
      class="bulk-choose-edits-modal__input-container"
    >
      <label class="u-body--regular bulk-choose-edits-modal__label">
        <input
          class="bulk-choose-edits-modal__input"
          type="checkbox"
          :checked="photoAddOnUsage[addOn.product.name].all"
          :indeterminate="photoAddOnUsage[addOn.product.name].some"
          @change="e => handleCheckboxClick(e, addOn.product.name)"
        />
        <template v-if="lineItemsCreditedByMembership">
          {{ addOn.product.name }}
        </template>
        <template v-else>
          {{ addOn.product.name }} {{ toCurrency(addOn.product.rate, 'USD') }}
        </template>
      </label>
      <p class="u-label--regular bulk-choose-edits-modal__helper">
        {{ addOn.product.description }}
      </p>
    </div>
    <template #footer="{ close }">
      <small class="u-small--regular bulk-choose-edits-modal__disclaimer">
        *some edit requests are out of soona’s scope. custom requests beyond
        standard edits and premium edits are subject to additional pricing.
      </small>
      <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
      <SoonaButton size="medium" type="submit" @click="handleSave">
        {{ applyCopy }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bulk-choose-edits-modal {
  &__pricing {
    margin: 1.5rem 0 1.25rem;
  }

  &__flag {
    margin: 0 auto;
  }

  &__title {
    word-break: break-word;
  }

  &__input-container:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &__input {
    cursor: pointer;
    accent-color: variables.$periwink-blue-60;
    margin-right: 0.3125rem;
  }

  &__helper {
    color: variables.$gray-60;
  }

  &__disclaimer {
    flex-grow: 1;
    flex-basis: 100%;
    margin: -0.75rem 0 0.75rem;
    color: variables.$gray-60;
  }
}
</style>
