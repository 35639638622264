import { useMutation } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import { useMe } from '@/composables/user/useMe';
import { http } from '@/config/vue-axios';

/**
 */
export function useCreateAccount() {
  const { resetMe } = useMe();

  return useMutation({
    mutationFn: async attributes => {
      const response = await http.post(`/accounts.json`, attributes);
      return response.data;
    },
    onSuccess: async () => {
      // reload absolutely everything
      await resetMe();
    },
    onError: error =>
      Sentry.captureException(new Error('failed to create account'), {
        extra: { error },
      }),
  });
}
