<script setup>
import { computed } from 'vue';
import AssetImage from '@/components/checkout/AssetImage.vue';
import AssetHeading from '@/components/checkout/AssetHeading.vue';
import AssetNotes from '@/components/checkout/AssetNotes.vue';
import AssetAddOns from '@/components/checkout/AssetAddOns.vue';
import StandardEditCopy from '@/components/checkout/StandardEditCopy.vue';
import { useGetAllEditHistory } from '@/queries/useGetAllEditHistory';
import { useReservationPaidOrderSummary } from '@/queries/reservations/useReservationPaidOrderSummary';
import { useFlag } from '@/composables/useFlag';
import OrderPreviewCard from '@/components/user/anytime/reservation/OrderPreviewCard.vue';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const toasterReservationEditHistoryFlag = useFlag(
  'toaster_reservation_edit_history'
);

const accountId = computed(() => props.accountId);
const reservationId = computed(() => props.reservationId);

// TODO: remove with toaster_reservation_edit_history
const { data: paidOrderSummary } =
  useReservationPaidOrderSummary(reservationId);

const { data: orders } = useGetAllEditHistory(reservationId);

function hasAddOn(mediaAddOn, lineItem) {
  return lineItem.media_add_on_line_items.filter(
    m => m.product_id == mediaAddOn.id
  ).length;
}

function sortAddOns(addOns) {
  const sortOrder = [
    'change background color / pure white',
    'product touch-up',
    'transparent background',
    'buy the raw',
    'smooth skin',
    'create pattern',
    'photoshop it',
  ];
  return [...addOns].sort(
    (a, b) => sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
  );
}

const addOnList = lineItem => {
  return sortAddOns(
    lineItem.available_media_add_ons.filter(
      a => !a._destroy && hasAddOn(a, lineItem)
    )
  );
};

const showSection = computed(
  () =>
    (paidOrderSummary.value?.line_items &&
      paidOrderSummary.value.line_items.length > 0) ||
    !!orders.value?.find(o => o.status === 'paid' || o.status === 'submitted')
);
</script>

<template>
  <section v-if="showSection" class="paid-order">
    <h2 class="paid-order__heading">recent editing orders:</h2>
    <ul class="paid-order__assets">
      <template v-if="toasterReservationEditHistoryFlag">
        <template v-for="order in orders" :key="order.id">
          <li v-if="order.status === 'paid' || order.status === 'submitted'">
            <OrderPreviewCard :order="order" />
          </li>
        </template>
      </template>
      <!-- TODO: remove with toaster_reservation_edit_history  -->
      <template v-else>
        <li
          v-for="(lineItem, index) in paidOrderSummary.line_items"
          :key="index"
          class="paid-order__asset"
        >
          <AssetImage
            :content-type="lineItem.content_type"
            :watermark-url="lineItem.watermark?.url || lineItem.watermark_url"
            :image-url="lineItem.preview?.url || lineItem.preview_url"
            :is-video="lineItem.is_video"
            :is-gif="lineItem.is_gif"
            :title="lineItem.title"
          />
          <div class="paid-order__asset-right">
            <AssetHeading
              :asset-id="lineItem.id"
              :reservation-id="lineItem.reservation_id"
              :title="lineItem.title"
              :using-preferred="lineItem.credited_by_preferred_credits"
              :show-price="false"
              :show-link="false"
            />
            <AssetAddOns
              v-if="addOnList(lineItem).length"
              :account-id="accountId"
              :add-on-list="addOnList(lineItem)"
              :reservation-id="lineItem.reservation_id"
              :hide-right="true"
            />
            <StandardEditCopy v-else :show-price="false" />
          </div>
          <AssetNotes
            class="paid-order__asset-bottom"
            :asset="lineItem"
            label="notes"
          />
        </li>
      </template>
    </ul>
  </section>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.paid-order {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  &__heading {
    @include variables_fonts.u-body--heavy;

    margin-bottom: 0.75rem;
  }

  &__assets {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &__asset {
    display: grid;
    grid-template-columns: 6.25rem auto;
    grid-template-rows: auto min-content 1fr auto;
    border-radius: 0.625rem;
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    padding: 1rem;
    max-width: 49.5rem;

    &-right {
      grid-area: 2 / 2 / 2 / 2;
      margin-left: 0.75rem;
      padding-left: 0;
      border-radius: 0;
    }

    &-bottom {
      grid-area: 3 / 1 / 3 / 3;
      margin-top: 1rem;
      padding-left: 0;
      border-radius: 0;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__heading {
      @include variables_fonts.u-subheader--heavy;

      margin-bottom: 1rem;
    }

    &__assets {
      gap: 1rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &__asset {
      grid-template-columns: 10.25rem auto;

      &-bottom {
        grid-area: 3 / 2 / 3 / 2;
        margin-left: 0.75rem;
      }
    }
  }
}
</style>
