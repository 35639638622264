<script setup>
import { ref, computed } from 'vue';
import ProductReservationCard from './ProductReservationCard.vue';
import PaginatorSmall from '@/components/PaginatorSmall.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useGetCatalogItemReservations } from 'src/queries/useGetCatalogItemReservations';
import { keepPreviousData } from '@tanstack/vue-query';

const props = defineProps({
  accountId: { type: String, required: true },
  productId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);
const productId = computed(() => props.productId);

const currentPage = ref(1);
const itemsPerPage = ref(5);

const { data: reservationsData, error } = useGetCatalogItemReservations(
  accountId,
  productId,
  {
    itemsPerPage,
    currentPage,
  },
  {
    placeholderData: keepPreviousData,
  }
);

const reservations = computed(() => reservationsData.value?.reservations ?? []);

const reservationsPagination = computed(() =>
  reservationsData.value
    ? reservationsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);
</script>

<template>
  <div v-if="reservations.length > 0" class="product-reservations">
    <h2 class="product-reservations__heading">related bookings</h2>
    <div class="product-reservations__content">
      <ProductReservationCard
        v-for="reservation in reservations"
        :key="reservation.id"
        :reservation="reservation"
      />
      <PaginatorSmall
        class="product-reservations__paginator"
        :page="reservationsPagination.currentPage"
        :pages="reservationsPagination.totalPages"
        @page-change="currentPage = $event"
      />
    </div>
  </div>
  <SoonaError v-if="error">{{ error.message }}</SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-reservations {
  &__heading {
    @include variables_fonts.u-title--heavy;

    margin-bottom: 0.75rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
  }

  &__paginator {
    border-bottom: 0.0625rem solid variables.$gray-30;
    width: 100%;
  }
}
</style>
