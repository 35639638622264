<script setup>
import SoonaUserAvatar from '@/components/ui_library/SoonaUserAvatar.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';
import { useFlag } from '@/composables/useFlag';

defineProps({
  preferredCredits: {
    type: Number,
    default: 0,
  },
});

const { currentAccountId, me } = useMe();
const { account: currentAccount } = useAccount(currentAccountId);

const paymentsDisablePreferredFlag = useFlag('payments_disable_preferred');
</script>

<template>
  <button
    class="user-button"
    :class="{
      'user-button__no-credits':
        paymentsDisablePreferredFlag && preferredCredits === 0,
    }"
    aria-label="open user menu"
    aria-describedby="sidebar-user-info"
    data-cypress="open-user-menu"
    v-bind="$attrs"
  >
    <SoonaUserAvatar
      class="user-account-avatar"
      :src="currentAccount?.avatar_url"
      img-size="2rem"
      svg-size="2rem"
    />
    <span class="user-button__container">
      <span class="user-button__account-name">
        {{ currentAccount?.name }}
      </span>
    </span>
    <SoonaIcon name="gear" size="small" />
  </button>
  <p
    id="sidebar-user-info"
    class="u-visually-hidden"
    data-cypress="sidebar-user-info-description"
  >
    current user: {{ me?.name }}. current account: {{ currentAccount?.name }}.
  </p>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.user-button {
  background-color: variables.$white-default;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  color: variables.$black-default;
  transition: background-color 0.1s ease-out;
  cursor: pointer;
  padding: 0.75rem;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:hover {
    background-color: variables.$bubbletape-pink-10;
  }

  &:active {
    background-color: variables.$bubbletape-pink-20;
  }

  .user-account-avatar {
    color: variables.$black-default;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }

  &__account-name {
    @include variables_fonts.u-small--heavy;

    text-align: left;
    word-break: break-word;
  }

  &__no-credits {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}
</style>
