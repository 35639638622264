import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { toValue } from 'vue';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} [reservationId]
 * @param {number | Ref<number>} [schedulingPreferenceId]
 *
 * @returns {UseQueryReturnType<any, unknown>}
 */
export function useUpdateSchedulingPreference(
  reservationId,
  schedulingPreferenceId
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const response = await http.put(
        `/reservations/${toValue(reservationId)}/scheduling_preferences/${toValue(schedulingPreferenceId)}`,
        {
          scheduling_preference: body,
        }
      );

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.schedulingPreference(reservationId),
      });
    },
  });
}
