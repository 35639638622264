<script setup>
import { computed, ref, watch } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import accountImage from 'images/account-placeholder.svg';
import CollaboratorRoleDropdown from './CollaboratorRoleDropdown.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import RemoveCollaboratorDialog from '@/components/user/anytime/dashboard/collaborators/RemoveCollaboratorDialog.vue';
import InviteCollaboratorDialog from './InviteCollaboratorDialog.vue';
import { useCollaborators } from '@/queries/collaborators/useCollaborators';
import { usePriorityError } from '@/composables/usePriorityError';
import { useStore } from 'vuex';
import { useCapability } from 'src/composables/useCapability';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});
const accountId = computed(() => props.accountId);

const store = useStore();
const currentUserId = computed(
  () => store.getters['currentUser/currentUserId']
);

const itemsPerPage = ref(20);
const currentPage = ref(1);
watch(itemsPerPage, () => {
  currentPage.value = 1;
});

const {
  data: collaborators,
  error: collaboratorsError,
  isSuccess: collaboratorsSuccess,
} = useCollaborators(accountId, {
  currentPage,
  itemsPerPage,
});
const collaboratorsItems = computed(
  () => collaborators.value?.collaborators ?? []
);
const collaboratorsPagination = computed(() => {
  if (collaborators.value) {
    return collaborators.value.pagination;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const accountOwnerId = computed(
  () =>
    collaborators.value?.collaborators.find(x => x.is_account_owner)?.user_id
);

const formatRole = role => role.replace('account ', '');

const collaboratorToRemove = ref(null);
const inviteDialogOpen = ref(false);

const priorityError = usePriorityError(collaboratorsError);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});
</script>

<template>
  <div class="collaborators-list">
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <SoonaNoResults
      v-if="collaboratorsSuccess && collaboratorsItems.length === 0"
      :show-image="false"
      class="collaborators-list__no-results"
    >
      <template #header>create and share assets with your team</template>
      <template #body>
        account collaborators can mark their favorites. create and edit
        reservations. make purchases.
      </template>
      <template #button>
        <SoonaButton
          variation="secondary-black"
          @on-click="inviteDialogOpen = true"
        >
          invite team members
        </SoonaButton>
      </template>
    </SoonaNoResults>
    <table v-else-if="collaboratorsItems.length > 0" class="collaborator-table">
      <thead class="table-header">
        <tr>
          <th colspan="2" class="avatar-column u-badge--small">name</th>
          <th class="role-column is-hidden-mobile u-badge--small">role</th>
          <th
            v-if="isFtSoonaStaff"
            class="role-column is-hidden-mobile u-badge--small"
          >
            phone number
          </th>
          <th class="menu-column">
            <span class="u-visually-hidden">actions</span>
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr v-for="item in collaboratorsItems" :key="item.id">
          <td class="avatar-column is-hidden-mobile">
            <img
              class="collaborator-image"
              :src="item.avatar_url || accountImage"
              alt="user avatar"
            />
          </td>
          <td class="name-column">
            <p class="u-body--heavy collaborator-name">
              {{ item.name }}
            </p>
            <p class="u-small--regular collaborator-email">
              {{ item.email }}
            </p>
            <div class="u-button--large-caps is-hidden-tablet">
              <CollaboratorRoleDropdown
                :account-id="accountId"
                :account-owner-id="accountOwnerId"
                :collaborator="item"
              />
            </div>
          </td>
          <td class="role-column is-hidden-mobile">
            <p
              v-if="
                accountOwnerId !== currentUserId &&
                currentUserId === item.user_id
              "
            >
              {{ formatRole(item.role.name) }}
            </p>
            <CollaboratorRoleDropdown
              v-else
              :account-id="accountId"
              :account-owner-id="accountOwnerId"
              :collaborator="item"
            />
          </td>
          <td v-if="isFtSoonaStaff" class="name-column">
            <p class="u-small--regular collaborator-email">
              {{ item.phone }}
            </p>
          </td>
          <td class="menu-column">
            <SoonaDropdownMenu
              v-if="
                currentUserId !== item.user_id &&
                accountOwnerId !== item.user_id
              "
              variation="icon-plain-gray"
              title="Collaborator Actions"
            >
              <template #trigger-content>
                <SoonaIcon name="dots-horizontal" size="large" />
              </template>
              <template #default="{ clickCapture, keydown, mouseover }">
                <SoonaDropdownMenuItem>
                  <button
                    role="menuitem"
                    @click="collaboratorToRemove = item"
                    @click.capture="clickCapture"
                    @keydown="keydown"
                    @mouseover="mouseover"
                  >
                    remove from team
                  </button>
                </SoonaDropdownMenuItem>
              </template>
            </SoonaDropdownMenu>
          </td>
        </tr>
      </tbody>
    </table>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <PaginatorFull
      v-model:page="currentPage"
      v-model:records-per-page="itemsPerPage"
      :total-pages="collaboratorsPagination.totalPages"
      :total-count="collaboratorsPagination.totalCount"
      :page-count="collaboratorsItems.length"
    />

    <RemoveCollaboratorDialog
      v-if="collaboratorToRemove"
      :account-id="accountId"
      :collaborator="collaboratorToRemove"
      @close="collaboratorToRemove = null"
    />

    <InviteCollaboratorDialog
      v-if="inviteDialogOpen"
      :account-id="accountId"
      @close="inviteDialogOpen = false"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.collaborators-list {
  &__no-results {
    margin-top: 1rem;
  }

  .collaborator-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;

    .avatar-column {
      padding: 1rem 0 1rem 0.75rem;
      width: 2.75rem;
    }

    .name-column {
      padding: 1rem 0.75rem;

      p {
        word-break: break-word;
      }
    }

    .role-column {
      padding: 1rem 0.75rem;
    }

    .menu-column {
      padding: 1rem 0.25rem;
      text-align: right;
    }

    .table-header {
      border-bottom: solid 0.0625rem variables.$gray-30;

      th {
        color: variables.$gray-60;
      }
    }

    .table-body {
      tr {
        border-bottom: solid 0.0625rem variables.$gray-30;

        td {
          vertical-align: middle;
        }
      }
    }

    .collaborator-image {
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 1rem;
      object-fit: cover;
    }

    .collaborator-email {
      user-select: all;
    }

    .collaborator-role-menu-item {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
