export const accountGalleryPaths = [
  {
    path: '/account/:accountId/gallery',
    name: 'account-gallery',
    component: () =>
      import('src/components/account_gallery/AccountGallery.vue'),
    meta: {
      context: 'account gallery',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/albums',
    name: 'albums',
    component: () => import('src/components/account_gallery/AllAlbums.vue'),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'my albums' },
      ],
      context: 'albums',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/albums/:collectionId',
    name: 'album-assets',
    component: () =>
      import(
        '@/components/account_gallery/collections/AlbumCollectionAssets.vue'
      ),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'my albums', routeName: 'albums' },
        { copy: 'album_title' },
      ],
      context: 'album assets',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
      collectionId: route.params.collectionId,
    }),
  },
  {
    path: '/account/:accountId/gallery/edits',
    name: 'edits-assets',
    component: () =>
      import(
        '@/components/account_gallery/collections/EditsCollectionAssets.vue'
      ),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'album_title' },
      ],
      context: 'edits assets',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/favorites',
    name: 'favorites-assets',
    component: () =>
      import(
        '@/components/account_gallery/collections/FavoritesCollectionAssets.vue'
      ),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'album_title' },
      ],
      context: 'favorites assets',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/expert-picks',
    name: 'expert-picks-assets',
    component: () =>
      import(
        '@/components/account_gallery/collections/StaffPicksCollectionAssets.vue'
      ),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'album_title' },
      ],
      context: 'expert picks assets',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/assets',
    name: 'assets',
    component: () => import('src/components/account_gallery/AllAssets.vue'),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'my assets' },
      ],
      context: 'assets',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/assets/asset/:digitalAssetId',
    name: 'all-assets-media-view',
    component: () =>
      import('@/components/account_gallery/AllAssetsAssetEditor.vue'),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
  {
    path: '/account/:accountId/gallery/uploads',
    name: 'uploads',
    component: () => import('src/components/account_gallery/AllAssets.vue'),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'my uploads' },
      ],
      context: 'uploads',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
      showOnlyCustomerOrigin: true,
    }),
  },
  {
    path: '/account/:accountId/gallery/uploads/asset/:digitalAssetId',
    name: 'uploads-assets-media-view',
    component: () =>
      import('@/components/account_gallery/UploadsAssetEditor.vue'),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
  {
    path: '/account/:accountId/gallery/shop-more',
    name: 'shop-more',
    component: () => import('src/components/account_gallery/AllShopMore.vue'),
    meta: {
      breadcrumbs: [
        { copy: 'gallery', routeName: 'account-gallery' },
        { copy: 'shop more' },
      ],
      context: 'shop more',
      requires_auth: true,
    },
    props: route => ({
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/gallery/shop-more/asset/:digitalAssetId',
    name: 'shop-more-assets-media-view',
    component: () =>
      import('@/components/account_gallery/ShopMoreAssetEditor.vue'),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
  {
    path: '/account/:accountId/gallery/albums/:collectionId/asset/:digitalAssetId',
    name: 'album-assets-media-view',
    component: () =>
      import(
        '@/components/account_gallery/collections/AlbumCollectionAssetEditor.vue'
      ),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      collectionId: route.params.collectionId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
  {
    path: '/account/:accountId/gallery/edits/asset/:digitalAssetId',
    name: 'edits-assets-media-view',
    component: () =>
      import(
        '@/components/account_gallery/collections/EditsCollectionAssetEditor.vue'
      ),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
  {
    path: '/account/:accountId/gallery/favorites/asset/:digitalAssetId',
    name: 'favorites-assets-media-view',
    component: () =>
      import(
        '@/components/account_gallery/collections/FavoritesCollectionAssetEditor.vue'
      ),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
  {
    path: '/account/:accountId/gallery/expert-picks/asset/:digitalAssetId',
    name: 'expert-picks-assets-media-view',
    component: () =>
      import(
        '@/components/account_gallery/collections/StaffPicksCollectionAssetEditor.vue'
      ),
    meta: {
      requires_auth: true,
      context: 'media editor',
    },
    props: route => ({
      accountId: route.params.accountId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
];
