<script setup>
import { computed } from 'vue';
import { useCreateReservationLineItem } from 'src/queries/reservationLineItem/useCreateReservationLineItem';
import { useDeleteReservationLineItem } from 'src/queries/reservationLineItem/useDeleteReservationLineItem';
import { useFlag } from '@/composables/useFlag';
import { useSubscription } from '@/composables/subscription/useSubscription';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  assetType: String,
  assetId: Number,
  isPhoto: {
    type: Boolean,
    default: false,
  },
  mediaAddOnLineItem: Object,
  reservationId: Number,
  readOnly: {
    type: Boolean,
    default: false,
  },
  usingPreferredCredit: {
    type: Boolean,
    default: false,
  },
});

const product = computed(() => props.mediaAddOnLineItem.product);
const accountId = computed(() => props.accountId);
const isPhoto = computed(() => props.isPhoto);
const usingPreferredCredit = computed(() => props.usingPreferredCredit);

const checkboxId = computed(
  () => `${props.assetType}-${props.assetId}-${product.value.id}`
);
const cypressId = computed(
  () =>
    `${props.assetType}-${props.assetId}-${product.value.name
      .toLowerCase()
      .replace(/[\s+/]+/g, '-')}`
);

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const { hasTierTwoSubscription, hasTierThreeSubscription } =
  useSubscription(accountId);

const hasMembershipDiscount = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return hasTierTwoSubscription.value || hasTierThreeSubscription.value;
  } else {
    return false;
  }
});

const productRate = computed(() => {
  const formattedRate = Number(product.value.rate).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return `(${formattedRate})`;
});

const reservationId = computed(() => props.reservationId);

const { mutate: addAddOn, isPending: addIsLoading } =
  useCreateReservationLineItem(reservationId);

const { mutate: deleteAddOn, isPending: deleteIsLoading } =
  useDeleteReservationLineItem(
    reservationId,
    computed(() => props.mediaAddOnLineItem.id)
  );

const disabled = computed(
  () => props.readOnly || addIsLoading.value || deleteIsLoading.value
);

const handleCheckboxToggle = () => {
  if (!props.mediaAddOnLineItem.selected) {
    addAddOn({
      product_id: product.value.id,
      quantity: props.mediaAddOnLineItem.quantity,
      ...(props.assetType === 'animated_collection' && {
        collection_id: props.assetId,
      }),
      ...(props.assetType === 'reservation_file' && {
        reservation_file_id: props.assetId,
      }),
    });
  } else {
    deleteAddOn();
  }
};
</script>

<template>
  <label class="add-on-item">
    <input
      :id="checkboxId"
      :data-cypress="cypressId"
      type="checkbox"
      class="add-ons-checkbox"
      :disabled="disabled"
      :checked="mediaAddOnLineItem.selected"
      @click.prevent="handleCheckboxToggle"
    />
    <span class="is-size-7">
      {{ product.name }}
      <template
        v-if="!(isPhoto && (usingPreferredCredit || hasMembershipDiscount))"
      >
        {{ productRate }}
      </template>
    </span>
  </label>
</template>

<style scoped lang="scss">
.add-on-item {
  display: flex;
  align-items: flex-start;
  width: fit-content;
}

.add-ons-checkbox {
  margin: 1px 5px 0 0;

  &:disabled {
    cursor: not-allowed;

    + span {
      cursor: not-allowed;
    }
  }
}
</style>
