<script setup>
import { computed, ref, watchEffect } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useAccount } from '@/queries/account/useAccount';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { useCreateAddress } from 'src/queries/useCreateAddress';
import { useUpdateAddress } from 'src/queries/useUpdateAddress';
import { usePriorityErrors } from '@/composables/usePriorityErrors';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const isEditing = ref(false);
const newCompanyName = ref('');
const newTaxId = ref('');
const stripe = ref(null);
const stripeElement = ref(null);
const addressElement = ref(null);
const billingAddress = ref(null);

const accountId = computed(() => Number(props.accountId));

const { data: account, isSuccess: accountIsLoaded } = useAccount(accountId);
const { mutate: createAddress, error: createAddressError } =
  useCreateAddress(accountId);
const { mutate: updateAddress, isError: updateAddressError } =
  useUpdateAddress(accountId);
const {
  mutate: updateAccount,
  isPending: isPending,
  error: updateAccountErrors,
} = useUpdateAccount(accountId);

const addressOptions = {
  fonts: [
    {
      cssSrc:
        'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
      family: 'Lato',
      style: 'normal',
    },
  ],
  appearance: {
    rules: {
      '.Label': {
        fontWeight: '800',
        fontSize: '0.875rem',
        color: '#000000',
        paddingBottom: '0.275rem',
      },
    },
    variables: {
      colorPrimary: '#5566EA',
      fontFamily: 'Lato',
    },
  },
};

const getAccountUpdateData = () => {
  const data = {};
  if (newCompanyName.value !== account.value?.name) {
    data.name = newCompanyName.value;
  }
  if (newTaxId.value !== account.value?.tax_id) {
    data.tax_id = newTaxId.value;
  }
  return data;
};

const getAddressUpdateData = address => {
  return {
    id: address.id,
    address_1: address.line1,
    address_2: address.line2,
    city: address.city,
    state: address.state,
    postal_code: address.postal_code,
    country_code: address.country,
    address_type: 'billing',
    is_default: true,
  };
};

const hasAddressChanged = address => {
  return (
    address.city !== billingAddress.value.city ||
    address.state !== billingAddress.value.state ||
    address.country !== billingAddress.value.country_code ||
    address.postal_code !== billingAddress.value.postal_code
  );
};

const hasAddressLinesChanged = address => {
  return (
    address.line1 !== billingAddress.value.address_1 ||
    address.line2 !== billingAddress.value.address_2
  );
};

const hasCompanyOrTaxChanged = () => {
  return (
    newCompanyName.value !== account.value?.name ||
    newTaxId.value !== account.value?.tax_id
  );
};

function toggleEdit() {
  if (!isEditing.value) {
    newCompanyName.value = account.value?.name || '';
  }
  isEditing.value = !isEditing.value;
}

const save = async () => {
  if (stripeElement.value && addressElement.value) {
    const element = stripeElement.value.getElement('address');
    const { complete, value } = await element.getValue();

    if (complete) {
      const address = value.address;
      newCompanyName.value = value.name;
      const accountUpdateData = getAccountUpdateData();

      if (!billingAddress.value) {
        const addressData = getAddressUpdateData(address);
        createAddress(addressData);
      } else {
        if (hasAddressChanged(address)) {
          const addressData = getAddressUpdateData(address);
          createAddress(addressData);
        } else if (hasAddressLinesChanged(address)) {
          const addressData = {
            id: billingAddress.value.id,
            address_1: address.line1,
            address_2: address.line2,
          };
          updateAddress(addressData);
        }
      }

      if (hasCompanyOrTaxChanged()) {
        updateAccount(accountUpdateData);
      }

      toggleEdit();
    }
  }
};

const mountAddressElement = () => {
  if (window.Stripe) {
    stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
    stripeElement.value = stripe.value.elements(addressOptions);
    addressElement.value = stripeElement.value.create('address', {
      mode: 'billing',
      display: {
        name: 'organization',
      },
      defaultValues: {
        name: account.value?.name,
        address: {
          line1: billingAddress.value?.address_1,
          line2: billingAddress.value?.address_2,
          city: billingAddress.value?.city,
          state: billingAddress.value?.state,
          postal_code: billingAddress.value?.postal_code,
          country: billingAddress.value?.country_code,
        },
      },
    });
    addressElement.value.mount('#address-element');
  }
};

const priorityErrors = usePriorityErrors(
  createAddressError,
  updateAddressError,
  updateAccountErrors
);

watchEffect(() => {
  if (accountIsLoaded.value && account.value) {
    newCompanyName.value = account.value.name || '';
    billingAddress.value = account.value.billing_address || {};
    newTaxId.value = account.value.tax_id || '';
    if (isEditing.value) {
      mountAddressElement();
    }
  }
  if (updateAccountErrors.value) {
    isEditing.value = false;
    newCompanyName.value = account.value.name || '';
    newTaxId.value = account.value.tax_id || '';
  }
});
</script>

<template>
  <div class="contact-info">
    <SoonaError v-if="priorityErrors" class="feedback-dialog__error" />
    <h3 class="u-title--heavy mb-l">Billing info (appears on invoices)</h3>
    <div class="field-wrapper">
      <form id="address-form">
        <div v-show="isEditing" id="address-element"></div>
      </form>
      <SoonaTextfield
        v-if="!isEditing"
        v-model="newCompanyName"
        class="contact-info__input"
        label="Company name"
        name="companyName"
        disabled
        type="text"
      />
      <SoonaTextfield
        v-if="!isEditing"
        v-model="billingAddress.country_code"
        class="contact-info__input"
        label="Country"
        name="country"
        disabled
        type="text"
      />
      <div class="field-row">
        <SoonaTextfield
          v-if="!isEditing"
          v-model="billingAddress.address_1"
          class="contact-info__input"
          label="Street address"
          name="street"
          disabled
          type="text"
        />
        <SoonaTextfield
          v-if="!isEditing"
          v-model="billingAddress.address_2"
          class="contact-info__input"
          label="Suite or apt. #"
          name="suite"
          disabled
          type="text"
        />
      </div>
      <SoonaTextfield
        v-if="!isEditing"
        v-model="billingAddress.city"
        class="contact-info__input"
        label="City"
        name="city"
        disabled
        type="text"
      />
      <div class="field-row">
        <SoonaTextfield
          v-if="!isEditing"
          v-model="billingAddress.state"
          class="contact-info__input"
          label="State"
          name="state"
          disabled
          type="text"
        />
        <SoonaTextfield
          v-if="!isEditing"
          v-model="billingAddress.postal_code"
          class="contact-info__input"
          label="Zip code"
          name="zip"
          disabled
          type="text"
        />
      </div>
      <SoonaTextfield
        v-model="newTaxId"
        class="contact-info__input"
        :disabled="!isEditing"
        label="Tax ID (VAT)"
        name="newTaxId"
        :placeholder="isEditing ? 'Tax ID' : ''"
        type="text"
      />
    </div>
    <Capability
      capability-name="manage_account"
      subject-type="account"
      :subject-id="account.id"
    >
      <div class="action-btns">
        <SoonaButton v-if="!isEditing" size="medium" @on-click="toggleEdit">
          edit
        </SoonaButton>
        <SoonaButton
          v-else
          class="save-btn"
          size="medium"
          :disabled="isPending"
          @on-click="save"
        >
          save
        </SoonaButton>
      </div>
    </Capability>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.contact-info {
  background-color: variables.$white-default;
  border-radius: 0.75rem;
  box-shadow: variables.$elevation-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  &__input {
    width: 100%;
  }

  .action-btns {
    margin-left: auto;
    width: max-content;
  }

  .save-btn {
    background-color: variables.$periwink-blue-60;

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-70;
    }
  }

  .field-row {
    display: flex;
    gap: 1rem;

    .contact-info__input {
      flex: 1;
    }
  }
}

#address-element {
  margin-bottom: 1rem;
}
</style>
