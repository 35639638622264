<script setup>
import { computed, ref } from 'vue';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useAccount } from '@/queries/account/useAccount';
import { useCreatePaymentMethod } from '@/queries/payment_methods/useCreatePaymentMethod';
import { useUpdatePaymentMethod } from '@/queries/payment_methods/useUpdatePaymentMethod';
import { useCancelPaymentMethod } from '@/queries/payment_methods/useCancelPaymentMethod';
import { convertToDDMMYYHHMM12z } from '@/lib/date-formatters';
import DefaultPaymentCardDisplayEdit from '@/components/user/anytime/billing_and_orders/DefaultPaymentCardDisplayEdit.vue';
import PaymentMethodsBillingCard from '@/components/user/anytime/billing_and_orders/PaymentMethodsBillingCard.vue';
import SoonaInfo from '@/components/ui_library/SoonaInfo.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { Tangerine40, WhiteDefault } from '@/variables.module.scss';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => Number(props.accountId));
const showDeleteModal = ref(false);
const paymentMethodToRemove = ref(null);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { data: account } = useAccount(accountId, {
  enabled: isFtSoonaStaff,
});

const { mutate: cancelPaymentMethod, isPending: isCancelingPaymentMethod } =
  useCancelPaymentMethod(accountId);

const { mutate: updatePaymentMethod, isPending: isUpdatingPaymentMethod } =
  useUpdatePaymentMethod(accountId);

const { mutate: createPaymentMethod, isPending: isAddingPaymentMethod } =
  useCreatePaymentMethod(accountId);

const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
const paymentMethods = computed(() => {
  return account.value?.payment_methods;
});

const activeSubscriptionId = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value.subscription.id;
});

const studioPaymentUpdatedAt = computed(() => {
  return account.value?.saved_default_card
    ? convertToDDMMYYHHMM12z(account.value.saved_default_card)
    : null;
});

const handleRemovePaymentMethod = paymentMethod => {
  showDeleteModal.value = true;
  paymentMethodToRemove.value = paymentMethod.id;
};

const deletePaymentMethod = () => {
  cancelPaymentMethod({ paymentMethodId: paymentMethodToRemove.value });
  showDeleteModal.value = false;
  paymentMethodToRemove.value = null;
};

const handleUpdatePaymentMethod = paymentMethod => {
  updatePaymentMethod({ paymentMethodId: paymentMethod.id });
};

const handleAddPaymentMethod = paymentMethod => {
  createPaymentMethod(paymentMethod);
};
</script>

<template>
  <div class="default-payment-cards">
    <SoonaLoading
      v-if="
        isCancelingPaymentMethod ||
        isUpdatingPaymentMethod ||
        isAddingPaymentMethod
      "
      is-loading
      :is-dark="false"
      loading-text="processing"
    />
    <section class="default-payment-card">
      <header>
        <SoonaFlag
          title="soona studio"
          :background-color="Tangerine40"
          class="default-payment-card__flag"
          font-weight="bolder"
        >
          <template #icon-left>
            <SoonaIcon name="camera" size="x-small" />
          </template>
        </SoonaFlag>
        <h2 class="u-subheader--heavy">studio payment methods</h2>
        <em class="default-payment-card__subheading u-body--regular">
          <strong>for:</strong> booking down payments, pro service invoices,
          purchasing content, etc.
        </em>
      </header>
      <PaymentMethodsBillingCard
        v-if="paymentsPaymentMethodsFlag"
        :payment-methods="paymentMethods"
        :account-id="accountId"
        :active-subscription-id="activeSubscriptionId"
        @remove-payment-method="handleRemovePaymentMethod($event)"
        @update-payment-method="handleUpdatePaymentMethod($event)"
        @add-payment-method="handleAddPaymentMethod($event)"
      />
      <DefaultPaymentCardDisplayEdit
        v-else
        :account-id="accountId"
        type="soona-studio"
      />
      <SoonaInfo v-if="isFtSoonaStaff && studioPaymentUpdatedAt" no-margin>
        studio payment card updated at {{ studioPaymentUpdatedAt }}
      </SoonaInfo>
    </section>
    <section v-if="activeSubscriptionId" class="default-payment-card">
      <header>
        <SoonaFlag
          title="platform subscription"
          :text-color="WhiteDefault"
          is-pizzazz
          class="default-payment-card__flag"
          font-weight="bolder"
        >
          <template #icon-left>
            <SoonaIcon name="crown" size="x-small" />
          </template>
        </SoonaFlag>
        <h2 class="u-subheader--heavy">saved payment method</h2>
        <em class="default-payment-card__subheading u-body--regular">
          <strong>for:</strong> basic, standard, or enterprise tier
          subscriptions. fast pass.
        </em>
      </header>
      <DefaultPaymentCardDisplayEdit
        :account-id="accountId"
        type="platform-subscription"
      />
    </section>
  </div>
  <SoonaDialog v-if="showDeleteModal" @close="showDeleteModal = false">
    <template #heading>
      <SoonaIcon class="warning-icon" name="circle-exclamation-solid" />
      remove payment method
    </template>

    <p>
      you are about to remove this payment method. this action cannot be undone.
    </p>
    <template #footer="{ close }">
      <SoonaButton variation="tertiary" @on-click="close">cancel</SoonaButton>
      <SoonaButton variation="solid-black" @on-click="deletePaymentMethod">
        confirm removal
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.default-payment-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.default-payment-card {
  flex: 1 0 48%;
  padding: 1.5rem;
  border-radius: 0.625rem;
  background-color: #f6f8fe;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__flag {
    margin-bottom: 0.75rem;
  }

  &__subheading {
    display: block;
    margin-top: 0.25rem;
  }
}

.warning-icon {
  margin-right: 0.5rem;
}
</style>
