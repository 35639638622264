import {
  computed,
  inject,
  onScopeDispose,
  provide,
  ref,
  toValue,
  watch,
  watchEffect,
} from 'vue';
import { useDocumentOverflowHidden } from '@/composables/useDocumentOverflowHidden';

const DIALOG_PROVIDE_KEY = Symbol('dialog-provider');

export function useAnyDialogOpen() {
  const { stack } = inject(DIALOG_PROVIDE_KEY);
  return computed(() => stack.value.length > 0);
}

export function useDialogProvider() {
  /**
   * The stack of dialogs. The last item in the array is the top dialog.
   * A `modal` indicates that the dialog should lock document scrolling.
   * @type {Ref<{id: string, modal: boolean}[]>}
   */
  const stack = ref([]);

  const testInjectedProvide = inject(DIALOG_PROVIDE_KEY, false);

  watchEffect(() => {
    if (testInjectedProvide !== false) {
      console.warn(
        'Should not use multiple `useDialogProvider` on the same component tree.'
      );
    }
  });

  function add({ id, modal }) {
    if (!id) {
      console.warn('adding a dialog requires an `id`. see `useDialogContext`.');
      return;
    }

    if (stack.value.find(dialog => dialog.id === id)) {
      console.warn(
        `Tried to add a duplicate dialog id \`${id}\` to \`useDialogProvider\`. This must be fixed to avoid functionality and accessibility issues.`
      );
      return;
    }

    stack.value = stack.value.concat({ id, modal });
  }

  function remove(dialogId) {
    // using filter here supports if remove is called with an id that is not in the stack
    stack.value = stack.value.filter(dialog => dialog.id !== dialogId);
  }

  const { start, stop } = useDocumentOverflowHidden();

  // if any dialogs are modal, lock the document scrolling
  watch(
    () => stack.value.some(dialog => dialog.modal),
    hasModals => {
      if (hasModals) {
        start();
      } else {
        stop();
      }
    },
    { immediate: true }
  );

  provide(DIALOG_PROVIDE_KEY, {
    add,
    remove,
    stack,
  });
}

/**
 * @param id - a unique identifier for the dialog
 * @param [immediate] - if true, the dialog will be added to the stack immediately, otherwise it will be added when `start` is called
 * @param [modal] - if true, the dialog will lock document scrolling, otherwise it will not. set to `false` for non-modal dialogs, such as popovers
 */
export function useDialogContext({ id, immediate = true, modal = true }) {
  const { add, remove, stack } = inject(DIALOG_PROVIDE_KEY);

  /*
   * if the id changes, we remove the old and add the new, but if there is
   * another dialog in the stack, the order will not be correct. this is an
   * edge case and will hopefully not be an issue.
   *
   * furthermore, if multiple nested dialogs are opened at the same time,
   * the order will be inverted as watchers fire from the leaf to the root of
   * the component tree. dialogs should be opened with user interaction and
   * only one opened at a time.
   */
  watch(
    () => [toValue(id), toValue(immediate), toValue(modal)],
    ([newId, newImmediate, newModal], oldValues) => {
      const [oldId] = oldValues ?? [];

      if (newImmediate) {
        if (oldId) {
          remove(oldId);
        }
        add({
          id: newId,
          modal: newModal,
        });
      }
    },
    { immediate: true }
  );

  onScopeDispose(() => {
    remove(toValue(id));
  });

  /**
   * Start the dialog. This should be called when the dialog is opened imperatively.
   * Should be paired with setting `immediate: false`.
   */
  function start() {
    add({
      id: toValue(id),
      modal: toValue(modal),
    });
  }

  /**
   * Stop the dialog. This should be called when the dialog is closed imperatively.
   * Should be paired with setting `immediate: false`.
   */
  function stop() {
    remove(toValue(id));
  }

  const isTopDialog = computed(() => {
    if (stack.value.length < 2) {
      return true;
    }

    const dialogId = toValue(id);
    const lastIndex = stack.value.length - 1;
    const currentIndex = stack.value.findIndex(
      dialog => dialog.id === dialogId
    );
    return currentIndex === lastIndex;
  });

  /**
   *
   * @param dialogEl - A getter, an existing ref, or a non-function value of a DOM element. Passed into `toValue`.
   * @return {boolean} - true if the dialog is the top dialog, and there are no other popover-style elements open, otherwise false
   */
  function checkAllowEscapeClose(dialogEl) {
    const el = toValue(dialogEl);
    if (!el) {
      console.warn('`checkAllowEscapeClose` requires a DOM element.');
      return false;
    }

    return (
      isTopDialog.value &&
      ![...toValue(dialogEl).querySelectorAll('.viewer-container')].some(
        el => getComputedStyle(el).display !== 'none'
      )
    );
  }

  return {
    checkAllowEscapeClose,
    start,
    stop,
  };
}
