<script setup>
import {
  computed,
  useTemplateRef,
  toRefs,
  watchEffect,
  nextTick,
  watch,
} from 'vue';
import { useInfiniteNotes } from '@/queries/notes/useInfiniteNotes';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import NoteCreate from './NoteCreate.vue';
import NoteDisplay from '@/components/notes/NoteDisplay.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useCapability } from '@/composables/useCapability';
import { useScroll } from '@vueuse/core';

const props = defineProps({
  autoFocusComposer: {
    type: Boolean,
    default: false,
  },
  subjectType: {
    type: String,
    required: true,
  },
  subjectId: {
    type: [String, Number],
    default: null,
  },
});

const { subjectType, subjectId } = toRefs(props);

const subjectCapabilityMap = {
  digital_assets: 'manage_digital_asset_notes',
};
const capability = computed(
  () => subjectCapabilityMap[props.subjectType] ?? null
);

const { hasCapability: canManageDigitalAssetNotes } = useCapability(
  {
    capability: capability,
  },
  {
    enabled: computed(() => !!capability.value),
  }
);

const nonCreatorEditingAllowed = computed(
  () => !!capability.value && canManageDigitalAssetNotes.value
);

const scrollContainer = useTemplateRef('scroller-wrap');

const {
  data: notesData,
  fetchNextPage,
  fetchPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isLoading,
  isFetchingNextPage,
  isFetchingPreviousPage,
  error,
} = useInfiniteNotes(
  subjectType,
  subjectId,
  {
    itemsPerPage: 10,
  },
  {
    enabled: computed(() => !!subjectType.value && !!subjectId.value),
  }
);

const notesPagination = computed(() => {
  const paginationData = notesData.value?.pages?.at(-1)?.pagination;
  if (paginationData) {
    return paginationData;
  } else {
    return {
      totalCount: 0,
      currentPage: 0,
      totalPages: 0,
      itemsPerPage: 0,
    };
  }
});

const priorityErrors = usePriorityErrors(error);

const allRows = computed(() => {
  return notesData.value
    ? notesData.value.pages.flatMap(d => d.notes).toReversed()
    : [];
});

function scrollToBottom() {
  nextTick(() => {
    scrollContainer.value.scroll({
      top: scrollContainer.value.scrollHeight,
    });
  });
}

const { arrivedState } = useScroll(scrollContainer);

// todo: fix this so that scrolling to the top keeps the user looking at the same note when new data loads further up
watch(notesData, (newNotes, prevNotes) => {
  if (arrivedState.top) {
    const prevFirstNoteId = prevNotes?.pages?.[0]?.notes?.[0]?.id;
    const noteEl = prevFirstNoteId
      ? scrollContainer.value.querySelector(
          `[data-note-id="${prevFirstNoteId}"]`
        )
      : null;
    if (noteEl) {
      nextTick(() => {
        scrollContainer.value.scroll({
          top: noteEl.offsetTop - noteEl.offsetHeight,
        });
      });
    }
  }

  if (arrivedState.bottom) {
    scrollToBottom();
  }
});

watchEffect(() => {
  if (
    arrivedState.bottom &&
    !isFetchingPreviousPage.value &&
    hasPreviousPage.value
  ) {
    fetchPreviousPage();
  }
  if (arrivedState.top && !isFetchingNextPage.value && hasNextPage.value) {
    fetchNextPage();
  }
});
</script>

<template>
  <div class="digital-asset-notes">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />
    <div
      v-if="!isLoading && !error && notesData && notesPagination.totalCount < 1"
      class="digital-asset-notes__empty"
    >
      <SoonaIcon name="message-circle-lines-alt" />
      <p class="u-label--heavy">no comments on the current version</p>
    </div>
    <div ref="scroller-wrap" class="digital-asset-notes__scroller-wrap">
      <div v-for="note in allRows" :key="note.id" :data-note-id="note.id">
        <NoteDisplay
          :non-creator-editing-allowed="nonCreatorEditingAllowed"
          :note="note"
          :subject-type="subjectType"
          :subject-id="subjectId"
        />
      </div>
    </div>
    <NoteCreate
      :auto-focus="autoFocusComposer"
      :subject-type="subjectType"
      :subject-id="subjectId"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.digital-asset-notes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 100%;

  &__empty {
    display: flex;
    padding: 1.5rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    align-self: stretch;
    background: variables.$gray-10;
    border-radius: 0.625rem;
    color: variables.$gray-60;
    text-align: center;
  }

  &__scroller-wrap {
    overflow-y: auto;
    /* props to https://codepen.io/mgrubinger/pen/LYKQaQz */
    scroll-snap-type: y proximity;
    /* todo: this height should be more dynamic based on savailable space */
    height: 50dvh;

    & > :last-child {
      scroll-snap-align: end;
    }
  }
}
</style>
