<script setup>
import { ref } from 'vue';
import { useTitle } from '@vueuse/core';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

// head over to /soona-button to see!
useTitle('SoonaButton test page | soona');

const variations = [
  'filter',
  'icon-gray-outline',
  'icon-plain-gray',
  'icon-primary',
  'icon-transparent',
  'pizzazz',
  'primary',
  'secondary-black',
  'secondary-gray',
  'secondary-transparent',
  'solid-black',
  'tertiary',
];

const sizes = ['large', 'medium', 'small'];

const element = ref('button');

function isIcon(variation) {
  return variation.startsWith('icon-');
}
function isButton(element) {
  return element === 'button';
}
</script>

<template>
  <div class="u-container soona-button-test-page">
    <h1 class="u-headline--heavy">SoonaButton test page</h1>

    <p>
      unsupported combinations of props, variations, and elements are hidden in
      their respective sections.
    </p>
    <ul>
      <li>a link element cannot be disabled</li>
      <li>a link element cannot be loading</li>
      <li>
        a non-icon variation must be used with some text and not just an icon
      </li>
      <li>
        <code>aria-pressed="true"</code> can only be used on a button element
      </li>
    </ul>

    <fieldset>
      <legend class="u-body--heavy">element to use</legend>
      <SoonaToggle
        v-model="element"
        type="radio"
        name="button-or-switch"
        native-value="button"
        label="button"
      />
      <SoonaToggle
        v-model="element"
        type="radio"
        name="button-or-switch"
        native-value="a"
        label="link"
      />
    </fieldset>

    <section>
      <h2 class="u-title--heavy">all variations of each size</h2>
      <div v-for="size in sizes" :key="size" class="buttons-list">
        <SoonaButton
          v-for="variation in variations"
          :key="variation"
          :element="element"
          href="#/soona-button"
          :variation="variation"
          :size="size"
        >
          <template v-if="isIcon(variation)">
            <SoonaIcon name="image-square" />
          </template>
          <template v-else>
            <SoonaIcon name="image-square" />
            {{ variation }}
          </template>
        </SoonaButton>
      </div>
    </section>

    <div v-for="variation in variations" :key="variation">
      <h2 class="u-title--heavy">{{ variation }}</h2>
      <template v-for="size in sizes" :key="size">
        <section :aria-label="`${variation} ${size}`">
          <div
            :class="[
              'buttons-list',
              { 'buttons-list--dark': variation.includes('transparent') },
            ]"
          >
            <template v-if="!isIcon(variation)">
              <SoonaButton
                :element="element"
                href="#/soona-button"
                :variation="variation"
                :size="size"
              >
                {{ variation }}
              </SoonaButton>
              <SoonaButton
                v-if="isButton(element)"
                :element="element"
                href="#/soona-button"
                :variation="variation"
                :size="size"
                aria-pressed="true"
              >
                aria-pressed="true"
              </SoonaButton>
              <SoonaButton
                :element="element"
                href="#/soona-button"
                :variation="variation"
                :size="size"
              >
                <SoonaIcon name="image-square" />
                {{ variation }}
                <SoonaIcon name="image-square" />
              </SoonaButton>
            </template>
            <SoonaButton
              v-if="isIcon(variation)"
              :element="element"
              href="#/soona-button"
              :variation="variation"
              :size="size"
              :title="variation"
            >
              <SoonaIcon name="image-square" />
            </SoonaButton>

            <template v-if="isButton(element)">
              <template v-if="isIcon(variation)">
                <SoonaButton
                  :element="element"
                  href="#/soona-button"
                  :variation="variation"
                  :size="size"
                  title='aria-pressed="true"'
                  aria-pressed="true"
                >
                  <SoonaIcon name="image-square" />
                </SoonaButton>
                <SoonaButton
                  :element="element"
                  href="#/soona-button"
                  :variation="variation"
                  :size="size"
                  title="disabled"
                  disabled
                >
                  <SoonaIcon name="image-square" />
                </SoonaButton>
                <SoonaButton
                  :element="element"
                  href="#/soona-button"
                  :variation="variation"
                  :size="size"
                  title="loading"
                  loading
                >
                  <SoonaIcon name="image-square" />
                </SoonaButton>
              </template>

              <template v-else>
                <SoonaButton
                  :element="element"
                  href="#/soona-button"
                  :variation="variation"
                  :size="size"
                  title="disabled"
                  disabled
                >
                  {{ variation }}
                </SoonaButton>
                <SoonaButton
                  :element="element"
                  href="#/soona-button"
                  :variation="variation"
                  :size="size"
                  title="loading"
                  loading
                >
                  {{ variation }}
                </SoonaButton>
                <SoonaButton
                  :element="element"
                  href="#/soona-button"
                  :variation="variation"
                  :size="size"
                  title="loading with a color"
                  loading
                  loader-color="hotpink"
                >
                  {{ variation }}
                </SoonaButton>
              </template>
            </template>
          </div>
        </section>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-button-test-page {
  h1 {
    margin-bottom: 1.5rem;
  }

  p,
  fieldset {
    margin-bottom: 1rem;
  }

  ul {
    list-style: disc;
    padding-left: 1em;
    margin-bottom: 1rem;
  }

  .buttons-list {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    border-radius: 0.8125rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    background: variables.$periwink-blue-10;

    &--dark {
      background: variables.$black-default;
    }
  }
}
</style>
