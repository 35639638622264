<script setup>
import { computed, ref, watch, nextTick } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import { useCapability } from '@/composables/useCapability';
import { useDefaultPayment } from '@/queries/account/useDefaultPayment';
import { useUpdateDefaultPayment } from '@/queries/account/useUpdateDefaultPayment';
import { useDefaultSubscriptionPaymentMethod } from '@/queries/subscriptions/useDefaultSubscriptionPaymentMethod';
import { useUpdateDefaultSubscriptionPaymentMethod } from '@/queries/subscriptions/useUpdateDefaultSubscriptionPaymentMethod';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaInfo from '@/components/ui_library/SoonaInfo.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaCreditCard from '@/components/ui_library/SoonaCreditCard.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import { Gray80, FriendlyRed50 } from '@/variables.module.scss';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  type: {
    type: String,
    default: 'soona-studio',
    validator: value => {
      return ['soona-studio', 'platform-subscription'].includes(value);
    },
  },
});

defineOptions({ inheritAttrs: false });

const elementStyles = {
  base: {
    fontFamily: 'Lato, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '14px', // rendered in an iframe, so we use pixels
    textTransform: 'lowercase',
    color: Gray80,
  },
  invalid: {
    color: FriendlyRed50,
    iconColor: FriendlyRed50,
  },
};

const elementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};

const accountId = computed(() => props.accountId);
const type = computed(() => props.type);

const { hasCapability: canManageDefaultPayment, status } = useCapability({
  capability: 'manage_default_payment',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { data: account, error: accountError } = useAccount(accountId);

const activeSubscriptionId = computed(() => {
  if (!account.value?.subscription) return null;

  return account.value?.subscription.id;
});

const hasDefaultPayment = computed(() => {
  if (type.value === 'platform-subscription')
    return !!activeSubscriptionId.value;
  return !!account.value?.saved_default_card;
});

const {
  data: studioPayment,
  error: studioPaymentError,
  isLoading: studioPaymentLoading,
} = useDefaultPayment(accountId, {
  enabled: computed(
    () => !!hasDefaultPayment.value && type.value === 'soona-studio'
  ),
});

const {
  data: defaultSubscriptionPaymentMethod,
  error: defaultSubscriptionPaymentMethodError,
  isLoading: defaultSubscriptionPaymentMethodLoading,
} = useDefaultSubscriptionPaymentMethod(activeSubscriptionId, {
  enabled: computed(
    () => !!hasDefaultPayment.value && type.value === 'platform-subscription'
  ),
});

const isLoading = computed(() => {
  if (type.value === 'platform-subscription')
    return defaultSubscriptionPaymentMethodLoading.value;
  return studioPaymentLoading.value;
});

const paymentInfo = computed(() => {
  if (type.value === 'platform-subscription') {
    return defaultSubscriptionPaymentMethod.value?.payment_info;
  }
  return studioPayment.value?.payment_info;
});

const {
  mutate: updateStudioPayment,
  error: updateStudioPaymentError,
  isPending: studioPaymentUpdating,
} = useUpdateDefaultPayment(accountId);

const {
  mutate: updatedefaultSubscriptionPaymentMethod,
  error: updatedefaultSubscriptionPaymentMethodError,
  isPending: defaultSubscriptionPaymentMethodUpdating,
} = useUpdateDefaultSubscriptionPaymentMethod(activeSubscriptionId);

const isUpdating = computed(() => {
  return (
    studioPaymentUpdating.value ||
    defaultSubscriptionPaymentMethodUpdating.value
  );
});

const cardElement = ref(null);
const stripe = ref(null);
const card = ref(null);
const stripeMountError = ref(null);
const showCardForm = ref(false);
const isComplete = ref(false);
const localError = ref(null);
const isEmpty = ref(true);

function mountStripeElement() {
  stripeMountError.value = null;
  try {
    if (!window.Stripe) {
      throw new Error(
        'Stripe does not exist on the window. could not initialize Stripe or elements.'
      );
    }
    stripe.value = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
    const elements = stripe.value.elements();
    card.value = elements.create('card', {
      style: elementStyles,
      classes: elementClasses,
      hidePostalCode: true,
    });
    card.value.mount(cardElement.value);
    card.value.on('change', event => {
      if (event.error) {
        localError.value = event.error.message;
      }
      if (event.complete) {
        isComplete.value = true;
        localError.value = null;
      }
      isEmpty.value = event.empty;
    });
  } catch (e) {
    stripeMountError.value = e;
  }
}

watch(
  [account, hasDefaultPayment, canManageDefaultPayment],
  ([acc, hasPayment, canManageDefaultPayment]) => {
    // if the account has loaded, and does not have payment, and can manage default payment, show the edit form
    if (acc && !hasPayment && canManageDefaultPayment) {
      showCardForm.value = true;
    }
  },
  { immediate: true }
);

watch(
  showCardForm,
  async newVal => {
    // we re-mount between edits to make sure any existing state is cleared
    if (newVal) {
      await nextTick(mountStripeElement);
    }
  },
  { immediate: true }
);

const priorityError = usePriorityError(
  updatedefaultSubscriptionPaymentMethodError,
  updateStudioPaymentError,
  defaultSubscriptionPaymentMethodError,
  studioPaymentError,
  accountError,
  stripeMountError
);

function savePaymentInfo() {
  if (localError.value || !stripe.value || !card.value) {
    return;
  }

  if (!isComplete.value && !localError.value) {
    localError.value = 'Your payment details are incomplete.';
    return;
  }

  if (type.value === 'platform-subscription') {
    updatedefaultSubscriptionPaymentMethod(
      {
        stripe: stripe.value,
        card: card.value,
      },
      {
        onSuccess: () => {
          showCardForm.value = false;
        },
      }
    );
    return;
  }

  updateStudioPayment(
    {
      stripe: stripe.value,
      card: card.value,
    },
    {
      onSuccess: () => {
        showCardForm.value = false;
      },
    }
  );
}

const expiry = computed(() => {
  if (!paymentInfo.value || !paymentInfo.value.exp_month) return null;

  const month = paymentInfo.value.exp_month;
  const monthPadded = month.toString().padStart(2, '0');
  const year = paymentInfo.value.exp_year;

  return `expires ${monthPadded}/${year}`;
});
</script>

<template>
  <SoonaError v-if="priorityError" no-margin>{{ priorityError }}</SoonaError>
  <SoonaSkeleton
    v-if="status === 'pending' || !account || isLoading"
    class="default-payment-card-display__skeleton-loader"
  />
  <template v-else>
    <template v-if="!showCardForm || !canManageDefaultPayment">
      <div
        v-if="hasDefaultPayment && paymentInfo"
        class="default-payment-card-display__payment-info"
      >
        <div class="default-payment-card-display__card-logo">
          <SoonaCreditCard :brand="paymentInfo?.brand" />
        </div>
        <div>
          <p class="u-title--heavy">
            {{ paymentInfo?.brand }} {{ paymentInfo?.funding }} ····
            {{ paymentInfo.last4 }}
          </p>
          <p class="u-body--regular">{{ expiry }}</p>
        </div>
        <SoonaInfo
          v-if="!canManageDefaultPayment && !isFtSoonaStaff"
          class="default-payment-card-display__change-contact"
          no-margin
        >
          to change the payment method, contact the account admin,
          {{ account?.owner?.name }}, at
          <span class="u-email-wrap">
            {{ account?.owner?.email }}
          </span>
        </SoonaInfo>
      </div>
      <SoonaInfo v-else-if="!canManageDefaultPayment" no-margin>
        to set up a payment method, contact the account admin,
        {{ account?.owner?.name }}, at
        <span class="u-email-wrap">
          {{ account?.owner?.email }}
        </span>
      </SoonaInfo>
      <div v-if="canManageDefaultPayment">
        <SoonaButton variation="secondary-gray" @on-click="showCardForm = true">
          update payment method
        </SoonaButton>
      </div>
    </template>
    <template v-else>
      <div ref="cardElement" class="default-payment-card-edit__element" />
      <div class="default-payment-card-edit__secure u-label--regular">
        <SoonaIcon name="lock-alt" size="small" />
        secure payment processed by Stripe
      </div>
      <SoonaError
        v-if="localError"
        class="default-payment-card-edit__margin-zero"
      >
        {{ localError }}
      </SoonaError>
      <div class="default-payment-card-edit__buttons">
        <SoonaButton
          variation="primary"
          :loading="isUpdating"
          :disabled="isEmpty"
          @on-click="savePaymentInfo"
        >
          save
        </SoonaButton>
        <SoonaButton
          v-if="!isUpdating && hasDefaultPayment"
          variation="tertiary"
          @on-click="showCardForm = false"
        >
          cancel
        </SoonaButton>
      </div>
    </template>
  </template>
</template>

<style lang="scss" scoped>
@use '@/variables';

.default-payment-card-display {
  &__skeleton-loader {
    height: 9rem;
  }

  &__payment-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
  }

  &__card-logo {
    background-color: variables.$periwink-blue-10;
    border: 0.0625rem solid variables.$periwink-blue-30;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    width: 5rem;

    svg,
    img {
      width: 2rem;
      height: 2rem;
    }
  }

  &__change-contact {
    margin-top: 0.75rem;
    width: 100%;
  }
}

.default-payment-card-edit {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__element {
    align-self: stretch;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: variables.$control-radius;
    padding: 0.625rem;
    user-select: none;
    max-width: 26.5rem;
  }

  &__secure {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  &__buttons {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__margin-zero {
    margin: 0;
  }
}
</style>
