<script setup>
import { computed, inject, toValue } from 'vue';
import { useLink, useRoute } from 'vue-router';
import SidebarIcon from './SidebarIcon.vue';
import { USE_ROUTER_META_PROVIDER_NAME } from '../platform-sidebar-layout-provide';

const props = defineProps({
  sidebarIcon: {
    type: String,
    default: null,
  },
  to: {
    type: [String, Object],
    required: null,
  },
  exactActive: {
    type: Boolean,
    default: false,
  },
  forceExternal: {
    type: Boolean,
    default: false,
  },
  internalCustomActive: {
    type: Function,
    default: null,
  },
});

// handle if we're not using vue router, and disable automatic link active states
const useRouterMeta = inject(USE_ROUTER_META_PROVIDER_NAME, true);

const currentRoute = useRoute();

const isExternalLink = computed(
  () =>
    props.forceExternal ||
    (typeof props.to === 'string' &&
      (props.to.startsWith('http') || props.to.includes('#/')))
);

// external link handling
const isExternalCurrent = computed(() => {
  if (!isExternalLink.value) return false;

  return props.exactActive
    ? currentRoute.path === props.to || window.location.pathname === props.to
    : currentRoute.path.includes(props.to) ||
        window.location.href.includes(props.to);
});

// vue router internal link handling
const { href, isActive, isExactActive, navigate } = useLink({
  to: computed(() => (isExternalLink.value ? '/' : props.to)),
});

const fixedHref = computed(() => {
  if (!toValue(useRouterMeta)) {
    return '/' + href.value;
  }

  return href.value;
});

function fixedNavigate(...args) {
  if (!toValue(useRouterMeta)) return;
  navigate(...args);
}

const isInternalCurrent = computed(() => {
  if (!toValue(useRouterMeta)) return undefined;

  if (props.internalCustomActive) {
    return props.internalCustomActive(currentRoute);
  }

  return (props.exactActive ? isExactActive.value : isActive.value)
    ? 'page'
    : undefined;
});
</script>

<template>
  <a
    v-if="isExternalLink"
    class="sidebar-link"
    :href="to"
    :aria-current="isExternalCurrent ? 'page' : undefined"
    v-bind="$attrs"
  >
    <SidebarIcon
      v-if="sidebarIcon"
      :name="sidebarIcon"
      class="sidebar-link__icon"
    />
    <slot />
    <slot name="right" :right-class="'sidebar-link__right'" />
  </a>
  <a
    v-else
    v-bind="$attrs"
    :href="fixedHref"
    :aria-current="isInternalCurrent ? 'page' : undefined"
    class="sidebar-link"
    @click="fixedNavigate"
  >
    <SidebarIcon
      v-if="sidebarIcon"
      :name="sidebarIcon"
      class="sidebar-link__icon"
    />
    <slot />
    <slot name="right" :right-class="'sidebar-link__right'" />
  </a>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.sidebar-link {
  @include variables_fonts.u-label--regular;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  color: variables.$black-default;
  transition: background-color 0.1s ease-out;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  border-radius: 0.3125rem;
  --accent-color: #{variables.$bubbletape-pink-20};

  &[aria-current='page'] {
    @include variables_fonts.u-label--heavy;

    background-color: variables.$bubbletape-pink-20;
    --accent-color: #{variables.$white-default};
  }

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
  }

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:active {
    background-color: variables.$bubbletape-pink-20;
    --accent-color: #{variables.$white-default};
  }

  &:hover:not([aria-current='page']):not(:active) {
    background-color: variables.$bubbletape-pink-10;
  }
}

:slotted(.sidebar-link__right) {
  margin-left: auto;
}
</style>
