<script setup>
import { inject, toRefs, computed, useTemplateRef } from 'vue';
const props = defineProps({
  value: {
    type: [Number, String],
    default: null,
  },
});

const { value } = toRefs(props);
const optionRef = useTemplateRef('comboboxOption');

const { modelValue, onChange, highlightedElementID } =
  inject('comboboxContext');

const isActive = computed(() => {
  const mValue = modelValue?.value;

  if (!mValue) return false;
  return mValue == value.value || mValue == optionRef.value?.textContent;
});

const optionId = computed(
  () => `combobox-option-${value.value ?? optionRef.value?.textContent}`
);
</script>

<template>
  <li
    :id="optionId"
    ref="comboboxOption"
    role="option"
    :aria-selected="isActive"
    :data-value="value ?? optionRef?.textContent"
    :data-highlighted="highlightedElementID == optionId"
    @click.capture="onChange(value ?? optionRef?.textContent)"
  >
    <slot />
  </li>
</template>

<style lang="scss" scoped>
@use '@/variables';

[aria-selected='true'] {
  background-color: variables.$periwink-blue-20;

  &:hover {
    background-color: variables.$periwink-blue-20 !important;
  }
}

[data-highlighted='true'] {
  background-color: variables.$periwink-blue-30;

  &:hover {
    background-color: variables.$periwink-blue-30 !important;
  }
}

[role='option'] {
  cursor: pointer;
  list-style-type: none;

  &:hover {
    background-color: variables.$periwink-blue-10;
  }
}
</style>
