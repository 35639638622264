<script setup>
import { computed, inject } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  hasAssetsToPublish: {
    type: Boolean,
    required: true,
  },
  hasPendingActions: {
    type: Boolean,
    required: true,
  },
  isPublishing: {
    type: Boolean,
    required: true,
  },
  isOptimizing: {
    type: Boolean,
    required: true,
  },
  actionInProgress: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['publish', 'bulkAction', 'showSubscriptionsDialog']);

const { buttonClicked } = useBaseEvents();

const pageContent = inject('pageContent');

const bulkActionBarContent = computed(() => {
  return pageContent.value.bulkActionBar;
});

const accountId = computed(() => props.accountId);

const { disablePublishFlow } = useDisablePublishFlow({
  accountId: accountId,
});

// lock buttons based on subx status
const { canUseBulkActions, isBasicUserAndOutOfCredits, userCanUpgrade } =
  useSubscriptionActionPermissions(accountId);

const publishButtonVariation = computed(() => {
  return canUseBulkActions?.value ? 'secondary-black' : 'pizzazz';
});

const bulkActionButtonVariation = computed(() => {
  return canUseBulkActions?.value ? 'primary' : 'pizzazz';
});

// emit to trigger workflow dialogs
const handleBulkActionClick = () => {
  if (!canUseBulkActions.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: pageContent.value?.pageTitle,
      buttonLabel: bulkActionBarContent.value?.buttonText,
      buttonAction: 'upgrade to standard',
    });
    return emit('showSubscriptionsDialog');
  }
  emit('bulkAction');
  buttonClicked({
    context: 'listing insights',
    subContext: pageContent.value?.pageTitle,
    buttonLabel: bulkActionBarContent.value?.buttonText,
    buttonAction: 'bulk action: ' + bulkActionBarContent.value?.buttonAction,
  });
};

// emit to publish
const handlePublishClick = () => {
  if (!canUseBulkActions.value) {
    buttonClicked({
      context: 'listing insights',
      subContext: pageContent.value?.pageTitle,
      buttonLabel: 'publish all',
      buttonAction: 'upgrade to standard',
    });
    return emit('showSubscriptionsDialog');
  }
  emit('publish');
  buttonClicked({
    context: 'listing insights',
    subContext: pageContent.value?.pageTitle,
    buttonLabel: 'publish all',
    buttonAction: 'bulk action: publish',
  });
};

const ctaText = computed(() => {
  if (isBasicUserAndOutOfCredits.value) {
    return 'you’ve used all of your 250 monthly AI generations!';
  }
  if (props.hasPendingActions) {
    return bulkActionBarContent.value.cta;
  }
  return 'publish updates';
});

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: pageContent.value?.pageTitle,
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: pageContent.value?.pageTitle,
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};
</script>

<template>
  <div class="bulk-action-bar">
    <div class="bulk-action-bar__cta u-subheader--heavy">
      <div class="bulk-action-bar__cta--icon">
        <SoonaIcon :name="bulkActionBarContent.icon" />
      </div>
      {{ ctaText }}
    </div>
    <div class="bulk-action-bar__actions">
      <SoonaButton
        v-if="isBasicUserAndOutOfCredits"
        variation="pizzazz"
        @click="handleUpgradeClick"
      >
        upgrade for more
      </SoonaButton>
      <template v-else>
        <SoonaButton
          v-if="!disablePublishFlow"
          :variation="publishButtonVariation"
          :disabled="actionInProgress || !hasAssetsToPublish"
          :loading="isPublishing || isOptimizing"
          @click="handlePublishClick"
        >
          publish all
        </SoonaButton>
        <SoonaButton
          v-if="bulkActionBarContent.buttonText"
          :disabled="actionInProgress || !hasPendingActions"
          :variation="bulkActionButtonVariation"
          :loading="isOptimizing || isPublishing"
          @click="handleBulkActionClick"
        >
          <SoonaIcon name="wand-magic-sparkles" />
          {{ bulkActionBarContent.buttonText }}
        </SoonaButton>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bulk-action-bar {
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$periwink-blue-30;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;

  &__cta {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &--icon {
      background-color: variables.$periwink-blue-20;
      color: variables.$periwink-blue-70;
      border-radius: 50%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
  }
}
</style>
