<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFaq from '@/components/ui_library/SoonaFaq.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import EmptyShotList from 'images/shot-list/empty-screen@2x.png';
import { useReservation } from '@/composables/useReservation';
import { WhiteDefault } from '@/variables.module.scss';
import faqData from './faq.json';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
});

const emit = defineEmits(['open-dialog', 'show-scenes']);

const { isInProgress, isWrapped } = useReservation(
  computed(() => props.reservationId)
);
</script>

<template>
  <div v-if="isWrapped" class="shot-list-empty__wrapped">
    <div class="shot-list-empty__wrapped--icon">
      <SoonaIcon name="circle-check-solid" size="large" />
    </div>
    <h3 class="u-headline--heavy">your shoot has wrapped</h3>
    <p>nothing to see here!</p>
  </div>
  <div v-else-if="isInProgress" class="shot-list-empty__in-progress">
    <div class="shot-list-empty__in-progress--icon">
      <SoonaIcon name="circle-check-solid" size="large" />
    </div>
    <h3 class="u-headline--heavy">shoot in progress</h3>
    <SoonaButton element="router-link" to="./">go to gallery</SoonaButton>
  </div>
  <div v-else class="shot-list-empty__new">
    <SoonaNoResults
      :background-color="WhiteDefault"
      :image-source="EmptyShotList"
      mask="none"
    >
      <template #header>let’s plan your shoot</template>
      <template #body>
        a few details about your vision will help our creative team prepare.
      </template>
      <template #button>
        <div class="shot-list-empty__new--action-btns">
          <SoonaButton
            data-cypress="button-add-new-shot"
            @on-click="emit('show-scenes')"
          >
            make a shot list <SoonaIcon name="arrow-right" />
          </SoonaButton>
          <SoonaButton
            variation="tertiary"
            @on-click="emit('open-dialog', true)"
          >
            upload your own shot list
          </SoonaButton>
        </div>
      </template>
    </SoonaNoResults>
    <SoonaFaq :faq-tab-list="faqData" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.shot-list-empty {
  &__wrapped,
  &__in-progress {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 2rem;
  }

  &__wrapped {
    &--icon {
      background-color: variables.$avo-toast-30;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      > svg {
        color: variables.$avo-toast-60;
      }
    }
  }

  &__in-progress {
    &--icon {
      background-color: variables.$friendly-red-30;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      > svg {
        color: variables.$friendly-red-50;
      }
    }
  }

  &__new {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;

    &--action-btns {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
</style>
