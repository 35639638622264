<script setup>
import { computed, ref, toRef } from 'vue';
import BookingLayout from '../BookingLayout.vue';
import { faqData } from './faq.js';
import PhotoSegmentCard from 'src/components/booking/v3/p2/number-of-photos/PhotoSegmentCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import WarningIcon from 'src/components/svgs/WarningIcon.vue';
import { useReservation } from 'src/queries/useReservation';
import { useReservation as useReservationComposable } from '@/composables/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import { useAddReservationTag } from 'src/queries/reservation-tags/useAddReservationTag';
import { useRemoveReservationTag } from 'src/queries/reservation-tags/useRemoveReservationTag';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useRoute } from 'vue-router';
import { useTagCategories } from '@/queries/useTagCategories';
import { useFlag } from '@/composables/useFlag';

// props and data
const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const { itemSelected, buttonClicked } = useBaseEvents();
const route = useRoute();

let showNotSureModal = ref(false);

// api calls
const {
  data: numberOfPhotoData,
  isLoading,
  status,
  error: resTagsError,
} = useReservationTags(toRef(props, 'reservationId'), toRef(props, 'stepName'));

const numberOfPhotoOptions = computed(() => {
  return numberOfPhotoData.value?.options || [];
});
const { data: reservation, error } = useReservation(
  toRef(props, 'reservationId')
);
const {
  mutate: addReservationTag,
  isPending: isAdding,
  error: addError,
} = useAddReservationTag(toRef(props, 'reservationId'));

const {
  mutate: removeReservationTag,
  isPending: isRemoving,
  error: removeError,
} = useRemoveReservationTag(toRef(props, 'reservationId'), {
  // skip invalidation so that we only update the reservation after the add has finished
  skipInvalidate: true,
});

const { proServiceSlugForSize } = useReservationComposable(
  toRef(props, 'reservationId')
);

const formattedProServiceSlugForSize = computed(() => {
  const slug = proServiceSlugForSize.value;

  return { slug };
});

const { data: proServiceTagCategory } = useTagCategories(
  formattedProServiceSlugForSize
);

const mostPopularPhotoQuantityFlag = useFlag(
  'apollo_most_popular_photo_quantity'
);

// computed
const isBusy = computed(() => {
  return isLoading.value || isAdding.value || isRemoving.value;
});
let currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});
let isStepValid = computed(() => {
  return !isBusy.value && currentStep.value?.complete;
});

const priorityError = usePriorityError(
  removeError,
  addError,
  resTagsError,
  error
);

// methods
function onBackClick() {
  props.onBack();
}

function onNextClick() {
  if (route.query?.pro_service) {
    const proServiceTagId = proServiceTagCategory.value?.[0].tags?.find(
      t =>
        t.title.replace(/\s+/g, '_').replace(/&/g, 'and') ===
        route.query.pro_service
    )?.id;

    const hasThisProService = reservation.value?.tags?.find(
      t =>
        t.title.replace(/\s+/g, '_').replace(/&/g, 'and') ===
        route.query.pro_service
    );

    if (!hasThisProService) {
      addReservationTag(
        { tagId: proServiceTagId },
        {
          onSuccess: () => {
            props.onNext();
          },
        }
      );
    } else {
      props.onNext();
    }
  } else {
    props.onNext();
  }
}

function addSelectedOption(selectedOption) {
  addReservationTag(
    { tagId: selectedOption.tag.id },
    {
      onSuccess: () => {
        itemSelected({
          context: 'booking',
          subContext: 'photo quantity',
          itemLabel: selectedOption.tag.title,
          itemPrice: 0,
          itemQuantity: 1,
        });
      },
    }
  );
}

function onSelectionChange(selectedOption) {
  if (selectedOption.selected || isBusy.value) return;

  const existingSelected = numberOfPhotoOptions.value.find(
    option => option.selected && option.tag.id !== selectedOption.tag.id
  );
  if (existingSelected) {
    removeReservationTag(
      {
        reservationTagId: existingSelected.reservation_tag.id,
        deleteObsoleted: true,
      },
      {
        onSuccess: () => {
          addSelectedOption(selectedOption);
        },
      }
    );
  } else {
    addSelectedOption(selectedOption);
  }
}

function handleShowNotSureModal() {
  showNotSureModal.value = true;
  buttonClicked({
    context: 'booking',
    subContext: 'photo quantity',
    buttonLabel: `I'm not sure`,
    buttonAction: `open "not sure" modal`,
  });
}

function handleNotSureConfirm() {
  onSelectionChange(
    numberOfPhotoOptions.value.find(option => option.tag.title === '6 - 10')
  );
  showNotSureModal.value = false;
  buttonClicked({
    context: 'booking',
    subContext: 'photo quantity',
    buttonLabel: 'select 6–10 photos',
    buttonAction: 'select 6–10 photos',
  });
}

function handleNotSureCancel() {
  showNotSureModal.value = false;
  buttonClicked({
    context: 'booking',
    subContext: 'photo quantity',
    buttonLabel: 'nevermind',
    buttonAction: `close "not sure" modal`,
  });
}
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div class="number-of-photos prebooking-transition__content">
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="loading"
        />
        <SoonaHeading>
          how many photos do you need?
          <template #subtitle>
            an estimate is just fine! this helps us determine the length of your
            shoot. photos are $39 each and you’ll only pay for what you love
            after your shoot wraps.
          </template>
        </SoonaHeading>
        <div v-if="status === 'success'" class="number-of-photos__segments">
          <PhotoSegmentCard
            v-for="(option, i) in numberOfPhotoOptions"
            :key="i"
            :is-disabled="isBusy"
            :is-selected="option.selected"
            :is-most-popular="
              mostPopularPhotoQuantityFlag
                ? option.tag.title === '11 - 15'
                : option.tag.title === '6 - 10'
            "
            :option="option"
            @on-selection-change="onSelectionChange"
          />
        </div>
        <button
          class="u-button-reset number-of-photos__not-sure"
          @click="handleShowNotSureModal"
        >
          I’m not sure!
        </button>
        <SoonaDialog v-if="showNotSureModal" @close="handleNotSureCancel">
          <template #heading>
            <strong class="number-of-photos__recommendation">
              <WarningIcon aria-title="Warning" />
              photo estimate recommendation
            </strong>
          </template>
          <p>
            not sure how many photos you need? we recommend starting with a
            shoot designed for 6–10 photos for your first soona experience.
            <strong>
              this is only for scheduling purposes. you are not required to
              purchase 6–10 photos.
            </strong>
          </p>
          <template #footer="{ close }">
            <SoonaButton variation="tertiary" @on-click="close">
              nevermind
            </SoonaButton>
            <SoonaButton
              data-cypress="button-dialog-confirm"
              @on-click="handleNotSureConfirm"
            >
              select 6-10 photos
            </SoonaButton>
          </template>
        </SoonaDialog>
        <SoonaFaq :faq-tab-list="faqData" />
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.number-of-photos {
  &__segments {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
  }

  &__not-sure {
    @include variables_fonts.u-body--regular;

    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  &__recommendation {
    display: flex;
    align-items: center;

    svg {
      flex: 0 0 1.75rem;
      margin-right: 0.5rem;
    }
  }

  :deep(.booking-checkbox) {
    label {
      @include variables_fonts.u-body--regular;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__segments {
      gap: 2rem;
    }
  }
}
</style>
