<script setup>
import { useCreateAccount } from '@/queries/account/useCreateAccount';
import { ref } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useRoute, useRouter } from 'vue-router';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenuItemCheckbox from '@/components/ui_library/SoonaDropdownMenuItemCheckbox.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaUserAvatar from '@/components/ui_library/SoonaUserAvatar.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

defineProps({
  accountName: {
    type: String,
    default: null,
  },
  orderedAccounts: {
    type: Array,
    required: true,
  },
  userAvatarSrc: {
    type: String,
    default: null,
  },
  userName: {
    type: String,
    required: true,
  },
});

const apolloCreateAccount = useFlag('apollo_create_account');
const { signInWithSelectedAccount } = useAuth();

const route = useRoute();
const router = useRouter();
const { linkClicked } = useBaseEvents();
const showDialog = ref(false);
const newAccountName = ref('');
const showAccountCreateDialog = () => {
  showDialog.value = true;

  linkClicked({
    context: route.meta.context,
    subContext: 'platform sidebar',
    linkLabel: 'add new account',
    linkHref: null,
  });
};
const hideAccountCreateDialog = () => {
  showDialog.value = false;
};

const {
  mutate: createAccount,
  isPending: isCreating,
  error: createAccountError,
} = useCreateAccount();

const priorityError = usePriorityError(createAccountError);
const { addToast } = useSoonaToast();

function handleCreateAccount() {
  const attributes = { account: { name: newAccountName.value } };

  createAccount(attributes, {
    onSuccess: async () => {
      showDialog.value = false;
      addToast('account created', {
        variation: 'success',
      });
      await router.push('/');
    },
  });
}
</script>

<template>
  <div class="accounts-list">
    <SoonaDropdownMenu class="accounts-list__menu" menu-width="100%">
      <template
        #trigger-button="{
          attrs,
          caretClass,
          isOpen,
          onTriggerClick,
          onTriggerKeydown,
        }"
      >
        <button
          v-bind="attrs"
          class="u-button-reset accounts-list__profile"
          aria-controls="account-list"
          aria-label="toggle account list"
          aria-haspopup="menu"
          :aria-expanded="isOpen"
          data-cypress="button-open-accounts-list"
          @click="onTriggerClick"
          @keydown="onTriggerKeydown"
        >
          <SoonaUserAvatar
            class="user-account-avatar"
            :src="userAvatarSrc"
            img-size="2rem"
            svg-size="2rem"
          />
          <span class="accounts-list__user-container">
            <span class="u-label--heavy">
              <span class="u-visually-hidden">current account:&nbsp;</span>
              {{ accountName }}
            </span>
            <span class="u-small--regular">
              <span class="u-visually-hidden">current user:&nbsp;</span>
              {{ userName }}
            </span>
          </span>
          <SoonaIcon :class="caretClass" name="chevron-down" />
        </button>
      </template>
      <template #default="{ keydown, mouseover, clickCapture }">
        <SoonaDropdownMenuItemCheckbox
          v-for="account in orderedAccounts"
          :key="account.id"
          role="menuitemradio"
          :checked="account.current"
          :show-checkmark="false"
          class="accounts-list__dropdown-button"
          data-cypress="user-nav-account-list-item"
          @click="signInWithSelectedAccount(account.id, '/')"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaUserAvatar
            class="user-account-avatar"
            :src="account.avatar ?? account.owner_avatar_url"
            img-size="1.5rem"
            svg-size="1.5rem"
          />
          <span class="u-label--regular account-name">
            {{ account.name }}
          </span>
          <SoonaIcon
            v-if="account.current"
            name="check"
            size="medium"
            class="active-check"
          />
        </SoonaDropdownMenuItemCheckbox>
        <template v-if="apolloCreateAccount">
          <SoonaDropdownMenuItem>
            <div role="separator"></div>
          </SoonaDropdownMenuItem>
          <SoonaDropdownMenuItem>
            <button
              class="u-button-reset accounts-list__dropdown-button add-account"
              role="menuitem"
              data-cypress="add-new-account"
              @click="showAccountCreateDialog"
              @keydown="keydown"
              @mouseover="mouseover"
              @click.capture="clickCapture"
            >
              <SoonaIcon name="plus" />
              <span class="u-label--heavy">add new account</span>
            </button>
          </SoonaDropdownMenuItem>
        </template>

        <SoonaDialog v-if="showDialog" @close="hideAccountCreateDialog">
          <template #heading> create a new account </template>

          <SoonaTextfield
            v-model="newAccountName"
            label="company or brand name"
            name="name"
            placeholder="enter the account name"
            :required="true"
            :rules="['required']"
            :disabled="isCreating"
            :maxlength="100"
            data-cypress="account-name"
          />

          <SoonaError v-if="priorityError">
            {{ priorityError }}
          </SoonaError>

          <template #footer="{ close }">
            <SoonaButton
              variation="secondary-gray"
              :disabled="isCreating"
              @on-click="close"
            >
              cancel
            </SoonaButton>
            <SoonaButton
              :disabled="isCreating || !accountName"
              :loading="isCreating"
              data-cypress="button-dialog-confirm"
              @on-click="handleCreateAccount"
            >
              create account
            </SoonaButton>
          </template>
        </SoonaDialog>
      </template>
    </SoonaDropdownMenu>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.accounts-list {
  padding-left: 1.25rem;
  padding-right: 0.75rem;

  &__profile {
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.375rem;
    display: flex;
    padding: 0.625rem 0.75rem;
    align-items: center;
    gap: 0.5rem;
    width: 100%;

    &:hover {
      background-color: variables.$gray-20;
    }

    svg {
      color: variables.$gray-60;
    }
  }

  &__user-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    overflow: hidden;

    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__dropdown-button {
    display: flex !important;
    align-items: center;
    gap: 0.5rem;
    text-transform: unset;
  }

  .account-name {
    flex: 1 1 auto;
    text-align: left;
  }

  .active-check {
    color: variables.$periwink-blue-60;
    flex-shrink: 0;
  }

  .user-account-avatar {
    color: variables.$black-default;
    flex-shrink: 0;
  }

  .add-account {
    color: variables.$gray-60;
  }
}
</style>
