<script setup>
import { computed, ref, watch, onMounted, watchEffect } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';
import TitleBar from '@/components/shared/TitleBar.vue';
import { WhiteDefault } from '@/variables.module.scss';
import BookingProducts from '@/components/user/anytime/details/BookingProducts.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useReservation } from 'src/queries/useReservation';
import TargetPlatforms from '@/components/user/anytime/details/TargetPlatforms.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useRouter } from 'vue-router';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useUpdateReservation } from '@/queries/useUpdateReservation';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
});

const { addToast } = useSoonaToast();

const router = useRouter();

const reservationId = computed(() => props.reservationId);

const {
  data: reservation,
  isSuccess,
  error: loadingReservationError,
} = useReservation(reservationId);

const {
  mutate: updateReservation,
  isPending: isMutating,
  error: updateError,
} = useUpdateReservation(reservationId);
const priorityError = usePriorityError(loadingReservationError, updateError);
const ignoreChanges = ref(false);
const showUnsavedChangesDialog = ref(false);
const accountId = ref('');
const status = ref(null);
const packQuantity = ref(null);
const selectedCatalogItems = ref([]);
const shootTitle = ref('');
const shootGoals = ref('');
const shootMoodboard = ref('');
const shootCrewNote = ref('');
const targetPlatforms = ref([]);
const isPack = ref(false);
const hasUnsavedChanges = ref(false);
const defaultDataHasBeenSet = ref(false);
const shootTitleDefault = computed(() => reservation.value?.name);
const shootGoalsDefault = computed(() => reservation.value?.description);
const shootMoodboardDefault = computed(() => reservation.value?.moodboard);
const shootCrewNoteDefault = computed(() => reservation.value?.shot_list_note);
const selectedCatalogItemsDefault = computed(() => {
  return reservation.value?.required_catalog_items
    .map(x => x)
    .sort((a, b) => {
      return a.id - b.id;
    });
});
const targetPlatformsDefault = computed(() => {
  return reservation.value?.target_platforms.map(x => x).sort();
});
const isDraft = computed(() => reservation.value?.status === 'draft');

function arraysMatch(newArray, existingArray) {
  if (!newArray || !existingArray || newArray.length !== existingArray.length) {
    return false;
  }
  return newArray.every((val, index) => {
    if (typeof val === 'object') {
      return val.id === existingArray[index].id;
    } else {
      return val === existingArray[index];
    }
  });
}

watch(targetPlatforms, val => {
  targetPlatforms.value = val?.sort() ?? [];
});

watchEffect(() => {
  hasUnsavedChanges.value =
    defaultDataHasBeenSet.value &&
    isSuccess.value &&
    (shootTitle.value !== shootTitleDefault.value ||
      shootGoals.value !== shootGoalsDefault.value ||
      shootMoodboard.value !== shootMoodboardDefault.value ||
      shootCrewNote.value !== shootCrewNoteDefault.value ||
      !arraysMatch(targetPlatforms.value, targetPlatformsDefault.value) ||
      !arraysMatch(
        selectedCatalogItems.value,
        selectedCatalogItemsDefault.value
      ));
});

function setDefaultData() {
  accountId.value = reservation.value?.account_id;
  status.value = reservation.value?.status;
  packQuantity.value = reservation.value?.pack_configuration?.quantity;
  selectedCatalogItems.value = reservation.value?.required_catalog_items.map(
    x => x
  );
  shootTitle.value = reservation.value?.name;
  shootGoals.value = reservation.value?.description;
  shootMoodboard.value = reservation.value?.moodboard;
  shootCrewNote.value = reservation.value?.shot_list_note;
  targetPlatforms.value = reservation.value?.target_platforms
    .map(x => x)
    .sort();
  isPack.value = reservation.value?.isPickAPack;
  defaultDataHasBeenSet.value = true;
}

watch(isSuccess, () => {
  if (reservation.value?.status === 'draft') {
    window.location.href = `/#/booking/${reservationId.value}/down-payment`;
  }
  setDefaultData();
});

onMounted(() => {
  if (reservation.value) {
    setDefaultData();
  }
});

const requiredCatalogItemsAttributes = computed(() => {
  const result = selectedCatalogItemsDefault.value?.map(originalCatalogItem => {
    const exists = selectedCatalogItems.value.find(
      ci => ci.id === originalCatalogItem.id
    );

    return {
      id: originalCatalogItem.required_catalog_item_id,
      catalog_item_id: originalCatalogItem.id,
      _destroy: exists ? 0 : 1,
    };
  });
  selectedCatalogItems.value?.forEach(catalogItem => {
    if (!result.find(x => x.catalog_item_id === catalogItem.id)) {
      result.push({ catalog_item_id: catalogItem.id });
    }
  });
  return result;
});

const reservationDetailsPage = () => {
  router.push(`/reservation/${reservationId.value}/info`);
};

function saveClicked() {
  updateReservation(
    {
      name: shootTitle.value,
      moodboard: shootMoodboard.value,
      description: shootGoals.value,
      shot_list_note: shootCrewNote.value,
      required_catalog_items_attributes: requiredCatalogItemsAttributes.value,
      target_platforms: targetPlatforms.value,
    },
    {
      onError: () => {
        addToast(
          'we are having trouble updating this booking. please try again later.',
          {
            variation: 'error',
          }
        );
      },
      onSuccess: () => {
        hasUnsavedChanges.value = false;
        reservationDetailsPage();
        addToast('booking details saved', {
          variation: 'success',
        });
      },
    }
  );
}

function ignoreChangesClicked() {
  ignoreChanges.value = true;
  reservationDetailsPage();
}

onBeforeRouteLeave(() => {
  if (hasUnsavedChanges.value && !ignoreChanges.value) {
    showUnsavedChangesDialog.value = true;
    return false;
  }
});
</script>
<template>
  <div
    v-if="isDraft || loadingReservationError || !isSuccess"
    class="container"
  >
    <div class="columns is-centered">
      <div class="column is-8">
        <div class="notification is-8">
          <h1 class="title">sorry, something went wrong…</h1>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="reservation-shoot-summary">
    <TitleBar
      :reservation-id="reservationId"
      heading-id="reservation-shoot-summary-heading"
      :title="shootTitle"
      :background-color="WhiteDefault"
      :border-color="WhiteDefault"
      :previous-page="reservationDetailsPage"
    />
    <section class="reservation-shoot-summary__content">
      <SoonaError v-if="priorityError">
        {{ priorityError }}
      </SoonaError>
      <fieldset class="reservation-shoot-summary__fieldset">
        <SoonaTextfield
          ref="booking-details"
          v-model:model-value="shootTitle"
          label="✏️ shoot title"
          placeholder="add a shoot name"
          :disabled="isPack"
        />
        <BookingProducts
          v-model:catalog-items="selectedCatalogItems"
          :reservation-id="reservationId"
          :reservation-status="status"
          :account-id="accountId"
          edit-view
        />
        <TargetPlatforms
          v-model:target-platforms="targetPlatforms"
          :reservation-id="reservationId"
          :account-id="accountId"
          edit-view
        />
        <SoonaTextfield
          v-model:model-value="shootGoals"
          label="🎯 shoot goal or objective"
          placeholder="a short sentence is fine! tell us what you want to accomplish"
          element="textarea"
          rows="4"
          :disabled="isPack"
        >
          <template #subtext>
            <span class="reservation-shoot-summary__sub-heading">
              understanding your goals helps us prepare! you’ll be able to
              provide more detail in your shotlist.
            </span>
          </template>
        </SoonaTextfield>
        <SoonaTextfield
          v-model:model-value="shootMoodboard"
          label="🔮 moodboard or inspiration link"
          placeholder="try pinterest for inspiration!"
        />
        <div
          v-if="packQuantity"
          class="reservation-shoot-summary__pack-quantity"
        >
          <h2 class="reservation-shoot-summary__pack-quantity-heading">
            quantity purchased
          </h2>
          <p>
            {{ packQuantity }}
          </p>
        </div>

        <SoonaTextfield
          v-model:model-value="shootCrewNote"
          label="📝 notes for the crew"
          element="textarea"
          rows="4"
          placeholder="anything you need your photographer to know before your shoot?"
        />

        <SoonaButton
          class="reservation-shoot-summary__action-btn"
          :disabled="isMutating"
          @on-click="saveClicked"
          >save</SoonaButton
        >
      </fieldset>
    </section>
    <SoonaDialog
      v-if="showUnsavedChangesDialog"
      size="small"
      @close="showUnsavedChangesDialog = false"
    >
      <template #heading>do you want to save changes?</template>
      <template #default>
        updates you’ve made to this page will be lost if you don’t save.
      </template>
      <template #footer>
        <SoonaButton
          type="button"
          variation="tertiary"
          size="medium"
          @on-click="ignoreChangesClicked"
        >
          don't save
        </SoonaButton>
        <SoonaButton
          form="coupon-form"
          type="submit"
          :loading="isMutating"
          @on-click="saveClicked"
        >
          save changes
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.reservation-shoot-summary {
  flex: 1 0 auto;

  &__fieldset {
    display: flex !important;
    flex-direction: column;
  }

  &__sub-heading {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-60;
    margin: 0 0 0.5rem 0;
  }

  &__content {
    max-width: 38.875rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding: 2rem 1rem;
  }

  &__action-btn {
    width: 100%;
    margin: 1rem 0;
  }

  &__pack-quantity {
    padding-bottom: 1.9375rem;
  }

  &__pack-quantity-heading {
    @include variables_fonts.u-label--regular;
    margin: 0 0 0.5rem 0;
    padding: 1.125rem 0 0.5rem;
    font-weight: 800;
    font-size: 0.875rem;
    color: variables.$black-default;
  }
}
</style>
