<script setup>
const asset = defineModel('asset', {
  default: null,
  type: Object,
});
</script>

<template>
  <div :key="asset?.file?.url || `comparison-item`" class="comparison-item">
    <div class="comparison-item__asset">
      <img
        v-if="asset && asset.file.is_image"
        :src="asset.file.url"
        :alt="`${asset.edit_status} uploaded at ${asset.created_at}`"
      />
      <video
        v-else-if="asset && asset.file.is_video"
        :key="asset.preview_url"
        :poster="asset.preview_url"
        controls
        controlsList="nodownload"
        class="preview-media"
      >
        <source :key="asset.file.url" :src="asset.file.url" type="video/mp4" />
      </video>
    </div>
    <div class="comparison-item__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.comparison-item {
  background-color: variables.$white-default;
  display: flex;
  flex: 1 0 50%;
  flex-direction: column;

  &:first-child {
    border-right: 0.0625rem solid variables.$gray-30;
  }

  &__asset {
    align-items: center;
    background-color: variables.$gray-10;
    border-bottom: 0.0625rem solid variables.$gray-30;
    display: flex;
    flex-shrink: 0;
    height: 60dvh;
    justify-content: center;

    img,
    video {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      min-width: 21.875rem;
      object-fit: contain;
      width: 100%;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    gap: 1rem;
    overflow: hidden;
    min-height: 18.75rem;
  }
}
</style>
