<script setup>
import { ref, toRef, computed, onMounted, watchEffect, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useMe } from '@/composables/user/useMe';
import { useReservation } from 'src/composables/useReservation';
import { useReservationAvailabilityErrors } from 'src/queries/useReservation';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import BookingLayout from './BookingLayout.vue';
import HowDidYouHearAboutUs from 'src/components/HowDidYouHearAboutUs.vue';
import BookingSidecart from 'src/components/booking/v3/p2/BookingSidecart.vue';
import PaymentInterstitial from 'src/components/booking/v3/p2/PaymentInterstitial.vue';
import SoonaPaymentBooking from 'src/components/booking/SoonaPaymentBooking.vue';
import AlertNotification from 'src/components/AlertNotification.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import isUndefined from 'lodash/isUndefined';
import { triggerBookingCheckoutStartedEvent } from '@/api/reservation';
import ArrowLeft from 'src/components/svgs/ArrowLeft.vue';
import { previousStep } from 'src/lib/booking/processNavigation';
import AccountInfo from 'src/components/booking/AccountInfo.vue';
import { usePaymentInterstitialRedirect } from './usePaymentInterstitialRedirect';
import { useIntegrations } from '@/composables/useIntegrations';
import { useApplyCoupon } from 'src/queries/reservations/useApplyCoupon';
import { useConfirmReservation } from '@/queries/checkouts/useConfirmReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useFlag } from '@/composables/useFlag';
import BillingInfo from 'src/components/checkout/BillingInfo.vue';
import { useAccount } from '@/composables/useAccount';
import { useUpdateStudioAccess } from 'src/queries/reservations/useUpdateStudioAccess';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { usePaymentMethodsStore } from '@/components/payment_methods/store/usePaymentMethodsStore';
import { storeToRefs } from 'pinia';
import unescape from 'lodash/unescape';
import { useRouter } from 'vue-router';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  packId: String,
  reservationId: String,
});
const { linkClicked } = useBaseEvents();
const router = useRouter();

const checkoutSubscriptionPromoBannerFlag = useFlag(
  'apollo_subscription_checkout_promo_banner'
);
const apolloSubxPostBookingUpsellFlag = useFlag(
  'apollo_subx_post_booking_upsell'
);

const enableDefaultTierTwoFlag = useFlag('pegasus_enable_default_tier_two');

const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
const phoenixByoDdpFlag = useFlag('phoenix_byo_ddp');
const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const store = useStore();
const { me, currentAccountId } = useMe();
const salesTaxStore = useSalesTaxStore();
const paymentMethodsStore = usePaymentMethodsStore();
const {
  salesTaxBillingAddressId,
  stripeSalesTaxCalculationId,
  taxAmountExclusiveInCents,
} = storeToRefs(salesTaxStore);

const { hasShopifyIntegration } = useIntegrations(currentAccountId);
const showInterstitial = ref(false);
const accountInfo = ref(undefined);
const activeSubscriptionPromo = ref('');
const reservationId = computed(() => props.reservationId);

const {
  mutate: confirmReservation,
  isPending: isProcessing,
  error: confirmReservationError,
} = useConfirmReservation(reservationId);

const {
  reservation,
  reservationAccountId,
  reservationLocation,
  isInternal,
  isHeadshotsForAll,
  isSurprise,
  isFree,
  isDownPaymentZero,
  isPack,
} = useReservation(toRef(props, 'reservationId'));

const { account, isDigitalSubscriber } = useAccount(
  reservation.value?.account_id
);

const isSigningUpForSubscription = computed(
  () =>
    phoenixStudioRentalFeeFlag.value &&
    (reservation.value?.studio_access?.level === 'tier-one' ||
      reservation.value?.studio_access?.level === 'tier-two')
);

const promoQuery = computed(() =>
  apolloSubxPostBookingUpsellFlag.value && !isSigningUpForSubscription.value
    ? { sub_promo: true }
    : {}
);

const previous = previousStep({
  currentStepName: 'down-payment',
  reservation: reservation.value,
});

const { startInterstitialTimeout, redirectTo } = usePaymentInterstitialRedirect(
  reservationId,
  reservationAccountId,
  promoQuery
);

const showShippingLocation = computed(() => !!reservationLocation.value?.id);

const isWithin72Hours = computed(() => {
  if (reservation.value?.start === null) return false;

  const now = new Date();
  const targetDate = new Date(reservation.value.start);
  const differenceInHours = (targetDate - now) / (1000 * 60 * 60);

  return Math.abs(differenceInHours) <= 72;
});

const hasDelayedPaymentDue = computed(() => {
  return !isPack.value && phoenixByoDdpFlag.value && !isWithin72Hours.value;
});

const { data: reservationAvailabilityErrors } =
  useReservationAvailabilityErrors(toRef(props, 'reservationId'));

const isSingleCol = computed(() => {
  return isHeadshotsForAll.value || isInternal.value || isSurprise.value;
});

const getErrorMessages = computed(
  () => store.getters['errors/getErrorMessages']
);

const errorMessages = computed(() => {
  return [].concat(
    getErrorMessages.value ?? [],
    reservationAvailabilityErrors?.value?.errors ?? []
  );
});

const formatReservationType = type => {
  if (type === 'anytime') return 'virtual';
  if (type === 'headshots_for_all') return 'h4a';
  return reservation.value?.reservation_type.replace(/_/g, '-');
};

const alertNotificationKey = computed(() => {
  const resType = reservation.value?.reservation_type;
  return `downpayment-banner-${formatReservationType(resType)}`;
});

const hasDiscount = computed(() => {
  return !isUndefined(reservation.value?.down_payment_order?.discount);
});

const isOrderTotalZero = computed(() => {
  return reservation.value?.down_payment_order_total === '0.0';
});

const unappliedDiscount = computed(() => {
  return reservation.value?.unapplied_coupon_code;
});

const { mutate: applyCoupon } = useApplyCoupon(
  toRef(props, 'reservationId'),
  reservationAccountId
);
watchEffect(() => {
  if (!hasDiscount.value && unappliedDiscount.value) {
    applyCoupon({
      coupon: unappliedDiscount.value,
      orderId: reservation.value?.down_payment_order?.id,
    });
  }
});

const discountCode = computed(() => {
  return reservation.value?.down_payment_order?.discount?.code;
});

const reservationDraftType = computed(() => {
  if (reservation.value?.previous) return 'continuation shoot';
  return reservation.value?.draft_type === 'client_draft'
    ? 'draft booking'
    : 'suggested booking';
});

const confirmReservationClicked = (stripe, card, saveCard) => {
  store.dispatch('flash/clearFlashMessages');

  if (hasShopifyIntegration.value) {
    return store
      .dispatch('createShopifyDownPaymentCharge', {
        reservationId: props.reservationId,
        reservationType: reservation.value?.reservation_type,
        phoneNumber: accountInfo.value?.phoneNumber,
        sales_tax: {
          id: stripeSalesTaxCalculationId.value,
          soona_billing_address_id: salesTaxBillingAddressId.value,
          tax_amount_exclusive: taxAmountExclusiveInCents.value,
        },
      })
      .then(response => {
        if (response['confirmation_url']) {
          // TODO: New tracking
          window.top.location.href = response['confirmation_url'];
        } else {
          showInterstitial.value = true;
          startInterstitialTimeout();
        }
      });
  } else if (isFree.value || isDownPaymentZero.value) {
    return store
      .dispatch('confirmReservation', {
        reservationId: props.reservationId,
        reservationType: reservation.value?.reservation_type,
        salesTax: {
          id: stripeSalesTaxCalculationId.value,
          soona_billing_address_id: salesTaxBillingAddressId.value,
          tax_amount_exclusive: taxAmountExclusiveInCents.value,
        },
      })
      .then(() => {
        // TODO: New tracking
        showInterstitial.value = true;
        startInterstitialTimeout();
      });
  } else if (paymentsPaymentMethodsFlag.value) {
    confirmReservation(
      {
        payment_method_id: stripe,
        payment_method_type: card,
        save_payment_method: saveCard,
        no_down_payment: hasDelayedPaymentDue.value,
        ...(activeSubscriptionPromo.value && {
          [activeSubscriptionPromo.value]: true,
        }),
        reservation_type: reservation.value?.reservation_type,
        sales_tax: {
          id: stripeSalesTaxCalculationId.value,
          soona_billing_address_id: salesTaxBillingAddressId.value,
          tax_amount_exclusive: taxAmountExclusiveInCents.value,
        },
      },
      {
        onSuccess: () => {
          showInterstitial.value = true;
          startInterstitialTimeout();
        },
      }
    );
  } else {
    return store
      .dispatch('confirmReservation', {
        account: reservation.value?.account,
        stripe: stripe,
        card: card,
        saveCard: saveCard,
        reservationId: props.reservationId,
        reservationType: reservation.value?.reservation_type,
        noDownPayment: hasDelayedPaymentDue.value,
        activePromo: activeSubscriptionPromo.value,
      })
      .then(() => {
        showInterstitial.value = true;
        startInterstitialTimeout();
      });
  }
};

const priorityError = usePriorityError(confirmReservationError);

const handleAccountInfoChange = value => {
  accountInfo.value = value;
};

const { mutate: updateStudioAccess } = useUpdateStudioAccess(
  toRef(props, 'reservationId')
);

const handleUpdatingStudioAccess = level => {
  updateStudioAccess({
    level: level,
    billingCycle: null,
  });
};

const studioAccessLevel = computed(() => {
  return reservation.value?.studio_access['level'];
});

const existingBillingCycle = computed(() => {
  return account.value?.current_subscription_recurring_interval;
});

watchEffect(() => {
  if (isDigitalSubscriber.value && studioAccessLevel) {
    if (phoenixStudioRentalFeeFlag.value && !isHeadshotsForAll.value) {
      if (isDigitalSubscriber.value && !!studioAccessLevel.value) {
        handleUpdatingStudioAccess(null);
      } else if (!isDigitalSubscriber.value && !studioAccessLevel.value) {
        handleUpdatingStudioAccess('day-pass');
      }
    }
  }
});

onMounted(async () => {
  triggerBookingCheckoutStartedEvent(props.reservationId);
});

onUnmounted(() => {
  salesTaxStore.$reset();
  paymentMethodsStore.$reset();
});

const trackBackToClick = () => {
  if (previous) {
    linkClicked({
      context: 'booking',
      subContext: 'down payment',
      linkLabel: `back to ${previous.button_text}`,
      linkHref: `/booking/${previous.slug}`,
    });
  }
};

const proServiceTags = computed(() => {
  return reservation.value.reservation_tags.filter(
    tag =>
      (tag.tag_category_slug?.includes('pro-service') ||
        tag.tag_category_slug?.includes('upgrades')) &&
      tag.tag_type !== 'skippable'
  );
});

const returnToSelectMembership = () => {
  trackBackToClick();
  let params = {
    level: 'tier-one',
    billingCycle: existingBillingCycle.value ?? 'year',
  };

  if (
    !isPack.value &&
    enableDefaultTierTwoFlag.value &&
    proServiceTags.value.length > 0
  ) {
    params = {
      level: 'tier-two',
      billingCycle: existingBillingCycle.value ?? 'month',
    };
  }
  updateStudioAccess(params);
  router.push(previous.slug);
};

const hasTrySoonaInCart = computed(() => {
  return discountCode?.value?.toLowerCase() === 'hifive';
});

const updateActiveSubscriptionPromo = value => {
  activeSubscriptionPromo.value = value;
};
</script>

<template>
  <div class="downpayment-wrapper">
    <BookingLayout v-bind="$attrs" no-sidecart hide-footer>
      <div class="prebooking-transition__content">
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="processing"
        />
        <PaymentInterstitial
          v-if="showInterstitial"
          :reservation-id="reservationId"
        >
          <template v-if="isFree || isOrderTotalZero" #heading>
            booking created!
          </template>
          <template v-else-if="hasDelayedPaymentDue" #heading>
            confirmed!
          </template>
          <template v-else #heading>payment received!</template>
          <template #bold-subheading>now hold tight...</template>
          <template #subheading>
            you’ll be redirected to your next steps
          </template>
          <template #payment-description>
            <p>
              if you don’t want to wait:
              <router-link :to="redirectTo">continue now!</router-link>
            </p>
            <p>or <router-link to="/booking/new">book again</router-link></p>
          </template>
        </PaymentInterstitial>
        <div v-if="reservation" class="downpayment">
          <SoonaAlert v-if="hasTrySoonaInCart">
            <strong>terms & conditions</strong>
            <br />
            valid for new clients only. does not apply if you have booked with
            soona before on any account. see full terms
            <a
              href="https://soona.co/trysoona-terms"
              target="_blank"
              rel="noopener noreferrer"
              >here</a
            >.
          </SoonaAlert>
          <SoonaAlert
            v-if="
              reservation.draft_type &&
              reservation.draft_type !== 'pending_draft'
            "
            data-cypress="draft-banner"
            cypress-name="alert-suggested-booking"
          >
            here is the summary for the {{ reservationDraftType
            }}<template v-if="reservation.name"
              >: <strong>{{ unescape(reservation.name) }}</strong
              >.
            </template>
            finish your order below!
          </SoonaAlert>
          <div class="downpayment__header">
            <h1 class="downpayment__title" data-cypress="booking-title">
              almost there! let’s get you booked!
            </h1>
            <router-link
              v-if="previous && previous.slug"
              class="downpayment__backlink"
              :to="previous.slug"
              @click="trackBackToClick"
            >
              <ArrowLeft aria-hidden="true" />
              back
              <template v-if="previous.button_text">
                to {{ previous.button_text }}
              </template>
            </router-link>
          </div>
          <section v-if="reservation && reservation.reservation_type">
            <alert-notification :alert-key="alertNotificationKey" />
          </section>
          <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
          <div
            v-if="errorMessages && errorMessages.length > 0"
            class="downpayment__errors"
            data-cypress="errors"
          >
            <SoonaError v-for="(error, index) in errorMessages" :key="index">
              {{ error }}
              <template v-if="error.includes('ah shoot!')">
                <router-link to="./schedule">click here</router-link>
                to select a new day and time.
              </template>
            </SoonaError>
          </div>
          <div
            class="downpayment__layout"
            :class="{
              'downpayment__layout--single-col': isSingleCol,
              'downpayment__layout--h4a': isHeadshotsForAll,
            }"
          >
            <BookingSidecart
              step-name="down-payment"
              class="downpayment__summary"
              :calculate-sales-tax="true"
              :reservation-id="reservationId"
              :affixed="false"
              :show-shipping-location="showShippingLocation"
              :customer-booking-flow="true"
              :show-subscription-promo-banner="
                !isFree &&
                !isDownPaymentZero &&
                checkoutSubscriptionPromoBannerFlag &&
                paymentsPaymentMethodsFlag
              "
              @return-to-previous-step="returnToSelectMembership"
              @update:active-promo="updateActiveSubscriptionPromo"
            />
            <div class="downpayment__handling-payment">
              <AccountInfo
                class="downpayment__account-info"
                :user="me"
                :checkout-buffer="
                  reservation.booking_flow === 'headshots_for_all' ||
                  reservation.booking_flow === 'headshots_for_all_event'
                "
                :company-name="reservation.account.name"
                :on-change="handleAccountInfoChange"
              />
              <!-- TODO: Re-add `|| suggestedBookingProcess` to conditional both class logics below-->
              <SoonaLoading
                v-if="isProcessing"
                is-loading
                :is-dark="false"
                loading-text="loading"
              />
              <BillingInfo
                class="downpayment__billing-info"
                :account-id="currentAccountId"
                variant="checkout"
              />
              <SoonaPaymentBooking
                class="downpayment__payment"
                :stripe-payment-required="
                  !hasShopifyIntegration && !isFree && !isDownPaymentZero
                "
                :on-payment-action="confirmReservationClicked"
                :reservation-id="reservationId"
                :account-info="accountInfo"
              />
            </div>
          </div>
        </div>
      </div>
    </BookingLayout>
    <HowDidYouHearAboutUs :has-subtitle="true" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

@mixin number-tag-element($content) {
  content: $content;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  border: 1px solid variables.$gray-30;
  background-color: variables.$white-default;
  color: variables.$gray-60;
  z-index: 10;
}

@mixin number-tag-sm($content) {
  position: relative;
  &::before {
    @include number-tag-element($content);
    @include variables_fonts.u-body--heavy;
    top: -1.25rem;
    width: 2.5rem;
    height: 2.5rem;
  }
}

@mixin number-tag-md($content) {
  position: relative;
  &::before {
    @include number-tag-element($content);
    @include variables_fonts.u-subheader--heavy;
    top: -1.5625rem;
    width: 3.125rem;
    height: 3.125rem;
  }
}

.downpayment-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.downpayment {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__layout {
    margin-bottom: 1.5rem;
  }

  &__header {
    margin-bottom: 1.25rem;
    text-align: center;
  }

  &__title {
    @include variables_fonts.u-title--heavy;

    color: variables.$black-default;
    margin: 0;
    text-align: center;
  }

  &__backlink {
    @include variables_fonts.u-label--regular;

    display: inline-flex;
    align-items: center;
    margin-top: 0.75rem;

    > svg {
      flex: 0 0 1rem;
      display: block;
      margin-right: 0.25rem;
      width: 1rem;
      height: 1rem;
    }
  }

  &__account-info {
    @include number-tag-sm('1');
    margin-top: 3.25rem;
    z-index: 0;
  }

  &__billing-info {
    margin-top: 2rem;
    @include number-tag-sm('2');
    z-index: 0;
  }

  &__payment {
    @include number-tag-sm('3');
    margin-top: 2rem;
    z-index: 0;
  }

  &__summary {
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    overflow: hidden;
  }
}

.how-did-you-hear-about-us {
  margin-bottom: -4rem;
}

@media (min-width: variables.$screen-sm-min) {
  .downpayment {
    &__header {
      margin-bottom: 2.8125rem;
    }

    &__title {
      @include variables_fonts.u-display--heavy;

      margin: 0;
    }

    &__backlink {
      @include variables_fonts.u-body--regular;

      > svg {
        flex-basis: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &__errors {
      margin-top: -1.375rem;
      margin-bottom: 0.3125rem;
    }

    &__layout {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      grid-column-gap: 2.25rem;
      grid-template-areas: 'left-col right-col';
      align-items: flex-start;

      &--single-col {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 35.625rem;
      }
    }

    &__summary {
      grid-area: right-col;
    }

    &__handling-payment {
      grid-area: left-col;
    }

    &__account-info {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  .how-did-you-hear-about-us {
    margin-bottom: -2rem;
  }
}

@media (min-width: variables.$screen-md-min) {
  .downpayment {
    &__header {
      display: grid;
      grid-template-columns: 1fr 2.8fr 1fr;
      gap: 1rem;
    }

    // in case there's no back link
    &__title:only-child {
      grid-column: span 3;
    }

    &__backlink {
      margin-top: 0;
      order: -1;
      align-self: center;
    }

    &__account-info {
      @include number-tag-md('1');
    }

    &__billing-info {
      @include number-tag-md('2');
    }

    &__payment {
      @include number-tag-md('3');
    }
  }
}
</style>
