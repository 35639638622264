<script setup>
import { computed } from 'vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  productId: { type: String, required: true },
  showWeb: {
    type: Boolean,
    required: true,
    default: false,
  },
});

const accountId = computed(() => props.accountId);
const productId = computed(() => props.productId);

const params = computed(() => {
  return `catalog_item_id=${productId.value}&origin[]=customer&origin[]=soona&ownership=customer&visibility=all`;
});

const webUrl = computed(() => {
  return `/api/accounts/${accountId.value}/digital_assets_downloads.zip?resolution=web&${params.value}`;
});

const hiResUrl = computed(() => {
  return `/api/accounts/${accountId.value}/digital_assets_downloads.zip?resolution=hi_res&${params.value}`;
});
</script>

<template>
  <div class="download-all">
    <SoonaDropdownMenu copy="download all" variation="secondary-gray">
      <template #default="{ keydown, mouseover }">
        <SoonaDropdownMenuItem v-if="showWeb">
          <a
            role="menuitem"
            :href="webUrl"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            web
          </a>
        </SoonaDropdownMenuItem>
        <SoonaDropdownMenuItem>
          <a
            role="menuitem"
            :href="hiResUrl"
            @keydown="keydown"
            @mouseover="mouseover"
          >
            hi-res
          </a>
        </SoonaDropdownMenuItem>
      </template>
    </SoonaDropdownMenu>
  </div>
</template>

<style lang="scss" scoped>
.download-all {
  display: flex;
  gap: 0.5625rem;

  &__tooltip-copy:not(:last-child) {
    padding-bottom: 0.75rem;
  }
}
</style>
