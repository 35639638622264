<script setup>
import { computed, ref } from 'vue';
import { useCapability } from '@/composables/useCapability';
import AlbumPicker from '@/components/user/anytime/album_picker/AlbumPicker.vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  addToAlbum: {
    type: Function,
    required: true,
  },
  addToFavorites: {
    type: Function,
    required: true,
  },
  isIconButton: {
    type: Boolean,
    default: false,
  },
  selectedAssets: {
    type: Array,
    required: true,
  },
});

defineEmits(['hide', 'cancel']);

const accountId = computed(() => props.accountId);
const selectedAssets = computed(() => props.selectedAssets);

const { hasCapability: hasFavoriteCapability } = useCapability({
  capability: 'favorite_gallery',
  subjectType: 'account',
  subjectId: accountId,
});

const selectedAreAllFavs = computed(() =>
  selectedAssets.value.every(a => a?.favorites_collection_digital_asset?.id)
);

const anySelectedAreOwnedBySoona = computed(() =>
  selectedAssets.value.some(a => a?.ownership !== 'soona')
);

const displayAlbumPicker = ref(false);
</script>

<template>
  <SoonaDropdownMenu
    :variation="isIconButton ? 'icon-gray-outline' : 'secondary-black'"
    :display-caret="false"
  >
    <template #trigger-content>
      <SoonaIcon name="plus" />
      <span :class="{ 'u-visually-hidden': isIconButton }">organize</span>
    </template>
    <template #default="{ keydown, mouseover, clickCapture }">
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="displayAlbumPicker = true"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="album" />
          add to album
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem
        v-if="
          hasFavoriteCapability &&
          !selectedAreAllFavs &&
          !anySelectedAreOwnedBySoona
        "
      >
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="addToFavorites"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="heart" />
          add to favorites
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>

  <AlbumPicker
    v-if="displayAlbumPicker"
    :account-id="accountId"
    :selected-digital-assets="selectedAssets"
    @cancel="displayAlbumPicker = false"
    @select="addToAlbum"
  />
</template>

<style lang="scss" scoped>
.organize-menu {
  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
