<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { toCurrency } from '@/lib/currency';
import { useGetOrderAddOn } from '@/queries/orders/useGetOrderAddOn';
import { usePriorityError } from '@/composables/usePriorityError';
import { useCapability } from '@/composables/useCapability';
import AssetEditingActions from '@/components/crew/orders/AddOnLineItem/AssetEditingActions.vue';
import AssetComparison from '@/components/dam/asset_comparison/AssetComparison.vue';
import OverlayDownloadAsset from '@/components/account_gallery/OverlayDownloadAsset.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import UploadDigitalAssetEdits from '@/components/crew/orders/UploadDigitalAssetEdits.vue';

const props = defineProps({
  orderId: {
    required: true,
    type: [Number, String],
  },
  addOnLineItemId: {
    required: true,
    type: [Number, String],
  },
});

defineOptions({ inheritAttrs: false });

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const router = useRouter();

const orderId = computed(() => props.orderId);
const addOnLineItemId = computed(() => props.addOnLineItemId);

const { data, error, isLoading } = useGetOrderAddOn({
  orderId,
  addOnLineItemId,
});

const order = computed(() => data.value?.order);
const digitalAsset = computed(() => data.value?.digital_asset);
const mediaAddOns = computed(() => data.value?.media_add_ons ?? []);

const pendingCount = computed(
  () =>
    digitalAsset.value?.derivatives?.filter(d => d.edit_status === 'pending')
      .length
);

const rejectedCount = computed(
  () =>
    digitalAsset.value?.derivatives?.filter(d => d.edit_status === 'rejected')
      .length
);

const priorityError = usePriorityError(error);

const nextRoute = computed(() => {
  if (!data.value.next) return null;

  return {
    name: 'crew-order-add-on-line-item',
    params: {
      orderId: orderId.value,
      addOnLineItemId: data.value.next,
    },
  };
});

const previousRoute = computed(() => {
  if (!data.value.previous) return null;

  return {
    name: 'crew-order-add-on-line-item',
    params: {
      orderId: orderId.value,
      addOnLineItemId: data.value.previous,
    },
  };
});

const goBack = () => {
  router.push({
    name: 'crew-order',
    params: { orderId: orderId.value },
  });
};
</script>

<template>
  <SoonaLoading v-if="isLoading" is-loading />
  <AssetComparison
    v-else
    back-btn-copy="back to order"
    :comparison-assets="digitalAsset?.derivatives"
    :errors="priorityError"
    :next-route="nextRoute"
    :original-asset="digitalAsset"
    :previous-route="previousRoute"
    @back="goBack"
  >
    <template #header-left-bottom>
      <div v-if="isSoonaStaff && pendingCount">
        {{ pendingCount }} pending edit{{ pendingCount > 1 ? 's' : '' }}
        will be delivered when the order is completed
      </div>
      <div v-if="isSoonaStaff && rejectedCount">
        {{ rejectedCount }} rejected edit{{ rejectedCount > 1 ? 's' : '' }}
        will not be delivered
      </div>
    </template>
    <template #header-right>
      <UploadDigitalAssetEdits
        :account-id="order?.account_id"
        :order-id="order?.id"
        :reservation-id="order?.reservation_id"
        :original-digital-asset-id="digitalAsset?.id"
        variation="secondary-black"
      />
    </template>
    <template #left-actions="{ selectedAsset }">
      <OverlayDownloadAsset
        :file-url="selectedAsset?.file?.url"
        :raw-url="selectedAsset?.raw?.url"
        :web-url="selectedAsset?.web?.url"
      />
      <AssetEditingActions
        v-if="isSoonaStaff && selectedAsset"
        :account-id="selectedAsset.account_id"
        :digital-asset="selectedAsset"
        :order-id="orderId"
        :enable-review="order?.status === 'paid'"
      />
    </template>
    <template #left-details>
      <div v-if="mediaAddOns?.length">
        <h3 class="u-label--heavy">add-ons</h3>
        <ul>
          <li
            v-for="mediaAddOn in mediaAddOns"
            :key="mediaAddOn.id"
            class="u-small--regular"
          >
            {{ mediaAddOn.name }} ({{ toCurrency(mediaAddOn.total) }})
          </li>
        </ul>
      </div>
    </template>
    <template #right-actions="{ selectedAsset }">
      <OverlayDownloadAsset
        :file-url="selectedAsset?.file?.url"
        :raw-url="selectedAsset?.raw?.url"
        :web-url="selectedAsset?.web?.url"
      />
      <AssetEditingActions
        v-if="isSoonaStaff && selectedAsset"
        :account-id="selectedAsset.account_id"
        :digital-asset="selectedAsset"
        :order-id="orderId"
        :enable-review="order?.status === 'paid'"
      />
    </template>
  </AssetComparison>
</template>
