<script setup>
import { reactive, computed } from 'vue';

const props = defineProps({ message: Object });
const state = reactive({ hasError: false });

const userInitials = computed(() => {
  return props.message.sender.name.substring(0, 1).toLocaleUpperCase();
});
// handles img src urls that are broken links; show placeholder if broken
const handleError = () => {
  state.hasError = true;
};
</script>

<template>
  <div class="ChatAvatar">
    <img
      v-if="message.sender.avatar && !state.hasError"
      :src="message.sender.avatar"
      alt="user's avatar"
      @error="handleError"
    />
    <div v-else class="placeholder-avatar">
      <p class="user-initials">{{ userInitials }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.ChatAvatar {
  flex: 0 0 32px;
  img {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    object-fit: cover;
  }
  .placeholder-avatar {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    background: variables.$periwink-blue-20;
    .user-initials {
      color: variables.$periwink-blue-50;
      line-height: 32px;
      text-align: center;
      font-weight: 800;
    }
  }
}
</style>
