<script setup>
import { computed, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useDigitalAssetDerivatives } from '@/queries/digital_assets/useDigitalAssetDerivatives';
import { useGetEditHistory } from '@/queries/useGetEditHistory';
import AssetAddOns from '@/components/dam/asset_comparison/AssetAddOns.vue';
import AssetComparison from '@/components/dam/asset_comparison/AssetComparison.vue';
import OverlayDownloadAsset from '@/components/account_gallery/OverlayDownloadAsset.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';

const props = defineProps({
  assetId: {
    required: true,
    type: [Number, String],
  },
  orderId: {
    default: null,
    type: [Number, String],
  },
  reEditId: {
    default: null,
    type: [Number, String],
  },
  reservationId: {
    required: true,
    type: [Number, String],
  },
});

const router = useRouter();

const { assetId, orderId, reservationId } = toRefs(props);
const editHistoryId = computed(() => orderId.value ?? props.reEditId);
const orderType = computed(() => (orderId.value ? 'order' : 're_edit'));

const {
  data: order,
  error: ordersError,
  isLoading: isOrderLoading,
} = useGetEditHistory(reservationId, editHistoryId, orderType);

const {
  data: digitalAsset,
  error: digitalAssetDerivativesError,
  isLoading: isDigitalAssetDerivativesLoading,
} = useDigitalAssetDerivatives(computed(() => props.assetId));

const derivatives = computed(() => digitalAsset.value?.derivatives || []);
const orderAssetIds = computed(
  () => order.value?.digital_assets.map(a => a.id) || []
);

const priorityErrors = usePriorityErrors(
  digitalAssetDerivativesError,
  ordersError
);

const isLoading = computed(
  () => isDigitalAssetDerivativesLoading.value || isOrderLoading.value
);

const nextRoute = computed(() => {
  if (
    !orderAssetIds.value?.length ||
    orderAssetIds.value?.indexOf(+assetId.value) + 1 ===
      orderAssetIds.value?.length
  ) {
    return null;
  }

  const nextAssetId =
    orderAssetIds.value[orderAssetIds.value.indexOf(+assetId.value) + 1];

  return {
    name: orderId.value ? 'order-asset-compare' : 're-edit-asset-compare',
    params: {
      assetId: nextAssetId,
      orderId: editHistoryId.value,
      reservationId: reservationId.value,
    },
  };
});

const previousRoute = computed(() => {
  if (
    !orderAssetIds.value?.length ||
    orderAssetIds.value?.indexOf(+assetId.value) === 0
  )
    return null;

  const previousAssetId =
    orderAssetIds.value[orderAssetIds.value.indexOf(+assetId.value) - 1];

  return {
    name: orderId.value ? 'order-asset-compare' : 're-edit-asset-compare',
    params: {
      assetId: previousAssetId,
      orderId: editHistoryId.value,
      reservationId: reservationId.value,
    },
  };
});

const goBack = () => {
  router.push({
    name: orderId.value ? 'order-editing-summary' : 're-edit-editing-summary',
    params: {
      orderId: editHistoryId.value,
      reservationId: reservationId.value,
    },
  });
};
</script>

<template>
  <SoonaLoading v-if="isLoading" is-loading />
  <AssetComparison
    v-else
    back-btn-copy="back to order"
    :comparison-assets="derivatives"
    :errors="priorityErrors"
    :next-route="nextRoute"
    :original-asset="digitalAsset"
    :previous-route="previousRoute"
    @back="goBack"
  >
    <template #left-actions="{ selectedAsset }">
      <OverlayDownloadAsset
        :file-url="selectedAsset?.file?.url"
        :raw-url="selectedAsset?.raw?.url"
        :web-url="selectedAsset?.web?.url"
      />
    </template>
    <template #left-details>
      <AssetAddOns
        :digital-asset-id="assetId"
        :order-id="orderId"
        :reservation-id="reservationId"
      />
    </template>
    <template #right-actions="{ selectedAsset }">
      <OverlayDownloadAsset
        :file-url="selectedAsset?.file?.url"
        :raw-url="selectedAsset?.raw?.url"
        :web-url="selectedAsset?.web?.url"
      />
    </template>
  </AssetComparison>
</template>
