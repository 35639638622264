<script setup>
import { computed, onMounted, ref } from 'vue';
import { refDebounced, useElementBounding, useTitle } from '@vueuse/core';
import { useAccount } from '@/queries/account/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { useMe } from '@/composables/user/useMe';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useRoute } from 'vue-router';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import DigitalAssetCard from '@/components/account_gallery/DigitalAssetCard.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import MultiSelectActionBar from '@/components/account_gallery/MultiSelectActionBar.vue';
import SearchBar from '@/components/account_gallery/SearchBar.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SortBy from '@/components/account_gallery/SortBy.vue';
import UploadMediaDialog from '@/components/account_gallery/UploadMediaDialog.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  showOnlyCustomerOrigin: {
    default: false,
    type: Boolean,
  },
});

const { linkClicked, pageViewed } = useBaseEvents();
const route = useRoute();

const trackClick = (label, href = null) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'header',
    linkLabel: label,
    linkHref: href,
  });
};

const { currentAccountId } = useMe();
const accountId = computed(() => +props.accountId);
const { data: account } = useAccount(accountId);
const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const wrapper = ref(null);

const itemsPerPage = ref(25);
const showUploadDialog = ref(false);
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 200);
const selectedSort = ref('date modified');
const sortDirection = ref('desc');
const startPage = ref(1);

const { left, width } = useElementBounding(wrapper);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const filters = computed(() => ({
  origin: props.showOnlyCustomerOrigin ? ['customer'] : ['customer', 'soona'],
  ownership: 'customer',
  visibility: 'all',
}));

const { assetRows, fetchPage, pagination, error, rawData } =
  useInfiniteDigitalAssets(accountId, {
    rowWidth,
    itemsPerPage,
    height: rowHeight,
    gutter,
    filters,
    searchQuery: searchDebounced,
    sortBy: selectedSort,
    sortDirection: sortDirection,
  });

const priorityErrors = usePriorityErrors(error);

const heading = computed(() => {
  if (props.showOnlyCustomerOrigin && isMyAccount.value) return 'my uploads';
  if (props.showOnlyCustomerOrigin && account.value)
    return `${account.value.name}’s uploads`;
  if (isMyAccount.value) return 'my assets';
  if (account.value) return `${account.value.name}’s assets`;
  return 'assets';
});

useTitle(heading, { titleTemplate: '%s | soona' });

const handleOpenUploadDialog = () => {
  trackClick('add media');
  showUploadDialog.value = true;
};

const handleCloseUploadDialog = () => {
  trackClick('cancel');
  showUploadDialog.value = false;
};

const changeSortDirection = () => {
  if (sortDirection.value === 'desc') sortDirection.value = 'asc';
  else sortDirection.value = 'desc';
};

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, da => da);

onMounted(() => {
  pageViewed();
});
</script>

<template>
  <div class="all-assets">
    <AccountInfoAuto :account-id="accountId" />
    <AccountGalleryBreadcrumbs />
    <div class="all-assets__heading">
      <h1 class="u-title--heavy">{{ heading }}</h1>
      <SoonaButton variation="solid-black" @on-click="handleOpenUploadDialog">
        <SoonaIcon name="plus-large" /> add media
      </SoonaButton>
    </div>
    <SearchBar v-model:search-text="searchText" input-name="my-assets-search" />
    <div class="all-assets__sort-wrapper">
      <p class="all-assets__items">
        {{ pagination.totalCount.toLocaleString() }} items
      </p>
      <SortBy
        v-model:selected-sort="selectedSort"
        :sort-direction="sortDirection"
        @change-sort-direction="changeSortDirection"
      />
    </div>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <section v-else ref="wrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :start-page="startPage"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <DigitalAssetCard
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :selected="isAssetSelected(asset)"
          :to="{
            name: showOnlyCustomerOrigin
              ? 'uploads-assets-media-view'
              : 'all-assets-media-view',
            params: {
              accountId: asset.account_id,
              digitalAssetId: asset.id,
            },
          }"
          @request-page="pageNumber => fetchPage(pageNumber)"
          @selection-click="onSelectionClick(asset, $event)"
        />
      </InfiniteGallery>
      <MultiSelectActionBar
        :account-id="accountId"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        :selected-assets="selectedAssets"
        @close="resetSelection"
      />
    </section>
    <UploadMediaDialog
      v-if="showUploadDialog"
      :account-id="accountId"
      @close="handleCloseUploadDialog"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.all-assets {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__heading {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  &__sort-wrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;
  }

  &__items {
    color: variables.$gray-60;
  }
}
</style>
