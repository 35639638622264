import { toValue } from 'vue';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

export function useReservationPaidOrderSummary(reservationId) {
  return useQuery({
    queryKey: queryKeys.reservationPaidOrderSummary(reservationId),
    queryFn: async ({ signal }) => {
      const response = await http.get(
        `reservations/${toValue(reservationId)}/paid_add_on_order_summary.json`,
        {
          signal,
        }
      );
      return response.data;
    },
  });
}
