import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

export function useSiteNavEvents() {
  const route = useRoute();
  const { linkClicked, buttonClicked } = useBaseEvents();
  const subContext = 'site sidebar navigation';

  function trackButtonClicked(linkLabel, linkHref) {
    if (route.meta.context) {
      buttonClicked({
        context: route.meta.context,
        subContext,
        linkLabel: linkLabel,
        linkHref: linkHref,
      });
    }
  }

  function trackLinkClicked(linkLabel, linkHref) {
    if (route.meta.context) {
      linkClicked({
        context: route.meta.context,
        subContext,
        linkLabel: linkLabel,
        linkHref: linkHref,
      });
    }
  }

  return { trackButtonClicked, trackLinkClicked, baseLinkClicked: linkClicked };
}
