<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import DuplicateReservationDialog from '@/components/reservation/DuplicateReservationDialog.vue';
import { ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';

const props = defineProps({
  reservationId: {
    type: [String, Number],
    required: true,
  },
  buttonsSize: {
    type: String,
    default: 'large',
  },
});

const route = useRoute();
const { linkClicked } = useBaseEvents();

const duplicateBookingDialogShown = ref(false);

function duplicateBooking(e) {
  linkClicked({
    context: route.meta?.context ?? 'unknown',
    subContext: `reservation ${props.reservationId} card`,
    linkLabel: e.currentTarget.textContent,
    linkHref: null,
  });
  duplicateBookingDialogShown.value = true;
}
</script>
<template>
  <div class="booking-actions__container">
    <div class="booking-actions__duplicate-container">
      <SoonaTooltip placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="setRef"
            :aria-describedby="ariaDescribedby"
            :size="buttonsSize"
            class="booking-actions__duplicate"
            variation="icon-gray-outline"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @on-click="duplicateBooking"
          >
            <SoonaIcon name="clone" />
            <span class="u-visually-hidden">duplicate shoot</span>
          </SoonaButton>
        </template>
        <template #tooltip-content>duplicate shoot</template>
      </SoonaTooltip>
      <DuplicateReservationDialog
        v-if="duplicateBookingDialogShown"
        :reservation-id="reservationId"
        @close="duplicateBookingDialogShown = false"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.booking-actions {
  &__container {
    position: absolute;
    display: block;
    right: 0.75rem;
    top: 0.75rem;
    z-index: 1;
  }
  &__duplicate {
    position: relative;
  }
}
</style>
