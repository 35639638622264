import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '../config/vue-axios';

export function useListingActionsAutoProcessBulkPublish(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async params => {
      await http.post(
        `/accounts/${accountId.value}/auto_process_bulk_publish`,
        params
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.listingActions(accountId),
      });
    },
  });
}
