<script setup>
import { computed, ref } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaReservationFilePreview from '@/components/ui_library/preview/SoonaReservationFilePreview.vue';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  compareLink: {
    default: null,
    type: Object,
  },
  contentType: {
    type: String,
    required: true,
  },
  watermarkUrl: {
    required: true,
    type: String,
  },
  imageUrl: {
    required: true,
    type: String,
  },
  isVideo: {
    required: true,
    type: Boolean,
  },
  isGif: {
    default: false,
    type: Boolean,
  },
  title: {
    type: String,
    required: true,
  },
});

const contentType = computed(() => props.contentType);
const watermarkUrl = computed(() => props.watermarkUrl);
const imageUrl = computed(() => props.imageUrl);
const isVideo = computed(() => props.isVideo);
const isGif = computed(() => props.isGif);
const title = computed(() => props.title);
const isModalOpen = ref(false);

const assetCompareFlag = useFlag('toaster_asset_compare');
</script>

<template>
  <div class="asset-image">
    <div class="u-label--regular asset-image__type">
      <template v-if="isVideo">
        <SoonaIcon name="video" size="small" /> video
      </template>
      <template v-else-if="isGif">
        <SoonaIcon name="gif-file" size="small" /> gif
      </template>
      <template v-else>
        <SoonaIcon name="camera" size="small" /> photo
      </template>
    </div>
    <div v-if="compareLink" class="asset-image__compare">
      <img class="asset-image__thumbnail" :src="imageUrl" />
      <SoonaButton
        v-if="assetCompareFlag"
        element="router-link"
        size="small"
        :to="compareLink"
        variation="secondary-gray"
      >
        compare versions
      </SoonaButton>
    </div>
    <button
      v-else
      type="button"
      class="asset-image__larger-btn u-button-reset"
      @click="() => (isModalOpen = true)"
    >
      <img class="asset-image__thumbnail" :src="imageUrl" />
      view larger
    </button>
    <SoonaReservationFilePreview
      v-if="isModalOpen"
      :title="title"
      :is-gif="isGif"
      :is-video="isVideo"
      :watermark-url="watermarkUrl"
      :image-url="imageUrl"
      :content-type="contentType"
      @close="() => (isModalOpen = false)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.asset-image {
  grid-area: 2 / 1 / 2 / 1;
  width: 6.25rem;
  padding-left: 0;
  border-radius: 0;

  &__type {
    display: flex;
    align-items: center;
    gap: 0.125rem;
    color: variables.$gray-80;
    margin-bottom: 0.5rem;
  }

  &__thumbnail {
    border-radius: 0.3125rem;
    max-height: 100%;
    max-width: 100%;
  }

  &__larger-btn {
    @include variables_fonts.u-label--small;

    display: flex;
    text-align: left;
    text-decoration: underline;
    transition: 0.2s;
    flex-direction: column;
    gap: 4px;

    &:hover,
    &:focus-visible {
      color: variables.$periwink-blue-70;
    }

    &:active {
      color: variables.$periwink-blue-80;
      text-decoration: none;
    }
  }

  &__compare {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  @media (min-width: variables.$screen-md-min) {
    grid-area: 2 / 1 / 4 / 1;
    width: 10.25rem;
  }
}
</style>
