<script setup>
import { inject } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  ariaLabel: {
    type: String,
    default: 'search and filter',
  },
});

const { toggleCombobox, ariaActivedescendantID, listboxId, open } =
  inject('comboboxContext');
</script>

<template>
  <SoonaButton
    tabindex="0"
    role="combobox"
    class="combo-input"
    aria-haspopup="listbox"
    :aria-expanded="open"
    :aria-label="ariaLabel"
    :aria-controls="listboxId"
    :aria-activedescendant="ariaActivedescendantID"
    @click="toggleCombobox"
  >
    <slot />
  </SoonaButton>
</template>
/>
