<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';

const props = defineProps({
  accountList: {
    required: true,
    type: Array,
  },
  selectedAccountId: {
    default: undefined,
    required: false,
    type: Number,
  },
  paddingBottom: {
    default: '50px',
    required: false,
    type: String,
  },
});

const emits = defineEmits(['on-click']);

const selectedAccountId = computed(() => props.selectedAccountId);
</script>

<template>
  <div class="account-picker" data-cypress="account-picker">
    <h2 class="u-headline--heavy account-picker__title">
      pick the account you wish to use
    </h2>
    <div class="account-picker__option-container">
      <button
        v-for="account in accountList"
        :key="account.id"
        class="account-picker__option"
        :aria-pressed="selectedAccountId === account.id"
        @click.prevent="() => emits('on-click', account)"
      >
        <img
          v-if="account.owner_avatar_url || account.avatar"
          class="account-picker__avatar"
          :src="
            account.owner_avatar_url
              ? `${account.owner_avatar_url}`
              : account.avatar
                ? `${account.avatar}`
                : null
          "
          :alt="account.name.charAt(0).toLowerCase()"
        />
        <span v-else class="u-subheader--heavy account-picker__avatar">
          {{ account.name.charAt(0).toLowerCase() }}
        </span>
        <span
          :class="
            selectedAccountId === account.id
              ? 'u-body--heavy'
              : 'u-body--regular'
          "
        >
          {{ account.name }}
        </span>
        <SoonaIcon
          v-if="selectedAccountId === account.id"
          class="account-picker__checkmark"
          name="circle-check-solid"
          data-cypress="account-checkmark"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.account-picker {
  padding-bottom: 2rem;

  &__title {
    color: variables.$black-default;
    padding-bottom: v-bind(paddingBottom);
  }

  &__option-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-height: 37.5rem;
    overflow-y: auto;
  }

  &__option {
    align-items: center;
    background: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    cursor: pointer;
    display: flex;
    gap: 1.25rem;
    outline-offset: -0.125rem;
    padding: 0.75rem 1rem;
    transition: 0.2s;

    &:hover {
      background-color: variables.$periwink-blue-10;
    }

    &[aria-pressed='true'] {
      background-color: variables.$periwink-blue-10;
      border-color: variables.$black-default;
    }
  }

  &__avatar {
    align-items: center;
    background-color: variables.$periwink-blue-20;
    border-radius: 50%;
    color: variables.$periwink-blue-40;
    display: flex;
    height: 3rem;
    justify-content: center;
    object-fit: cover;
    width: 3rem;
  }

  &__checkmark {
    color: variables.$avo-toast-40;
    margin-left: auto;
  }
}
</style>
