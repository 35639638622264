<script setup>
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import winkImage from '@images/wink.gif';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  album: {
    required: true,
    type: Object,
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const trackClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'album grid',
    linkLabel: props.album.title,
    linkHref: `/account/${props.accountId}/my-albums/${props.album.id}`,
  });
};
</script>

<template>
  <RouterLink
    class="album-card"
    :to="
      props.album.routerTo
        ? props.album.routerTo
        : `/account/${accountId}/gallery/albums/${album.id}`
    "
    @click="trackClick"
  >
    <img class="album-card__img" :src="album.preview.url || winkImage" alt="" />
    <div class="album-card__content">
      <h2 class="u-subheader--heavy album-card__title">{{ album.title }}</h2>
      <div class="album-card__assets-wrapper">
        <p>
          <SoonaIcon name="image-square" size="small" />
          {{ album.total_assets }}
        </p>
      </div>
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
@use '@/variables';

.album-card {
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.1s;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__img {
    aspect-ratio: 3 / 2;
    flex: 2;
    object-fit: cover;
    width: 100%;
  }

  &__content {
    padding: 1.5rem;
  }

  &__assets-wrapper {
    align-items: center;
    color: variables.$gray-60;
    display: flex;
    gap: 0.5rem;

    p {
      align-items: center;
      display: flex;
      gap: 0.25rem;
    }
  }
}
</style>
