<script setup>
import { useStore } from 'vuex';
import { computed, onMounted, ref, toRef, watchEffect } from 'vue';

import { useFlag } from '@/composables/useFlag';
import { useAccount } from '@/composables/useAccount';
import { useReservation } from '@/composables/useReservation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useIntegrations } from '@/composables/useIntegrations';
import { usePriorityError } from '@/composables/usePriorityError';
import { useLastSupportedLocation } from '@/queries/useLastSupportedLocation';
import { useUpdateReservationLocation } from '@/queries/useUpdateReservation';
import { useUpdateReservationToAnytime } from '@/queries/useUpdateReservation';
import { useUpdateStudioAccess } from '@/queries/reservations/useUpdateStudioAccess';
import { useGetSuggestedReservationLocation } from '@/queries/useGetSuggestedReservationLocation';

import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaFaq from '@/components/ui_library/SoonaFaq.vue';
import SoonaHeading from '@/components/ui_library/SoonaHeading.vue';
import SoonaExplainer from '@/components/ui_library/SoonaExplainer.vue';

import faqData from './faq.json';
import explainerData from './explainer.json';
import BookingLayout from '../BookingLayout.vue';
import LocationSelectionModal from './LocationSelectionModal.vue';
import StudioFeeSelection from './StudioFeeSelection.vue';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onNext: Function,
  onBack: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});
const { buttonClicked } = useBaseEvents();
const store = useStore();

let showModal = ref(false);
let userSelectedLocation = ref(false);

// api calls
const {
  isAnytime,
  isInStudio,
  reservation,
  currentLocationId,
  isHeadshotsForAll,
  hasUnassignedLocation,
  isSuccess: isReservationSuccess,
  isPack,
} = useReservation(toRef(props, 'reservationId'));

const isAuthenticated = computed(() => !!store.state.currentUser.userID);
const specificExplainerData = computed(() => {
  return isAnytime.value ? explainerData.virtual : explainerData.inStudio;
});

const weCannotWaitCopy = computed(() => {
  const shoot = isAnytime.value
    ? 'virtual shoot'
    : `in-studio shoot in ${reservation.value?.location?.display_name}`;
  return `we can't wait for your ${shoot}!`;
});

const { mutate: updateStudioAccess, isPending: isUpdatingStudioAccess } =
  useUpdateStudioAccess(toRef(props, 'reservationId'));

// last supported location
const {
  data: lastSupportedLocation,
  isSuccess: isLastSupportedLocationSuccess,
} = useLastSupportedLocation(toRef(props, 'reservationId'));

const {
  mutate: updateResLocation,
  isPending: isUpdatingLocation,
  error: updateLocationError,
} = useUpdateReservationLocation(toRef(props, 'reservationId'));

const {
  mutate: updateToAnytime,
  isPending: isUpdatingToAnytime,
  error: updateToAnytimeError,
} = useUpdateReservationToAnytime(toRef(props, 'reservationId'));

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
const { account, isDigitalSubscriber } = useAccount(
  reservation.value?.account_id
);

const enableDefaultTierTwoFlag = useFlag('pegasus_enable_default_tier_two');

const isPreferred = computed(
  () => reservation.value?.account?.preferred?.is_preferred ?? false
);

const enforceMembershipSelection = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return (
      !isDigitalSubscriber.value &&
      !isPreferred.value &&
      !isHeadshotsForAll.value
    );
  }

  return false;
});

let membershipLevel = ref(null);
let membershipBillingCycle = ref(null);

const existingBillingCycle = computed(() => {
  return account.value?.current_subscription_recurring_interval;
});

const handleUpdatingStudioAccess = () => {
  updateStudioAccess({
    level: membershipLevel.value,
    billingCycle:
      membershipLevel.value === 'day-pass'
        ? null
        : membershipBillingCycle.value,
  });
};

const handleUpdatingMembershipBillingCycle = () => {
  if (membershipLevel.value === 'day-pass') {
    membershipLevel.value = 'tier-one';
  }

  handleUpdatingStudioAccess();
};

const handleUpdatingMembershipLevel = level => {
  if (
    (level === 'tier-one' || level === 'tier-two') &&
    !membershipBillingCycle.value
  ) {
    membershipBillingCycle.value = existingBillingCycle.value
      ? existingBillingCycle.value
      : 'year';
  }

  handleUpdatingStudioAccess();
};

const determinedWhetherToAutoAssignInStudio = ref(false);
const hasLoaded = computed(() => {
  return (
    isReservationSuccess.value &&
    isLastSupportedLocationSuccess.value &&
    determinedWhetherToAutoAssignInStudio.value
  );
});
const shouldAutoAssignToStudio = computed(() => {
  return !!(
    isAnytime.value &&
    hasUnassignedLocation.value &&
    lastSupportedLocation.value?.reservation_type === 'in_studio' &&
    !lastSupportedLocation.value?.incompatible
  );
});
const previousInStudioIsIncompatible = computed(() => {
  return (
    lastSupportedLocation.value?.reservation_type === 'in_studio' &&
    lastSupportedLocation.value?.incompatible
  );
});
const previousInStudioMatchesCurrentInStudio = computed(() => {
  return (
    !userSelectedLocation.value &&
    isInStudio.value &&
    lastSupportedLocation.value?.location &&
    currentLocationId.value === lastSupportedLocation.value?.location.id &&
    lastSupportedLocation.value?.reservation_type === 'in_studio'
  );
});
const previousBookingWasVirtual = computed(() => {
  return (
    !userSelectedLocation.value &&
    lastSupportedLocation.value?.reservation_type === 'anytime'
  );
});
const virtualIsBest = computed(() => {
  return (
    (!userSelectedLocation.value && !isAuthenticated.value) ||
    (isAuthenticated.value && !lastSupportedLocation.value)
  );
});

// suggested location
const { data: suggestedLocation, isLoading: isLoadingSuggestedLocation } =
  useGetSuggestedReservationLocation(toRef(props, 'reservationId'));

watchEffect(() => {
  if (shouldAutoAssignToStudio.value && !userSelectedLocation.value) {
    updateResLocation(
      {
        locationId: lastSupportedLocation.value.location.id,
        reservationType: 'in_studio',
      },
      {
        onSuccess: () => {
          determinedWhetherToAutoAssignInStudio.value = true;
        },
      }
    );
  } else {
    determinedWhetherToAutoAssignInStudio.value = true;
  }
});

const isBusy = computed(() => {
  return (
    isUpdatingLocation.value ||
    isUpdatingToAnytime.value ||
    isLoadingSuggestedLocation.value ||
    isUpdatingStudioAccess.value
  );
});

const isStepValid = computed(() => {
  let studioAccessSelectionRequired = false;
  if (enforceMembershipSelection.value) {
    studioAccessSelectionRequired = !reservation.value.studio_access['level'];
  }

  return !isBusy.value && !studioAccessSelectionRequired;
});

const { hasShopifyIntegration } = useIntegrations(
  reservation.value?.account_id
);

const onBackClick = () => {
  props.onBack();
};

const showSubscriptionDialog = ref(false);

const handleOpenSubscriptionDialog = () => {
  showSubscriptionDialog.value = true;
};

const handleCloseSubscriptionDialog = () => {
  showSubscriptionDialog.value = false;
};

const onNextClick = () => {
  const openSubscriptionDialog =
    enforceMembershipSelection.value &&
    hasShopifyIntegration.value &&
    membershipLevel.value !== 'day-pass';

  if (isAnytime.value && hasUnassignedLocation.value) {
    updateResLocation(
      {
        locationId: suggestedLocation.value.id,
      },
      {
        onSuccess: () => {
          if (openSubscriptionDialog) {
            handleOpenSubscriptionDialog();
          } else {
            props.onNext();
          }
        },
      }
    );
  } else if (openSubscriptionDialog) {
    handleOpenSubscriptionDialog();
  } else {
    props.onNext();
  }
};

// switch reservation type
const switchToVirtual = () => {
  updateToAnytime(
    {},
    {
      onSuccess: () => {
        userSelectedLocation.value = true;
        buttonClicked({
          context: 'booking',
          subContext: 'location',
          buttonLabel: 'switch to a virtual shoot',
          buttonAction: 'switch to virtual',
        });
      },
    }
  );
};

const handleSwitchToInStudio = () => {
  showModal.value = true;
  buttonClicked({
    context: 'booking',
    subContext: 'location',
    buttonLabel: 'switch to an in-studio shoot',
    buttonAction: 'open location selection modal',
  });
};

// modal
const modalConfirmClicked = () => {
  userSelectedLocation.value = true;
  showModal.value = false;
};

// errors
const priorityError = usePriorityError(
  updateToAnytimeError,
  updateLocationError
);

const proServiceTags = computed(() => {
  return reservation.value.reservation_tags.filter(
    tag =>
      (tag.tag_category_slug?.includes('pro-service') ||
        tag.tag_category_slug?.includes('upgrades')) &&
      tag.tag_type !== 'skippable'
  );
});

watchEffect(() => {
  if (reservation.value.studio_access) {
    membershipLevel.value = reservation.value.studio_access.level;
    membershipBillingCycle.value =
      reservation.value.studio_access.billing_cycle ?? 'year';
  }
});

onMounted(() => {
  if (enforceMembershipSelection.value) {
    if (Object.keys(reservation.value.studio_access).length === 0) {
      if (
        enableDefaultTierTwoFlag.value &&
        !isPack.value &&
        proServiceTags.value.length > 0
      ) {
        membershipLevel.value = 'tier-two';
        membershipBillingCycle.value = existingBillingCycle.value ?? 'month';
      } else {
        membershipLevel.value = 'tier-one';
        membershipBillingCycle.value = existingBillingCycle.value ?? 'year';
      }
      handleUpdatingStudioAccess();
    } else {
      membershipLevel.value = reservation.value.studio_access.level;
      membershipBillingCycle.value =
        reservation.value.studio_access.billing_cycle ?? 'year';
    }
  }
});
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :next-button-copy="nextButtonCopy"
      :back-button-copy="backButtonCopy"
      :on-next-click="onNextClick"
      :on-back-click="onBackClick"
      :is-loading="isBusy"
      :is-step-valid="isStepValid"
      :reservation-id="reservationId"
    >
      <template v-if="priorityError" #priority-error>
        {{ priorityError }}
      </template>
      <div
        v-if="hasLoaded"
        class="booking-location prebooking-transition__content"
      >
        <SoonaLoading
          v-if="!reservation"
          :is-loading="!reservation"
          :is-dark="false"
          loading-text="loading"
        />
        <!-- virtual -->
        <div v-if="isAnytime" class="booking-location__content">
          <p v-if="previousInStudioIsIncompatible">
            some of your scene selections aren’t available for an in-studio
            shoot in {{ lastSupportedLocation.location.display_name }}
          </p>
          <p v-else-if="virtualIsBest">
            a virtual shoot can accommodate your selections best
          </p>
          <p v-else-if="previousBookingWasVirtual">
            your last shoot was virtual
          </p>
          <SoonaHeading v-if="previousInStudioIsIncompatible">
            let’s have a virtual shoot instead!
            <template #subtitle>
              all of your selected scenes are possible with a virtual shoot
            </template>
          </SoonaHeading>
          <SoonaHeading v-else>
            {{ weCannotWaitCopy }}
          </SoonaHeading>
          <template v-if="!previousInStudioIsIncompatible">
            <p class="booking-location__toggle-text">
              want to attend in person?
            </p>
            <button
              class="booking-location__toggle-btn"
              data-cypress="button-toggle"
              @click="handleSwitchToInStudio"
            >
              switch to an in-studio shoot
            </button>
          </template>
        </div>
        <!-- in studio -->
        <div v-else class="booking-location__content">
          <p v-if="previousInStudioMatchesCurrentInStudio">
            you had an in-studio shoot in
            {{ lastSupportedLocation.location.display_name }} last time. let’s
            do it again!
          </p>
          <SoonaHeading>
            {{ weCannotWaitCopy }}
          </SoonaHeading>
          <p class="booking-location__toggle-text">
            want to have a virtual shoot instead?
          </p>
          <button
            class="booking-location__toggle-btn"
            data-cypress="button-toggle"
            @click="switchToVirtual"
          >
            switch to a virtual shoot
          </button>
        </div>
        <!-- common -->
        <LocationSelectionModal
          v-if="showModal"
          :reservation-id="reservationId"
          :show-modal="showModal"
          @confirm="() => modalConfirmClicked()"
          @cancel="showModal = false"
        />

        <StudioFeeSelection
          v-if="enforceMembershipSelection"
          v-model:membership-level="membershipLevel"
          v-model:membership-billing-cycle="membershipBillingCycle"
          :is-disabled="isBusy"
          :is-loading="isUpdatingStudioAccess"
          :account-id="reservation?.account_id"
          @update:membership-level="handleUpdatingMembershipLevel"
          @update:membership-billing-cycle="
            handleUpdatingMembershipBillingCycle
          "
        />

        <div v-else>
          <SoonaExplainer :content="specificExplainerData" />
          <SoonaFaq v-if="isAnytime" :faq-tab-list="faqData" />
        </div>
        <SubscriptionsDialog
          v-if="showSubscriptionDialog"
          :price-interval="membershipBillingCycle"
          flow="checkoutOnly"
          :reservation-id="reservationId"
          :selected-tier-slug="membershipLevel"
          @close="handleCloseSubscriptionDialog"
        />
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.booking-location {
  color: variables.$black-default;

  &__content {
    text-align: center;
    padding-bottom: 2rem;

    @media (min-width: variables.$screen-md-min) {
      padding-bottom: 2.5rem;
    }
  }

  &__last-location {
    @include variables_fonts.u-subheader--regular;
  }

  &__toggle-text {
    @include variables_fonts.u-body--heavy;

    @media (min-width: variables.$screen-md-min) {
      @include variables_fonts.u-subheader--heavy;
    }
  }

  &__toggle-btn {
    @include variables_fonts.u-body--regular;

    background: transparent;
    border: none;
    text-decoration: underline;
  }
}
</style>
