<script setup>
import {
  computed,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from 'vue';
import { useStore } from 'vuex';
import { useReservation } from '@/composables/useReservation';
import TitleBar from '@/components/shared/TitleBar.vue';
import AddOnForm from '@/components/user/anytime/self_serve_purchases/AddOnForm.vue';
import AdditionalOrderSidecart from '@/components/user/anytime/billing_and_orders/AdditionalOrderSidecart.vue';
import SelfServeCheckout from '@/components/user/anytime/self_serve_purchases/SelfServeCheckout.vue';
import { onKeyStroke, useMediaQuery, useTimeoutFn } from '@vueuse/core';
import { useRouter, useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import { useTitle } from '@vueuse/core';
import UpgradeCard from '@/components/user/anytime/UpgradeCard.vue';
import { useGetBestAutoApplyCoupon } from '@/queries/reservations/useGetBestAutoApplyCoupon';
import { useIntegrations } from '@/composables/useIntegrations';
import { useFlag } from '@/composables/useFlag';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import { storeToRefs } from 'pinia';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';

const props = defineProps({
  reservationId: {
    type: String,
    default: null,
    required: true,
  },
});

const { pageViewed } = useBaseEvents();

const store = useStore();
const salesTaxStore = useSalesTaxStore();
const router = useRouter();
const route = useRoute();
const queryClient = useQueryClient();
useTitle(route.meta.page_title);

const reservationId = computed(() => props.reservationId);

const { reservation, reservationAccountId, estimatedPhotosNeeded } =
  useReservation(reservationId);

const currentAccountId = computed(() => store.state.currentUser?.accountId);

const {
  salesTaxBillingAddressId,
  stripeSalesTaxCalculationId,
  taxAmountExclusiveInCents,
} = storeToRefs(salesTaxStore);

const { hasShopifyIntegration } = useIntegrations(currentAccountId);

const checkingOut = ref(false);

const isOrderProcessing = ref(false);

const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');

const previousPage = () => {
  router.back();
};

const products = ref([]);

const { data: bestAutoApplyDiscount } = useGetBestAutoApplyCoupon(
  reservationId,
  { products, orderType: 'additional_charge' }
);

async function loadReservation() {
  await store.dispatch('reservation/loadReservation', reservationId.value);
}

const clientUpgradable = computed(() => {
  return reservation.value?.scheduled_reservation_date === null;
});

onMounted(async () => {
  pageViewed();
  await loadReservation();
});

onBeforeUnmount(() => {
  store.dispatch('reservation/clearAdditionalChargeItems');
});

onUnmounted(() => {
  salesTaxStore.$reset();
});

const handleProductPriceInfoUpdate = newPriceInfosObj => {
  for (let i = 0; i < products.value.length; i++) {
    if (!newPriceInfosObj[products.value[i].id]) return;
    products.value[i].price_info = newPriceInfosObj[products.value[i].id];
  }
};

const handleSelect = event => {
  const product = event.product;
  const quantity = event.quantity;

  const p = products.value.find(p => p.id === product.id);
  if (p) {
    p.quantity = quantity;
  } else {
    products.value.push({ ...product, quantity: quantity });
  }
};

const handleDeselect = product => {
  products.value = products.value.filter(p => p.id !== product.id);
};

function handleSetProductCount(product, newVal) {
  if (newVal < 1) {
    handleDeselect(product);
  } else {
    handleSelect({
      product,
      quantity: newVal,
    });
  }
}

function handleIncrementProduct(product) {
  const p = products.value.find(p => p.id === product.id);
  if (p) {
    p.quantity++;
  }
}

function handleDecrementProduct(product) {
  const p = products.value.find(p => p.id === product.id);
  if (p) {
    p.quantity--;
  }
}

async function placeOrder(stripe, value, saveCard, options = {}) {
  const { externalDiscountId = null } = options;

  try {
    isOrderProcessing.value = true;

    products.value.forEach(p => {
      store.dispatch('reservation/setAdditionalChargeItems', {
        [p.id]: p.quantity,
      });
    });

    if (!hasShopifyIntegration.value) {
      await store.dispatch('reservation/setAdditionalChargeImmediately', true);
    }

    if (externalDiscountId !== null) {
      await store.dispatch(
        'reservation/setAdditionalChargeCoupon',
        externalDiscountId
      );
    }

    if (!hasShopifyIntegration.value && paymentsPaymentMethodsFlag) {
      await store.dispatch(
        'reservation/createPaymentMethodAdditionalChargeOrder',
        {
          isShopifyOrder: hasShopifyIntegration.value,
          paymentMethodId: stripe,
          paymentMethodType: value,
          savePaymentMethod: saveCard,
          salesTax: {
            soona_billing_address_id: salesTaxBillingAddressId.value,
            id: stripeSalesTaxCalculationId.value,
            tax_amount_exclusive: taxAmountExclusiveInCents.value,
          },
        }
      );
    } else if (!hasShopifyIntegration.value) {
      await store.dispatch('reservation/createAdditionalChargeOrder', {
        isShopifyOrder: hasShopifyIntegration.value,
        stripe,
        card: value,
        saveCard,
      });
    } else {
      await store.dispatch('reservation/createAdditionalChargeOrder', {
        isShopifyOrder: hasShopifyIntegration.value,
        salesTax: {
          soona_billing_address_id: salesTaxBillingAddressId.value,
          id: stripeSalesTaxCalculationId.value,
          tax_amount_exclusive: taxAmountExclusiveInCents.value,
        },
      });
    }

    await queryClient.invalidateQueries({
      queryKey: queryKeys.accountCreditInfo(reservation.value?.account_id),
    });
    await store.dispatch('reservation/clearAdditionalChargeItems');
    await router.push(`/reservation/${props.reservationId}/pro-service-traits`);
  } finally {
    isOrderProcessing.value = false;
  }
}

const titleBarTitle = computed(() => {
  if (checkingOut.value) {
    return `checkout (${products.value?.length} item)`;
  } else {
    const title = 'upgrade your shoot';
    return reservation.value?.name
      ? `${title}: ${reservation.value.name}`
      : title;
  }
});

/* sidecart */
const sidecartIsAffixed = useMediaQuery('(max-width: 59.9375rem)');
const sidecartOpen = ref(false);
const sidecartClosing = ref(false);
const { start: closeSidecart, stop: stopCloseSidecart } = useTimeoutFn(
  () => {
    sidecartOpen.value = !sidecartOpen.value;
    sidecartClosing.value = false;
  },
  300,
  { immediate: false }
);

const toggleSidecart = () => {
  if (!sidecartOpen.value || sidecartClosing.value) {
    sidecartClosing.value = false;
    stopCloseSidecart();
    sidecartOpen.value = !sidecartOpen.value;
    return;
  }

  sidecartClosing.value = true;
  closeSidecart();
};

onKeyStroke('Escape', () => {
  if (sidecartOpen.value && !sidecartClosing.value) {
    toggleSidecart();
  }
});

watch(checkingOut, () => {
  window.scrollTo(0, 0);
});

const discountObj = ref(null);
const handleNoOrderCouponApply = e => {
  discountObj.value = e;
};
const handleNoOrderCouponRemove = () => {
  discountObj.value = null;
};

watch(
  bestAutoApplyDiscount,
  e => {
    if (!e) return;
    if (discountObj.value && discountObj.value?.auto_apply === false) return;

    if (e.external_discount_id) {
      handleNoOrderCouponApply(e);
      return;
    }

    handleNoOrderCouponApply(null);
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <div>
    <template v-if="clientUpgradable">
      <div
        class="client-add-on-order"
        :class="{
          'client-add-on-order--hidden': checkingOut,
        }"
      >
        <section
          class="client-add-on-order__content"
          aria-labelledby="client-add-on-order-edit-heading"
        >
          <TitleBar
            :reservation-id="reservationId"
            heading-id="client-add-on-order-edit-heading"
            :title="titleBarTitle"
            :previous-page="previousPage"
          />
          <AddOnForm
            :reservation-id="reservationId"
            :products="products"
            :discount-obj="discountObj"
            @select="handleSelect"
            @deselect="handleDeselect"
            @product-price-info-update="handleProductPriceInfoUpdate"
          />
        </section>
        <AdditionalOrderSidecart
          id="client-add-on-order-sidecart"
          :reservation-id="reservationId"
          :products="products"
          :preferred="reservation.account.preferred"
          :show-product-level="false"
          :affixed="sidecartIsAffixed"
          class="client-add-on-order__sidecart"
          :class="{
            'client-add-on-order__sidecart--open': sidecartOpen,
            'client-add-on-order__sidecart--closing': sidecartClosing,
          }"
          :toggle-sidecart="toggleSidecart"
          :discount-obj="discountObj"
          @click.self="toggleSidecart"
          @increment-product="handleIncrementProduct"
          @decrement-product="handleDecrementProduct"
          @set-product-count="handleSetProductCount"
        >
          <template #checkout-button>
            <div class="client-add-on-order__sidecart__checkout">
              <SoonaButton
                class="client-add-on-order__sidecart__checkout-button"
                :disabled="products.length === 0"
                @click="() => (checkingOut = true)"
              >
                checkout
              </SoonaButton>
            </div>
          </template>
        </AdditionalOrderSidecart>
        <button
          type="button"
          class="u-button-reset client-add-on-order__sidecart-toggle"
          aria-controls="add-on-order-sidecart"
          :aria-expanded="sidecartOpen"
          @click="toggleSidecart"
        >
          <span class="u-visually-hidden">toggle </span>Order Summary
        </button>
      </div>
      <SelfServeCheckout
        v-if="checkingOut"
        :account-id="reservationAccountId"
        :reservation-id="reservationId"
        :products="products"
        :preferred="reservation.account.preferred"
        :segment="estimatedPhotosNeeded"
        :place-order="placeOrder"
        :is-order-processing="isOrderProcessing"
        :discount-obj="discountObj"
        show-promo-code
        @increment-product="handleIncrementProduct"
        @decrement-product="handleDecrementProduct"
        @set-product-count="handleSetProductCount"
        @back="() => (checkingOut = false)"
        @no-order-coupon-validated="handleNoOrderCouponApply"
        @no-order-coupon-removed="handleNoOrderCouponRemove"
      />
    </template>
    <template v-else>
      <UpgradeCard
        :reservation-id="reservationId"
        class="upgrade-card"
      ></UpgradeCard>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.upgrade-card {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
}

.client-add-on-order {
  flex: 1 0 auto;

  &--hidden {
    display: none !important;
  }

  &__content {
    grid-area: content;
  }

  &__sidecart {
    position: fixed;
    z-index: 4;
    top: var(--app-header-viewport-offset);
    bottom: 0;
    right: 0;
    background-color: rgba(63, 67, 75, 0.75);
    animation: 0.2s ease-out both fade-in-sidecart-bg;

    &--open {
      display: flex;
      overflow: hidden;
    }

    &--closing {
      animation: 0.3s ease-out both fade-out-sidecart-bg;
    }
  }

  &__sidecart-toggle {
    @include variables_fonts.u-small--heavy;

    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-right: 0;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    letter-spacing: 0.09375rem;
    border-radius: 0.625rem 0 0 0;
    background-color: variables.$white-default;
    transition: background-color 0.1s ease-out;

    > svg {
      display: block;
      margin-left: 0.5rem;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }
  }

  &__sidecart {
    position: fixed;
    z-index: 4;
    top: var(--app-header-viewport-offset);
    bottom: 0;
    right: 0;
    background-color: rgba(63, 67, 75, 0.75);
    animation: 0.2s ease-out both fade-in-sidecart-bg;

    &--open {
      display: flex;
      overflow: hidden;
    }

    &--closing {
      animation: 0.3s ease-out both fade-out-sidecart-bg;
    }

    &__checkout {
      padding-top: 1rem;

      &-button {
        width: calc(100% - 0.25rem);
      }
    }
  }
}

@media (min-width: 60rem) {
  .client-add-on-order {
    display: grid;
    grid-template-areas: 'content sidecart';
    grid-template-columns: 1fr 27.625rem;
    grid-template-rows: 1fr;

    &__content {
      grid-area: content;
    }
    &__sidecart-toggle {
      display: none;
    }

    &__sidecart {
      grid-area: sidecart;
      /* reset to a more standard layout for use in the grid */
      position: fixed;
      background-color: rgba(63, 67, 75, 0);
      animation: none;
      bottom: 0px;
      max-width: 27.625rem;
    }
  }
}
</style>
