<script setup>
import { computed, ref, watch } from 'vue';
import { useElementBounding, useMediaQuery } from '@vueuse/core';
import TitleBar from '@/components/shared/TitleBar.vue';
import ProductMultiPicker from '@/components/user/anytime/products/picker/ProductMultiPicker.vue';
import PickerProductMultiselectOption from '@/components/user/anytime/products/picker/PickerProductMultiselectOption.vue';
import { WhiteDefault } from '@/variables.module.scss';
import { useReservation } from '@/composables/useReservation';
import { useRouter } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ProductCard from '@/components/user/anytime/products/ProductCard.vue';
import MultiSelectActionBar from '@/components/user/anytime/products/MultiSelectActionBar.vue';
import { useUpdateReservation } from '@/queries/useUpdateReservation';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import useGroupCatalogItemsByReservation from '@/composables/useGroupCatalogItemsByReservation';

const props = defineProps({
  reservationId: {
    type: String,
    default: null,
    required: true,
  },
});

const router = useRouter();
const isSmallScreen = useMediaQuery('(max-width: 30rem)');

const pageWrapper = ref(null);
const { left, width } = useElementBounding(pageWrapper);

const reservationId = computed(() => props.reservationId);

const { reservation, requiredCatalogItems, isSuccess, error } =
  useReservation(reservationId);

const {
  mutate: updateReservation,
  isPending: isMutating,
  error: updateError,
  reset,
} = useUpdateReservation(reservationId);

const isProductPickerOpen = ref(false);
const selectedProducts = ref([]);

const shootTitle = computed(() => reservation.value?.name || '');
const accountId = computed(() => reservation.value?.account_id);

const isDraft = computed(() => reservation.value?.status === 'draft');

watch(isSuccess, () => {
  if (reservation.value?.status === 'draft') {
    window.location.href = `/#/booking/${reservationId.value}/down-payment`;
  }
});

const reservationDetailsPage = () => {
  router.push(`/reservation/${reservationId.value}/info`);
};

const { groupProducts } = useGroupCatalogItemsByReservation(reservationId);

function selectCatalogItems(selectedCatalogItems) {
  let requiredCatalogItemsAttributes = selectedCatalogItems.map(product => {
    return { catalog_item_id: product.id };
  });
  updateReservation(
    {
      required_catalog_items_attributes: requiredCatalogItemsAttributes,
    },
    {
      onSuccess: () => {
        reset();
        isProductPickerOpen.value = false;
      },
    }
  );
}

const existingCatalogItemIds = computed(
  () => requiredCatalogItems.value?.map(item => item.id) ?? []
);

const isSelected = id => selectedProducts.value.includes(id);

const selectProduct = productId => {
  if (selectedProducts.value.includes(productId)) {
    selectedProducts.value = selectedProducts.value.filter(
      x => x !== productId
    );
  } else {
    selectedProducts.value.push(productId);
  }
};

const cancelMultiSelect = () => {
  reset();
  selectedProducts.value = [];
};

const removeSelected = () => {
  let products = requiredCatalogItems.value.filter(item =>
    selectedProducts.value.includes(item.id)
  );

  let requiredCatalogItemsAttributes = products.map(product => {
    return { id: product['required_catalog_item_id'], _destroy: 1 };
  });

  updateReservation(
    {
      required_catalog_items_attributes: requiredCatalogItemsAttributes,
    },
    {
      onSuccess: () => {
        cancelMultiSelect();
      },
    }
  );
};
</script>
<template>
  <div v-if="isDraft || error" class="container">
    <div class="columns is-centered">
      <div class="column is-8">
        <div class="notification is-8">
          <h1 class="title">sorry, something went wrong…</h1>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="isSuccess"
    ref="pageWrapper"
    class="reservation-shoot-products"
  >
    <TitleBar
      :reservation-id="reservationId"
      heading-id="reservation-shoot-products-heading"
      :title="shootTitle"
      :background-color="WhiteDefault"
      :border-color="WhiteDefault"
      :previous-page="reservationDetailsPage"
      justify="left"
    />
    <section class="reservation-shoot-products__content">
      <div class="reservation-shoot-products__header">
        <h2 class="reservation-shoot-products__heading">
          products for this booking
        </h2>
        <SoonaButton
          class="booking-products__add-products-btn"
          variation="secondary-black"
          type="button"
          size="medium"
          @click="isProductPickerOpen = true"
        >
          <SoonaIcon name="plus" />
          {{ isSmallScreen ? 'add' : 'add products' }}
        </SoonaButton>
      </div>
      <div>
        <TransitionGroup
          name="product"
          tag="div"
          class="product-catalog__product-list"
        >
          <SoonaNoResults
            v-if="requiredCatalogItems.length === 0"
            key="product-catalog-empty-search-filter"
          >
            <template #header>
              this booking doesn't have any products yet...
            </template>
            <template #button>
              <SoonaButton
                class="booking-products__add-products-btn"
                variation="secondary-black"
                type="button"
                size="medium"
                @click="isProductPickerOpen = true"
              >
                <SoonaIcon name="plus" />
                add products
              </SoonaButton>
            </template>
          </SoonaNoResults>
          <ProductCard
            v-for="product in requiredCatalogItems"
            :key="product.id"
            :product="product"
            :account-id="accountId"
            :is-selected="isSelected(product.id)"
            :disabled="!product.removable"
            crew-view
            @handle-select="selectProduct(product.id)"
          />
        </TransitionGroup>
        <MultiSelectActionBar
          :error="updateError"
          :is-updating="isMutating"
          :selected-products="selectedProducts"
          button-copy="remove"
          button-icon="trash"
          :page-bounding-rect-left="left"
          :page-bounding-rect-width="width"
          @action-selected="removeSelected"
          @cancel-multi-select="cancelMultiSelect"
        />
      </div>
    </section>
    <ProductMultiPicker
      v-if="isProductPickerOpen"
      :account-id="accountId"
      heading="add products"
      :existing-selected-items="existingCatalogItemIds"
      :attached-to-reservation-id="reservationId"
      order-by="attached_to_reservation_id"
      @cancel="isProductPickerOpen = false"
      @select="items => selectCatalogItems(items)"
    >
      <template
        #product-options="{
          catalogItems: catalogProducts,
          selectedCatalogItemIdsString,
          multiSelectChange,
        }"
      >
        <template
          v-for="(items, group) in groupProducts(catalogProducts)"
          :key="group"
        >
          <h3 class="u-body--heavy">{{ group }}</h3>
          <PickerProductMultiselectOption
            v-for="product in items"
            :key="product.id"
            :product="product"
            :checked="
              selectedCatalogItemIdsString.includes(product.id.toString()) ||
              existingCatalogItemIds.includes(product.id)
            "
            :disabled="existingCatalogItemIds.includes(product.id)"
            @change="multiSelectChange($event, product)"
          />
        </template>
      </template>
    </ProductMultiPicker>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
.reservation-shoot-products {
  flex: 1 0 auto;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    padding: 2rem 1rem;

    @media (min-width: 768px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    @media (min-width: 1200px) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    @include variables_fonts.u-title--heavy;
  }
}

// transitions

/* base */
.product {
  backface-visibility: hidden;
  z-index: 1;
}

/* moving */
.product-move {
  transition: all 0.4s ease;
}

/* appearing */
.product-enter-active {
  transition: all 0.3s ease-out;
}

/* disappearing */
.product-leave-active {
  transition: all 0.3s ease-in;
  position: absolute;
  z-index: 0;
}

/* appear at / disppear to */
.product-enter-from,
.product-leave-to {
  opacity: 0;
}
</style>
