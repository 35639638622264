<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ExpertPickFavoriteAddToBag from '@/components/infinite_asset_gallery/ExpertPickFavoriteAddToBag.vue';

const props = defineProps({
  digitalAsset: {
    type: Object,
    required: true,
  },
});

const isHidden = computed(() => props.digitalAsset?.visibility !== 'all');
const isIndependentRawAsset = computed(
  () => !props.digitalAsset.preview?.url && !!props.digitalAsset.raw?.url
);
const isOwnedBySoona = computed(
  () => props.digitalAsset?.ownership === 'soona'
);
</script>

<template>
  <span v-if="isHidden" class="da-common-actions__hidden u-label--heavy">
    <SoonaIcon name="broken-link" size="medium" />hidden
  </span>
  <ExpertPickFavoriteAddToBag
    v-if="isOwnedBySoona && !isHidden && !isIndependentRawAsset"
    :digital-asset="digitalAsset"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.da-common-actions {
  &__hidden {
    align-self: flex-end;
    display: flex;
    padding: 0.125rem 0.25rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.3125rem;
    background-color: variables.$black-translucent-60;
    color: variables.$white-default;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
}
</style>
