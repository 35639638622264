<script setup>
import { computed, onMounted, reactive, ref, watchEffect } from 'vue';
import SoonaForm from 'src/components/ui_library/SoonaForm.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import AllInOneConsent from 'src/components/shared/AllInOneConsent.vue';
import fallbackAccountImage from 'images/account-placeholder.svg';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import SoonaTelephoneField from '../ui_library/SoonaTelephoneField.vue';
import ThirdPartyConnections from 'src/components/user/ThirdPartyConnections.vue';
import { useMe } from '@/composables/user/useMe';
import { useUpdateUserAvatar } from '@/queries/users/useUpdateUserAvatar';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaUploadV2 from '@/components/uploader/SoonaUploadV2.vue';
import { useTitle } from '@vueuse/core';
import { useUpdateCurrentUser } from '@/queries/users/useUpdateCurrentUser';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { useAccountsUsers } from '@/queries/accounts-users/useAccountsUsers';
import { useUpdateAccountsUser } from '@/queries/accounts-users/useUpdateAccountsUser';

useTitle('user settings', { titleTemplate: '%s | soona' });

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const { me, currentUserId, invalidateMe } = useMe();

const currentUserAvatar = computed(
  () => me.value?.avatar_url || fallbackAccountImage
);

const isGoogleUser = computed(() => {
  return me.value?.provider === 'google_oauth2';
});

const hasNonSoonaProvider = computed(() => {
  return me.value?.provider !== 'soona_default';
});

const avatarUploadErrorMessage = ref(null);

const {
  mutate: updateUserAvatar,
  isPending: isAvatarLoading,
  error: updateAvatarError,
} = useUpdateUserAvatar(currentUserId);

function handleAvatarUploadComplete(blob) {
  if (currentUserId.value && blob?.signed_id) {
    updateUserAvatar(
      { avatarSignedId: blob.signed_id },
      {
        onSuccess: async () => {
          await invalidateMe();
          avatarUploadErrorMessage.value = null;
        },
      }
    );
  }
}

function handleAvatarUploadError(msg) {
  avatarUploadErrorMessage.value = new Error(msg);
}

const userFormData = reactive({
  name: '',
  email: '',
  phone: '',
  passwordNew: '',
  passwordNewConfirm: '',
  passwordCurrent: '',
  acceptTextMessages: true,
});

watchEffect(() => {
  if (me.value) {
    userFormData.name = me.value.name;
    userFormData.email = me.value.email;
    userFormData.phone = me.value.phone;
    userFormData.acceptTextMessages = me.value.accept_text_messages;
    userFormData.passwordNew = '';
    userFormData.passwordNewConfirm = '';
    userFormData.passwordCurrent = '';
  }
});

const {
  mutate: mutateUpdateUser,
  isPending: isUpdatingCurrentUser,
  error: updateCurrentUserError,
} = useUpdateCurrentUser();

function updateCurrentUser() {
  const user = {
    name: userFormData.name,
    email: userFormData.email,
    phone: userFormData.phone,
    acceptTextMessages: userFormData.acceptTextMessages,
  };

  if (userFormData.passwordNew) {
    user.password = userFormData.passwordNew;
  }
  if (userFormData.passwordNewConfirm) {
    user.passwordConfirmation = userFormData.passwordNewConfirm;
  }
  if (userFormData.passwordCurrent) {
    user.currentPassword = userFormData.passwordCurrent;
  }
  mutateUpdateUser(user);
}

const isUpdatingPassword = computed(() => {
  return userFormData.passwordNew && userFormData.passwordNew.length > 0;
});

const {
  data: accountsUsers,
  isLoading: isAccountsUsersLoading,
  error: accountsUsersError,
} = useAccountsUsers({
  userId: currentUserId,
});

const {
  mutate: updateAccountsUsers,
  isPending: isUpdatingAccountsUsers,
  error: updateAccountsUsersError,
} = useUpdateAccountsUser();

const isLoading = computed(
  () =>
    isAvatarLoading.value ||
    isUpdatingCurrentUser.value ||
    isAccountsUsersLoading.value ||
    isUpdatingAccountsUsers.value
);

const priorityErrors = usePriorityErrors(
  avatarUploadErrorMessage,
  updateAvatarError,
  updateCurrentUserError,
  updateAccountsUsersError,
  accountsUsersError
);
</script>

<template>
  <div class="user-settings">
    <h2 class="user-settings__heading u-headline--heavy">
      update your settings
    </h2>

    <SoonaLoading v-if="isLoading" is-loading />

    <SoonaForm
      v-slot="{ hasErrors }"
      :disabled="isLoading"
      @submit="updateCurrentUser"
    >
      <div class="user-settings__avatar-wrap">
        <SoonaError
          v-if="priorityErrors"
          :priority-errors="priorityErrors"
          no-margin
          style="width: 100%"
        />
        <img
          :key="currentUserAvatar"
          class="user-settings__avatar"
          :src="currentUserAvatar"
          alt=""
        />
        <SoonaUploadV2
          label="upload a selfie"
          accept=".jpeg, .jpg, .png, .gif"
          :file-size-limit-mb="5"
          @upload-complete="handleAvatarUploadComplete"
          @upload-error="handleAvatarUploadError"
        >
          <template #default="{ onUploadClick }">
            <SoonaButton
              size="medium"
              variation="secondary-gray"
              @on-click="onUploadClick"
            >
              upload a selfie
            </SoonaButton>
          </template>
        </SoonaUploadV2>
      </div>
      <SoonaTextfield
        v-model="userFormData.name"
        :disabled="isLoading"
        class="user-settings__form-input"
        label="name"
        name="user-name"
        placeholder="first & last"
        autocomplete="name"
        type="text"
        :rules="['required']"
        required
      />
      <SoonaTelephoneField
        v-model="userFormData.phone"
        :disabled="isLoading"
        class="user-settings__form-input"
        label="phone number"
      />
      <SoonaToggle
        v-model="userFormData.acceptTextMessages"
        :disabled="isLoading"
        class="user-settings__form-input"
        type="checkbox"
        name="accept-text-messages"
        label="yes! soona can text me! I understand message and data rates may apply."
      />
      <SoonaTextfield
        v-model="userFormData.email"
        :disabled="isLoading || isGoogleUser"
        class="user-settings__form-input"
        label="email"
        name="user-email"
        autocomplete="email"
        type="email"
        :rules="['email', 'required']"
        required
      />
      <SoonaTextfield
        v-if="!isGoogleUser"
        v-model="userFormData.passwordNew"
        :disabled="isLoading"
        class="user-settings__form-input"
        autocomplete="new-password"
        placeholder="********"
        label="new password"
        name="user-password-new"
        type="password"
        :minlength="8"
        :rules="['minlength']"
      >
        <template #helper-bottom>
          <span class="u-label--regular">
            leave blank if you don't want to change your password.
          </span>
        </template>
      </SoonaTextfield>
      <SoonaTextfield
        v-if="!isGoogleUser"
        v-model="userFormData.passwordNewConfirm"
        :disabled="isLoading"
        class="user-settings__form-input"
        autocomplete="new-password"
        placeholder="********"
        label="confirm new password"
        name="user-password-new-confirm"
        type="password"
        :password="userFormData.passwordNew"
        :rules="['match']"
      />

      <ThirdPartyConnections
        v-if="hasNonSoonaProvider"
        :has-google="isGoogleUser"
      />

      <div class="user-settings__account-notifications">
        <div>
          <h3
            class="u-body--heavy user-settings__account-notifications__heading"
          >
            account notification settings
          </h3>
          <p class="u-label--regular">
            soona will send you email notifications about actions you take. you
            can set additional account specific email notification preferences
            below.
          </p>
        </div>

        <fieldset v-for="accountsUser in accountsUsers" :key="accountsUser.id">
          <legend class="u-label--heavy">
            {{ accountsUser.account_name }}
          </legend>
          <p
            class="u-label--regular user-settings__account-notifications__role"
          >
            role: {{ accountsUser.role }}
          </p>
          <SoonaToggle
            :model-value="accountsUser.booking_opt_in"
            type="checkbox"
            label="send me email notifications about any booking activity on this account"
            @update:model-value="
              updateAccountsUsers({
                accountsUserId: accountsUser.id,
                params: {
                  booking_opt_in: $event,
                },
              })
            "
          />
          <SoonaToggle
            :model-value="accountsUser.financial_opt_in"
            type="checkbox"
            label="send me email notifications about any financial activity on this account"
            @update:model-value="
              updateAccountsUsers({
                accountsUserId: accountsUser.id,
                params: {
                  financial_opt_in: $event,
                },
              })
            "
          />
        </fieldset>
      </div>

      <template v-if="!isGoogleUser && isUpdatingPassword">
        <hr class="user-settings__line-rule-divider" />
        <h2 class="user-settings__confirm-heading u-body--heavy">
          confirm your changes
        </h2>
        <SoonaTextfield
          v-model="userFormData.passwordCurrent"
          :disabled="isLoading"
          class="user-settings__form-input"
          autocomplete="current-password"
          label="current password"
          name="user-password-current"
          placeholder="********"
          type="password"
          :minlength="8"
          :rules="['minlength', 'required']"
          required
        />
      </template>

      <div class="user-settings__marketing-email-consent">
        <AllInOneConsent />
      </div>

      <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
      <div class="user-settings__submit-button-wrap">
        <SoonaButton
          :disabled="hasErrors || isLoading"
          size="medium"
          type="submit"
        >
          update profile
        </SoonaButton>
      </div>
    </SoonaForm>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.user-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  max-width: min(100%, 30rem);
  margin: 0 auto;

  &__heading {
    text-align: center;
  }

  &__avatar-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  &__avatar {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
    display: block;
    border-radius: 4rem;
  }

  &__form-input {
    padding-bottom: 1rem;
  }

  &__account-notifications {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__heading {
      margin-bottom: 0.5rem;
    }

    &__role {
      margin-bottom: 0.875rem;
    }
  }

  &__submit-button-wrap {
    display: flex;
    justify-content: center;
  }

  &__line-rule-divider {
    margin-top: 2.75rem;
    border: 0.0625rem solid variables.$gray-30;
  }

  &__confirm-heading {
    text-align: center;
  }

  &__marketing-email-consent {
    margin-bottom: 1rem;
  }
}
</style>
