<script setup>
import { ref, computed } from 'vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import AlbumPicker from '@/components/user/anytime/album_picker/AlbumPicker.vue';
import ScenePicker from '@/components/user/anytime/collection/ScenePicker.vue';
import GifPicker from '@/components/user/anytime/reservation/gif_picker/GifPicker.vue';
import { useCapability } from '@/composables/useCapability';
import { usePurchasedFilesSelected } from './usePurchasedFilesSelected';
import { useInvalidAssetsForGifSelected } from './useInvalidAssetsForGifSelected';
import { useReservationGallerySelection } from '@/components/user/anytime/reservation/useReservationGallerySelection';
import { useBaggedAssetsSelected } from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useBaggedAssetsSelected';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  addToAlbum: {
    type: Function,
    required: true,
  },
  addToFavorites: {
    type: Function,
    required: true,
  },
  isIconButton: {
    type: Boolean,
    default: false,
  },
  reservationId: {
    type: [String, Number],
    required: true,
  },
});

// todo: change name to ReservationOrganizeMenu
defineEmits(['hide', 'cancel']);

const { selectedReservationDigitalAssets } = useReservationGallerySelection();

const selectedDigitalAssets = computed(() => {
  return selectedReservationDigitalAssets.value?.map(rda => rda.digital_asset);
});

const accountId = computed(() => props.accountId);

// capabilities module
const { hasCapability: canFavoriteAsset } = useCapability({
  capability: 'favorite_gallery',
  subjectType: 'account',
  subjectId: accountId,
});
const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const { purchasedFilesSelected } = usePurchasedFilesSelected(
  selectedReservationDigitalAssets
);
const { baggedAssetsSelected } = useBaggedAssetsSelected(
  selectedReservationDigitalAssets
);
const { invalidAssetsForGifSelected, moreThanMaxFramesSelected } =
  useInvalidAssetsForGifSelected(selectedReservationDigitalAssets);

const invalidForAddingToSceneSelected = computed(() =>
  selectedReservationDigitalAssets.value.some(
    rda => rda.digital_asset.asset_type !== 'original'
  )
);

const displayAlbumPicker = ref(false);
const displaySceneSelector = ref(false);
const displayGifPicker = ref(false);
</script>

<template>
  <SoonaDropdownMenu
    :variation="isIconButton ? 'icon-gray-outline' : 'secondary-black'"
    :display-caret="false"
    data-cypress="organize-menu"
  >
    <template #trigger-content>
      <SoonaIcon name="plus" />
      <span :class="{ 'u-visually-hidden': isIconButton }">organize</span>
    </template>
    <template #default="{ keydown, mouseover, clickCapture }">
      <SoonaDropdownMenuItem v-if="isSoonaStaff">
        <button
          role="menuitem"
          class="organize-menu__item"
          :disabled="baggedAssetsSelected"
          @click="$emit('hide')"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="eye-slash" />
          hide
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem
        v-if="isSoonaStaff && !invalidForAddingToSceneSelected"
      >
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="displaySceneSelector = true"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="clapperboard" />
          add to scene
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem>
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="displayAlbumPicker = true"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="album" />
          add to album
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="canFavoriteAsset && !purchasedFilesSelected">
        <button
          role="menuitem"
          class="organize-menu__item"
          @click="addToFavorites"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="heart" />
          add to favorites
        </button>
      </SoonaDropdownMenuItem>
      <SoonaDropdownMenuItem v-if="!invalidAssetsForGifSelected">
        <button
          role="menuitem"
          class="organize-menu__item"
          :disabled="moreThanMaxFramesSelected"
          data-cypress="button-add-to-gif"
          @click="displayGifPicker = true"
          @keydown="keydown"
          @mouseover="mouseover"
          @click.capture="clickCapture"
        >
          <SoonaIcon name="gif-file" />
          add to gif
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>

  <AlbumPicker
    v-if="displayAlbumPicker"
    :account-id="accountId"
    :selected-digital-assets="selectedDigitalAssets"
    @cancel="displayAlbumPicker = false"
    @select="addToAlbum"
  />

  <ScenePicker
    v-if="displaySceneSelector"
    :reservation-id="reservationId"
    :selected-rdas="selectedReservationDigitalAssets"
    @close="displaySceneSelector = false"
  />

  <GifPicker
    v-if="displayGifPicker"
    :account-id="accountId"
    :reservation-id="reservationId"
    :selected-assets="selectedDigitalAssets"
    @close="displayGifPicker = false"
    @cancel="$emit('cancel')"
  />
</template>

<style lang="scss" scoped>
.organize-menu {
  &__item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
