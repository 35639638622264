<script setup>
import { computed } from 'vue';
import { convertToDateLong } from '@/lib/date-formatters';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useBookingsCategory } from '@/components/bookings/useBookingsCategory';
import { useRoute } from 'vue-router';
import { useFlag } from '@/composables/useFlag';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import ReservationActions from '@/components/bookings/ReservationActions.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const duplicateFlag = useFlag('toaster_duplicate_booking');

const { linkClicked } = useBaseEvents();
const route = useRoute();

const accountId = computed(() => props.accountId);

const { isLoading, reservations: pastBookings } = useBookingsCategory({
  accountId,
  filter: 'past',
});

const trackClick = (label, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'past studio bookings',
    linkLabel: label,
    linkHref: href,
  });
};
</script>

<template>
  <section
    v-if="pastBookings?.length"
    class="bookings"
    data-cypress="section-bookings"
  >
    <div class="bookings__heading">
      <h2
        id="bookings"
        class="u-title--heavy"
        data-cypress="text-bookings-heading"
      >
        past studio bookings
      </h2>
      <SoonaButton
        element="router-link"
        :to="{ name: 'accountBookings', query: { filter: 'past' } }"
        variation="tertiary"
        @on-click="
          trackClick('see all', `/account/${accountId}/bookings?filter=past`)
        "
      >
        see all <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <CarouselLoading v-if="isLoading" />
    <PageContainerOffset v-else>
      <SoonaSlider tag-name="ul" aria-labelledby="bookings">
        <SoonaImageCard
          v-for="booking in pastBookings"
          :key="booking.id"
          inner-element="router-link"
          :to="`/reservation/${booking.id}`"
          cypress-name="account-past-booking-card"
          :data-reservation-id="booking.id"
          @click="trackClick(booking.name, `/reservation/${booking.id}/info`)"
        >
          <img
            :src="
              booking.preview_url ?? booking.location?.booking_gallery_image_url
            "
            alt=""
          />
          <template #overlay>
            <ReservationActions
              v-if="duplicateFlag"
              buttons-size="medium"
              :reservation-id="booking.id"
            />
          </template>
          <template #caption>
            <p>{{ booking.name }}</p>
            <p class="u-small--regular bookings__date">
              <SoonaIcon
                :name="
                  booking.shoot_type === 'photo'
                    ? 'image-square'
                    : 'video-file-play'
                "
                size="small"
              />
              {{ convertToDateLong(booking.end) }}
            </p>
          </template>
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bookings {
  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }

  &__date {
    align-items: center;
    color: variables.$gray-60;
    display: flex;
    gap: 0.25rem;
  }
}
</style>
