<script setup>
import { computed, onMounted, ref, watch, provide } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useIntegrations } from '@/composables/useIntegrations';
import { useActiveListing, useRefreshListing } from '@/queries/useListings';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useTitle } from '@vueuse/core';
import ListingDetailSummary from './ListingDetailSummary.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import AnalyzingYourListingsBanner from '../AnalyzingYourListingsBanner.vue';
import ListingDetailNav from './ListingDetailNav.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  externalProductId: { type: String, required: true },
  integrationId: { type: String, required: true },
  productId: { type: String, required: true }, // catalog item id -- to do: update name
});

const accountId = computed(() => props.accountId);
const externalProductId = computed(() => props.externalProductId);
const integrationId = computed(() => props.integrationId);

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

// listing data
const { integrationsSuccess, integrationsError, currentPlatformName } =
  useIntegrations(accountId, integrationId);

const pageTitle = computed(() => `${currentPlatformName.value} listing`);

useTitle(`${pageTitle.value} | soona`);

const {
  data: listingData,
  error: listingError,
  isSuccess,
} = useActiveListing(integrationId, externalProductId, {
  enabled: integrationsSuccess,
  refetchInterval: query => {
    if (query.state.error?.response?.status === 403) {
      return false;
    }
    return query.state.data?.scoring_in_progress ||
      !query.state.data?.last_scored
      ? 5000
      : false;
  },
});

// provide digital assets
const listingAssets = computed(() => listingData.value?.assets ?? null);
provide('listingAssets', listingAssets);

const lastScoredTimestamp = computed(() => listingData.value?.last_scored);

const { mutate } = useRefreshListing(integrationId, externalProductId);

const refreshListingActivated = ref(false);

const refreshListing = () => {
  refreshListingActivated.value = true;
  mutate(null, {
    onSuccess: () => {
      refreshListingActivated.value = false;
    },
  });
};

watch([lastScoredTimestamp, integrationId, externalProductId], () => {
  if (lastScoredTimestamp.value) {
    refreshListingActivated.value = false;
  }
});

const priorityError = usePriorityError(integrationsError, listingError);

const listingIsCalculating = computed(() => {
  return listingData.value?.scoring_in_progress ?? false;
});

const listingIsLoading = computed(
  () => listingIsCalculating.value || refreshListingActivated.value
);
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <div v-if="listingIsLoading" class="listing-detail__loading">
    <AnalyzingYourListingsBanner />
  </div>
  <div v-if="!listingIsLoading && isSuccess" class="listing-detail u-container">
    <ListingDetailSummary
      :listing="listingData"
      :account-id="accountId"
      :integration-id="integrationId"
      :external-product-id="externalProductId"
      :platform="currentPlatformName"
      @refresh-listing="refreshListing"
    />
    <ListingDetailNav
      :account-id="accountId"
      :integration-id="integrationId"
      :external-product-id="externalProductId"
      :product-id="productId"
      :listing="listingData"
    />
    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        :platform="currentPlatformName"
        :listing-data="listingData"
        @refresh-listing="refreshListing"
      />
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-detail {
  margin-top: -2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__loading {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
</style>
