<script setup>
import { computed, provide, ref } from 'vue';
import MediaEditor from '@/components/user/anytime/gallery/media-editor/MediaEditor.vue';
import { useDigitalAsset } from '@/queries/digital_assets/useDigitalAsset';

import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_NEXT_KEY,
  MEDIA_POPOVER_PREV_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRouter } from 'vue-router';
import { usePreloadDigitalAsset } from '@/queries/digital_assets/usePreloadDigitalAsset';
import { useMediaQuery } from '@vueuse/core';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import ProductAssetsAssetCarousel from './ProductAssetsAssetCarousel.vue';
import { useCatalogItem } from '@/queries/useCatalog';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  productId: {
    type: [String, Number],
    required: true,
  },
  digitalAssetId: {
    type: [Number, String],
    required: true,
  },
});
const router = useRouter();
const accountId = computed(() => props.accountId);
const productId = computed(() => props.productId);
const digitalAssetId = computed(() => props.digitalAssetId);

const { data: catalogItem, error: catalogItemError } = useCatalogItem(
  accountId,
  productId
);

const params = computed(() => ({
  ownership: 'customer',
  visibility: 'all',
  catalog_item_id: productId.value,
}));

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

const {
  data: file,
  isLoading: isFileLoading,
  error: fileLoadingError,
} = useDigitalAsset({ accountId, assetId: digitalAssetId, params }, {});

// routes and preloading
const nextId = computed(() => file.value?.next_id);
const prevId = computed(() => file.value?.prev_id);

usePreloadDigitalAsset(accountId, nextId, params);
usePreloadDigitalAsset(accountId, prevId, params);

const createRouteLocation = id => {
  if (!id) return null;

  return {
    name: 'product-assets-media-view',
    params: {
      accountId: accountId.value,
      productId: productId.value,
      digitalAssetId: id,
    },
  };
};

const prevRoute = computed(() => createRouteLocation(prevId.value));
const nextRoute = computed(() => createRouteLocation(nextId.value));

const backUrl = computed(() => {
  return `/account/${accountId.value}/products/${props.productId}`;
});

const backText = computed(() => {
  return catalogItem.value?.name ?? 'product';
});

provide(MEDIA_POPOVER_BACK_KEY, backUrl);
provide(MEDIA_POPOVER_PREV_KEY, prevRoute);
provide(MEDIA_POPOVER_NEXT_KEY, nextRoute);
provide(MEDIA_POPOVER_BACK_TEXT, backText);

const isRedirecting = ref(false);
const handleClose = () => {
  isRedirecting.value = true;
  router.push(backUrl.value);
};

const priorityError = usePriorityError(fileLoadingError, catalogItemError);
</script>

<template>
  <MediaEditor
    v-if="!isRedirecting"
    :asset="file"
    :is-asset-loading="isFileLoading"
    @close="handleClose"
  >
    <template v-if="priorityError" #error>
      <SoonaError>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #carousel>
      <ProductAssetsAssetCarousel
        v-if="matchMediaIsWide"
        :account-id="accountId"
        :product-id="productId"
        :active-asset-id="file?.id"
        :back-text="backText"
      />
    </template>
  </MediaEditor>
</template>
