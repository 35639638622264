import * as types from 'src/store/mutation-types';
// eslint-disable-next-line no-restricted-imports
import { queryClient } from 'src/queries/query-client';
import { queryKeys } from 'src/queries/query-keys';

import white from 'images/manicure-colors/white.png';
import cream from 'images/manicure-colors/cream.png';
import blushCoffin from 'images/manicure-colors/blush-coffin.png';
import yellow from 'images/manicure-colors/yellow.png';
import orange from 'images/manicure-colors/orange.png';
import pink from 'images/manicure-colors/pink.png';
import red from 'images/manicure-colors/red.png';
import redCoffin from 'images/manicure-colors/red-coffin.png';
import purple from 'images/manicure-colors/purple.png';
import blue from 'images/manicure-colors/blue.png';
import green from 'images/manicure-colors/green.png';
import black from 'images/manicure-colors/black.png';

const state = {
  currentTagCategories: [],
  proServiceRequirements: [],
  proServices: [],
  currentProfileServices: [],
  currentServiceSelected: null,
  currentPSPchoices: [],
  proServiceProvider: {
    arrivedLateCount: undefined,
    lateCancelCount: undefined,
    providerNote: undefined,
  },
  pressOnColorOptions: [
    {
      label: 'white - square',
      value: 'white - square',
      image: white,
    },
    {
      label: 'cream - square',
      value: 'cream - square',
      image: cream,
    },
    {
      label: 'blush - coffin',
      value: 'blush - coffin',
      image: blushCoffin,
    },
    {
      label: 'yellow - square',
      value: 'yellow - square',
      image: yellow,
    },
    {
      label: 'orange - square',
      value: 'orange - square',
      image: orange,
    },
    {
      label: 'pink - square',
      value: 'pink - square',
      image: pink,
    },
    {
      label: 'red - square',
      value: 'red - square',
      image: red,
    },
    {
      label: 'red - coffin',
      value: 'red - coffin',
      image: redCoffin,
    },
    {
      label: 'purple - square',
      value: 'purple - square',
      image: purple,
    },
    {
      label: 'blue - square',
      value: 'blue - square',
      image: blue,
    },
    {
      label: 'green - square',
      value: 'green - square',
      image: green,
    },
    {
      label: 'black - square',
      value: 'black - square',
      image: black,
    },
  ],
};

const getters = {
  proServiceTypes: state =>
    state.currentTagCategories.find(
      category => category.title === 'pro services'
    ),
  profileIncompleteTraits: (state, getters, rootState) => {
    let incompleteTraits = state.currentProfileServices.reduce(
      (traitCount, profile) => {
        const serviceRequiresDisplayName = [
          'baby: 0-1 y/o',
          'toddler: 1-3 y/o',
          'kid: 4-12 y/o',
          'teen: 12-18 y/o',
          'pet model',
        ];

        const serviceRequiresSampleWork = ['content creator'];

        const showDisplayName = profile.selected_tags.some(t =>
          serviceRequiresDisplayName.includes(t.title)
        );

        const showSampleWork = profile.selected_tags.some(t =>
          serviceRequiresSampleWork.includes(t.title)
        );

        const ignoreTC = ['bra bust size', 'bra cup size', 'barefoot modeling'];

        const categoriesToShowLength = state.currentTagCategories.filter(
          tc =>
            tc.title !== 'manicure' &&
            !ignoreTC.includes(tc.title) &&
            profile.selected_tags.some(t =>
              t.tag_follow_up_categories.some(
                tfuc => tfuc.tag_category_id == tc.id
              )
            )
        ).length;

        // require measurements length

        const requiredMeasurementsLength = profile.selected_tags.find(
          t => t.requires_measurements !== null
        )?.requires_measurements.length;

        // filter selected measurements length
        const filterSelectedMeasurementsLength = profile.measurements
          ? profile.measurements.filter(m => m.value !== '').length
          : 0;

        // select traits length
        const displayNameFilled = profile.name;
        const sampleWorkFilled = profile.sample_work;

        const filterMultipleSelectedTraits = Array.from(
          new Set(profile.selected_tags.map(a => a.tag_category_id))
        ).map(tci => {
          return profile.selected_tags.find(a => a.tag_category_id === tci);
        });

        const ignoreTrait = [
          'full body model',
          'hand model',
          'foot model',
          'pet model',
          'content creator',
          'manicure',
          'hair and makeup',
          'grocery shopping',
          'styling',
          'steaming',
        ];

        const filterSelectedTraits = filterMultipleSelectedTraits.filter(
          t =>
            !ignoreTC.includes(t.tag_category_title) &&
            !ignoreTrait.includes(t.title)
        );

        let profileSelectedTraits =
          showDisplayName && displayNameFilled
            ? filterSelectedTraits.length + 1
            : filterSelectedTraits.length;

        profileSelectedTraits =
          showSampleWork && sampleWorkFilled
            ? profileSelectedTraits + 1
            : profileSelectedTraits;

        // total selected (measurements + traits)
        const totalSelected =
          profileSelectedTraits + filterSelectedMeasurementsLength;

        let totalOptions = showDisplayName
          ? categoriesToShowLength + 1
          : categoriesToShowLength;

        totalOptions = showSampleWork ? totalOptions + 1 : totalOptions;

        const tagsWithMeasurements = [
          'kid: 4-12 y/o',
          'teen: 12-18 y/o',
          'young adult: 19-24 y/o',
          'adult: 25-35 y/o',
          'middle age adult: 36-55 y/o',
          'mature: 56-65+ y/o',
          'pet model',
        ];

        const showMeasurements =
          requiredMeasurementsLength &&
          profile.selected_tags.some(t =>
            tagsWithMeasurements.includes(t.title)
          );

        if (showMeasurements) {
          const totalWithMeasurements =
            totalOptions + requiredMeasurementsLength;

          const incompleteTraits = totalWithMeasurements - totalSelected;

          traitCount[profile.id] = incompleteTraits;
        } else {
          let totalCategoriesToShow = showDisplayName
            ? categoriesToShowLength + 1
            : categoriesToShowLength;

          totalCategoriesToShow = showSampleWork
            ? totalCategoriesToShow + 1
            : totalCategoriesToShow;

          const incompleteTraits = totalCategoriesToShow - totalSelected;

          traitCount[profile.id] = incompleteTraits;
        }

        return traitCount;
      },
      {}
    );
    let infoIncompleteTraits = 2;
    if (rootState.user.profile_first_name) {
      infoIncompleteTraits--;
    }
    if (rootState.user.profile_last_name) {
      infoIncompleteTraits--;
    }
    incompleteTraits['profile_info_section'] = infoIncompleteTraits;
    return incompleteTraits;
  },
};

const mutations = {
  [types.SET_PRO_SERVICES](state, proServices) {
    state.proServices = proServices;
  },
  [types.SET_CURRENT_TAG_CATEGORIES](state, sortedCategories) {
    state.currentTagCategories = sortedCategories;
  },
  [types.SET_PRO_SERVICE_REQUIREMENTS](state, requirements) {
    state.proServiceRequirements = requirements;
  },
  [types.SET_PRO_SERVICE_PROVIDER_DETAILS](state, proServiceProvider) {
    state.proServiceProvider.arrivedLateCount =
      proServiceProvider.arrived_late_count;
    state.proServiceProvider.lateCancelCount =
      proServiceProvider.late_cancel_count;
    state.proServiceProvider.providerNote =
      proServiceProvider.pro_service_provider_note;
  },
  [types.SET_CURRENT_PROFILE_TRAITS](state, services) {
    state.currentProfileServices = services;
  },
  [types.ADD_NEW_PSP_CHOICE](state, choice) {
    // remove existing choice with the same order
    let matchingIndexByOrder = state.currentPSPchoices
      .map(function (x) {
        return x.order;
      })
      .indexOf(choice.order);
    if (matchingIndexByOrder >= 0) {
      this.commit(
        'proService/REMOVE_SELECTED_PROVIDER',
        state.currentPSPchoices[matchingIndexByOrder]
      );
    }
    let activeChoices = () =>
      state.currentPSPchoices.filter(choice => !choice.destroy);
    // if existing choice exists update order + remove
    // _destroy property and if choice doesn't existing
    // then add choice to currentPSPchoices
    let matchingIndexByProfile = state.currentPSPchoices
      .map(function (x) {
        return x.pro_service_profile_id;
      })
      .indexOf(choice.pro_service_profile_id);
    if (matchingIndexByProfile >= 0) {
      let existingChoice = state.currentPSPchoices[matchingIndexByProfile];
      state.currentPSPchoices[choice.order] = existingChoice;
      state.currentPSPchoices[choice.order].order = choice.order;
      delete state.currentPSPchoices[choice.order]._destroy;
      if (existingChoice.id && activeChoices.length > 3) {
        state.currentPSPchoices.splice(matchingIndexByProfile, 1);
      } else {
        state.currentPSPchoices.splice(matchingIndexByProfile, 1, {});
      }
    } else {
      state.currentPSPchoices.splice(choice.order, 1, choice);
    }
  },
  [types.SET_CURRENT_PSP_CHOICE](state, choice) {
    let order =
      choice.initial_order !== undefined ? choice.initial_order : choice.order;
    if (order !== undefined) {
      choice.order = order;
      delete choice._destroy;
      state.currentPSPchoices[order] = choice;
    }
  },
  [types.CLEAR_CURRENT_PSP_CHOICES](state) {
    state.currentPSPchoices = [{}, {}, {}];
  },
  [types.RESET_CURRENT_PSP_CHOICES](state) {
    state.currentPSPchoices.forEach(choice => {
      choice._destroy = '1';
    });
  },
  [types.SELECT_CURRENT_SERVICE](state, currentServiceId) {
    state.currentServiceSelected = currentServiceId;
  },
  [types.REMOVE_SELECTED_PROVIDER](state, choice) {
    let matchingIndex = state.currentPSPchoices
      .map(function (x) {
        return x.pro_service_profile_id;
      })
      .indexOf(choice.pro_service_profile_id);
    if (matchingIndex >= 0) {
      if (!choice.id) {
        state.currentPSPchoices.splice(matchingIndex, 1, {});
      } else {
        let existingOption = state.currentPSPchoices[matchingIndex];
        existingOption.order = null;
        existingOption._destroy = '1';
        state.currentPSPchoices[state.currentPSPchoices.length] =
          existingOption;
        state.currentPSPchoices[matchingIndex] = {};
      }
    }
  },
};

const actions = {
  postProServiceRequirementChoices(
    { dispatch },
    {
      reservationId,
      pspRequirementId,
      status,
      extrasPayload,
      imagesPayload,
      proServiceSelectionReset,
    }
  ) {
    let nonEmptyChoices = state.currentPSPchoices.reduce((acc, choice) => {
      if (Object.keys(choice).length > 0) {
        choice['reservation_id'] = reservationId;
        acc.push(choice);
      }
      return acc;
    }, []);
    let cleanChoices = nonEmptyChoices.filter(choice => !choice._destroy);
    return this.http
      .patch(
        `reservations/${reservationId}/pro_service_requirements/${pspRequirementId}.json`,
        {
          pro_service_requirement: {
            id: pspRequirementId,
            pro_service_requirement_choices_attributes: nonEmptyChoices,
            pro_service_extras: extrasPayload,
            hmu_reference_image: imagesPayload?.hmu_reference_image,
            manicure_reference_image: imagesPayload?.manicure_reference_image,
            pedicure_reference_image: imagesPayload?.pedicure_reference_image,
            grocery_shopping_reference_image:
              imagesPayload?.grocery_shopping_reference_image,
            status: status,
          },
          pro_service_selection_reset:
            proServiceSelectionReset ??
            (nonEmptyChoices.length > 0 && cleanChoices.length === 0),
          pro_service_selection_completed: cleanChoices.length > 1,
        }
      )
      .then(res => {
        if (
          nonEmptyChoices.length > 0 &&
          res.data.pro_service_requirement_choices.length > 0
        ) {
          res.data.pro_service_requirement_choices.forEach(choice => {
            state.currentPSPchoices[choice.order].id = choice.id;
          });
        }
        dispatch('loadProServiceRequirements', {
          reservationId: reservationId,
        })
          .then(() => {
            queryClient.invalidateQueries({
              queryKey: queryKeys.topChoicesOnReservation(reservationId),
            });
            return;
          })
          .catch(error => {
            console.error(error, 'there was an issue hidding this PSP');
          });
      });
  },
  loadProServices({ commit }) {
    return this.http
      .get('tag_categories.json?type=0')
      .then(response => {
        let services = response.data.filter(
          item => item.title === 'Pro Service - Small'
        );
        commit(types.SET_PRO_SERVICES, services[0].tags);
      })
      .catch(error => {
        console.error(error, 'there was an issue loading PSP');
      });
  },
  loadProServiceCategories({ commit }) {
    return this.http
      .get('tag_categories.json?type=2')
      .then(response => {
        commit(
          types.SET_CURRENT_TAG_CATEGORIES,
          response.data.sort((a, b) => a.order - b.order)
        );
      })
      .catch(error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      });
  },
  loadProServiceRequirements({ commit }, { reservationId }) {
    return this.http
      .get(`reservations/${reservationId}/pro_service_requirements.json`)
      .then(response => {
        commit(
          types.SET_PRO_SERVICE_REQUIREMENTS,
          response.data.pro_service_requirements.sort((a, b) => a.id - b.id)
        );
      });
  },
  hideProService({ dispatch }, { reservationId, pspRequirementId, reason }) {
    return this.http
      .patch(
        `reservations/${reservationId}/pro_service_requirements/${pspRequirementId}.json`,
        {
          pro_service_requirement: {
            display: false,
            hide_reason: reason,
          },
        }
      )
      .then(() => {
        dispatch('loadProServiceRequirements', {
          reservationId: reservationId,
        }).then(() => {
          return;
        });
      })
      .catch(error => {
        console.error(error, 'there was an issue hidding this PSP');
      });
  },
  loadProServiceProfiles({ commit }, userId) {
    return this.http
      .get(`users/${userId}/pro_service_profiles.json`)
      .then(response => {
        commit(
          types.SET_CURRENT_PROFILE_TRAITS,
          response.data.pro_service_profiles
        );
      })
      .catch(error => {
        commit(`errors/${types.SET_ERRORS}`, error.response.data, {
          root: true,
        });
      });
  },
  submitChosenProfileTraits(
    { dispatch },
    { userId, selectedList, displayName, sampleWork, images, measurements }
  ) {
    let arrayParams = selectedList.map(item => {
      return { tag_id: item.id };
    });

    return this.http
      .post(`users/${userId}/pro_service_profiles.json`, {
        pro_service_profile: {
          is_active: true,
          is_visible: true,
          name: displayName,
          sample_work: sampleWork,
          ...(!!measurements && { measurements: measurements }),
          pro_service_profile_tags: arrayParams,
          images: images,
        },
      })
      .then(response => {
        dispatch('loadProServiceProfiles', userId);
        return response;
      })
      .catch(error => {
        console.error('error', error);
        return error;
      });
  },
  updateProfileTraits(
    { dispatch },
    {
      userId,
      profileId,
      selectedList,
      isActive,
      displayName,
      sampleWork,
      images,
      measurements,
    }
  ) {
    let arrayParams = [];
    if (selectedList) {
      arrayParams = selectedList.map(item => {
        return { tag_id: item.id };
      });
    }
    return this.http
      .put(`users/${userId}/pro_service_profiles/${profileId}.json`, {
        pro_service_profile: {
          name: displayName,
          sample_work: sampleWork,
          ...(!!measurements && { measurements: measurements }),
          ...(!!selectedList && { pro_service_profile_tags: arrayParams }),
          ...((isActive || isActive === false) && { is_active: isActive }),
          ...(!!images && { images: images }),
        },
      })
      .then(response => {
        dispatch('loadProServiceProfiles', userId);
        return response;
      })
      .catch(error => {
        console.error('error', error);
        return error;
      });
  },
  submitChosenTraits(
    { dispatch },
    { reservationId, proServiceRequirementId, selectedList }
  ) {
    let arrayParams = selectedList.map(item => {
      return { tag_id: item.id };
    });
    return this.http
      .put(
        `reservations/${reservationId}/pro_service_requirements/${proServiceRequirementId}.json`,
        {
          pro_service_requirement: {
            pro_service_requirement_tags: arrayParams,
            status: 'submitted',
          },
        }
      )
      .then(() => {
        dispatch('loadProServiceRequirements', {
          reservationId: reservationId,
        });
      })
      .catch(error => {
        console.error('error', error);
      });
  },
  loadProServiceProvider({ commit, rootState }) {
    let accountOwnerId = rootState.account.owner.id;
    return this.http
      .get(`users/${accountOwnerId}.json`)
      .then(response => {
        commit(types.SET_PRO_SERVICE_PROVIDER_DETAILS, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  updateProServiceProfile(
    { dispatch, rootState },
    { arrivedLateCount, lateCancelCount, note }
  ) {
    let accountOwnerId = rootState.account.owner.id;
    return this.http
      .put(`users/${accountOwnerId}.json`, {
        arrived_late_count: arrivedLateCount,
        late_cancel_count: lateCancelCount,
        pro_service_provider_note: note,
      })
      .then(() => {
        return dispatch('loadProServiceProvider');
      })
      .catch(error => {
        console.error(error);
      });
  },
  batchUpdateProServiceProfiles({ dispatch, rootState }, { isActive }) {
    let accountOwnerId = rootState.account.owner.id;
    return this.http
      .put(
        `users/${accountOwnerId}/pro_service_profiles/batch_update_profiles.json`,
        { is_active: isActive }
      )
      .then(response => {
        dispatch('loadProServiceProfiles', accountOwnerId);
        return response;
      })
      .catch(error => {
        return error;
      });
  },
  deleteProServiceProfile({ dispatch }, { userId, profileId }) {
    return this.http
      .delete(`users/${userId}/pro_service_profiles/${profileId}.json`)
      .then(response => {
        dispatch('loadProServiceProfiles', userId);
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
