<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import AddOnV2 from './AddOnV2.vue';

const props = defineProps({
  asset: Object,
  showAddOnDescriptions: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const showAddOnInfoModal = ref(false);

const setAddOnModalVisibility = value => {
  showAddOnInfoModal.value = value;
};

const usingPreferredCredit = computed(() => props.asset.using_preferred_credit);

const mediaAddOns = computed(() => {
  return props.asset?.media_add_on_line_items ?? [];
});
</script>

<template>
  <div class="add-ons-list">
    <h3 class="u-subheader--heavy">
      add-ons
      <button
        v-if="showAddOnDescriptions"
        class="u-button-reset"
        @click="setAddOnModalVisibility(true)"
      >
        <SoonaIcon name="circle-question" size="medium" />
        <span class="u-visually-hidden">view description</span>
      </button>
    </h3>
    <AddOnV2
      v-for="mediaAddOn in mediaAddOns"
      :key="mediaAddOn.product.id"
      class="add-on"
      :account-id="asset.account_id"
      :asset-type="asset.asset_type"
      :asset-id="asset.id"
      :is-photo="asset.is_photo"
      :media-add-on-line-item="mediaAddOn"
      :reservation-id="asset.reservation_id"
      :read-only="readOnly"
      :using-preferred-credit="usingPreferredCredit"
    />
    <SoonaDialog
      v-if="showAddOnInfoModal"
      @close="() => setAddOnModalVisibility(false)"
    >
      <template #heading>add-ons</template>
      <template v-if="mediaAddOns">
        <p>add some extra oomph to your images!</p>
        <div v-for="mediaAddOn in mediaAddOns" :key="mediaAddOn.product.id">
          <strong>{{ mediaAddOn.product.name }}</strong>
          <p class="mb-m">{{ mediaAddOn.product.description }}</p>
        </div>
      </template>
      <template #footer="{ close }">
        <SoonaButton data-cypress="button-dialog-confirm" @on-click="close">
          ok!
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.add-ons-list {
  .add-on {
    border-radius: variables.$control-radius;
    display: flex;
    align-items: flex-start;
    margin: 0.625rem 0;
    position: relative;

    .add-ons-checkbox {
      margin: 2px 5px 0 0;
    }

    .prevent-click {
      pointer-events: none;
    }
  }
}
</style>
