<script setup>
import { computed, onUnmounted } from 'vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';
import BillingInfo from '@/components/checkout/BillingInfo.vue';
import { useFlag } from '@/composables/useFlag';
import { useSalesTax } from '@/composables/sales_tax/useSalesTax';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import EstimatedSalesTax from '@/components/sales_tax/EstimatedSalesTax.vue';

const props = defineProps({
  hasShopifyIntegration: {
    type: Boolean,
    required: true,
  },
  productsList: {
    type: Array,
    default: () => [],
  },
  preSalesTaxSubtotal: {
    type: Number,
    required: true,
  },
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const emits = defineEmits(['submit']);

const paymentsSalesTaxFlag = useFlag('payments_sales_tax');

const accountId = computed(() => props.accountId);
const preSalesTaxSubtotal = computed(() => props.preSalesTaxSubtotal);
const productsList = computed(() => props.productsList);
const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const salesTaxStore = useSalesTaxStore();

const submitPayment = (
  paymentMethodId,
  paymentMethodType,
  savePaymentMethod,
  shopifyStore
) =>
  emits(
    'submit',
    paymentMethodId,
    paymentMethodType,
    savePaymentMethod,
    shopifyStore
  );

const {
  isLoading: isCalculatingSalesTax,
  taxAmountExclusiveDisplay,
  totalWithSurchargeInDollars,
  totalWithSurchargeDisplay,
} = useSalesTax({ accountId, preSalesTaxSubtotal, productsList });

onUnmounted(() => {
  salesTaxStore.$reset();
});
</script>
<template>
  <BillingInfo class="billing-info" :account-id="accountId" />
  <SoonaPaymentMethods
    :on-payment-action="submitPayment"
    :stripe-payment-required="!hasShopifyIntegration"
    :is-stripe-disclaimer-below-checkout-button="true"
    :action-text="'CONFIRM & PAY'"
    :total="totalWithSurchargeInDollars"
    :account-id="accountId"
    :payment-method-types="['card', 'us_bank_account']"
    :has-wide-payment-button="true"
    action-variation="solid-black"
    :disable-confirm-button="isCalculatingSalesTax"
  >
    <template #action-block>
      <div>
        <div v-if="paymentsSalesTaxFlag" class="subtotal">
          <h4 class="subtotal__label">subtotal</h4>
          <h4 class="subtotal__amount">${{ preSalesTaxSubtotal }}</h4>
        </div>
        <div v-if="paymentsSalesTaxFlag" class="surcharge">
          <h4 class="surcharge__label">
            <EstimatedSalesTax />
          </h4>
          <h4 v-if="isCalculatingSalesTax" class="surcharge__amount">
            calculating...
          </h4>
          <h4 v-else class="surcharge__amount">
            {{ taxAmountExclusiveDisplay }}
          </h4>
        </div>
        <div class="total">
          <h4 class="total__label">TOTAL</h4>
          <h4 class="total__amount">{{ totalWithSurchargeDisplay }}</h4>
        </div>
      </div>
    </template>
    <template #footer-disclaimer>
      <p class="u-small--regular">
        our crew commitment: we believe in quality content for all. if you don’t
        LOVE your content <b>we make it right</b>. please review our
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.soona.co/refund-policy"
          class="terms-of-use-section__link"
          @click="cancellationAndRefundPolicyClicked"
          >cancellation and refund policy</a
        >. by placing an order with soona you agree to our
        <a
          href="https://soona.co/terms/"
          target="_blank"
          class="terms-of-use-section__link"
          >terms of service</a
        >

        ({{ termsOfServiceDate }}).
      </p>
    </template>
  </SoonaPaymentMethods>
</template>
<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.billing-info {
  margin-bottom: 0.75rem;
}

.total,
.subtotal,
.surcharge {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.surcharge__label {
  display: flex;
  gap: 0.25rem;
}

.total {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

  &__label {
    font-weight: 900 !important;
  }

  &__amount {
    font-weight: 800 !important;
  }
}

.terms-of-use-section {
  &__copy {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  &__link {
    text-decoration: underline;
  }

  &__mobile {
    display: block;
    margin-top: 1rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    display: block;
    margin-top: 1rem;
  }

  &__decline_order {
    text-align: center;
    margin-top: 0.5rem;
  }
}
</style>
