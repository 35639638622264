<script setup>
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import DigitalAssetStatusBadge from './DigitalAssetStatusBadge.vue';

const props = defineProps({
  digitalAsset: {
    type: Object,
    required: true,
  },
});

const isExpertPick = computed(
  () => !!props.digitalAsset?.staff_picks_collection_digital_asset?.id
);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const showRaw = computed(() => {
  const hasRawAttached = !!props.digitalAsset?.raw?.url;
  const isIndependentRawAsset =
    !props.digitalAsset.preview?.url && !!props.digitalAsset.raw?.url;

  const isRaw = hasRawAttached || isIndependentRawAsset;
  const canViewRawStatus =
    isSoonaStaff.value || props.digitalAsset?.ownership === 'customer';

  return isRaw && canViewRawStatus;
});
</script>

<template>
  <DigitalAssetStatusBadge
    v-if="isExpertPick"
    class="digital-asset-status--expert-pick"
  >
    <SoonaIcon name="trophy" />
    expert pick
  </DigitalAssetStatusBadge>
  <DigitalAssetStatusBadge v-if="showRaw">raw</DigitalAssetStatusBadge>
</template>

<style lang="scss" scoped>
@use '@/variables';

.digital-asset-status {
  &--expert-pick {
    background-color: variables.$black-default;

    svg {
      color: variables.$tangerine-60;
    }
  }
}
</style>
