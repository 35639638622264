<script setup>
import { useSlots, watch, ref, toRefs, computed } from 'vue';
import { useScroll, useResizeObserver, useDebounceFn } from '@vueuse/core';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  // TODO: extend to support vertical layout
  // direction: { type: String, default: 'horizontal' },
  overrideUContainer: { type: Boolean, default: false },
  tagName: { type: String, default: 'div' },
  noAnimation: { type: Boolean, default: false },
});

const noAnimation = computed(() => props.noAnimation);

const slots = useSlots();
const gap = 8;
const contentRef = ref();
const isOverflowing = ref(true);
const { x: scrollX, arrivedState } = useScroll(contentRef, {
  behavior: 'smooth',
});
const { left: atLeftEnd, right: atRightEnd } = toRefs(arrivedState);

const debouncedResize = useDebounceFn(() => {
  if (contentRef.value?.scrollWidth <= contentRef.value?.clientWidth) {
    isOverflowing.value = false;
    return;
  }
  isOverflowing.value = true;
}, 80);

useResizeObserver(contentRef, debouncedResize);

watch(() => slots.default(), debouncedResize, { immediate: true });

const handleScrollClick = (direction = 'right') => {
  // for now, we'll assume each card is the same width
  // TODO: scroll amount should be total width of fully visible children
  // TODO: perhaps use `useIntersectionObserver`?

  const sliderWidth = contentRef.value?.clientWidth;
  let fullyVisibleChildrenQty, childWidth, scrollAmount;
  if (contentRef.value?.children.length) {
    childWidth = contentRef.value.children[0].clientWidth;
    fullyVisibleChildrenQty = Math.max(Math.floor(sliderWidth / childWidth), 1);

    scrollAmount =
      fullyVisibleChildrenQty * childWidth +
      (fullyVisibleChildrenQty - 1) * gap;

    if (direction === 'left') {
      scrollX.value -= scrollAmount;
      return;
    }
    if (direction === 'right') scrollX.value += scrollAmount;
  }
};

const scrollPadding = computed(() => (props.overrideUContainer ? 0 : '0 1rem'));
const largeScreenPadding = computed(() =>
  props.overrideUContainer ? 0 : '0 2rem'
);
</script>

<template>
  <div class="soona-slider">
    <SoonaButton
      v-if="!atLeftEnd"
      variation="icon-gray-outline"
      size="medium"
      class="soona-slider__btn soona-slider__btn--left"
      @on-click="handleScrollClick('left')"
    >
      <SoonaIcon name="arrow-left" />
      <span class="u-visually-hidden">scroll to left</span>
    </SoonaButton>
    <SoonaButton
      v-if="!atRightEnd && isOverflowing"
      variation="icon-gray-outline"
      size="medium"
      class="soona-slider__btn soona-slider__btn--right"
      @on-click="handleScrollClick('right')"
    >
      <SoonaIcon name="arrow-right" />
      <span class="u-visually-hidden">scroll to right</span>
    </SoonaButton>
    <component
      :is="tagName"
      ref="contentRef"
      class="soona-slider__content"
      :class="{
        'u-container': !overrideUContainer,
        'soona-slider__content--animate-in': !noAnimation,
      }"
    >
      <slot />
    </component>
  </div>
</template>

<style scoped lang="scss">
@use '@/variables';

.soona-slider {
  position: relative;

  &__btn {
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 33%;
    transform: scale(0.2);
    will-change: transform;
    transition:
      opacity 0.15s ease-out,
      transform 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: 50ms;

    @media (prefers-reduced-motion: reduce) {
      transform: scale(1);
    }

    &--left {
      left: 0.25rem;
    }

    &--right {
      right: 0.25rem;
    }
  }

  &__content {
    display: flex;
    gap: 0.5rem; // if updated, update `gap` constant above
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    scroll-padding: v-bind(scrollPadding);
    position: relative;
    z-index: 0;

    :slotted(> *) {
      scroll-snap-align: start;
    }

    &--animate-in {
      :slotted(> *) {
        animation: k-fade-in 0.8s both ease-in-out;
      }
    }
  }

  @media (hover: none) {
    &__btn {
      display: none;
    }
  }

  @media (hover: hover) {
    &:hover .soona-slider__btn {
      transform: scale(1);
      opacity: 1;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__btn {
      &--left {
        left: 1rem;
      }

      &--right {
        right: 1rem;
      }
    }

    &__content {
      scroll-padding: 0 1.5rem;
    }
  }

  @media (min-width: variables.$screen-lg-min) {
    &__btn {
      &--left {
        left: 1.75rem;
      }

      &--right {
        right: 1.75rem;
      }
    }

    &__content {
      scroll-padding: 0 2rem;
    }
  }

  @media (min-width: 90rem) {
    padding: v-bind(largeScreenPadding);

    &__btn {
      &--left {
        left: 1.5rem;
      }

      &--right {
        right: 1.5rem;
      }
    }

    &__content {
      padding-left: 0;
      padding-right: 0;
      scroll-padding: 0;
    }
  }
}
</style>
