<script setup>
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

// You can generate a deep link here: https://www.canva.dev/docs/apps/deep-linking/#generate-a-deep-link
const canvaDeepLinkUrl = import.meta.env.VITE_CANVA_DEEP_LINK_URL;
</script>
<template>
  <SoonaTooltip placement="top">
    <template
      #default="{
        setRef,
        mouseenter,
        focus,
        mouseleave,
        blur,
        ariaDescribedby,
      }"
    >
      <SoonaButton
        :ref="setRef"
        :aria-describedby="ariaDescribedby"
        class="canva-btn"
        element="a"
        :href="canvaDeepLinkUrl"
        target="_blank"
        size="medium"
        variation="icon-gray-outline"
        title="open in canva"
        @mouseenter="mouseenter"
        @focus="focus"
        @mouseleave="mouseleave"
        @blur="blur"
      >
        <img src="@images/canva.svg" alt="canva logo" />
      </SoonaButton>
    </template>
    <template #tooltip-content>open in Canva</template>
  </SoonaTooltip>
</template>
<style lang="scss" scoped>
.canva-btn {
  img {
    height: 1rem;
  }
}
</style>
