import { useMutation } from '@tanstack/vue-query';
import { changeAccount } from '@/api/users';
import * as Sentry from '@sentry/vue';
import { useMe } from '@/composables/user/useMe';

/**
 */
export function useChangeAccount() {
  const { resetMe } = useMe();

  return useMutation({
    mutationFn: attributes => changeAccount(attributes),
    onSuccess: async () => {
      // reload absolutely everything
      await resetMe();
    },
    onError: error =>
      Sentry.captureException(new Error('failed to change account'), {
        extra: { error },
      }),
  });
}
