<script setup>
import { computed } from 'vue';
import { useCreateBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useCreateBagCollectionDigitalAsset';
import { useDeleteBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useDeleteBagCollectionDigitalAsset';
import { useCreateFavoritesCollectionDigitalAsset } from '@/queries/favorites-collection-digital-assets/useCreateFavoritesCollectionDigitalAsset';
import { useDeleteFavoritesCollectionDigitalAsset } from '@/queries/favorites-collection-digital-assets/useDeleteFavoritesCollectionDigitalAssets';
import { useCreateStaffPickCollectionDigitalAsset } from '@/queries/staff_picks_collection_digital_assets/useCreateStaffPicksCollectionDigitalAsset';
import { useDeleteStaffPickCollectionDigitalAsset } from '@/queries/staff_picks_collection_digital_assets/useDeleteStaffPicksCollectionDigitalAsset';
import OverlayButtonToggle from '@/components/infinite_asset_gallery/OverlayButtonToggle.vue';
import { useCapability } from '@/composables/useCapability';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';

const props = defineProps({
  digitalAsset: {
    type: Object,
    required: true,
  },
});

const accountId = computed(() => props.digitalAsset.account_id);

const { hasCapability: canFavoriteAsset } = useCapability({
  capability: 'favorite_gallery',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: canBagAsset } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: canManageCustomerCollections } = useCapability({
  capability: 'manage_customer_collections',
});

//== bag
const { data: bagCollection } = useBagCollection(accountId, {
  // frequently fetched in galleries, keep it fresh for a minute to reduce calls
  staleTime: 1000 * 60,
});
const bagCollectionId = computed(() => bagCollection.value?.id);
const bagCDAId = computed(
  () => props.digitalAsset.bag_collection_digital_asset?.id
);

const { mutate: mutateAddToBag, isPending: isAddingToBag } =
  useCreateBagCollectionDigitalAsset(bagCollectionId);

const { mutate: mutateRemoveFromBag, isPending: isRemovingFromBag } =
  useDeleteBagCollectionDigitalAsset(bagCollectionId, bagCDAId);

const isAddToBagLoading = computed(
  () => isAddingToBag.value || isRemovingFromBag.value
);
const isAddToBagDisabled = computed(() => {
  return !canBagAsset.value || isAddToBagLoading.value;
});
const isAddedToBag = computed(() => !!bagCDAId.value);

function toggleAddToBag() {
  if (!bagCollectionId.value || !canBagAsset.value) return;

  if (isAddedToBag.value) {
    mutateRemoveFromBag();
  } else {
    mutateAddToBag({
      digital_asset_id: props.digitalAsset.id,
    });
  }
}
//== end bag

// favorites
const { data: favoriteCollection } = useFavoritesCollection(accountId, {
  // frequently fetched in galleries, keep it fresh for a minute to reduce calls
  staleTime: 1000 * 60,
});
const favoriteCollectionId = computed(() => favoriteCollection.value?.id);
const favoriteCDAId = computed(
  () => props.digitalAsset.favorites_collection_digital_asset?.id
);

const { mutate: mutateAddToFavorite, isPending: isAddingToFavorites } =
  useCreateFavoritesCollectionDigitalAsset(favoriteCollectionId);

const { mutate: mutateRemoveFromFavorite, isPending: isRemovingFromFavorites } =
  useDeleteFavoritesCollectionDigitalAsset(favoriteCollectionId, favoriteCDAId);

const isFavoriteLoading = computed(
  () => isAddingToFavorites.value || isRemovingFromFavorites.value
);
const isFavoriteDisabled = computed(() => {
  return !canFavoriteAsset.value || isFavoriteLoading.value;
});
const isFavorited = computed(() => !!favoriteCDAId.value);

function toggleFavorite() {
  if (!favoriteCollectionId.value || !canFavoriteAsset.value) return;

  if (isFavorited.value) {
    mutateRemoveFromFavorite({
      digital_asset_id: props.digitalAsset.id,
    });
  } else {
    mutateAddToFavorite({
      digital_asset_id: props.digitalAsset.id,
    });
  }
}
//== end favorites

// staff picks
const { data: staffPicksCollection } = useStaffPicksCollection(accountId, {
  // frequently fetched in galleries, keep it fresh for a minute to reduce calls
  staleTime: 1000 * 60,
});
const staffPicksCollectionId = computed(() => staffPicksCollection.value?.id);
const staffPickCDAId = computed(
  () => props.digitalAsset.staff_picks_collection_digital_asset?.id
);
const { mutate: mutateAddtoStaffPicks, isPending: isAddingToStaffPicks } =
  useCreateStaffPickCollectionDigitalAsset(staffPicksCollectionId);
const {
  mutate: mutateRemoveFromStaffPicks,
  isPending: isRemovingFromStaffPicks,
} = useDeleteStaffPickCollectionDigitalAsset(
  staffPicksCollectionId,
  staffPickCDAId
);

const isStaffPicksLoading = computed(
  () => isAddingToStaffPicks.value || isRemovingFromStaffPicks.value
);
const isStaffPicksDisabled = computed(() => {
  return !canManageCustomerCollections.value || isStaffPicksLoading.value;
});
const isStaffPicked = computed(() => !!staffPickCDAId.value);

function toggleStaffPick() {
  if (!staffPicksCollectionId.value || !canManageCustomerCollections.value) {
    return;
  }

  if (isStaffPicked.value) {
    mutateRemoveFromStaffPicks({
      digital_asset_id: props.digitalAsset.id,
    });
  } else {
    mutateAddtoStaffPicks({
      digital_asset_id: props.digitalAsset.id,
    });
  }
}
//== end staff picks
</script>

<template>
  <OverlayButtonToggle
    v-if="canManageCustomerCollections"
    data-cypress="button-toggle-expert-pick"
    :disabled="isStaffPicksDisabled"
    :loading="isStaffPicksLoading"
    :icon-name="isStaffPicked ? 'trophy-solid' : 'trophy'"
    :pressed="isStaffPicked"
    visually-hidden-text="expert pick"
    @click="toggleStaffPick"
  >
    <template #tooltip-content>
      {{ isStaffPicked ? 'remove from expert picks' : 'add to expert picks' }}
    </template>
  </OverlayButtonToggle>

  <OverlayButtonToggle
    :disabled="isFavoriteDisabled"
    :loading="isFavoriteLoading"
    :icon-name="isFavorited ? 'heart-solid' : 'heart'"
    :pressed="isFavorited"
    visually-hidden-text="favorite"
    @click="toggleFavorite"
  >
    <template #tooltip-content>
      {{ isFavorited ? 'remove from favorites' : 'add to favorites' }}
    </template>
  </OverlayButtonToggle>

  <OverlayButtonToggle
    data-cypress="button-toggle-bag"
    :disabled="isAddToBagDisabled"
    :loading="isAddToBagLoading"
    :icon-name="isAddedToBag ? 'soona-bag-solid' : 'soona-bag'"
    :pressed="isAddedToBag"
    visually-hidden-text="bag"
    @click="toggleAddToBag"
  >
    <template #tooltip-content>
      {{ isAddedToBag ? 'remove from bag' : 'add to bag' }}
    </template>
  </OverlayButtonToggle>
</template>
