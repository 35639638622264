<script setup>
import { computed, toRefs, inject } from 'vue';
import { useFlag } from '@/composables/useFlag';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import OptimizeImageSizeAction from '@/components/user/anytime/listing_insights/listing_actions/actions/image_size/OptimizeImageSizeAction.vue';
import OptimizeImageSizeActionButtons from '@/components/user/anytime/listing_insights/listing_actions/actions/image_size/OptimizeImageSizeActionButtons.vue';
import { useOptimizeImageSizeActions } from '@/components/user/anytime/listing_insights/listing_actions/actions/image_size/useOptimizeImageSizeActions';
import ReplaceOnlyAction from '@/components/user/anytime/listing_insights/listing_actions/actions/replace_only/ReplaceOnlyAction.vue';
import ReplaceOnlyActionButtons from '@/components/user/anytime/listing_insights/listing_actions/actions/replace_only/ReplaceOnlyActionButtons.vue';

const props = defineProps({
  accountId: { type: [String, Number], default: undefined }, // accountId is undefined when accessed from temporary listing insights
  listingAction: { type: Object, default: () => {} },
  failingImage: { type: Object, required: true },
});

const emit = defineEmits(['showSubscriptionsDialog']);
const { buttonClicked } = useBaseEvents();
const { accountId, listingAction, failingImage } = toRefs(props);
const externalProductId = inject('externalProductId');
const integrationId = inject('integrationId');

const listingAssets = inject('listingAssets');

const failingImageAsset = computed(() => {
  return listingAssets.value.find(
    asset => asset.digital_asset_id === failingImage.value.digital_asset_id
  );
});

const ruleTitle = rule => {
  if (rule.rule === 'low resolution replace') {
    return 'low resolution';
  }
  return rule.rule;
};

const subtext = rule => {
  if (rule.rule === 'inconsistent aspect ratio') {
    return failingImageAsset.value?.aspect_ratio;
  }
  if (['low resolution', 'low resolution replace'].includes(rule.rule)) {
    return failingImageAsset.value?.resolution;
  }
  return null;
};

// listing actions module
const actionableListingDetailFlag = useFlag(
  'toaster_actionable_listing_detail'
);

const {
  isProcessing: optimizeImageSizeIsProcessing,
  handleUploadComplete,
  optimizeTriggered: optimizeImageSizeTriggered,
  linkSourceAsset: linkImageSizeActionSourceAsset,
} = useOptimizeImageSizeActions(accountId, listingAction, {
  externalProductId,
  integrationId,
});

const listingActionId = computed(() => props.listingAction?.id ?? null);
const displayListingActionButtons = computed(
  () =>
    actionableListingDetailFlag.value &&
    !!listingActionId.value &&
    listingAction.value.status === 'active' &&
    listingAction.value.listing_action_slug !== 'missing-content' &&
    externalProductId.value &&
    accountId.value
);

const hasProcessingBulkAction = computed(() => {
  return (
    listingAction.value.most_recent_bulk_action !== undefined &&
    listingAction.value.most_recent_bulk_action.status !== 'delivered'
  );
});
const displayListingAction = computed(() => {
  return (
    actionableListingDetailFlag.value &&
    listingActionId.value &&
    (['pending_approval', 'publishing', 'failed_to_publish'].includes(
      props.listingAction?.status
    ) ||
      hasProcessingBulkAction.value ||
      optimizeImageSizeIsProcessing.value)
  );
});
const listingActionComponentLookup = {
  'low-resolution': OptimizeImageSizeAction,
  replace: ReplaceOnlyAction,
};
const listingActionButtonsComponentLookup = {
  'low-resolution': OptimizeImageSizeActionButtons,
  replace: ReplaceOnlyActionButtons,
};

const { canTakeAction, availableCreditsError, userCanUpgrade } =
  useSubscriptionActionPermissions(accountId);

const handleActionClick = () => {
  if (listingAction.value.listing_action_slug === 'low-resolution') {
    if (canTakeAction.value) {
      optimizeImageSizeTriggered.value = true;
      linkImageSizeActionSourceAsset(listingAction.value.original_asset.id);
      buttonClicked({
        context: 'listing insights',
        subContext: 'listing detail page',
        buttonLabel: 'optimize',
        buttonAction: 'upscale resolution',
      });
    } else {
      emit('showSubscriptionsDialog');
      buttonClicked({
        context: 'listing insights',
        subContext: 'listing detail page',
        buttonLabel: 'optimize',
        buttonAction: 'show subscriptions dialog',
      });
    }
  } else if (listingAction.value.listing_action_slug === 'replace') {
    if (canTakeAction.value) {
      buttonClicked({
        context: 'listing insights',
        subContext: 'listing detail page',
        buttonLabel: 'upload new',
        buttonAction: 'upload new',
      });
    } else {
      emit('showSubscriptionsDialog');
      buttonClicked({
        context: 'listing insights',
        subContext: 'listing detail page',
        buttonLabel: 'upload new',
        buttonAction: 'show subscriptions dialog',
      });
    }
  }
};

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: 'listing detail page',
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

// error handling
const priorityError = usePriorityError(availableCreditsError);
</script>

<template>
  <div v-if="displayListingAction" class="listing-action__wrapper">
    <component
      :is="listingActionComponentLookup[listingAction.listing_action_slug]"
      :account-id="accountId"
      :listing-action="listingAction"
      :external-product-id="externalProductId"
      :integration-id="integrationId"
      :is-processing="optimizeImageSizeIsProcessing"
    />
  </div>
  <div v-else class="rule-result__wrapper">
    <div class="rule-result__image-icon">
      <SoonaIcon name="circle-exclamation-solid" size="small" />
    </div>
    <div class="rule-result__image">
      <img :src="failingImage.image_url" alt="" />
    </div>

    <div class="rule-result__content">
      <div
        v-for="(rule, i) in failingImage.rules"
        :key="i"
        class="rule-result__broken-rule"
      >
        <div class="rule-result__text">
          <div class="rule-result__icon">
            <SoonaIcon :name="rule.icon" />
          </div>
          <div class="rule-result__broken-rule--content">
            <p class="rule-result__title">
              {{ ruleTitle(rule) }}
              <span class="rule-result__title--subtext u-body--regular">{{
                subtext(rule)
              }}</span>
            </p>
            <p class="rule-result__text">
              {{ rule.description }}
            </p>
            <SoonaError v-if="priorityError">
              {{ priorityError }}
            </SoonaError>
          </div>
        </div>
        <div>
          <template v-if="displayListingActionButtons">
            <component
              :is="
                listingActionButtonsComponentLookup[
                  listingAction.listing_action_slug
                ]
              "
              :account-id="accountId"
              :listing-action="listingAction"
              :external-product-id="externalProductId"
              :integration-id="integrationId"
              @capture-upload-new-event="handleActionClick"
              @handle-optimize-click="handleActionClick"
              @handle-upgrade-click="handleUpgradeClick"
              @handle-upload-complete="handleUploadComplete($event)"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-action {
  &__wrapper {
    padding: 1rem 0rem;
  }
}

.rule-result {
  &__content {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-flow: column;
    gap: 1rem;
    position: relative;
    padding: 1rem 0rem;

    &:not(:last-child) {
      border-bottom: 0.0625rem solid variables.$gray-30;
    }
  }

  &__image-icon {
    color: variables.$friendly-red-50;
    position: absolute;
    top: 0.375rem;
    left: -0.5625rem;
  }

  &__image {
    width: 9.25rem;
    height: 9.25rem;
    flex: 0 0 9.25rem;

    img {
      border-radius: 0.3125rem;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__text {
    flex: 0 1 75%;
    display: flex;
    gap: 0.5rem;
  }

  &__broken-rule {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;

    &--content {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  &__title {
    @include variables_fonts.u-body--heavy;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__wrapper {
      flex-direction: row;
    }
  }
}
</style>
