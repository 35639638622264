export const paths = [
  {
    path: '/account/:accountId/products',
    name: 'product-catalog-parent',
    component: () =>
      import('src/components/user/anytime/products/ProductsPage.vue'),
    meta: {
      page_title: 'products',
      context: 'account products',
      requires_auth: true,
      requires_capability: {
        capability: 'manage_catalog_items',
        subjectType: 'account',
        subjectId: to => to.params.accountId,
      },
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId,
      accountId: route.params.accountId,
    }),
    children: [
      {
        path: '',
        name: 'product-catalog',
        component: () =>
          import('src/components/user/anytime/products/ProductCatalog.vue'),
        meta: {
          requires_auth: true,
          requires_capability: {
            capability: 'manage_catalog_items',
            subjectType: 'account',
            subjectId: to => to.params.accountId,
          },
        },
      },
      {
        path: 'inventory',
        name: 'product-inventory',
        component: () =>
          import(
            'src/components/user/anytime/products/inventory/ProductInventory.vue'
          ),
        meta: {
          context: 'account inventory',
          requires_auth: true,
          requires_capability: {
            capability: 'manage_catalog_items',
            subjectType: 'account',
            subjectId: to => to.params.accountId,
          },
        },
      },
    ],
  },
  {
    path: '/account/:accountId/products/new',
    name: 'create-product',
    component: () =>
      import(
        'src/components/user/anytime/products/product-page/CreateProductPage.vue'
      ),
    meta: {
      requires_auth: true,
      routeLayoutOptions: {
        noContainer: true,
      },
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId,
      accountId: route.params.accountId,
    }),
  },
  {
    path: '/account/:accountId/products/:productId',
    name: 'product-root',
    component: () =>
      import(
        'src/components/user/anytime/products/product-page/ProductRoot.vue'
      ),
    meta: {
      context: 'product detail',
      requires_auth: true,
    },
    props: route => ({
      // force the component to unmount and mount again when the accountId changes
      key: route.params.accountId + route.params.productId,
      accountId: route.params.accountId,
      productId: route.params.productId,
    }),
    children: [
      {
        path: '',
        name: 'product-detail',
        component: () =>
          import(
            'src/components/user/anytime/products/product-page/ProductDetailPage.vue'
          ),
        meta: {
          context: 'product detail',
          requires_auth: true,
          requires_capability: {
            capability: 'manage_catalog_items',
            subjectType: 'account',
            subjectId: to => to.params.accountId,
          },
          routeLayoutOptions: {
            noContainer: true,
          },
        },
        props: route => ({
          // force the component to unmount and mount again when the accountId changes
          key: route.params.accountId + route.params.productId,
          accountId: route.params.accountId,
          productId: route.params.productId,
        }),
      },
      {
        path: '/account/:accountId/products/:productId/variants',
        name: 'product-variants',
        component: () =>
          import('src/components/user/anytime/products/ProductVariants.vue'),
        meta: {
          context: 'product variants',
          requires_auth: true,
          requires_capability: {
            capability: 'manage_catalog_items',
            subjectType: 'account',
            subjectId: to => to.params.accountId,
          },
          routeLayoutOptions: {
            noContainer: true,
          },
        },
        props: route => ({
          // force the component to unmount and mount again when the accountId changes
          key: route.params.accountId + route.params.productId,
          accountId: route.params.accountId,
          productId: route.params.productId,
        }),
      },
      {
        path: '/account/:accountId/products/:productId/listing-insights/integration/:integrationId/external-product/:externalProductId/active-listing',
        component: () =>
          import(
            '@/components/user/anytime/listing_insights/listing_detail/ListingDetail.vue'
          ),
        meta: {
          context: 'listing insights listing detail',
          requires_auth: true,
          show_quest: true,
          routeLayoutOptions: {
            noContainer: true,
          },
        },
        props: route => ({
          // force the component to unmount and mount again when the accountId changes
          key:
            route.params.accountId +
            route.params.productId +
            route.params.integrationId +
            route.params.externalProductId,
          accountId: route.params.accountId,
          productId: route.params.productId, // catalog_item_id
          integrationId: route.params.integrationId,
          externalProductId: route.params.externalProductId,
        }),
        children: [
          {
            path: '',
            name: 'listing-optimize',
            component: () =>
              import(
                '@/components/user/anytime/listing_insights/listing_detail/ListingOptimize.vue'
              ),
            meta: {
              context: 'listing insights listing detail',
              requires_auth: true,
            },
          },
          {
            path: ':pathmatch(.*)*',
            name: 'notFound',
            redirect: () => {
              return { name: 'listing-optimize' };
            },
          },
          {
            path: 'variants',
            name: 'listing-variants',
            component: () =>
              import(
                '@/components/user/anytime/listing_insights/listing_detail/VariantListings.vue'
              ),
            meta: {
              context: 'listing insights listing detail variants',
              requires_auth: true,
            },
          },
          {
            path: ':pathmatch(.*)*',
            name: 'notFound',
            redirect: () => {
              return { name: 'listing-optimize' };
            },
          },
        ],
      },
    ],
  },

  {
    path: '/account/:accountId/products/:productId/asset/:digitalAssetId',
    name: 'product-assets-media-view',
    component: () =>
      import('@/components/user/anytime/products/ProductAssetsAssetEditor.vue'),
    meta: {
      context: 'product detail',
      requires_auth: true,
      requires_capability: {
        capability: 'manage_catalog_items',
        subjectType: 'account',
        subjectId: to => to.params.accountId,
      },
    },
    props: route => ({
      accountId: route.params.accountId,
      productId: route.params.productId,
      digitalAssetId: route.params.digitalAssetId,
    }),
  },
];
