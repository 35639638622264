<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';
import { useRoute } from 'vue-router';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import UploadAssetCard from '@/components/account_gallery/UploadAssetCard.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import { useMe } from '@/composables/user/useMe';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const accountId = computed(() => +props.accountId);
const { currentAccountId: authedAccountId } = useMe();
const isMyAccount = computed(() => authedAccountId.value === accountId.value);

const { data: digitalAssets, isPending: isDigitalAssetsLoading } =
  useDigitalAssets({
    accountId,
    itemsPerPage: 10,
    filters: {
      origin: ['customer', 'soona'],
      ownership: 'customer',
      media_type: 'photo',
      visibility: 'all',
    },
  });

const allAssets = computed(() => digitalAssets.value?.assets);

const isLoading = computed(() => isDigitalAssetsLoading.value);

const trackClick = (label, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'recent assets',
    linkLabel: label,
    linkHref: href,
  });
};
</script>

<template>
  <CarouselLoading v-if="isLoading" />
  <section
    v-else-if="isMyAccount || allAssets?.length > 0"
    class="assets"
    data-cypress="section-assets"
  >
    <div class="assets__heading">
      <h2 id="assets" class="u-title--heavy" data-cypress="text-assets-heading">
        recent assets
      </h2>
      <SoonaButton
        element="router-link"
        :to="{ name: 'assets' }"
        variation="tertiary"
        @on-click="trackClick('see all', `/account/${accountId}/my-assets`)"
      >
        see all <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <PageContainerOffset>
      <SoonaSlider tag-name="ul" aria-labelledby="assets">
        <SoonaImageCard v-if="isMyAccount">
          <UploadAssetCard
            :account-id="accountId"
            :hide-cloud-icon="true"
            label="add media"
            label-icon="plus-large"
            sub-context="recent assets"
          />
        </SoonaImageCard>
        <SoonaImageCard
          v-for="asset in allAssets"
          :key="asset.id"
          inner-element="router-link"
          :to="{
            name: 'all-assets-media-view',
            params: { accountId: accountId, digitalAssetId: asset.id },
          }"
          @click="
            trackClick(
              'digital asset thumbnail',
              $event.currentTarget.getAttribute('href')
            )
          "
        >
          <DigitalAssetImage :src="asset.preview?.url" :alt="asset.title" />
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';

.assets {
  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
}
</style>
