import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useIntegrations } from '@/composables/useIntegrations';
import { useProratedSubscription } from '@/queries/subscriptions/useProratedSubscription';
import { toCurrency } from '@/lib/currency';
import { convertToMMDDYYYY } from '@/lib/date-formatters';
import { useFlag } from '@/composables/useFlag';

export function useSubscriptionItemProration(
  accountId,
  subscriptionPrice,
  extraParams = {}
) {
  const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');
  const { tierSlug } = extraParams;

  const { account, eligibleForPlatformSubscriptionTrial } =
    useAccount(accountId);
  const { hasShopifyIntegration } = useIntegrations(accountId);

  const activeSubscriptionId = computed(() => {
    return account.value?.subscription?.id;
  });

  const isTrialing = computed(() => account.value?.is_trialing);
  const isFastPass = computed(() => tierSlug && tierSlug === 'fast-pass');

  const showProrationPreview = computed(
    () =>
      account.value?.subscription?.tier?.slug !== 'tier-three' &&
      !!activeSubscriptionId.value &&
      !hasShopifyIntegration.value &&
      !isTrialing.value
  );

  const subscriptionPriceId = computed(() => {
    return subscriptionPrice?.id;
  });

  const {
    data: proratedData,
    isLoading: proratedDataLoading,
    isFetching: isFetchingProratedData,
    error,
  } = useProratedSubscription(activeSubscriptionId, subscriptionPriceId, {
    enabled: computed(() => showProrationPreview.value),
  });

  const prorationDate = computed(() => {
    return proratedData.value?.proration_date;
  });

  const prorationSubtotalExcludingTaxInDollars = computed(
    () => proratedData.value?.subtotal_excluding_tax
  );

  const prorationTaxAmountInDollars = computed(
    () => proratedData.value?.tax_amount
  );

  const proratedTotalInDollars = computed(() => {
    return proratedData.value?.prorated_total ?? subscriptionPrice?.unit_amount;
  });

  const proratedTotal = computed(() => {
    if (!showProrationPreview.value) return '';

    return toCurrency(proratedTotalInDollars.value, 'USD', 2);
  });

  const addOnTotal = computed(() => {
    if (!showProrationPreview.value) return '';

    return toCurrency(proratedData.value?.add_on_total, 'USD', 0);
  });

  const nextSubscriptionStart = computed(() => {
    const today = new Date();
    if (isTrialing.value)
      return convertToMMDDYYYY(account.value.subscription.trial_end);

    if (
      apolloTrialForEveryoneFlag.value &&
      eligibleForPlatformSubscriptionTrial.value &&
      !isFastPass.value
    )
      return convertToMMDDYYYY(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() + 31)
      );
    if (!showProrationPreview.value) return convertToMMDDYYYY(today);

    return convertToMMDDYYYY(proratedData.value?.next_subscription_start);
  });

  const recurringInterval = computed(() => {
    return proratedData.value?.recurring_interval ?? 'month';
  });

  const remainingTime = computed(() => {
    if (!showProrationPreview.value) return '';

    if (proratedData.value?.recurring_interval === 'year') {
      let months = proratedData.value?.current_subscription_remaining_months;

      if (months === 1) {
        return '1 month';
      } else {
        return `${months} months`;
      }
    } else {
      let days = proratedData.value?.current_subscription_remaining_days;

      if (days === 1) {
        return '1 day';
      } else {
        return `${days} days`;
      }
    }
  });

  const proratedAccountBalanceAmountInDollars = computed(() => {
    if (prorationSubtotalExcludingTaxInDollars.value > 0) return 0;

    return prorationSubtotalExcludingTaxInDollars.value * -1;
  });

  const proratedAccountBalanceAmountDisplay = computed(() => {
    if (!proratedAccountBalanceAmountInDollars.value) return '';
    return toCurrency(proratedAccountBalanceAmountInDollars.value, 'USD', 2);
  });

  return {
    addOnTotal,
    error,
    nextSubscriptionStart,
    isFetchingProratedData,
    proratedAccountBalanceAmountInDollars,
    proratedAccountBalanceAmountDisplay,
    proratedDataLoading,
    prorationDate,
    prorationSubtotalExcludingTaxInDollars,
    prorationTaxAmountInDollars,
    proratedTotalInDollars,
    proratedTotal,
    recurringInterval,
    remainingTime,
    showProrationPreview,
    subscriptionPriceId,
  };
}
