<script setup>
import { computed, toRefs } from 'vue';
import ListingDetailImages from './ListingDetailImages.vue';
import ListingInsightsHelpDialog from '../ListingInsightsHelpDialog.vue';
import SoonaDonutChart from '@/components/ui_library/SoonaDonutChart.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaMeter from '@/components/ui_library/SoonaMeter.vue';
import { Gray20 } from '@/variables.module.scss';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import CurrentIntegration from './CurrentIntegration.vue';
import { useTopOpportunities } from '@/queries/useListingActions';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';
import ListingActionCard from '@/components/user/anytime/listing_insights/dashboard/ListingActionCard.vue';
import BeatTheCompetitionCard from '@/components/user/anytime/listing_insights/dashboard/BeatTheCompetitionCard.vue';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';

const props = defineProps({
  accountId: { type: String, required: true },
  externalProductId: { type: String, required: true },
  integrationId: { type: String, required: true },
  listing: { type: Object, required: true },
  platform: { type: String, required: true },
});

defineEmits(['refreshListing']);

const { accountId, integrationId, listing, platform } = toRefs(props);

dayjs.extend(relativeTime);

// visual average
const formattedMeterData = computed(() => {
  return {
    value: props.listing.visual_average.score.value,
    label: props.listing.visual_average.score.label,
    minValue: 0,
    maxValue: 1,
    low: 0.33,
    high: 0.66,
  };
});

// score averages
const iconLookup = {
  'gallery count': 'album',
  'image quality': 'aperture',
  'visual mix': 'stars',
};

const visualAverageScore = computed(
  () => props.listing?.visual_average.score.label
);
const classNames = computed(() => {
  let classes = `listing-details-summary listing-details-summary__background--${visualAverageScore.value}`;

  return classes;
});

const scoreBreakdown = computed(() => {
  return listing.value.score_breakdown.filter(x => x.rule !== 'video');
});

// video score
const calculateLabel = score => {
  if (score < 0.33) {
    return 'poor';
  } else if (score < 0.66) {
    return 'okay';
  } else {
    return 'great';
  }
};

const videoScore = computed(() => {
  if (platform.value === 'Amazon') {
    return null;
  }
  let videoRuleResult = listing.value?.rule_results?.find(
    x => x.rule_group === 'video count'
  )?.rules[0];
  if (!videoRuleResult) {
    return null;
  }
  return {
    display_value: videoRuleResult.score * 100,
    rule: 'video count',
    score: {
      label: calculateLabel(videoRuleResult.score),
      value: videoRuleResult.score === 0 ? 0.01 : videoRuleResult.score,
    },
  };
});

// listing action cards module
const isParent = computed(() => listing.value.variant_count > 0);

const catalogItemId = computed(() => listing.value.catalog_item_id);

const enableTopOpportunities = computed(() => {
  return isParent.value && platform.value === 'Amazon';
});

const {
  data: topOpportunities,
  isLoading,
  error: topOpportunitiesError,
} = useTopOpportunities(accountId, catalogItemId, {
  enabled: enableTopOpportunities,
});

const priorityError = usePriorityError(topOpportunitiesError);
</script>

<template>
  <div v-if="listing" :class="classNames">
    <div class="listing-details-summary__header">
      <CurrentIntegration
        :integration-id="integrationId"
        :account-id="accountId"
        :listing="listing"
        @refresh-listing="$emit('refreshListing')"
      />
      <div class="listing-details-summary__asset-count">
        <p>
          {{ listing.image_count }} photo{{
            listing.image_count === 1 ? '' : 's'
          }}
          <span v-if="platform === 'Shopify'">
            · {{ listing.video_count }} video{{
              listing.video_count === 1 ? '' : 's'
            }}
          </span>
        </p>
      </div>
      <ListingDetailImages :listing="listing" />
    </div>
    <div class="listing-details-summary__scores">
      <div class="listing-details-summary__scores--heading">
        <h2 class="listing-details-summary__scores--title u-body--heavy">
          soona score
        </h2>
      </div>
      <div class="listing-details-summary__visual-performance">
        <div class="listing-details-summary__visual-performance--overall-score">
          {{ listing.visual_average.display_value }}
          <span
            class="listing-details-summary__visual-performance--denominator u-display--regular"
            >/ 100</span
          >
        </div>
        <div class="listing-details-summary__meta">
          <ListingInsightsHelpDialog />
        </div>
      </div>

      <SoonaMeter :data="formattedMeterData" accessible-title="overall score" />

      <div class="listing-details-summary__score-averages">
        <div
          v-for="category in scoreBreakdown"
          :key="category.rule"
          class="listing-details-summary__score-average"
        >
          <SoonaDonutChart
            :accessible-title="category.rule"
            :data="[category.score]"
            :display-value="category.display_value"
            label-position="right"
            size="medium"
          >
            <template #label>
              <SoonaIcon
                class="soona-donut-chart__description--icon"
                :name="iconLookup[category.rule]"
                size="medium"
              />
              <p class="u-body--regular">{{ category.rule }}</p>
            </template>
          </SoonaDonutChart>
        </div>
        <div class="listing-details-summary__score-average">
          <div class="listing-details-summary__video u-title--regular">
            <div class="listing-details-summary__video--description">
              <SoonaIcon
                class="soona-donut-chart__description--icon"
                name="video-file-play"
                size="medium"
              />
              <p class="u-body--regular">video</p>
            </div>
            <div
              v-if="videoScore"
              class="listing-details-summary__video--score"
            >
              <SoonaDonutChart
                accessible-title="video score"
                :data="[videoScore.score]"
                :display-value="videoScore.display_value"
                label-position="right"
                size="medium"
              />
            </div>
            <div v-else class="listing-details-summary__video--flag">
              <SoonaFlag
                :background-color="Gray20"
                title="coming soon"
                type="pill"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="listing-details-summary__actions">
        <SoonaError v-if="priorityError" no-margin>
          {{ priorityError }}
        </SoonaError>
        <CarouselLoading v-if="isLoading" />
        <PageContainerOffset>
          <SoonaSlider v-if="topOpportunities" tag-name="ul">
            <ListingActionCard
              v-for="listingAction in topOpportunities"
              :key="listingAction.id"
              :listing-action="listingAction"
              :catalog-item-id="catalogItemId"
            />
            <BeatTheCompetitionCard :account-id="accountId" />
          </SoonaSlider>
        </PageContainerOffset>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.listing-details-summary {
  padding: 1rem 1.5rem; // to do - check what this should be for mobile

  &__background {
    &--poor {
      background-color: variables.$roses-20;
    }

    &--okay {
      background-color: variables.$tangerine-10;
    }

    &--great {
      background-color: variables.$avo-toast-20;
    }
  }

  &__flex-space-between {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }

  &__header {
    margin-bottom: 1rem;
  }

  &__asset-count {
    color: variables.$black-translucent-60;
    margin-bottom: 2rem;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__scores {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__visual-performance {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    &--overall-score {
      font-size: 4.5rem;
      font-weight: 800;
      line-height: 5rem;
      letter-spacing: 0.015625rem;
    }

    &--denominator {
      color: variables.$gray-60;
      margin-left: -0.5rem;
    }
  }

  &__score-averages {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__score-average {
    color: variables.$black-default;
    flex: 1 0 0;
    background-color: variables.$white-default;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    min-width: 15rem;
    padding: 1rem 0;
  }

  &__video {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;

    gap: 0.5rem;

    &--description {
      display: flex;
      align-items: center;
      gap: 0.25rem;
    }

    &--flag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 4rem;
      padding-right: 1rem;
    }
  }

  &__meta {
    padding-top: 1rem;
  }

  &__updated-at {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 0.5rem;

    p {
      color: variables.$gray-60;
    }
  }

  &__actions {
    margin-top: 3.5rem;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    padding: 1.5rem 2rem;
  }
}
</style>
