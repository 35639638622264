import { useQuery } from '@tanstack/vue-query';
import { getDefaultPayment } from '@/api/accounts';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

/**
 * @param {MaybeRef<string>} accountId
 * @param {Object} [queryOptions]
 */
export function useDefaultPayment(accountId, queryOptions = undefined) {
  return useQuery({
    queryKey: queryKeys.defaultPayment(accountId),
    queryFn: ({ signal }) => getDefaultPayment(unref(accountId), signal),
    ...queryOptions,
  });
}
