import { computed, ref, toValue, watch, watchEffect } from 'vue';
import { useCreateSalesTax } from '@/queries/sales_tax/useCreateSalesTax';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useFlag } from '@/composables/useFlag';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { storeToRefs } from 'pinia';

/**
 * @param {Ref<Number|String>} accountId
 * @param {Ref<Number|String>} preSalesTaxSubtotal
 * @param {Ref<Array>} productsList
 * @param {Ref<Number|String>} discountId
 * @param {Ref<Boolean>} calculate
 * */
export function useSalesTax({
  accountId,
  preSalesTaxSubtotal,
  productsList = ref([]),
  discountId = ref(null),
  calculate = ref(true),
}) {
  const salesTaxStore = useSalesTaxStore();
  const paymentsSalesTaxFlag = useFlag('payments_sales_tax');
  const {
    salesTaxBillingAddressId,
    stripeSalesTaxCalculationId,
    taxAmountExclusiveInCents,
    taxAmountExclusiveDisplay,
    totalWithSurchargeInDollars,
    totalWithSurchargeDisplay,
  } = storeToRefs(salesTaxStore);

  const { data: account } = useAccountQuery(accountId, {
    enabled: computed(() => !!accountId.value),
  });

  const billingAddressId = computed(() => {
    return account.value?.billing_address?.id ?? null;
  });

  const enabled = computed(() => {
    if (!toValue(calculate)) return false;
    const breakdownIsIncluded = toValue(productsList)?.length > 0;

    return (
      paymentsSalesTaxFlag.value &&
      !!billingAddressId.value &&
      (!!preSalesTaxSubtotal.value ||
        (!preSalesTaxSubtotal.value && breakdownIsIncluded)) &&
      toValue(calculate)
    );
  });

  const {
    data: response,
    error,
    isPending,
    isFetching,
  } = useCreateSalesTax(
    {
      accountId,
      orderTotal: preSalesTaxSubtotal,
      billingAddressId,
      discountId,
      productsList,
    },
    {
      enabled,
      retry: 0,
      staleTime: 0,
      gcTime: 0,
    }
  );

  // todo: when checkout is refactored, look into having this call be at the top level and provide down to the child components
  watchEffect(() => {
    if (error.value || !response.value) {
      salesTaxStore.$reset();
    } else {
      salesTaxStore.setStripeSalesTaxCalculationResponse(response.value);
    }
  });

  watch(
    preSalesTaxSubtotal,
    () => {
      salesTaxStore.setPreSalesTaxSubtotalInDollars(preSalesTaxSubtotal.value);
    },
    { immediate: true }
  );

  const isLoading = computed(() => {
    if (!enabled.value) return false;
    return isFetching.value || isPending.value;
  });

  watch(
    isLoading,
    () => {
      salesTaxStore.setIsCalculatingSalesTax(isLoading.value);
    },
    { immediate: true }
  );

  // payments_todo: why does watch not work??
  watchEffect(() => {
    if (
      salesTaxStore.salesTaxBillingAddressId?.value !==
      account.value?.billing_address?.id
    ) {
      salesTaxStore.setSalesTaxBillingAddressId(
        account.value?.billing_address?.id
      );
    }
  });

  // make sure to call salesTaxStore.$reset() when the component unmounts
  return {
    error,
    isLoading,
    salesTaxBillingAddressId,
    stripeSalesTaxCalculationId,
    taxAmountExclusiveInCents,
    taxAmountExclusiveDisplay,
    totalWithSurchargeInDollars,
    totalWithSurchargeDisplay,
  };
}
