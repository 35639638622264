<script setup>
import SidebarIcon from './SidebarIcon.vue';
import { useSiteNavEvents } from '@/components/platform-layout/useSiteNavEvents';

const { trackLinkClicked } = useSiteNavEvents();
</script>

<template>
  <form action="/users/sign_out" method="post">
    <button
      type="submit"
      class="sidebar-sign-out-button"
      @click="trackLinkClicked('sign out', `/users/sign_out`)"
    >
      <SidebarIcon
        name="arrow-right-from-bracket"
        class="sidebar-sign-out-button__icon"
      />
      sign out
    </button>
  </form>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.sidebar-sign-out-button {
  @include variables_fonts.u-label--regular;

  background: none;
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  color: variables.$black-default;
  transition: background-color 0.1s ease-out;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  border-radius: 0.3125rem;
  text-transform: lowercase;
  --accent-color: #{variables.$bubbletape-pink-20};

  &__icon {
    height: 1.25rem;
    width: 1.25rem;
    flex-shrink: 0;
  }

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
  }

  &:active {
    background-color: variables.$bubbletape-pink-20;
    --accent-color: #{variables.$white-default};
  }

  &:hover:not(:active) {
    background-color: variables.$bubbletape-pink-10;
  }
}
</style>
