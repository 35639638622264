<script setup>
import { computed, reactive, toRef, ref } from 'vue';
import {
  useCreateShotTemplate,
  useUpdateShotTemplate,
} from '@/queries/useShotTemplate';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSelectedShotOptions } from '@/components/user/anytime/scene_selector/useSelectedShotOptions';
import { useTagCategories } from '@/queries/useTagCategories';
import PackImageUploader from '@/components/crew/pack_builder/PackImageUploader.vue';
import SceneBuilderActions from '@/components/user/anytime/scene_selector/SceneBuilderActions.vue';
import SceneOption from '@/components/user/anytime/scene_selector/SceneOption.vue';
import SceneEnvironmentSelector from '@/components/user/anytime/scene_selector/SceneEnvironmentSelector.vue';
import SceneSection from '@/components/user/anytime/scene_selector/SceneSection.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
const props = defineProps({
  editMode: {
    type: Boolean,
    required: true,
  },
  lastSceneOrderNumber: {
    type: Number,
    required: false,
  },
  originalSceneNumber: {
    type: Number,
    required: false,
  },
  packTagId: {
    type: Number,
    required: true,
  },
  packId: {
    type: String,
    required: true,
  },
  packCategory: {
    type: String,
    required: true,
  },
  sceneToCopy: {
    type: Object,
    required: false,
  },
  shotListLength: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['closeSceneBuilder']);

const shotListLength = computed(() => props.shotListLength);
const editMode = computed(() => props.editMode);

const { data: shotTypeOptions } = useTagCategories({
  type: 1,
});

const contentTypeOptions = computed(
  () => shotTypeOptions.value?.find(x => x.title === 'Content').tags ?? []
);
const environmentTypeOptions = computed(
  () => shotTypeOptions.value?.find(x => x.title === 'Environment').tags ?? []
);
const packCategory = computed(() => props.packCategory);

// shot options module
const { contentType, environmentType, environmentFollowup, description } =
  useSelectedShotOptions(
    toRef(() => props.sceneToCopy),
    packCategory,
    contentTypeOptions,
    environmentTypeOptions
  );

const selectedShotOptions = reactive({
  contentType: contentType,
  environmentType: environmentType,
  environmentFollowup: environmentFollowup,
});

const isSelected = (type, id) => {
  if (Array.isArray(selectedShotOptions[type])) {
    return selectedShotOptions[type].includes(id);
  }
  return selectedShotOptions[type] === id;
};

const selectedContentTypeTag = computed(() =>
  contentTypeOptions.value?.find(x => x.id === selectedShotOptions.contentType)
);

const environmentFollowupOptions = computed(() => {
  const followUpCategories =
    environmentTypeOptions.value?.find(
      x => x.id === selectedShotOptions.environmentType
    )?.tag_follow_up_categories ?? [];
  return followUpCategories.reduce((options, category) => {
    const tag = shotTypeOptions.value?.find(
      tagCategory => tagCategory.id === category.tag_category_id
    );
    if (tag) {
      options.push(...tag.tags);
    }
    return options;
  }, []);
});

const selectContentType = event => {
  selectedShotOptions.contentType = Number(event.target.value);
};

// create or update shot module
const packTagId = computed(() => props.packTagId);
const packId = computed(() => props.packId);

const {
  mutate: createShotTemplate,
  isPending: isLoadingCreateShotTemplate,
  error: createShotTemplateError,
} = useCreateShotTemplate(packId);

const {
  mutate: updateShotTemplate,
  isPending: isLoadingUpdateShotTemplate,
  error: updateShotTemplateError,
} = useUpdateShotTemplate(packId);

const closeSceneBuilder = sceneId => {
  emit('closeSceneBuilder', sceneId);
};

// scene image
const referenceImageTagCategoryId = computed(
  () => shotTypeOptions.value?.find(x => x.title === 'Reference Image')?.id
);

const currentReferenceImage = computed(() =>
  editMode.value
    ? props.sceneToCopy?.shot_tags.find(
        x => x.tag.tag_category_title === 'Reference Image'
      )
    : undefined
);

const order = computed(() =>
  props.lastSceneOrderNumber ? props.lastSceneOrderNumber + 10 : 10
);

const referenceImageBlob = ref(undefined);
const referenceImageUrl = ref(currentReferenceImage.value?.tag.image_url);

const setReferenceImageBlob = blob => {
  referenceImageBlob.value = blob;
  referenceImageUrl.value = blob
    ? `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`
    : currentReferenceImage.value?.tag.image_url;
};

const createOrUpdateScene = () => {
  const envTypeTag = environmentTypeOptions.value?.find(
    x => x.id === selectedShotOptions.environmentType
  );
  const envFollowupTag = environmentFollowupOptions.value?.find(
    x => x.id === selectedShotOptions.environmentFollowup
  );
  const environmentTags = [];
  if (envTypeTag) environmentTags.push(envTypeTag.id);
  if (envTypeTag && envFollowupTag) environmentTags.push(envFollowupTag.id);
  const body = {
    tag_id: packTagId.value,
    description: description.value,
    ...(!!referenceImageBlob.value && {
      tags_attributes: [
        {
          image: referenceImageBlob.value.signed_id,
          tag_category_id: referenceImageTagCategoryId.value,
          title: referenceImageBlob.value.filename,
          display_rule: 'false',
          order: editMode.value ? props.sceneToCopy.order : order.value,
        },
      ],
    }),
  };
  if (editMode.value) {
    const tagIds = [selectedContentTypeTag.value?.id, ...environmentTags];
    if (!referenceImageBlob.value && currentReferenceImage.value)
      tagIds.push(currentReferenceImage.value.tag_id);
    body.id = props.sceneToCopy.id;
    body.tag_ids = tagIds;
    updateShotTemplate(body, {
      onSuccess: () => {
        closeSceneBuilder();
      },
    });
  } else {
    const contentTypeParam = {
      tag_id: selectedContentTypeTag.value?.id,
    };
    const mappedTags = environmentTags.map(id => ({
      tag_id: id,
    }));
    const attributes = [contentTypeParam, ...mappedTags];

    body.order = order.value;
    body.shot_template_tags_attributes = attributes;
    createShotTemplate(body, {
      onSuccess: data => {
        closeSceneBuilder(data.id);
      },
    });
  }
};

const priorityError = usePriorityError(
  createShotTemplateError,
  updateShotTemplateError
);

const iconName = computed(() =>
  selectedContentTypeTag.value?.title === 'Video' ? 'video' : 'camera'
);
</script>
<template>
  <SoonaForm
    class="pack-scene-builder"
    aria-labelledby="scene-builder-heading"
    @submit="createOrUpdateScene"
  >
    <div class="pack-scene-builder__header">
      <div class="pack-scene-builder__title">
        <SoonaIcon :name="iconName" size="medium" />
        <h4 id="scene-builder-heading" class="u-subheader--heavy">
          scene {{ editMode ? originalSceneNumber : shotListLength + 1 }}
        </h4>
      </div>
      <SceneBuilderActions
        :is-loading="isLoadingCreateShotTemplate || isLoadingUpdateShotTemplate"
        @close-scene-builder="closeSceneBuilder"
      />
    </div>
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div class="pack-scene-builder__content">
      <SceneSection component="fieldset">
        <template #heading>content type</template>
        <template #description>pick one</template>
        <SceneOption
          v-for="option in contentTypeOptions"
          :id="option.id"
          :key="option.id"
          :image-url="option.image_url"
          :checked="isSelected('contentType', option.id)"
          :value="option.id"
          type="radio"
          name="scene-content-type"
          is-lowercase
          @change="selectContentType"
        >
          {{ option.title }}
        </SceneOption>
      </SceneSection>
      <SceneSection component="fieldset">
        <template #heading>environment</template>
        <template #description>pick one</template>
        <SceneEnvironmentSelector
          v-model:environment-type="selectedShotOptions.environmentType"
          v-model:environment-followup="selectedShotOptions.environmentFollowup"
          :environment-type-options="environmentTypeOptions"
          :environment-followup-options="environmentFollowupOptions"
        />
      </SceneSection>
      <SceneSection component="fieldset">
        <template #heading>description</template>
        <SoonaTextfield
          v-model="description"
          class="pack-scene-builder__description"
          element="textarea"
          placeholder="notes on the goals for this scene"
          :maxlength="500"
        >
          <template #helper>optional</template>
        </SoonaTextfield>
      </SceneSection>
      <SceneSection component="fieldset">
        <template #heading>reference image</template>
        <PackImageUploader
          image-type="scene"
          :set-pack-image-blob="setReferenceImageBlob"
          :pack-image-url="referenceImageUrl"
        />
      </SceneSection>
    </div>
    <SoonaError v-if="priorityError" aria-hidden="true">
      {{ priorityError }}
    </SoonaError>
    <SceneBuilderActions
      class="pack-scene-builder__footer-actions"
      :is-footer="true"
      :is-loading="isLoadingCreateShotTemplate || isLoadingUpdateShotTemplate"
      @close-scene-builder="closeSceneBuilder"
    />
  </SoonaForm>
</template>
<style lang="scss" scoped>
@use '@/variables';
.pack-scene-builder {
  width: 100%;

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
    margin-bottom: 1.5rem;
  }

  &__title {
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__description {
    padding-bottom: 0;
  }

  &__footer-actions {
    padding: 1rem 0;
  }
}
</style>
