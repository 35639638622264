<script setup>
import { toRefs, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';

import SoonaUploadV2 from '@/components/uploader/SoonaUploadV2.vue';
import SoonaMediumImageBanner from '@/components/ui_library/SoonaMediumImageBanner.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

import { BubbletapePink60 } from 'src/variables.module.scss';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: { type: [Number, String], required: true },
});

const { accountId } = toRefs(props);

const route = useRoute();
const router = useRouter();
const { linkClicked } = useBaseEvents();
const { addToast } = useSoonaToast();

const { mutate: createDigitalAsset } = useCreateDigitalAsset(accountId);

const apolloGifBannerFlag = useFlag('apollo_ai_studio_gif_banner');

const acceptedFileTypes = ref('.png,.jpg,.jpeg');
const isRedirecting = ref(false);

const uploadErrorToast = () => {
  addToast(`Your file couldn't be loaded`, {
    variation: 'error',
  });
};

const trackClick = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'homepage AIStudio banner',
    linkLabel: 'create custom scene',
    linkHref: '',
  });
  isRedirecting.value = true;
};

const sendFileToAIStudio = blob => {
  trackClick();

  const digitalAsset = {
    asset_type: 'original',
    file: blob.signed_id,
    title: blob.filename,
    media_type: 'photo',
    ownership: 'customer',
    origin: 'customer',
    origin_source: 'manual_upload',
    visibility: 'all',
  };

  createDigitalAsset(digitalAsset, {
    onSuccess: data => {
      if (data.id) {
        const params = {
          accountId: accountId.value,
        };
        params.digitalAssetId = data.id;

        router.push({
          name: 'uploads-assets-media-view',
          params: params,
          query: { tool: 'ai-scenes' },
        });
      } else {
        uploadErrorToast();
        isRedirecting.value = false;
      }
    },
    onError: () => {
      uploadErrorToast();
      isRedirecting.value = false;
    },
  });
};
</script>
<template>
  <Teleport to="body">
    <div v-if="isRedirecting" id="loading-overlay">
      we're processing your image upload. almost done!
      <beat-loader :color="BubbletapePink60" size="0.75em" />
    </div>
  </Teleport>
  <SoonaMediumImageBanner id="ai-studio-banner" background-color="#fbefd5">
    <template #title>create any scene with AI studio</template>
    <template #content>
      upload a product photo and create custom scenes with our new AI studio.
    </template>
    <template v-if="apolloGifBannerFlag" #image>
      <img src="@images/mokker_animation.gif" alt="" />
    </template>
    <template v-else #image>
      <img src="@images/ai_editor_4@2x.jpg" alt="" />
    </template>
    <template #button>
      <SoonaUploadV2
        :accept="acceptedFileTypes"
        :file-size-limit-mb="50"
        @upload-complete="sendFileToAIStudio"
        @upload-error="uploadErrorToast"
      >
        <template #default="{ onUploadClick }">
          <SoonaButton
            variation="secondary-black"
            element="a"
            @on-click="onUploadClick"
          >
            create <span class="desktop-text">custom</span> scene
          </SoonaButton>
        </template>
      </SoonaUploadV2>
    </template>
  </SoonaMediumImageBanner>
</template>

<style lang="scss">
@use '@/variables';

#loading-overlay {
  background-color: rgba(255, 255, 255, 0.92);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#ai-studio-banner {
  .medium-image-banner__details .desktop-text {
    display: none;
    @media (min-width: variables.$screen-sm-min) {
      display: inline;
    }
  }
  .medium-image-banner__img img {
    @media (max-width: variables.$screen-md-min) {
      height: 70%;
    }
  }
}
</style>
