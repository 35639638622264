<script setup>
import { computed } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useDigitalAssets } from '@/queries/digital_assets/useDigitalAssets';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useInfiniteAlbumCollections } from '@/queries/album_collections/useAlbumCollections';
import { useRoute } from 'vue-router';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import editsImage from '@images/edits.png';
import favoritesImage from '@images/favorites.png';
import staffPicksImage from '@images/staff picks.png';
import uploadsImage from '@images/uploads.jpg';
import winkImage from '@images/wink.gif';
import CarouselLoading from '@/components/platform-home/CarouselLoading.vue';
import PageContainerOffset from '@/components/platform-layout/PageContainerOffset.vue';
import SoonaImageCard from '@/components/ui_library/SoonaImageCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSlider from '@/components/ui_library/SoonaSlider.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();

const accountId = computed(() => props.accountId);

const { data: editsCollection, isPending: isEditsCollectionLoading } =
  useEditsCollection(accountId);
const { data: favoritesCollection, isPending: isFavoritesCollectionLoading } =
  useFavoritesCollection(accountId);
const { data: staffPicksCollection, isPending: isStaffPicksCollectionLoading } =
  useStaffPicksCollection(accountId);

const { data: albumCollections, isPending: isAlbumCollectionsLoading } =
  useInfiniteAlbumCollections(accountId, {
    itemsPerPage: 8,
    sortBy: 'date modified',
    sortDirection: 'desc',
  });

const formattedAlbumCollections = computed(
  () =>
    albumCollections.value?.pages[0]?.collections?.map(albumCollection => ({
      ...albumCollection,
      routerTo: {
        name: 'album-assets',
        params: {
          accountId: accountId.value,
          collectionId: albumCollection.id,
        },
      },
    })) ?? []
);

const { data: digitalAssets, isPending: isDigitalAssetsLoading } =
  useDigitalAssets({
    accountId,
    itemsPerPage: 1,
    filters: {
      origin: 'customer',
      ownership: 'customer',
      media_type: 'photo',
      visibility: 'all',
    },
  });

const uploadCollectionWithFallbackImage = computed(() => {
  const previewUrl =
    digitalAssets.value?.assets?.length > 0
      ? digitalAssets.value.assets[0].preview?.url
      : undefined;

  return {
    title: 'my uploads',
    preview: {
      url: previewUrl ?? uploadsImage,
    },
    total_assets: digitalAssets.value?.pagination?.totalCount ?? 0,
    routerTo: {
      name: 'uploads',
      params: { accountId: accountId.value },
    },
  };
});

const editsCollectionWithFallbackImage = computed(() => {
  return {
    ...editsCollection.value,
    preview: { url: editsCollection.value?.preview?.url || editsImage },
    routerTo: {
      name: 'edits-assets',
      params: { accountId: accountId.value },
    },
  };
});

const favoritesCollectionWithFallbackImage = computed(() => {
  return {
    ...favoritesCollection.value,
    preview: { url: favoritesCollection.value?.preview?.url || favoritesImage },
    routerTo: {
      name: 'favorites-assets',
      params: { accountId: accountId.value },
    },
  };
});

const staffPicksCollectionWithFallbackImage = computed(() => {
  return {
    ...staffPicksCollection.value,
    title: staffPicksCollection.value.title.replace(
      /^staff picks$/,
      'expert picks'
    ),
    preview: {
      url: staffPicksCollection.value?.preview?.url || staffPicksImage,
    },
    routerTo: {
      name: 'expert-picks-assets',
      params: { accountId: accountId.value },
    },
  };
});

const allCollections = computed(() => [
  editsCollectionWithFallbackImage.value || {},
  favoritesCollectionWithFallbackImage.value || {},
  staffPicksCollectionWithFallbackImage.value || {},
  uploadCollectionWithFallbackImage.value || {},
  ...formattedAlbumCollections.value,
]);

const isLoading = computed(
  () =>
    isAlbumCollectionsLoading.value ||
    isEditsCollectionLoading.value ||
    isDigitalAssetsLoading.value ||
    isFavoritesCollectionLoading.value ||
    isStaffPicksCollectionLoading.value
);

const albumUrl = album => {
  if (album.title !== 'my uploads') {
    return `/account/${accountId.value}/gallery/albums/${album.id}`;
  }

  return `/account/${accountId.value}/gallery/uploads`;
};

const trackClick = (label, href) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'albums',
    linkLabel: label,
    linkHref: href,
  });
};
</script>

<template>
  <section class="albums" data-cypress="section-albums">
    <div class="albums__heading">
      <h2 id="albums" class="u-title--heavy" data-cypress="text-albums-heading">
        albums
      </h2>
      <SoonaButton
        element="router-link"
        :to="{ name: 'albums' }"
        variation="tertiary"
        @on-click="trackClick('see all', `/account/${accountId}/my-albums`)"
      >
        see all <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
    <CarouselLoading v-if="isLoading" />
    <PageContainerOffset v-else>
      <SoonaSlider tag-name="ul" aria-labelledby="albums">
        <SoonaImageCard
          v-for="album in allCollections"
          :key="album.id"
          inner-element="router-link"
          :to="album.routerTo"
          cypress-name="account-album-card"
          @click="trackClick(album.title, albumUrl(album))"
        >
          <img :src="album.preview?.url || winkImage" alt="" />
          <template #caption>
            {{ album.title }} ({{ album.total_assets || 0 }})
          </template>
        </SoonaImageCard>
      </SoonaSlider>
    </PageContainerOffset>
  </section>
</template>

<style lang="scss" scoped>
.albums {
  &__heading {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
  }
}
</style>
