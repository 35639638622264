<script setup>
import SubscriptionPurchase from '@/components/platform-layout/sidebar/SubscriptionPurchase.vue';
import CreditPurchase from '@/components/platform-layout/sidebar/CreditPurchase.vue';
import UserButton from '@/components/platform-layout/sidebar/UserButton.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';
import { computed } from 'vue';

const emits = defineEmits(['open-user-nav']);

const { currentAccountId } = useMe();
const { account } = useAccount(currentAccountId);

const preferredCredits = computed(
  () => account.value?.preferred_credit_count || 0
);
</script>

<template>
  <div class="user-hub">
    <SubscriptionPurchase />
    <UserButton
      :preferred-credits="preferredCredits"
      @click="emits('open-user-nav')"
    />
    <CreditPurchase :preferred-credits="preferredCredits" />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.user-hub {
  background: variables.$gradient-periwink-error;
  border-radius: 0.5rem;
}
</style>
