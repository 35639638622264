import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { updateDefaultPayment } from '@/api/accounts';
import { unref } from 'vue';
import { queryKeys } from '@/queries/query-keys';

export function useUpdateDefaultPayment(accountId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ stripe, card }) =>
      updateDefaultPayment(unref(accountId), stripe, card),
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.defaultPayment(accountId),
        }),
        // we don't care about other account queries, just the main one
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
          exact: true,
        }),
      ]);
    },
  });
}
