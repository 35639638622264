<script setup>
import { computed, ref } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSkeleton from '@/components/ui_library/SoonaSkeleton.vue';
import SubscriptionProductTierCardV2 from '@/components/subscriptions/SubscriptionProductTierCardV2.vue';
import SubscriptionProductTierCardEmpty from '@/components/subscriptions/SubscriptionProductTierCardEmpty.vue';
import { useAccount } from '@/composables/useAccount';
import { useCapability } from '@/composables/useCapability';
import CancellationDialog from '@/components/subscriptions/CancellationDialog.vue';
import { useTiers } from '@/queries/tiers/useTiers';
import { convertToMMDDYYYY, convertToDateLong } from '@/lib/date-formatters';
import { PeriwinkBlue20, PeriwinkBlue80 } from 'src/variables.module.scss';
import { useFlag } from '@/composables/useFlag';
import SubscriptionsDialog from '@/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['show-paywall-dialog', 'set-subscription-tier']);

const route = useRoute();
const { linkClicked } = useBaseEvents();

const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');
const pegasusTrialSubscriptionsFlag = useFlag(
  'pegasus_accept_trial_subscriptions'
);
const paymentsUpgradeSubscriptionFlag = useFlag(
  'payments_subscription_upgrade'
);

const context = computed(() => route.meta?.context);
const accountId = computed(() => props.accountId);

const { data: tiers, isLoading } = useTiers();

const {
  account,
  isBusinessSubscriber,
  eligibleForPlatformSubscriptionTrial,
  soonaStoragePendingCancellation,
} = useAccount(accountId);

const { hasCapability: canManageAccountSubscription } = useCapability({
  capability: 'manage_account_subscription',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: canCrewManageSubscription } = useCapability({
  capability: 'crew_manage_subscription',
});

const linkHref = 'https://soona.co/contact-sales';

const trackChatClick = () => {
  linkClicked({
    context: context,
    subContext: 'manage plan page',
    linkLabel: 'chat with us',
    linkHref: linkHref,
  });
};

const offerTrial = computed(
  () =>
    apolloTrialForEveryoneFlag.value &&
    eligibleForPlatformSubscriptionTrial.value
);

const currentSubscription = computed(() => account.value?.subscription);

const baseSubscriptionItem = computed(() => {
  return currentSubscription.value?.subscription_items?.find(
    x => x.subscription_item_type === 'base'
  );
});

const currentTier = computed(() => {
  if (!currentSubscription.value || !tiers.value) return null;
  if (currentSubscription.value?.tier?.slug === 'tier-three') {
    return currentSubscription.value.tier;
  }

  return tiers.value.find(
    tier => tier.slug === currentSubscription.value.tier.slug
  );
});

const currentRecurringInterval = computed(() => {
  if (!currentSubscription.value) {
    return 'year';
  }

  return baseSubscriptionItem.value?.recurring_interval ?? 'month';
});
const isYearly = computed(() => currentRecurringInterval.value === 'year');

const isPendingCancellation = computed(() => {
  return currentSubscription.value?.is_pending_cancelation;
});

const isDowngrading = computed(() => {
  return !!currentSubscription.value?.downgrade_tier_id;
});

const isTrialing = computed(() => account.value?.is_trialing);

const hasNotAcceptedTrial = computed(() => {
  return currentSubscription.value?.subscription_items?.some(item => {
    return (
      item.subscription_item_type === 'base' && item.accepted_terms_at === null
    );
  });
});

const baseSubscriptionEndDate = computed(() => {
  if (baseSubscriptionItem.value && isTrialing.value) {
    return currentSubscription.value?.trial_end;
  } else {
    return baseSubscriptionItem.value?.ended_at ?? null;
  }
});

const recurringIntervalPlan = computed(() => {
  const recurringInterval = baseSubscriptionItem.value?.recurring_interval;

  return recurringInterval === 'year' ? 'annual' : 'monthly';
});

const subscriptionEndCopy = computed(() => {
  if (!currentSubscription.value) return null;

  let planInfo = {};
  if (
    isTrialing.value &&
    (isDowngrading.value || !isPendingCancellation.value)
  ) {
    // below is for the old non-auto-enrolling Preferred tier 2 sub trial promos
    if (hasNotAcceptedTrial.value) {
      planInfo.buttonCopy = 'free trial';
      planInfo.endDateCopy = 'expires';
      planInfo.endDate = currentSubscription.value.trial_end;
      // below is what we want to keep once the Preferred trial promos are deprecated
    } else {
      planInfo.buttonCopy = 'free trial';
      planInfo.endDateCopy = `your trial will end ${
        isDowngrading.value
          ? ''
          : 'and your subscription payment method will be charged'
      } on`;
      planInfo.endDate = currentSubscription.value.trial_end;
    }
  } else if (isPendingCancellation.value) {
    planInfo.buttonCopy = 'pending cancellation';
    planInfo.endDateCopy = 'ends';
    planInfo.endDate = baseSubscriptionEndDate.value;
  } else {
    planInfo.buttonCopy = `current ${recurringIntervalPlan.value} plan`;
    planInfo.endDateCopy = 'renews';
    planInfo.endDate = currentSubscription.value?.current_period_end;
  }

  return planInfo;
});

const downgradeCopy = computed(() => {
  if (!currentSubscription.value) return null;
  let planInfo = {};
  let date = convertToMMDDYYYY(currentSubscription.value.trial_end);

  planInfo.buttonCopy = `starts ${date}`;
  planInfo.endDateCopy = `your subscription payment method will be charged on ${date}`;

  return planInfo;
});

// cancel
const showCancellationModal = ref(false);

const cancellationClosed = () => {
  showCancellationModal.value = false;
  window.location.reload();
};

const subscriptionId = computed(() => {
  if (!currentSubscription.value) return null;

  return currentSubscription.value.id;
});

const subscriptionBaseItemPriceId = computed(() => {
  if (!currentSubscription.value) {
    return null;
  }
  return baseSubscriptionItem.value?.price_id;
});

const subscriptionBaseItemId = computed(() => {
  if (!currentSubscription.value) {
    return null;
  }

  return baseSubscriptionItem.value?.id;
});

const subscriptionPendingActivationItemId = computed(() => {
  return currentSubscription.value?.pending_activation_subscription_item?.id;
});

const currentSubscriptionEnd = computed(() => {
  if (!currentSubscription.value) {
    return null;
  }
  return convertToDateLong(currentSubscription.value?.current_period_end);
});

const cancelSubscriptionBaseId = ref(null);

const cancelSubscription = mode => {
  if (mode === 'downgrade') {
    cancelSubscriptionBaseId.value = subscriptionPendingActivationItemId.value;
  } else {
    cancelSubscriptionBaseId.value = subscriptionBaseItemId.value;
  }
  showCancellationModal.value = true;
};

// const priorityError = usePriorityError(cancelSubscriptionError);

const handleSubscriptionDialogOpen = slug => {
  emits('show-paywall-dialog');
  emits('set-subscription-tier', slug);
};

const canManageSubscription = computed(
  () => canManageAccountSubscription.value || canCrewManageSubscription.value
);

const canSubscribeToTier = tier => {
  // the user cannot subscribe if they cannot manage subscriptions
  if (!canManageAccountSubscription || !canCrewManageSubscription) {
    return false;
  }

  // the user cannot subscribe if they're already a non-trialing subscriber
  if (currentTier.value && !isTrialing.value) {
    return false;
  }

  // the user cannot subscribe if they have a subscription that is pending cancellation
  if (isPendingCancellation.value) {
    return false;
  }

  // the user cannot subscribe if already subscribed to the given tier
  if (tier.slug === currentTier.value?.slug) {
    return false;
  }

  // do not show the choose plan button if the user has a pending cancelled fast pass subscription
  return !soonaStoragePendingCancellation.value;
};

// upgrade
const showSubscriptionsDialog = ref(false);
const upgradeFlow = ref('');
const upgradeTierSlug = ref('');
const updateShowSubscriptionsDialog = (val, flow = '', slug = '') => {
  showSubscriptionsDialog.value = val;
  upgradeFlow.value = flow;
  upgradeTierSlug.value = slug;
};

const showBillingCycleButton = computed(() => {
  if (!paymentsUpgradeSubscriptionFlag.value) return false;
  if (soonaStoragePendingCancellation.value) return false;
  if (!currentSubscription.value) return false;
  if (currentSubscription.value.is_pending_cancelation) return false;
  return !isYearly.value;
});
const showUpgradeButton = tierSlug => {
  if (!paymentsUpgradeSubscriptionFlag.value) return false;
  // do not allow upgrade if user has pending cancelled fast pass subx
  if (soonaStoragePendingCancellation.value) return false;
  // do not show if user has no current subscription or is fast pass only
  if (!currentSubscription.value || !currentTier.value) return false;
  if (currentSubscription.value.is_pending_cancelation) return false;
  if (currentSubscription.value.status !== 'active') return false;
  if (tierSlug === currentTier.value?.slug) return false;
  if (currentTier.value?.slug === 'fast-pass') return false;
  return tierSlug !== 'tier-one';
};
</script>

<template>
  <div class="subscription-product-tiers">
    <div class="subscription-product-tiers--inner">
      <template v-if="isLoading">
        <SoonaSkeleton
          v-for="n in 3"
          :key="n"
          class="subscription-product-tiers__skeleton"
        />
      </template>
      <template v-else>
        <template v-if="isBusinessSubscriber">
          <SubscriptionProductTierCardEmpty
            :key="currentTier.name"
            :tier="currentTier"
            :show-border="true"
          >
            <span
              v-if="canManageAccountSubscription || canCrewManageSubscription"
              class="subscription-product-tiers__current-tier--link-container"
            >
              <SoonaButton
                element="a"
                :href="linkHref"
                target="_blank"
                variation="solid-black"
                @on-click="trackChatClick"
              >
                chat with us <SoonaIcon name="phone" />
              </SoonaButton>
            </span>
          </SubscriptionProductTierCardEmpty>
        </template>
        <template v-else>
          <SubscriptionProductTierCardV2
            v-for="tier in tiers"
            :key="tier.name"
            :tier="tier"
            :selected-billing-interval="currentRecurringInterval"
            :show-border="true"
            :best-offer-border="tier.slug === 'tier-two' ? 'pizzazz' : false"
          >
            <!-- current -->
            <div
              v-if="tier.slug === currentTier?.slug"
              class="subscription-product-tiers__current-tier"
            >
              <SoonaFlag
                :background-color="PeriwinkBlue20"
                :text-color="PeriwinkBlue80"
                :title="subscriptionEndCopy.buttonCopy"
              />
              <p
                class="subscription-product-tiers__current-tier--renewal-date u-label--small"
              >
                {{ subscriptionEndCopy.endDateCopy }}
                {{
                  convertToMMDDYYYY(
                    subscriptionEndCopy.endDate ||
                      convertToMMDDYYYY(subscriptionEndCopy.trial_end)
                  )
                }}
              </p>
              <SoonaButton
                v-if="canManageSubscription && showBillingCycleButton"
                class="subscription-product-tiers__current-tier--change-billing-cycle"
                element="button"
                variation="solid-black"
                @click="
                  () =>
                    updateShowSubscriptionsDialog(
                      true,
                      'billingCycleUpgrade',
                      tier.slug
                    )
                "
              >
                change billing cycle
              </SoonaButton>
              <span
                v-if="canManageSubscription"
                class="subscription-product-tiers__current-tier--cancel-container"
              >
                <SoonaButton
                  v-if="
                    (!isPendingCancellation && !isTrialing) ||
                    (!hasNotAcceptedTrial && !isPendingCancellation)
                  "
                  class="subscription-product-tiers__current-tier--cancel"
                  variation="tertiary"
                  @on-click="cancelSubscription"
                >
                  cancel plan
                </SoonaButton>
                <SoonaButton
                  v-else-if="
                    isTrialing &&
                    pegasusTrialSubscriptionsFlag &&
                    hasNotAcceptedTrial &&
                    !isDowngrading &&
                    !isPendingCancellation
                  "
                  class="subscription-product-tiers__current-tier--cancel"
                  variation="solid-black"
                  @on-click="handleSubscriptionDialogOpen(tier?.slug)"
                >
                  keep this plan
                </SoonaButton>
              </span>
            </div>
            <!-- downgrade -->
            <div
              v-else-if="tier.id === account?.subscription?.downgrade_tier_id"
              class="subscription-product-tiers__current-tier"
            >
              <SoonaFlag
                :background-color="PeriwinkBlue20"
                :text-color="PeriwinkBlue80"
                :title="downgradeCopy.buttonCopy"
              />
              <p
                class="subscription-product-tiers__current-tier--renewal-date u-label--small"
              >
                {{ downgradeCopy.endDateCopy }}
              </p>
              <span
                v-if="canManageAccountSubscription || canCrewManageSubscription"
                class="subscription-product-tiers__current-tier--cancel-container"
              >
                <SoonaButton
                  class="subscription-product-tiers__current-tier--cancel"
                  variation="tertiary"
                  @on-click="cancelSubscription('downgrade')"
                >
                  cancel plan
                </SoonaButton>
              </span>
            </div>
            <SoonaButton
              v-if="canManageSubscription && showUpgradeButton(tier.slug)"
              class="subscription-product-tiers__current-tier--change-billing-cycle"
              element="button"
              variation="solid-black"
              @click="
                () =>
                  updateShowSubscriptionsDialog(true, 'tierUpgrade', tier.slug)
              "
            >
              upgrade
            </SoonaButton>
            <!-- subscribe -->
            <SoonaButton
              v-if="canSubscribeToTier(tier)"
              variation="solid-black"
              @on-click="handleSubscriptionDialogOpen(tier.slug)"
            >
              {{ offerTrial ? 'try it free for 7 days' : 'choose plan' }}
            </SoonaButton>
          </SubscriptionProductTierCardV2>
        </template>
        <CancellationDialog
          v-if="showCancellationModal"
          :account-id="accountId"
          :subscription-id="subscriptionId"
          :subscription-item-price-id="subscriptionBaseItemPriceId"
          :subscription-item-id="cancelSubscriptionBaseId"
          subscription-item-type="base"
          :subscription-current-period-end="currentSubscriptionEnd"
          @close="cancellationClosed()"
        />
        <SubscriptionsDialog
          v-if="showSubscriptionsDialog"
          :flow="upgradeFlow"
          :selected-tier-slug="upgradeTierSlug"
          @close="() => updateShowSubscriptionsDialog(false)"
        />
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.subscription-product-tiers {
  container: tiers-wrapper / inline-size;

  &--inner {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;

    @container tiers-wrapper (min-width: 40rem) {
      flex-wrap: nowrap;
    }

    @media (min-width: variables.$screen-sm-min) {
      gap: 2rem;
    }
  }

  &__skeleton {
    height: 16rem;
    border-radius: 0.625rem;
  }

  &__current-tier {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1rem 0;
    padding-bottom: 0.1rem;

    &--cancel-container,
    &--link-container {
      width: 100%;

      a {
        width: 100%;
      }
    }

    &--cancel {
      width: 100%;
    }

    &--renewal-date {
      color: variables.$gray-60;
      margin-bottom: 1rem;

      &.u-label--small {
        width: 90%;
        font-size: 0.875rem;
        text-align: center;
        margin-top: 0.5rem;
      }
    }

    &--change-billing-cycle {
      width: 100%;
    }
  }
}
</style>
