<script setup>
import { computed, ref, toRefs } from 'vue';
import { useListingActions } from '@/queries/useListingActions';
import ListingActionComplete from '@/components/user/anytime/listing_insights/listing_actions/actions/ListingActionComplete.vue';
import OptimizeImageSizeAction from '@/components/user/anytime/listing_insights/listing_actions/actions/image_size/OptimizeImageSizeAction.vue';
import MainProductOnWhiteAction from '@/components/user/anytime/listing_insights/listing_actions/actions/main_image_on_white/MainProductOnWhiteAction.vue';
import LifestyleAction from '@/components/user/anytime/listing_insights/listing_actions/actions/lifestyle/LifestyleAction.vue';
import BulkActionBar from './BulkActionBar.vue';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';
import { useRoute } from 'vue-router';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { useListingActionsAutoProcessBulkActions } from '@/queries/useListingActionsAutoProcessBulkActions';
import { useListingActionsAutoProcessBulkPublish } from '@/queries/useListingActionsAutoProcessBulkPublish';
import ActionsListEmpty from './ActionsListEmpty.vue';
import ReplaceOnlyAction from '@/components/user/anytime/listing_insights/listing_actions/actions/replace_only/ReplaceOnlyAction.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useDisableBulkActions } from '@/composables/useDisableBulkActions';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  actionSlug: {
    type: String,
    required: true,
  },
});

const route = useRoute();

const listingActionStatus = computed(() => {
  return route.name === 'complete-actions' ? 'complete' : 'active';
});
const { accountId, actionSlug } = toRefs(props);
const catalogItemId = computed(() => route.query.catalog_item);

// subx dialog module
const showSubscriptionsDialog = ref(false);
const subscriptionDialogFlow = ref('pricingAndCheckout');
const selectedTierSlug = ref(null);
const promptSubscription = tier => {
  selectedTierSlug.value = tier;
  subscriptionDialogFlow.value =
    tier === 'tier-two' ? 'full' : 'pricingAndCheckout';
  showSubscriptionsDialog.value = true;
};

const currentPageToDo = ref(1);
const currentPageComplete = ref(1);
const itemsPerPageToDo = ref(10);
const itemsPerPageComplete = ref(10);

const { disableBulkActions } = useDisableBulkActions({
  accountId: accountId,
});

const {
  mutate: autoProcessBulkActions,
  isPending: autoProcessBulkActionsPending,
} = useListingActionsAutoProcessBulkActions(accountId);

const {
  mutate: autoProcessBulkPublish,
  isPending: autoProcessBulkPublishPending,
} = useListingActionsAutoProcessBulkPublish(accountId);

const currentPage = computed({
  get() {
    return listingActionStatus.value === 'active'
      ? currentPageToDo.value
      : currentPageComplete.value;
  },
  set(value) {
    if (listingActionStatus.value === 'active') {
      currentPageToDo.value = value;
    } else {
      currentPageComplete.value = value;
    }
  },
});

const itemsPerPage = computed({
  get() {
    return listingActionStatus.value === 'active'
      ? itemsPerPageToDo.value
      : itemsPerPageComplete.value;
  },
  set(value) {
    if (listingActionStatus.value === 'active') {
      itemsPerPageToDo.value = value;
    } else {
      itemsPerPageComplete.value = value;
    }
  },
});

const statusParam = computed(() => {
  // api returns actions in 'active' or 'pending_approval' if not provided with a status
  return listingActionStatus.value === 'active' ? null : 'complete';
});

const {
  data: actionsData,
  isLoading: listingActionsLoading,
  error: listingActionsError,
} = useListingActions(accountId, {
  actionSlug,
  currentPage,
  itemsPerPage,
  status: statusParam,
  catalogItemId,
});

const actions = computed(() => {
  return actionsData.value?.listing_actions || [];
});

const actionsPagination = computed(() =>
  actionsData.value
    ? actionsData.value.pagination
    : {
        totalCount: 0,
        currentPage: 0,
        totalPages: 0,
        itemsPerPage: 0,
      }
);

const componentLookup = {
  'low-resolution': OptimizeImageSizeAction,
  lifestyle: LifestyleAction,
  'main-image-product-on-white': MainProductOnWhiteAction,
  replace: ReplaceOnlyAction,
};

// bulk actions module
const hasAssetsToPublish = computed(() => {
  return actions.value.some(action => action.status === 'pending_approval');
});

const hasPendingActions = computed(() => {
  return actions.value.some(
    action =>
      action.status === 'active' && !action.requires_manual_source_selection
  );
});

const bulkAction = () => {
  autoProcessBulkActions({
    listing_action_slug: actionSlug.value,
  });
};

const bulkPublish = () => {
  autoProcessBulkPublish({
    listing_action_slug: actionSlug.value,
  });
};

const actionInProgress = computed(() => {
  return actionsData.value?.action_in_progress;
});

const priorityError = usePriorityError(listingActionsError);
</script>

<template>
  <SoonaError v-if="priorityError" no-margin>
    {{ priorityError }}
  </SoonaError>
  <template v-else-if="listingActionStatus === 'active'">
    <template v-if="actions.length > 0">
      <BulkActionBar
        v-if="!disableBulkActions"
        :account-id="accountId"
        :has-assets-to-publish="hasAssetsToPublish"
        :has-pending-actions="hasPendingActions"
        :is-publishing="autoProcessBulkPublishPending || false"
        :is-optimizing="autoProcessBulkActionsPending || false"
        :action-in-progress="actionInProgress"
        @show-subscriptions-dialog="promptSubscription('tier-two')"
        @bulk-action="bulkAction"
        @publish="bulkPublish"
      />
    </template>

    <ActionsListEmpty
      v-else-if="!listingActionsLoading"
      :account-id="accountId"
      :action-slug="actionSlug"
    />
  </template>

  <div class="active-actions">
    <div
      v-for="action in actions"
      :key="action.id"
      class="active-actions__action"
    >
      <ListingActionComplete
        v-if="listingActionStatus === 'complete'"
        :account-id="accountId"
        :listing-action="action"
      />
      <component
        :is="componentLookup[action.listing_action_slug]"
        v-else
        :account-id="accountId"
        :listing-action="action"
        :include-sibling-actions="true"
        @show-subscriptions-dialog="promptSubscription"
      />
    </div>
  </div>
  <PaginatorFull
    v-model:page="currentPage"
    v-model:records-per-page="itemsPerPage"
    class="active-actions__paginator"
    :total-pages="actionsPagination.totalPages"
    :total-count="actionsPagination.totalCount"
    :page-count="actions.length"
  />
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    :flow="subscriptionDialogFlow"
    :selected-tier-slug="selectedTierSlug"
    @close="showSubscriptionsDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.active-actions {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__action {
    &:last-child {
      div {
        border-bottom: none;
      }
    }
  }
}
</style>
