<script setup>
import { computed, ref } from 'vue';
import PlaceholderImage from 'images/placeholder.png';
import { component as Viewer } from 'v-viewer';

const props = defineProps({
  allowPreview: {
    type: Boolean,
    required: false,
    default: false,
  },
  imageUrl: {
    type: String,
    required: false,
  },
  imageHqUrl: {
    type: String,
    required: false,
  },
  inventoryCount: {
    type: [String, Number],
    required: false,
  },
  mobileCardView: {
    type: Boolean,
    required: false,
    default: false,
  },
  ariaLabelledby: {
    type: String,
    required: true,
  },
});

const imageThumbnail = computed(() => {
  return props.imageUrl ?? PlaceholderImage;
});

const imageHq = computed(() => {
  return props.imageHqUrl ?? imageThumbnail.value;
});

const $viewer = ref(null);

const inited = viewer => {
  $viewer.value = viewer;
};

const triggerViewer = () => {
  $viewer.value.show();
};
</script>

<template>
  <article
    class="item-card"
    :class="{ 'item-card--card-view': mobileCardView }"
    :aria-labelledby="ariaLabelledby"
  >
    <div class="item-card__image">
      <Viewer
        v-if="allowPreview"
        :options="{
          toolbar: false,
          navbar: false,
          title: false,
          backdrop: true,
          minZoomRatio: 0.01,
          maxZoomRatio: 2,
          url: 'data-source',
        }"
        @inited="inited"
      >
        <img
          :src="imageThumbnail"
          :data-source="imageHq"
          alt=""
          tabindex="0"
          @keyup.enter="triggerViewer()"
        />
      </Viewer>
      <img v-else :src="imageThumbnail" alt="" />
      <div v-if="$slots['image-badge']" class="item-card__image--badge">
        <slot name="image-badge"></slot>
      </div>
      <span
        v-if="inventoryCount && !mobileCardView"
        class="item-card__inventory-count-badge"
        :title="`${inventoryCount} items in inventory`"
      >
        {{ inventoryCount
        }}<span class="u-visually-hidden">items in inventory</span>
      </span>
    </div>

    <div class="item-card__content">
      <slot name="content"></slot>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.item-card {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 0.25rem;
  position: relative;
  background-color: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  overflow: hidden;
  min-height: 4.5rem;
  width: 100%;

  &__image {
    flex: 0 0 4.5rem;
    position: relative;
    background-color: variables.$gray-20;
    min-height: 4.5rem;

    // images wrapped in v-viewer
    div {
      width: 100%;
      height: 100%;

      img {
        cursor: pointer;

        &:focus-visible {
          outline-offset: -0.125rem;
        }
      }

      &:hover {
        img {
          transform: scale(1.05);
        }
      }
    }

    // images without v-viewer
    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--badge {
      position: absolute;
      display: flex;
      gap: 0.25rem;
      top: 0.25rem;
      left: 0.25rem;
    }
  }

  &__inventory-count-badge {
    @include variables_fonts.u-label--heavy;

    position: absolute;
    left: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    padding: 0.25em calc(0.25em - 2 * 0.0625rem);
    background-color: variables.$white-default;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 10em;
    min-width: calc(1.5em + 2 * 0.0625rem);
  }

  &__content {
    flex: 1 1 12rem;
  }

  &--card-view {
    display: block;
    .item-card__image {
      height: 12rem;
    }
    @media (min-width: variables.$screen-sm-min) {
      display: flex;
      .item-card__image {
        height: auto;
      }
    }
  }
}
</style>
