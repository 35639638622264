<script setup>
import SoonaTab from '@/components/ui_library/SoonaTab.vue';
import { useRoute } from 'vue-router';

defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integrationId: {
    type: String,
    required: true,
  },
  externalProductId: {
    type: String,
    required: true,
  },
  productId: {
    // catalog item id -- to do: update name
    type: String,
    required: true,
  },
  listing: {
    type: Object,
    required: true,
  },
});

const route = useRoute();
</script>

<template>
  <div class="listing-detail-nav__wrapper">
    <div class="listing-detail-nav">
      <SoonaTab
        :to="{
          name: 'listing-optimize',
          params: { accountId, integrationId, externalProductId, productId },
        }"
        :is-selected="route.name === 'listing-optimize'"
        replace
      >
        optimize
      </SoonaTab>
      <SoonaTab
        v-if="listing.variant_count > 0"
        :to="{
          name: 'listing-variants',
          params: { accountId, integrationId, externalProductId, productId },
        }"
        :is-selected="route.name === 'listing-variants'"
        replace
      >
        variant listings
      </SoonaTab>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-detail-nav {
  display: flex;
  border-bottom: 0.0625rem solid variables.$gray-30;
  overflow-x: auto;

  :deep(.soona-tab:not([aria-current='true'])) {
    border-bottom: none;
    display: flex;
    gap: 0.25rem;
  }

  &__wrapper {
    margin-top: 1rem;
  }
}
</style>
