import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';
import applyDigitalAssetFilters from '@/queries/digital_assets/applyDigitalAssetFilters';

/**
 *
 * @param {Object} [params]
 * @param {string | number} [accountId]
 * @param {number} [params.currentPage]
 * @param {number} [params.itemsPerPage]
 * @param {number} [params.activeAssetId]
 * @param {number} [params.filters]
 * @param {AbortSignal} [signal]
 */
export async function digitalAssets(
  accountId,
  {
    activeAssetId,
    currentPage,
    facets,
    filters,
    itemsPerPage,
    searchQuery,
    sortBy,
    sortDirection,
    similarityText,
    similarityThreshold,
  } = {},
  signal
) {
  const params = {};

  if (currentPage) params['page'] = currentPage;
  if (itemsPerPage) params['items'] = itemsPerPage;
  if (activeAssetId) params['active_asset_id'] = activeAssetId;
  if (searchQuery) params['search_query'] = searchQuery;
  if (sortBy) params['order_by'] = sortBy;
  if (sortDirection) params['direction'] = sortDirection;
  if (similarityText) params['similarity_text'] = similarityText;
  if (similarityThreshold) params['similarity_threshold'] = similarityThreshold;
  applyDigitalAssetFilters(params, filters);

  if (facets) params['facets'] = facets;

  const response = await http.get(`/accounts/${accountId}/digital_assets`, {
    params,
    signal,
  });

  return {
    pagination: mapPaginationHeaders(response.headers),
    assets: response.data?.digital_assets || [],
    photo_count: response.data?.photo_count ?? 0,
    gif_count: response.data?.gif_count ?? 0,
    video_count: response.data?.video_count ?? 0,
  };
}

export async function attachOrCreateRaw(reservationId, body) {
  const response = await http.post(
    `/reservations/${reservationId}/digital_asset_raws`,
    { digital_asset: body }
  );
  return response.data;
}

export async function deleteDigitalAsset(accountId, assetId) {
  const response = await http.delete(
    `/accounts/${accountId}/digital_assets/${assetId}`
  );
  return response.data;
}

export async function saveDigitalAssetForeground(accountId, assetId) {
  const response = await http.post(
    `/accounts/${accountId}/digital_assets/${assetId}/save_foreground`
  );
  return response.data;
}

export async function saveResizedDigitalAsset(accountId, assetId, body) {
  const response = await http.post(
    `/accounts/${accountId}/digital_assets/${assetId}/save_resize`,
    body
  );
  return response.data;
}

export async function bulkHideDigitalAssets(accountId, ids) {
  const response = await http.put(
    `/accounts/${accountId}/digital_assets/bulk_hide`,
    { digital_asset_ids: ids }
  );
  return response.data;
}
