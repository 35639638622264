<template>
  <Teleport to="body">
    <div
      class="update-reservation-modal modal"
      :class="{ 'is-active': showUpdateReservationModal }"
    >
      <div
        class="modal-background"
        @click.prevent="toggleUpdateReservationModal"
      />
      <div class="modal-content main-modal">
        <div class="box box-main">
          <div class="schedule-sticky-top">
            <SoonaButton
              variation="icon-plain-gray"
              @on-click="toggleUpdateReservationModal"
            >
              <SoonaIcon name="xmark" />
            </SoonaButton>
            <div class="update-reservation-modal-header">
              <span class="shoot-readiness" :class="shootReadinessClass"></span>
              <h2 class="sticky-title">{{ reservation.title }}</h2>
              <SoonaButton
                element="router-link"
                :to="`/reservation/${reservation.id}/info`"
                target="_blank"
                variation="icon-plain-gray"
                :title="reservation.title"
              >
                <SoonaIcon name="arrow-up-right-from-square" size="medium" />
              </SoonaButton>
            </div>
            <h3 class="u-small--heavy update-reservation-modal-subheader">
              <div class="subtitle-details">
                <SoonaFlag
                  v-for="flag in filteredAccountFlags"
                  :key="flag.id"
                  :title="flag.title"
                  :text-color="flag.text_color"
                  :background-color="flag.background_color"
                />
                {{ currentLocation.display_name }} -
                {{ reservation.order_status }}
              </div>
              <p
                v-if="reservation.scheduled_reservation_date"
                class="start-time has-text-centered u-small--regular"
              >
                scheduled: {{ formattedScheduledTime }}
              </p>
            </h3>
          </div>

          <div v-if="activeTransferPackages?.length > 0" class="transfer-alert">
            <SoonaAlert v-for="pkg in activeTransferPackages" :key="pkg.id">
              package transfer
              {{ translatePackageTransferStatus(pkg) }} from
              {{ pkg.origin.short_name }} > {{ pkg.destination.short_name }}.
            </SoonaAlert>
          </div>
          <div
            class="columns is-centered"
            :class="{ 'sticky-spacer': !activeTransferPackages }"
          >
            <div class="update-reservation-modal-body column is-three-quarters">
              <div v-if="!manualScheduleView" class="scheduling-view">
                <SoonaButton
                  size="medium"
                  variation="tertiary"
                  @on-click="showManualSchedule"
                >
                  manual schedule
                </SoonaButton>
                <SoonaGenie />
              </div>
              <div v-else class="scheduling-view">
                <SoonaButton
                  :disabled="!canBeScheduledAtLocation"
                  size="medium"
                  variation="tertiary"
                  @on-click="showGenieView"
                >
                  soona genie
                </SoonaButton>
                <ManualScheduler />
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">crew member</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <AddCrewToReservation
                      :reservation-id="reservation.id"
                      :reservation-start="reservation.start"
                      :reservation-end="reservation.end"
                      :disabled="isCompleted"
                    />
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">tags</label>
                </div>
                <div class="field-body">
                  <div class="field tags-section">
                    <ul>
                      <li
                        v-for="tag in reservationTags"
                        :key="tag.id"
                        class="u-body--regular"
                      >
                        {{ tag.title }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="reservation.isPickAPack" class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">pack quantity</label>
                </div>
                <div class="field-body">
                  <div class="field multi-pack-section">
                    <span>
                      {{ reservation.pack_configuration.quantity }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">pro service</label>
                </div>
                <div v-if="purchasedProServicesNames" class="field-body">
                  <div class="field tags-section">
                    <ul v-if="purchasedProServicesNames.length > 0">
                      <li
                        v-for="(proservice, index) in purchasedProServicesNames"
                        :key="index"
                        class="u-body--regular"
                      >
                        <span v-if="proservice.quantity > 1">
                          {{ proservice.quantity }} x {{ proservice.name }} ({{
                            proservice.level
                          }})
                        </span>
                        <span v-else>
                          {{ proservice.name }}
                        </span>
                      </li>
                    </ul>
                    <ul v-else>
                      <li class="u-body--regular">none</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">shotlist</label>
                </div>
                <div class="field-body">
                  <div class="field shotlist-section">
                    <span v-if="reservation.shot_count != 1">
                      {{ reservation.shot_count }} shots
                    </span>
                    <span v-else> {{ reservation.shot_count }} shot</span>
                    <span v-if="hasCustomProp"> - has custom props</span>
                  </div>
                </div>
              </div>
              <div
                v-if="reservation.reservation_packages"
                class="field is-horizontal"
              >
                <div class="field-label is-normal">
                  <label class="label">package status </label>
                </div>
                <div class="field-body">
                  <div class="field package-section">
                    <ul>
                      <li
                        v-for="reservation_package in reservation.reservation_packages"
                        :key="reservation_package.id"
                        class="u-body--regular"
                      >
                        <span class="package-icon mr-s">{{
                          packageStatus(reservation_package.status)
                        }}</span>
                        <span class="mr-s"
                          >{{ reservation_package.location }} -
                          {{ reservation_package.id }}</span
                        >
                        |
                        <span class="ml-s">
                          {{ reservation_package.date_status }}
                          {{ intakeDate(reservation_package) }}
                        </span>
                        <p>{{ reservation_package.storage_location }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  reservation.account &&
                  reservation.account.past_shoots.length !== 0
                "
                class="field is-horizontal"
              >
                <div class="field-label is-normal">
                  <label class="label">past shoots </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <ul>
                      <li
                        v-for="shoot in reservation.account.past_shoots"
                        :key="shoot.id"
                        class="u-body--regular"
                      >
                        <SoonaButton
                          v-if="shoot.is_video"
                          element="a"
                          :href="`/user/anytime#/reservation/${shoot.id}/info`"
                          target="_blank"
                          rel="noopener noreferrer"
                          variation="tertiary"
                        >
                          {{ convertToMMDDYY(shoot.start) }} | video |
                          {{ shootDuration(shoot) }} |
                          {{ shoot.photographer_assigned_name }}
                        </SoonaButton>
                        <SoonaButton
                          v-else
                          element="a"
                          :href="`/user/anytime#/reservation/${shoot.id}/info`"
                          target="_blank"
                          rel="noopener noreferrer"
                          variation="tertiary"
                        >
                          {{ convertToMMDDYY(shoot.start) }}
                          <span v-if="shoot.shoot_size">
                            | {{ shoot.shoot_size }}</span
                          >
                          <span v-else> | h4a</span> |
                          {{ shootDuration(shoot) }}
                          <span v-if="shoot.photographer_assigned_name"
                            >| {{ shoot.photographer_assigned_name }}</span
                          >
                        </SoonaButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div v-if="packageOption" class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">post shoot option</label>
                </div>
                <div class="field-body">
                  <div class="field pso-section">
                    {{ packageOption }}
                  </div>
                </div>
              </div>
              <div
                v-if="reservation.shoot_exceptions"
                class="field is-horizontal"
              >
                <div class="field-label is-normal">
                  <label class="label">shoot exceptions</label>
                </div>
                <div class="field-body is-flex-direction-column">
                  <ul>
                    <li
                      v-for="shootException in reservation.shoot_exceptions"
                      :key="shootException.id"
                      class="field pso-section"
                    >
                      <p>
                        {{ convertToMMDDYY(shootException.created_at) }} -
                        {{ shootException.exception_type }} -
                        {{ shootException.reason }}
                      </p>
                      <p v-if="shootException.note">
                        notes: {{ shootException.note }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <flash />
          <div class="buttons is-centered">
            <SoonaButton
              size="medium"
              variation="secondary-gray"
              @on-click="toggleUpdateReservationModal"
            >
              close
            </SoonaButton>
            <SoonaButton
              :disabled="!canUpdateReservation || isCompleted"
              size="medium"
              @on-click="saveClicked"
            >
              save
            </SoonaButton>
          </div>
          <p v-if="!isCompleted" class="u-small--regular buttons">
            <SoonaButton
              v-if="canCancelReservationCapability"
              size="small"
              variation="tertiary"
              @on-click="toggleCancellationModal"
            >
              cancel booking
            </SoonaButton>
            <SoonaButton
              v-if="reservation.start"
              size="small"
              variation="tertiary"
              @on-click="toggleUnschedule"
            >
              unschedule booking
            </SoonaButton>
            <SoonaTooltip>
              <template
                #default="{
                  setRef,
                  mouseenter,
                  focus,
                  mouseleave,
                  blur,
                  ariaDescribedby,
                }"
              >
                <SoonaButton
                  v-if="canUpdateLocationOfReservation"
                  :ref="el => setRef(el)"
                  :disabled="disableLocationTransfer"
                  :aria-describedby="ariaDescribedby"
                  size="small"
                  variation="tertiary"
                  data-cypress="button-change-location"
                  @mouseenter="mouseenter"
                  @focus="focus"
                  @mouseleave="mouseleave"
                  @blur="blur"
                  @on-click="toggleChangeLocationModal"
                >
                  change location
                </SoonaButton>
              </template>
              <template v-if="disableLocationTransfer" #tooltip-content
                >unschedule before changing reservation’s location</template
              >
            </SoonaTooltip>
          </p>
        </div>
      </div>

      <SoonaDialog
        v-if="isChangeLocationActive"
        @close="toggleChangeLocationModal"
      >
        <template #heading>change reservation location</template>
        <div>
          <p class="modal-body-text">
            select a new location below to transfer this reservation.
          </p>
          <label for="location">location</label>
          <SoonaSelect
            :model-value="newLocation"
            name="location"
            :placeholder="'select a location'"
            :options="formattedLocations"
            data-cypress="select-update-location"
            @update:model-value="val => setNewLocation(val)"
          />
          <SoonaError v-if="locationWarning">
            {{ newLocation.display_name }} does not support all of the tags on
            this reservation.
          </SoonaError>
          <input
            v-model="transferInventory"
            type="checkbox"
            name="transfer-inventory"
          />
          <label class="transfer-inventory">
            transfer all account inventory
          </label>
          <SoonaAlert v-if="transferInventory">
            checking this box will alert inventory managers to transfer all
            inventory to a selected location.
          </SoonaAlert>
        </div>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="changeLocation"
          >
            change location
          </SoonaButton>
        </template>
      </SoonaDialog>

      <SoonaDialog v-if="isCancelActive" @close="toggleCancellationModal">
        <template #heading>cancel booking</template>
        <p v-if="cannotBeRefunded" class="alert-text u-title--regular">
          this down payment cannot be refunded. please speak to the finance team
          if a refund is required
        </p>
        <p v-else class="alert-text u-title--regular">refund the customer?</p>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            variation="secondary-black"
            @on-click="cancelReservationClicked(false)"
          >
            no refund
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="cancelReservationClicked(true)"
          >
            give refund
          </SoonaButton>
        </template>
      </SoonaDialog>

      <SoonaDialog
        v-if="showNoDownPaymentModal"
        @close="cancelNoDownPaymentClicked"
      >
        <template #heading>warning!</template>
        <div>
          <p class="modal-body-text">
            scheduling this shoot within 3 days will automatically charge the
            customer. are you sure?
          </p>
        </div>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="acceptNoDownPaymentClicked"
          >
            confirm
          </SoonaButton>
        </template>
      </SoonaDialog>
    </div>
  </Teleport>
</template>

<script>
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { computed } from 'vue';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useStore } from 'vuex';
import * as types from 'src/store/mutation-types';
import dateTimeMixin from 'src/mixins/dateTimeMixin';
import Flash from 'src/components/Flash.vue';
import ManualScheduler from 'src/components/schedule/ManualScheduler.vue';
import SoonaGenie from 'src/components/schedule/SoonaGenie.vue';
import AddCrewToReservation from 'src/components/user/anytime/crew/AddCrewToReservation.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaFlag from 'src/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export default {
  components: {
    Flash,
    ManualScheduler,
    SoonaGenie,
    AddCrewToReservation,
    SoonaButton,
    SoonaDialog,
    SoonaAlert,
    SoonaSelect,
    SoonaError,
    SoonaFlag,
    SoonaIcon,
    SoonaTooltip,
  },
  mixins: [dateTimeMixin],
  emits: ['refresh-events'],
  setup() {
    const { hasCapability: canCancelReservationCapability } = useCapability({
      capability: 'cancel_reservation',
    });

    const { hasCapability: canUpdateLocationOfReservation } = useCapability({
      capability: 'update_location_of_reservation',
    });

    const store = useStore();
    const shootComplexityFlag = useFlag('toaster_shoot_complexity');

    const reservation = computed(() => store.state.reservations.reservation);

    const shootReadinessClass = computed(() => {
      return shootComplexityFlag.value
        ? `shoot-readiness__${reservation.value?.shoot_complexity}`
        : null;
    });

    return {
      canCancelReservationCapability,
      canUpdateLocationOfReservation,
      reservation,
      shootReadinessClass,
    };
  },
  data() {
    return {
      isCancelActive: false,
      newLocation: undefined,
      isChangeLocationActive: false,
      manualScheduleView: true,
      transferInventory: false,
      locationWarning: false,
      showNoDownPaymentModal: false,
    };
  },
  computed: {
    ...mapState({
      reservationTags: state =>
        state.reservations.reservation.reservation_tags.filter(
          t => t.display_rule !== 'false'
        ),
      showUpdateReservationModal: state =>
        state.schedule.showUpdateReservationModal,
      currentLocation: state => state.locations.currentLocation,
      locations: state => state.locations.locations,
      supportedLocations: state => state.locations.supportedLocations,
      calendarStart: state => state.schedule.calendarStart,
      calendarEnd: state => state.schedule.calendarEnd,
      soonaGenieSelectedDate: state => state.soonaGenie.selectedDate,
      soonaGenieSelectedTime: state => state.soonaGenie.selectedTime,
      soonaGenieSelectedBay: state => state.soonaGenie.selectedBay,
      soonaGenieSelectedPhotographer: state =>
        state.soonaGenie.selectedPhotographer,
      transferPackages: state =>
        state.reservations?.reservation?.account?.transfer_package?.requirement,
    }),
    ...mapGetters([
      'isPaid',
      'isConfirmed',
      'checkNoDownPayment',
      'purchasedProServicesNames',
      'isAnySlotSelected',
      'canBeScheduledAtLocation',
    ]),
    ...mapGetters('account', ['guessedTimeZone']),
    canUpdateReservation() {
      if (!this.manualScheduleView) {
        return (
          //all genie selections were made
          this.soonaGenieSelectedTime &&
          this.soonaGenieSelectedDate &&
          this.soonaGenieSelectedBay &&
          this.soonaGenieSelectedPhotographer
        );
      } else {
        return (
          !this.hasDatePassed(this.reservation.start) && this.isAnySlotSelected
        );
      }
    },
    checkIsNoDownPayment() {
      return this.checkNoDownPayment;
    },
    canCancelReservation() {
      return this.isPaid || this.isConfirmed;
    },
    cannotBeRefunded() {
      return (
        this.reservation.down_payment_order_provider === 'shopify' ||
        this.isConfirmed
      );
    },
    isCompleted() {
      return this.reservation.order_status === 'completed';
    },
    hasCustomProp() {
      return this.reservation.shot_count > 0
        ? this.reservation.shot_custom_prop.find(
            shot => shot.has_custom_prop === true
          )
        : false;
    },
    packageOption() {
      return this.reservation.package_option === 'already_stored' ||
        this.reservation.package_option === 'no_products_to_send'
        ? this.reservation.package_option.replace(/_/g, ' ')
        : false;
    },
    studioTimezone() {
      let studioTimezone = this.currentLocation?.timezone
        ? this.currentLocation.timezone
        : this.guessedTimeZone;
      return studioTimezone;
    },
    currentUserTime() {
      let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return this.convertTime(
        this.reservation.scheduled_reservation_date,
        browserTimezone
      );
    },
    scheduledReservationStudioTime() {
      if (this.reservation.scheduled_reservation_date) {
        return this.convertTime(
          this.reservation.scheduled_reservation_date,
          this.studioTimezone
        );
      } else {
        return '';
      }
    },
    formattedScheduledTime() {
      if (this.scheduledReservationStudioTime === this.currentUserTime) {
        return `${this.scheduledReservationStudioTime}`;
      } else {
        return `${this.scheduledReservationStudioTime} (${this.currentUserTime})`;
      }
    },
    formattedLocations() {
      let locations = [];
      this.locations.forEach(x => {
        if (x.location_type !== 'disabled') {
          locations.push({ value: x, label: x.name });
        }
      });

      locations.sort((a, b) => a.label.localeCompare(b.label));
      return locations;
    },
    activeTransferPackages() {
      return this.transferPackages?.filter(
        p => p.status === 'pending' || p.status === 'initiated'
      );
    },
    filteredAccountFlags() {
      return this.reservation.account?.flags?.filter(
        flag => flag.slug === 'preferred'
      );
    },
    disableLocationTransfer() {
      return !!this.reservation.start;
    },
  },
  watch: {
    showUpdateReservationModal(open) {
      if (open) {
        this.manualScheduleView = true;
      }
    },
  },
  methods: {
    ...mapMutations([
      types.SET_BOOKABLE_SPACE_ID,
      types.SET_RESERVATION_START_AND_END,
      types.RESET_START_TIME,
      types.RESET_END_TIME,
    ]),
    ...mapActions([
      'addPhotographerToReservation',
      'cancelReservation',
      'updateReservation',
      'unScheduleReservation',
      'updateLocation',
    ]),
    ...mapActions('schedule', ['toggleUpdateReservationModal']),
    ...mapActions('locations', [
      'loadLocationEmployees',
      'loadSupportedLocations',
      'loadLocations',
    ]),
    ...mapActions('soonaGenie', ['resetSoonaGenie']),
    refreshSchedule() {
      this.$emit('refresh-events');
    },
    saveClicked() {
      if (this.checkIsNoDownPayment && this.checkNoDownPaymentTimeRange()) {
        this.showNoDownPaymentModal = true;
      } else {
        this.proceedWithSaving();
      }
    },
    checkNoDownPaymentTimeRange() {
      const now = new Date();
      const futureDate = new Date(now.getTime() + 72 * 60 * 60 * 1000);
      const startTime = new Date(this.reservation.start);

      return startTime >= now && startTime <= futureDate;
    },
    acceptNoDownPaymentClicked() {
      this.showNoDownPaymentModal = false;
      this.proceedWithSaving();
    },
    cancelNoDownPaymentClicked() {
      this.showNoDownPaymentModal = false;
      this.toggleUpdateReservationModal();
    },
    proceedWithSaving() {
      if (!this.manualScheduleView) {
        //update reservation items set by Genie (photographer is already set)
        if (
          !(
            this.soonaGenieSelectedTime &&
            this.soonaGenieSelectedDate &&
            this.soonaGenieSelectedBay &&
            this.soonaGenieSelectedPhotographer
          )
        ) {
          return;
        }
        //set reservation.bookable_space_ids
        this.SET_BOOKABLE_SPACE_ID(this.soonaGenieSelectedBay.id);
        //set reservation.start and reservation.end
        this.SET_RESERVATION_START_AND_END({
          startDate: this.soonaGenieSelectedDate,
          startTime: this.soonaGenieSelectedTime,
          timezone: this.soonaGenieSelectedTime.timezone,
        });

        this.addPhotographerToReservation(this.soonaGenieSelectedPhotographer);
      }

      this.updateReservation().then(() => {
        this.$success('Reservation updated.');
        this.refreshSchedule();
        this.toggleUpdateReservationModal();
        this.loadLocationEmployees({
          id: this.currentLocation.id,
          start: this.calendarStart,
          end: this.calendarEnd,
        });
      });
    },
    cancelReservationClicked(refund) {
      this.isCancelActive = false;
      this.cancelReservation(refund).then(() => {
        this.refreshSchedule();
        this.toggleUpdateReservationModal();
      });
    },
    toggleCancellationModal() {
      return (this.isCancelActive = !this.isCancelActive);
    },
    toggleShowNoDownPaymentModal() {
      return (this.showNoDownPaymentModal = !this.showNoDownPaymentModal);
    },
    hasDatePassed(date) {
      return dayjs(date).isBefore(dayjs(), 'day');
    },
    async toggleChangeLocationModal() {
      if (!this.isChangeLocationActive) {
        await this.loadSupportedLocations(this.reservationTags);
        await this.loadLocations();
      }

      this.isChangeLocationActive = !this.isChangeLocationActive;
    },
    setNewLocation(location) {
      this.newLocation = location;
      if (
        !this.supportedLocations.some(
          supportedLocation => supportedLocation.name === location.name
        )
      ) {
        this.locationWarning = true;
      } else {
        this.locationWarning = false;
      }
    },
    changeLocation: function () {
      this.updateLocation({
        location: this.newLocation,
        transferInventory: this.transferInventory,
      }).then(() => {
        this.refreshSchedule();
        this.toggleChangeLocationModal();
        this.toggleUpdateReservationModal();
        this.newLocation = undefined;
      });
    },
    async toggleUnschedule() {
      await this.unScheduleReservation();
      await this.refreshSchedule();
      this.toggleUpdateReservationModal();
    },
    packageStatus(rp) {
      if (rp === 'on_the_way') return '🚚';
      if (rp === 'in_studio') return '✅';
      if (rp === 'stored') return '🗃';
      if (rp === 'returned') return '🔙';
      return '🗑';
    },
    intakeDate(rp) {
      if (rp.in_studio_date) {
        return dayjs(rp.in_studio_date).format('MM/DD');
      } else if (rp.shipping_provider === 'drop off') {
        return 'drop-off';
      } else if (rp.eta) {
        return dayjs(rp.eta).format('MM/DD');
      } else {
        return 'tbd';
      }
    },
    shootDuration(s) {
      let start = dayjs(s.start);
      let end = dayjs(s.end);
      return dayjs.duration(end.diff(start)).humanize();
    },
    showManualSchedule() {
      this.manualScheduleView = true;
    },
    showGenieView() {
      this.manualScheduleView = false;
    },
    timeZoneName(timezone) {
      var timeZone = timezone;
      let date = new Date();
      let options = {
        timeZone: timeZone,
        timeZoneName: 'short',
        hour: 'numeric',
        minute: 'numeric',
      };
      let shortname = new Intl.DateTimeFormat('en-US', options)
        .formatToParts(date)
        .filter(option => option.type === 'timeZoneName')[0].value;
      return shortname;
    },
    convertTime(time, timezone) {
      if (time && timezone) {
        return `${dayjs(time).tz(timezone).format('h:mma')} ${this.timeZoneName(
          timezone
        )}`;
      } else {
        return '';
      }
    },
    translatePackageTransferStatus(pkg) {
      if (pkg.status === 'pending') {
        return 'needed';
      }
      if (pkg.status === 'initiated') {
        return 'in progress';
      }
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';

.update-reservation-modal {
  z-index: 0;

  .main-modal {
    min-width: 22.5rem;
    max-width: 56.25rem;
    width: 100%;
  }

  .schedule-sticky-top {
    align-items: flex-end;
    background: variables.$white-default;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem;
  }

  .subtitle-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
  }

  .update-reservation-modal-subheader {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
  }

  .update-reservation-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-transform: lowercase;
    width: 100%;

    .sticky-title {
      padding-left: 0.625rem;
      padding-right: 0.625rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 800;
    }

    .shoot-readiness {
      padding: 0.3125rem;
      border-radius: 50%;
      color: variables.$white-default;
      width: 1rem;
      height: 1rem;

      &__low {
        background: variables.$green-apple-50;
      }

      &__basic {
        background: variables.$daisy-50;
      }
      &__medium {
        background: variables.$tangerine-50;
      }
      &__advanced {
        background: variables.$roses-80;
      }
      &__high {
        background: variables.$bubbletape-pink-70;
      }
      &__complex {
        background: variables.$periwink-blue-60;
      }
    }
  }

  .box-main {
    background-color: variables.$white-default;
    color: #3c3c3c;
    display: block;
    padding: 1.25rem;
    overflow-y: auto;
    border-radius: initial;
    .update-reservation-modal-body {
      padding-top: 1.25rem;
    }
  }
  .start-time {
    font-weight: normal;
    text-transform: initial;
  }

  .transfer-alert {
    margin-top: 6.25rem;

    @media (min-width: variables.$screen-sm-min) {
      margin: 6.25rem 6.25rem 2rem 6.25rem;
    }
  }
  .change-location-modal {
    .modal-content {
      margin: 0 auto !important;
      padding: 0 !important;
    }

    .modal-content,
    .modal-card {
      overflow: visible;
    }

    .modal-body-text {
      margin-bottom: 0.5rem;
    }

    label {
      font-weight: 800;
    }

    .transfer-inventory {
      font-weight: 400;
      margin-bottom: 1rem;
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.625rem;
  }
}

.scheduling-view {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shotlist-section,
.tags-section,
.package-section,
.multi-pack-section,
.pso-section {
  padding-top: 0.5rem;
}
</style>
