import * as types from 'src/store/mutation-types';

const state = {
  allTrendSets: {},
  allDeletedTrendSets: [],
  allTrendSetFilters: [],
  trendSetCategoryId: 0,
};

const getters = {};

const mutations = {
  [types.SET_ALL_TREND_SETS](state, allTrendSets) {
    let allTags = {};
    let deleted = [];

    for (const ts of allTrendSets) {
      state.trendSetCategoryId = ts.id;
      for (const fn of ts.filter_names) {
        allTags[fn] = [];
      }

      for (const t of ts.tags) {
        if (t.status === 'discontinued') {
          deleted.push(t);
        } else if (
          Object.prototype.hasOwnProperty.call(allTags, t.filter_names)
        ) {
          // Currently, filter_names is a single string but can be an array in the future.
          // This will need to change if the logic changes
          allTags[t.filter_names].push(t);
        }
      }
    }

    // Remove any filter names that have all deleted tags
    for (const k in allTags) {
      if (
        Object.prototype.hasOwnProperty.call(allTags, k) &&
        !allTags[k].length
      ) {
        delete allTags[k];
      }
    }

    state.allTrendSets = allTags;
    state.allDeletedTrendSets = deleted;
  },
  [types.SET_ALL_TREND_SET_FILTERS](state, allTrendSets) {
    let uniqueFilterNames = [];

    for (const ts of allTrendSets) {
      uniqueFilterNames = uniqueFilterNames.concat(ts.filter_names);
    }

    state.allTrendSetFilters = uniqueFilterNames;
  },
  [types.SET_TREND_SET_CATEGORY_ID](state, id) {
    state.trendSetCategoryId = id;
  },
};

const actions = {
  loadTrendSet(_, params) {
    return this.http
      .get(
        `tag_categories/${params.tag_category_id}/tags/${params.tag_id}.json`
      )
      .then(
        response => {
          return [response.data, null];
        },
        error => [null, error]
      )
      .catch(e => [null, e]);
  },
  loadTrendSets({ commit }) {
    const params = {
      only_trend_set_category: true,
      tag_statuses: ['draft', 'published', 'off', 'discontinued'],
    };
    return this.http
      .get(`tag_categories.json`, { params })
      .then(
        response => {
          commit(types.SET_ALL_TREND_SETS, response.data);
          commit(types.SET_ALL_TREND_SET_FILTERS, response.data);
        },
        error => [null, error]
      )
      .catch(e => [null, e]);
  },
  createTrendSet(_, params) {
    return this.http
      .post(`tag_categories/${params.tag_category}/tags.json`, params)
      .then(
        response => {
          return [response.data, null];
        },
        error => [null, error]
      )
      .catch(e => [null, e]);
  },
  createShotTemplate(_, params) {
    return this.http
      .post(`shot_templates.json`, params)
      .then(
        response => {
          return [response.data, null];
        },
        error => [null, error]
      )
      .catch(e => [null, e]);
  },
  updateTrendSet(_, { trendSetId, trendSetCategoryId, params }) {
    return this.http
      .put(`tag_categories/${trendSetCategoryId}/tags/${trendSetId}.json`, {
        tag: params,
      })
      .then(
        response => [response.data, null],
        error => [null, error]
      )
      .catch(e => [null, e]);
  },
  updateTrendSetStatus(_, { trendSetCategoryId, trendSetId, params }) {
    return this.http
      .put(
        `tag_categories/${trendSetCategoryId}/tags/${trendSetId}/status.json`,
        params
      )
      .then(
        response => [response.data, null],
        error => [null, error]
      )
      .catch(e => [null, e]);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
