<script setup>
import { computed, ref, toRefs } from 'vue';
import AlbumPickerOption from './AlbumPickerOption.vue';
import AlbumPickerCreateNew from './AlbumPickerCreateNew.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useInfiniteAlbumCollections } from '@/queries/album_collections/useAlbumCollections';
import { useMe } from '@/composables/user/useMe';
import { useRouter } from 'vue-router';
import { useCreateAlbumCollection } from '@/queries/album_collections/useCreateAlbumCollection';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import SoonaError from '@/components/ui_library/SoonaError.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  existingOptions: {
    type: Array,
    default: () => [],
  },
  selectedDigitalAssets: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(['cancel', 'select', 'toggleLoader']);

const { accountId } = toRefs(props);

function cancel() {
  emit('cancel');
}

function select(albumId) {
  emit('select', albumId);
}

const {
  data: albumCollectionPages,
  fetchNextPage,
  hasNextPage,
} = useInfiniteAlbumCollections(accountId, {
  itemsPerPage: 5,
});

const albumCollections = computed(() =>
  (albumCollectionPages.value?.pages || []).flatMap(page => page.collections)
);

const createViewIsActive = ref(false);

const router = useRouter();

const redirectToAlbumPage = albumId => {
  router.push({
    name: 'album-assets',
    params: {
      accountId: accountId.value,
      collectionId: albumId,
    },
  });
};

const { currentUserId: authedUserId } = useMe();
const { mutate: createNewAlbum, error: createAlbumError } =
  useCreateAlbumCollection(accountId);

const addDigitalAssetsToNewAlbum = albumTitle => {
  emit('toggleLoader', true);
  createNewAlbum(
    {
      body: {
        account_id: accountId.value,
        title: albumTitle,
        album_collection_digital_assets_attributes:
          props.selectedDigitalAssets.map(da => ({
            digital_asset_id: da.id,
            added_by_user_id: authedUserId.value,
          })),
      },
    },
    {
      onSuccess: newAlbum => {
        redirectToAlbumPage(newAlbum.id);
      },
      onSettled: () => {
        emit('toggleLoader', false);
      },
    }
  );
};

const createAlbum = newAlbumTitle => {
  addDigitalAssetsToNewAlbum(newAlbumTitle);
};

const cancelNewAlbum = () => {
  createViewIsActive.value = false;
};

const priorityErrors = usePriorityErrors(createAlbumError);
</script>

<template>
  <SoonaDialog max-width="48rem" top-affixed @close="cancel">
    <template #heading>add to album</template>
    <template #header>
      <div v-if="$slots.header" class="album-picker__header-slot">
        <slot name="header" />
      </div>
    </template>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-if="!createViewIsActive">
      <SoonaButton
        variation="secondary-gray"
        size="medium"
        class="album-picker__add-album-button"
        data-cypress="button-open-create-album-form"
        @on-click="createViewIsActive = true"
      >
        <SoonaIcon name="plus-large" />
        create new album
      </SoonaButton>
      <div v-if="albumCollections.length > 0" class="album-picker__options">
        <p>or choose existing</p>
        <AlbumPickerOption
          v-for="album in albumCollections"
          :key="album.id"
          :album="album"
          :disabled="existingOptions.includes(album.id)"
          @select="select"
        />
        <div v-if="hasNextPage" class="load-more-button-container">
          <SoonaButton
            variation="secondary-black"
            size="medium"
            @click="fetchNextPage"
          >
            load more
          </SoonaButton>
        </div>
      </div>
    </template>
    <AlbumPickerCreateNew
      v-else-if="createViewIsActive"
      @cancel="cancelNewAlbum"
      @create-album="createAlbum"
    />
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.album-picker {
  &__add-album-button {
    width: 100%;
    margin-bottom: 1.5rem;

    svg {
      color: variables.$gray-60;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    p {
      text-align: center;
      margin-bottom: 1.25rem;
    }
  }
}
</style>
