<script setup>
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import OrganizeMenu from './OrganizeMenu.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useUpdateBagCollection } from '@/queries/bag_collection/useUpdateBagCollection';
import { useBulkCreateAlbumCollectionDigitalAsset } from '@/queries/album_collection_digital_assets/useBulkCreateAlbumCollectionDigitalAsset';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useUpdateFavoritesCollection } from '@/queries/favorites_collection/useUpdateFavoritesCollection';
import { useBulkHideDigitalAssets } from '@/queries/digital_assets/useBulkHideDigitalAssets';
import { useMe } from '@/composables/user/useMe';
import { useCapability } from '@/composables/useCapability';
import { usePurchasedFilesSelected } from './usePurchasedFilesSelected';
import useActionBarMediaQuery from './useActionBarMediaQuery';
import { useReservationGallerySelection } from '@/components/user/anytime/reservation/useReservationGallerySelection';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  isChatOpen: {
    type: Boolean,
    default: false,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
  reservationId: {
    type: [String, Number],
    default: null,
  },
});

const router = useRouter();

const accountId = computed(() => props.accountId);
const { currentUserId: authedUserId } = useMe();
const { hasCapability: canBagAsset } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: accountId,
});

const { selectedReservationDigitalAssets, resetSelection } =
  useReservationGallerySelection();

const cancel = () => {
  resetSelection();
};

const { purchasedFilesSelected } = usePurchasedFilesSelected(
  selectedReservationDigitalAssets
);

// bag collection module
const { data: bagCollection } = useBagCollection(accountId);
const bagCollectionId = computed(() => bagCollection.value?.id);

const {
  mutate: updateBagCollectionAssets,
  isPending: updateBagCollectionAssetsIsLoading,
  error: updateBagCollectionAssetsError,
  reset: resetUpdateBagCollectionAssets,
} = useUpdateBagCollection(accountId, bagCollectionId);

const addToBag = () => {
  const body = selectedReservationDigitalAssets.value
    ?.filter(sf => !sf?.digital_asset?.bag_collection_digital_asset?.id)
    .map(sf => ({
      added_by_user_id: authedUserId.value,
      digital_asset_id: sf.digital_asset.id,
    }));
  updateBagCollectionAssets(
    {
      bag_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        cancel();
      },
    }
  );
};

// album collections module
const {
  mutate: bulkCreateAlbumCollectionDigitalAssets,
  error: updateAlbumCollectionError,
  reset: resetBulkCreateAlbumCollectionDigitalAssets,
} = useBulkCreateAlbumCollectionDigitalAsset();

const bulkAddToAlbum = collectionId => {
  bulkCreateAlbumCollectionDigitalAssets(
    {
      collectionId: collectionId,
      body: {
        digital_asset_ids: selectedReservationDigitalAssets.value.map(
          rda => rda.digital_asset.id
        ),
      },
    },
    {
      onSuccess: () => {
        router.push({
          name: 'album-assets',
          params: {
            accountId: accountId.value,
            collectionId: collectionId,
          },
        });
      },
    }
  );
};

// favorites collection module
const { data: favoriteCollection } = useFavoritesCollection(accountId);
const favoritesCollectionId = computed(() => favoriteCollection.value?.id);

const {
  mutate: updateFavoritesCollectionAssets,
  error: updateFavoritesCollectionAssetsError,
  reset: resetUpdateFavoritesCollectionAssets,
} = useUpdateFavoritesCollection(accountId, favoritesCollectionId);

const addBulkToFavorites = () => {
  const body = selectedReservationDigitalAssets.value
    ?.filter(sf => !sf?.digital_asset?.favorites_collection_digital_asset?.id)
    .map(sf => ({
      added_by_user_id: authedUserId.value,
      digital_asset_id: sf.digital_asset.id,
    }));
  updateFavoritesCollectionAssets(
    {
      favorites_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        cancel();
      },
    }
  );
};

// hide files module
const {
  mutate: hideDigitalAssets,
  error: hideDigitalAssetsError,
  reset: resetHideDigitalAssets,
} = useBulkHideDigitalAssets(accountId);

const bulkHideFiles = () => {
  const ids = selectedReservationDigitalAssets.value.map(
    rda => rda.digital_asset.id
  );
  hideDigitalAssets(ids, {
    onSuccess: () => {
      cancel();
    },
  });
};

// loading state, errors, display, etc.
const { actionBarIsMobile } = useActionBarMediaQuery();

const isIconButton = computed(
  () => actionBarIsMobile.value || props.isChatOpen
);

const numberOfAssetsSelected = computed(
  () => selectedReservationDigitalAssets.value.length
);

watch(numberOfAssetsSelected, newSelectedCount => {
  if (newSelectedCount === 0) {
    resetUpdateBagCollectionAssets();
    resetBulkCreateAlbumCollectionDigitalAssets();
    resetUpdateFavoritesCollectionAssets();
    resetHideDigitalAssets();
  }
});

const isLoading = computed(() => updateBagCollectionAssetsIsLoading.value);

const hasError = computed(() => {
  return [
    updateBagCollectionAssetsError.value,
    updateAlbumCollectionError.value,
    updateFavoritesCollectionAssetsError.value,
    hideDigitalAssetsError.value,
  ].some(err => !!err);
});
</script>

<template>
  <SoonaActionBar
    class="digital-asset-multi-select-action-bar"
    :display="numberOfAssetsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #content>
      <SoonaError
        v-if="hasError"
        no-margin
        class="digital-asset-multi-select-action-bar__error"
      >
        something went wrong. please try again.
      </SoonaError>
      <p class="u-label--heavy">
        {{ numberOfAssetsSelected }} asset{{
          numberOfAssetsSelected > 1 ? 's' : ''
        }}
        selected
      </p>
    </template>
    <template #actions>
      <SoonaButton
        size="medium"
        :variation="isIconButton ? 'icon-transparent' : 'secondary-transparent'"
        :disabled="isLoading"
        @on-click="cancel"
      >
        <SoonaIcon v-if="isIconButton" name="xmark" />
        <span :class="{ 'u-visually-hidden': isIconButton }">cancel</span>
      </SoonaButton>
      <div class="organize-menu">
        <OrganizeMenu
          :account-id="accountId"
          :reservation-id="reservationId"
          :is-icon-button="isIconButton"
          :add-to-album="bulkAddToAlbum"
          :add-to-favorites="addBulkToFavorites"
          @hide="bulkHideFiles"
          @cancel="cancel"
        />
      </div>
      <SoonaButton
        size="medium"
        variation="primary"
        :disabled="!canBagAsset || purchasedFilesSelected"
        :loading="isLoading"
        @on-click="addToBag"
      >
        <SoonaIcon name="soona-bag" />
        add to bag
      </SoonaButton>
    </template>
  </SoonaActionBar>
</template>

<style lang="scss" scoped>
.digital-asset-multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }
}

.organize-menu {
  margin-left: auto;
}
</style>
