import * as types from 'src/store/mutation-types';
import segmentMixin from 'src/mixins/segmentMixin';
import isEmpty from 'lodash/isEmpty';
import { capitalizeWords } from 'src/store/api_helpers/helper-functions';

const state = {
  allTagCategories: [],
  followUpTagCategories: [],
  currentTagCategory: {},
  tagHistory: [],
  selectedTags: [],
  tagsPreLoaded: false,
  tagCategoriesPreloadedIds: [],
};

const getters = {
  currentTagCategorySelectedTags: function (state) {
    return state.currentTagCategory.tags
      ? state.currentTagCategory.tags.filter(t =>
          state.selectedTags.includes(t)
        )
      : [];
  },
};

const mutations = {
  [types.RESET_ALL_TAG_CATEGORIES](state) {
    state.allTagCategories = [];
    state.currentTagCategory = {};
    state.followUpTagCategories = [];
    state.selectedTags = [];
    state.tagHistory = [];
    state.currentTagCategoryComplete = false;
  },
  [types.SELECT_TAG](state, tag) {
    state.selectedTags.push(tag);

    //queue up follow up categories unless they have already been queued by another selected tag
    tag.tag_follow_up_categories.forEach(tfc => {
      let previouslyAddedByOtherTag = state.followUpTagCategories.some(
        tc => tc.id == tfc.tag_category_id
      );
      if (!previouslyAddedByOtherTag) {
        state.followUpTagCategories.push(
          state.allTagCategories.find(tc => tc.id == tfc.tag_category_id)
        );
      }
    });
  },
  [types.DESELECT_TAG](state, tag) {
    let index = state.selectedTags.findIndex(t => t.id == tag.id);
    if (index >= 0) {
      state.selectedTags.splice(index, 1);
    }

    //remove any follow up categories unless they are required by another selected tag
    tag.tag_follow_up_categories.forEach(tfc => {
      let requiredByOtherTag = state.selectedTags.some(t =>
        t.tag_follow_up_categories.some(
          other_tfc => other_tfc.tag_category_id == tfc.tag_category_id
        )
      );
      if (!requiredByOtherTag) {
        let index = state.followUpTagCategories.findIndex(
          tc => tc.id == tfc.tag_category_id
        );
        if (index >= 0) {
          state.followUpTagCategories.splice(index, 1);
        }
      }
    });
  },
  [types.SET_ALL_TAG_CATEGORIES](state, sortedCategories) {
    state.allTagCategories = sortedCategories;
  },
  [types.SET_CURRENT_TAG_CATEGORY](state, currentTC) {
    state.currentTagCategory = currentTC;
  },
  [types.LOAD_NEXT_TAG_CATEGORY](state) {
    state.tagHistory.push(state.currentTagCategory);
    state.followUpTagCategories = state.followUpTagCategories.sort(
      (a, b) => a.order - b.order
    );
    let currentTC = state.followUpTagCategories.shift();
    state.currentTagCategory = currentTC;
  },
  [types.LOAD_PREVIOUS_TAG_CATEGORY](state) {
    state.followUpTagCategories.push(state.currentTagCategory);
    let tagCategory = state.tagHistory.pop();
    state.currentTagCategory = tagCategory;
  },
  [types.UPDATE_TAG_HISTORY](state) {
    state.tagHistory.push(state.currentTagCategory);
  },
  [types.TAGS_PRELOADED_COMPLETE](state) {
    state.tagsPreLoaded = true;
  },
  [types.TAG_CATEGORY_PRELOADED](state, id) {
    state.tagCategoriesPreloadedIds.push(id);
  },
};

const actions = {
  loadTagCategories({ state, commit, dispatch }) {
    if (!isEmpty(state.allTagCategories)) {
      return;
    }

    return this.http.get('tag_categories.json?type=0').then(response => {
      let tagCategories = response.data;
      let sortedCategories = tagCategories.sort((a, b) => a.order - b.order);
      commit(types.SET_ALL_TAG_CATEGORIES, sortedCategories);
      if (!state.currentTagCategory.id) {
        let firstTC = sortedCategories[0];
        commit(types.SET_CURRENT_TAG_CATEGORY, firstTC);
        dispatch('preloadTagCategoryImages', firstTC);
      }
    });
  },
  toggleTag({ state, rootState, getters, dispatch }, tag) {
    // if the toggled tag has already been picked, deselect
    if (
      rootState.reservations.reservation.reservation_tags.find(
        rt => rt.tag_id == tag.id
      )
    ) {
      segmentMixin.methods.$track(
        `Book - ${capitalizeWords(state.currentTagCategory.title)} Deselected`,
        {
          value: tag.title,
          ...segmentMixin.methods.$userAndAccountTraits(rootState.currentUser),
        }
      );

      dispatch('deselectTag', tag);
    } else {
      // if select_type is single, toggle the tag and deselect all the others automatically
      if (state.currentTagCategory.select_type == 'single') {
        getters.currentTagCategorySelectedTags.forEach(t => {
          segmentMixin.methods.$track(
            `Book - ${capitalizeWords(
              state.currentTagCategory.title
            )} Deselected`,
            {
              value: t.title,
              ...segmentMixin.methods.$userAndAccountTraits(
                rootState.currentUser
              ),
            }
          );

          dispatch('deselectTag', t);
        });
      }

      // if not single select, filter out the currentTagCategory tags are in selectedTags
      const selectedTagsOfCurrentTagCategory = state.selectedTags.filter(
        tag => tag.tag_category_id === state.currentTagCategory.id
      );

      // only select the tag if:
      // there is no select_limit, or:
      // you haven't hit the tagCategory's select_limit yet
      if (
        !state.currentTagCategory.select_limit ||
        selectedTagsOfCurrentTagCategory.length <
          state.currentTagCategory.select_limit
      ) {
        segmentMixin.methods.$track(
          `Book - ${capitalizeWords(state.currentTagCategory.title)} Selected`,
          {
            value: tag.title,
            ...segmentMixin.methods.$userAndAccountTraits(
              rootState.currentUser
            ),
          }
        );

        dispatch('selectTag', tag);
      }
    }
  },
  deselectTag({ commit, dispatch }, tag) {
    dispatch('removeReservationTag', { tag, removeAll: true }, { root: true });
    commit(types.DESELECT_TAG, tag);
  },
  selectTag({ commit, dispatch, state }, tag) {
    commit(types.SELECT_TAG, tag);

    let newTagCategories = state.followUpTagCategories.filter(
      futc => !state.tagCategoriesPreloadedIds.includes(futc.id)
    );
    newTagCategories.forEach(tc => dispatch('preloadTagCategoryImages', tc));

    dispatch('addReservationTag', tag, { root: true });
  },
  preloadTagCategoryImages({ commit }, tagCategory) {
    let imageUrls = tagCategory.tags
      .filter(t => t.image_url && t.display_rule != 'false')
      .map(t => t.image_url);
    imageUrls.forEach(imageUrl => (new Image().src = imageUrl));
    commit(types.TAG_CATEGORY_PRELOADED, tagCategory.id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
