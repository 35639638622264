<script setup>
import { toCurrency } from '@/lib/currency';
import { useFlag } from '@/composables/useFlag';

// Props
defineProps({
  isPaymentPending: {
    type: Boolean,
    required: true,
    default: false,
  },
  subtotal: {
    type: Number,
    required: true,
  },
  promoCode: {
    type: String,
    required: false,
  },
  discountTotal: {
    type: Number,
    required: true,
  },
  creditsTotal: {
    type: Number,
    required: true,
  },
  totalAmount: {
    type: Number,
    required: true,
  },
  amountDue: {
    type: Number,
    required: true,
  },
  surchargeAmount: {
    type: Number,
    required: true,
    default: 0,
  },
});

const paymentsSalesTaxFlag = useFlag('payments_sales_tax');
</script>

<template>
  <div class="billing-costs">
    <!-- Billing Costs -->
    <div class="billing-costs__cost">
      <p>Subtotal:</p>
      <p>{{ toCurrency(subtotal) }}</p>
    </div>
    <template v-if="promoCode">
      <div class="billing-costs__cost">
        <p>Promo Code:</p>
        <p>{{ promoCode }}</p>
      </div>
    </template>
    <template v-if="discountTotal !== 0">
      <div class="billing-costs__cost">
        <p>Discounts:</p>
        <p>-{{ toCurrency(discountTotal) }}</p>
      </div>
    </template>
    <template v-if="creditsTotal !== 0">
      <div class="billing-costs__cost">
        <p>Credits:</p>
        <p>-{{ toCurrency(creditsTotal) }}</p>
      </div>
    </template>
    <template v-if="paymentsSalesTaxFlag">
      <div class="billing-costs__cost">
        <p>Sales tax:</p>
        <p v-if="isPaymentPending">sales tax calculated at checkout</p>
        <p v-else>{{ toCurrency(surchargeAmount) }}</p>
      </div>
    </template>
    <div class="billing-costs__cost">
      <p>Total Amount:</p>
      <p>{{ toCurrency(totalAmount) }}</p>
    </div>
    <hr class="billing-costs__cost-break" />
    <div class="billing-costs__amount-due">
      <p class="u-body--heavy">Amount Due</p>
      <p>{{ toCurrency(amountDue) }} USD</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.billing-costs {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__cost {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    p {
      @include variables_fonts.u-small--regular;
    }
  }

  &__cost-break {
    margin: 0.875rem 0;
  }

  &__amount-due {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;

    p {
      @include variables_fonts.u-label--heavy;
    }
  }
}

@mixin billing-costs-desktop-styles {
  .billing-costs {
    // Billing Costs
    &__cost {
      p {
        @include variables_fonts.u-label--regular;
      }
    }
  }
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 39.5rem) {
    @include billing-costs-desktop-styles;
  }
}

@container order-view (min-width: 37.5rem) {
  @include billing-costs-desktop-styles;
}

// Print Styles
@media print {
  .billing-costs {
    align-items: flex-end;

    &__cost {
      &:first-child {
        margin-top: 2rem;
        padding-top: 0.75rem;
        border-top: 1px solid variables.$gray-30;
      }
    }

    &__cost,
    &__amount-due {
      width: 50%;
    }

    &__cost-break {
      display: none;
    }

    &__amount-due {
      padding: 0.75rem 0;
      margin-top: 0.25rem;
      border-top: 1px solid variables.$gray-30;
      border-bottom: 1px solid variables.$gray-30;
    }
  }
}
</style>
