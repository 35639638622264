import { computed, ref } from 'vue';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { useCreateListingActionDigitalAsset } from '@/queries/useListingActionDigitalAssets';
import { useUpdateListingAction } from '@/queries/useListingActions';
import { useRouter } from 'vue-router';
import { useCreatePublishTask } from '@/queries/useDigitalAssetPublishTasks';

export function useLifestyleActions(
  accountId,
  listingAction,
  { externalProductId = null, integrationId = null }
) {
  const router = useRouter();

  const {
    canTakeAction,
    availableCreditsError,
    optimizeButtonVariation,
    isBasicUserAndOutOfCredits,
    userCanUpgrade,
  } = useSubscriptionActionPermissions(accountId);

  const catalogItemId = computed(() => {
    return listingAction.value.catalog_item_id;
  });

  const listingActionId = computed(() => listingAction.value.id);

  const { mutate: updateListingAction, error: updateListingActionError } =
    useUpdateListingAction(accountId, listingActionId);

  const linkSourceAsset = digitalAssetId => {
    updateListingAction(
      {
        source_asset_id: digitalAssetId,
      },
      {
        onSuccess: data => {
          router.push({
            name: 'listing-action-media-view',
            params: {
              accountId: accountId.value,
              digitalAssetId: data.source_asset_id,
              listingActionId: listingAction.value.id,
            },
            query: {
              tool: 'ai-scenes',
            },
          });
        },
      }
    );
  };

  // reset listing action module
  const resetListingAction = () => {
    updateListingAction({
      status: 'pending_approval',
    });
  };

  // upload image module
  const isProcessing = ref(false);
  const showUploadDialog = ref(false);
  const {
    mutate: createListingActionDigitalAsset,
    error: createListingActionDigitalAssetError,
  } = useCreateListingActionDigitalAsset(accountId, {
    externalProductId,
    integrationId,
  });

  const { mutate: createDigitalAsset, error: createDigitalAssetError } =
    useCreateDigitalAsset(accountId);

  const linkUploadedAsset = digitalAssetId => {
    createListingActionDigitalAsset(
      {
        digital_asset_id: digitalAssetId,
        listing_action_id: listingAction.value.id,
      },
      {
        onSuccess: async () => {
          isProcessing.value = false;
        },
        onError: () => {
          isProcessing.value = false;
        },
      }
    );
  };

  const handleUploadComplete = blob => {
    isProcessing.value = true;
    showUploadDialog.value = false;

    const digitalAsset = {
      asset_type: 'original',
      file: blob.signed_id,
      title: blob.filename,
      media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
      ownership: 'customer',
      origin: 'customer',
      origin_source: 'manual_upload',
      visibility: 'all',
    };

    createDigitalAsset(digitalAsset, {
      onSuccess: data => {
        linkUploadedAsset(data.id);
      },
      onError: () => {
        isProcessing.value = false;
      },
    });
  };

  // publish module
  const publishTriggered = ref(false);
  const isPublishing = computed(() => {
    if (listingAction.value.most_recent_publish_task) {
      return listingAction.value.most_recent_publish_task.processing;
    } else {
      return publishTriggered.value;
    }
  });

  const requiresManualImagePositionSelection = computed(() => {
    const { integration_type, active_listing_image_count } =
      listingAction.value;

    if (integration_type !== 'AmazonIntegration') {
      return false;
    }

    return !active_listing_image_count || active_listing_image_count > 8;
  });

  const { mutate: createPublishTask, error: createPublishTaskError } =
    useCreatePublishTask(accountId, {
      externalProductId,
      integrationId,
    });

  const publish = manuallySelectedSlot => {
    publishTriggered.value = true;
    const activeImageCount =
      listingAction.value.active_listing_image_count ?? 0;
    const lastAvailableImagePosition = activeImageCount + 1;
    createPublishTask(
      {
        publish_assets: [
          {
            digital_asset_id: listingAction.value.replacement_asset.id,
            external_product_id: listingAction.value.external_product_id,
            image_position: manuallySelectedSlot ?? lastAvailableImagePosition,
          },
        ],
      },
      {
        onSuccess: () => {
          publishTriggered.value = false;
        },
        onError: () => {
          updateListingAction({
            status: 'failed_to_publish',
          });
          publishTriggered.value = false;
        },
      }
    );
  };

  const downloadReplacementAsset = () => {
    if (listingAction.value?.replacement_asset?.file_url) {
      window.open(listingAction.value.replacement_asset.file_url, '_blank');
    }
  };

  return {
    canTakeAction,
    userCanUpgrade,
    availableCreditsError,
    catalogItemId,
    optimizeButtonVariation,
    isBasicUserAndOutOfCredits,
    linkSourceAsset,
    isProcessing,
    showUploadDialog,
    handleUploadComplete,
    createListingActionDigitalAssetError,
    createDigitalAssetError,
    resetListingAction,
    updateListingActionError,
    requiresManualImagePositionSelection,
    createPublishTaskError,
    isPublishing,
    publish,
    downloadReplacementAsset,
  };
}
